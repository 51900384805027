import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { Flex, VStack, Button, useDisclosure } from '@chakra-ui/react';
import Tile from 'app/modules/CreditStepper/SyntheseStep/Tile';
import PalierRecap from './PalierRecap';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CreditAccordion = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const format = (x: number, suffix: string) => {
    return new Intl.NumberFormat('MA', {
      style: 'currency',
      currency: suffix,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(x);
  };
  return (
    <div>
      <Accordion className="mx-6">
        <AccordionSummary
          className="bg-gray-400"
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {' '}
            <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>Crédit {props.index} : {props.produit.nomProduit}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-100 border-t border-gray-200">
          <Typography className="w-100">
            <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
              <Flex>
                <VStack flex="1" align="left" className="">
                  <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
                    <span className="border-b-4 border-blue-800 ">Synthèse du crédit</span>
                  </h4>
                  <Tile label="Montant du crédit" value={format(props.produit?.montant, 'MAD')} />
                  <Tile label="Durée du crédit" value={props.produit.dureeMois + ' mois'} />
                  <Tile label="Type de taux" value={props.produit.typeTaux} />
                  <Tile label="Taux" value={props.produit.taux + ' %'} />
                  {props.produit.isConvention && <Tile label="Convention" value={props.produit.convention} />}
                </VStack>

                <VStack flex="1" align="left" className="">
                  <div>
                    <div className="grid justify-items-end ">
                      <div>
                        {' '}
                        {props.produit.isFranchise ? (
                          <div>
                            <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 mb-2">
                              <span className="border-b-4 border-green-600 ">Franchise</span>
                            </h4>

                            <Tile label="Type de Franchise" value={props.produit.typeFranchise === 'T' ? 'Totale' : 'Partielle'} />

                            <Tile label="Durée Franchise" value={props.produit.dureeFranchise + ' mois'} />
                          </div>
                        ) : null}
                      </div>

                      <div>
                        {' '}
                        <Button colorScheme="blue" variant="outline" onClick={onOpen}>
                          Details Paliers
                        </Button>
                      </div>
                    </div>
                  </div>
                </VStack>
              </Flex>
            </div>
          </Typography>
        </AccordionDetails>
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className="">
                {' '}
                <i className="fal fa-file-invoice mx-2"> </i> Assurance
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-100  border border-gray-200">
              <Typography className="w-100">
                <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                  <Flex></Flex>
                </div>
                <Tile label="Type d'assurance" value={props.produit.typeAssurance} />
                <Tile
                  label={(props.produit.typeAssurance === 'MONTANT' ? 'Montant' : 'Taux') + ' de l’assurance:'}
                  value={
                    props.produit.typeAssurance === 'MONTANT'
                      ? format(props.produit.valueAssurance, 'MAD')
                      : props.produit.valueAssurance + '%'
                  }
                />
                <Tile label="Frais de Dossier" value={format(props.produit.fraisDossier, 'MAD')} />
                <Tile label="Nature Frais de dossier" value={props.produit.natureFDossier} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Accordion>

      <PalierRecap onClose={onClose} isOpen={isOpen} paliers={props.produit.paliers} />
    </div>
  );
};
