//TODO: interface properties has no meaning if they are declared optional

import { IClient } from './client.model';

export interface IDemandeSAV {
  id?: number;
  balanceAfterEvent?: number;
  balanceBeforeEvent?: number;
  durationRemainingAfterEvent?: number;
  durationRemainingBeforeEvent?: number;
  amountRepaymentAfterEvent?: number;
  amountRepaymentBeforeEvent?: number;
  firstAmountRepaymentAfterEvent?: number;
  dateOfNextRepaymentAfterEvent?: number;
  capitalRestantDu?: number;
  interest?: number;
  taxeOnInterest?: number;
  amountPenalty?: number;
  taxeOnPenalty?: number;
  amountFees?: number;
  taxeOnFees?: number;
  tegAfterEvent?: number;
  savInputSimulationDTO?: any;
  echeancier?: any[];
  savSimulationResultId?: any;
  userId?: any;
  codeBanque?: any;
  nomBanque?: any;
  codeAgence?: any;
  // nomAgence?: any;
  statut?: any;
  dateDemande?: any;
  message?: any;
}

export const defaultValue: Readonly<IDemandeSAV> = {};
