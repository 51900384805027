import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormattedInput3 from '../../commonComponents/FormatedInputs/FormattedInput3';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { editCaracteristiqueCredit } from '../Reducers/syntheseCreditSlice';

const ConsoForm = props => {
  const dispatch = useAppDispatch();

  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const handleOnTypeCreditChange = (champ, formattedValue) => {
    dispatch(editCaracteristiqueCredit({ champ, inputValue: formattedValue }));
  };

  return (
    <dl className="animate__animated animate__fadeInDown  ml-12 2xl:ml-20 2xl:w-3/4 ">
      <div className="row">
        <div className="col-6 col-xl-6 mt-2">
          <div className="row">
            <div className="col-4 ">
              <dt className=" block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Objet du Crédit : </dt>
            </div>
            <div className="col-8 ">
              <RadioGroup
                aria-label="Objet du Crédit"
                name="objetCredit"
                value={simulationCredit.objetCredit}
                onChange={e => handleOnTypeCreditChange('objetCredit', e.target.value)}
                row
              >
                <FormControlLabel value="RACHAT" control={<Radio />} label="Rachat de crédit" />
                <FormControlLabel value="PERSONNEL" control={<Radio />} label="Besoins personnels" />
              </RadioGroup>
            </div>
          </div>{' '}
        </div>
        <div className="col-6 col-xl-6">
          <FormattedInput3
            label={'Montant du Crédit'}
            isAllowed={undefined}
            value={simulationCredit.mntCrdt}
            onChange={values => {
              const { formattedValue, value } = values;
              handleOnTypeCreditChange('mntCrdt', value);
            }}
            readOnly={false}
            errorCarac={props.errorCarac}
            ide={'ide4'}
          />
        </div>
      </div>

      <dl></dl>
      <hr />
    </dl>
  );
};

export default ConsoForm;
