import React from 'react';

const infoTile = ({ label, value }) => {
  return (
    <li className="row my-1">
      <span className="col-6 text-sm font-bold text-black-500">{label}</span> <div className="col-6">{value}</div>{' '}
    </li>
  );
};

export default infoTile;
