import axios from 'axios';

class UploadFilesService {
  upload(file, name, selectedTypePiece, type, demandeId, onUploadProgress) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('name', name);
    formData.append('selectedTypePiece', selectedTypePiece);
    formData.append('type', type);
    formData.append('demandeId', demandeId);

    return axios.post('api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }
  getFiles() {
    return axios.get('api/files', { params: { demandeId: 111 } });
  }
}
export const UploadService = new UploadFilesService();
