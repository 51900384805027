import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useAppSelector } from 'app/config/store';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import { BrandIcon } from 'app/shared/layout/header/header-components';

type PropsType = {
  demandeId: number;
};

const EcheancierSavPDF = React.forwardRef<HTMLDivElement, any>(({ produit, ta }, ref) => {
  const HEADER_TITLES = ['N°', 'Échéance', 'Capital', 'Assurance', 'Frais', 'Intérêts', 'Date'];

  const displayDemandes = () => {
    var x = null;
    return ta?.map((demande, index) => {
      var v = demande.termDate.toString().substring(0, 4) !== x;
      if (v) {
        x = demande.termDate.toString().substring(0, 4);
      }
      return (
        <tr key={demande.id}>
          <td>{index + 1}</td>
          <td>{demande.mntEcheance}</td>
          <td> {demande.mntCapital}</td>
          <td>{demande.mntAssurance}</td>
          <td>{demande.mntFrais}</td>
          <td>{demande.mntInteret}</td>
          <td>
            <Text fontSize="md" color={v ? 'blue' : ''} fontWeight={v ? 'extrabold' : ''}>
              {demande.termDate.toString().substring(0, 10)}
            </Text>
          </td>
          <td></td>
        </tr>
      );
    });
  };

  return (
    <div ref={ref} className="w-full">
      <div className="ml-8 mt-8 mb-8 brandpdf-logo" style={{ width: 'auto', height: '50px' }}>
        {' '}
        <BrandIcon />{' '}
      </div>
      <div className="flex justify-center ">
        <Text as="h3" className="my-24" fontSize="xl" fontWeight="extrabold">
          Évenement SAV:"{produit}"
        </Text>
      </div>
      <Text className="ml-8 mb-4" fontSize="md" color="">
        Tableau d'amortissement
      </Text>
      <div className="pngcontainer1">
        <div>
          <table className="ml-8 table table-auto table-bordered">
            <thead>
              <tr>
                {HEADER_TITLES.map(title => (
                  <th className="p-3" key={title}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody id="myTable"> {displayDemandes()}</tbody>
          </table>
        </div>
        <div>
          <img
            style={{ paddingRight: '100px', width: 'auto', height: '800px' }}
            src="https://pbs.twimg.com/profile_images/1229797275876151301/fSVPBcPP_400x400.png"
          />
        </div>
      </div>
    </div>
  );
});

export default EcheancierSavPDF;
