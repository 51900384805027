import dayjs from 'dayjs';
import { ISimulation } from 'app/shared/model/simulation.model';
import { ISimulationSav } from 'app/shared/model/simulation-sav.model';
import { TypePieceJustificatif } from 'app/shared/model/enumerations/type-piece-justificatif.model';
import { StatutPJustif } from 'app/shared/model/enumerations/statut-p-justif.model';

export interface IPieceJustificatif {
  id?: number;
  emplacement?: string | null;
  type?: TypePieceJustificatif | null;
  libele?: string | null;
  statut?: StatutPJustif | null;
  modifie?: string | null;
  modifiePar?: string | null;
  simulation?: ISimulation | null;
  simulationSav?: ISimulationSav | null;
}

export const defaultValue: Readonly<IPieceJustificatif> = {};
