import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PieceJustificatif from './piece-justificatif';
import Client from './client';
import Credit from './credit';
import { PJ } from './PJ/PJ';
import SimulationSav from './simulation-sav';
import StepperPage from 'app/modules/CreditStepper/MainSteppers/StepperPage';
import StepperPageCorpo from 'app/modules/CreditStepper/MainSteppers/StepperPageCorpo';
import SavStepperPage from 'app/modules/SavStepper/MainStepper/SavStepperPage';

import { ThemeProvider } from '@material-ui/core/styles';
import SuiviDemande from './taches2/suiviDemande';
import DelegateTaches from './taches2/delegateTaches';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}piece-justificatif`} component={PieceJustificatif} />
      <ErrorBoundaryRoute path={`${match.url}client`} component={Client} />
      <ErrorBoundaryRoute path={`${match.url}credit`} component={Credit} />
      <ErrorBoundaryRoute path={`${match.url}PJ`} component={PJ} />
      <ErrorBoundaryRoute path={`${match.url}simulation-sav`} component={SimulationSav} />
      <ErrorBoundaryRoute path={`${match.url}step`} component={StepperPage} />
      <ErrorBoundaryRoute path={`${match.url}SAV`} component={SavStepperPage} />
      <ErrorBoundaryRoute path={`${match.url}stepCorpo`} component={StepperPageCorpo} />
      {/* <ErrorBoundaryRoute path={`${match.url}testing/:simulationId`} component={Testing} /> */}
      <ErrorBoundaryRoute path={`${match.url}testing/:idDemandeSav`} component={SuiviDemande} />
      <ErrorBoundaryRoute path={`${match.url}delegateTaches`} component={DelegateTaches} />

      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
