import {
  Stack,
  Box,
  Flex,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import FicheClient from 'app/modules/CreditStepper/IdentificationDuTiers/Client/FicheClient';
import Steps, { Step } from 'rc-steps';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Button } from '@chakra-ui/react';
import { identifyClient } from '../client/client.reducer';
import { PJAccordion2 } from '../testing/PJAccordion';
import { PJAccordion } from 'app/modules/CreditStepper/ResultatStep/Accordions/PJAccordion';
import Tile from 'app/modules/CreditStepper/SyntheseStep/Tile';
import { propositionRest } from 'app/modules/CreditStepper/Reducers/syntheseCreditSlice';
import { creatEventInLS, getEntity } from 'app/entities/demande/demandeSAV.reducer';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { getStatus } from '../testing/decisionMaking';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import {
  aModifierActiveEntity,
  fetchDemandeId,
  getActiveEntity,
  getEntityTask,
  getHistoryEntities,
  getHistoryEntitiesByDemandeId,
  getTasks,
  rejectActiveEntity,
  validateActiveEntity,
} from './taches.reducer';
import moment from 'moment';
import { ITache2 } from 'app/shared/model/tachecopy.model';
import { ResultAccordionSAV } from 'app/modules/ListeDemandesSAV/detail/Accordions/SAVResultAccordion';
import MessageLS from './messageLS';

const CompleteTache = () => {
  const dispatch = useAppDispatch();
  const ActiveTache = useAppSelector(state => state.taches2.entity);
  const HistTache   = useAppSelector(state => state.taches2.history);
  const user        = useAppSelector(state => state.authentication.account);
  const demandeSAV  = useAppSelector(state => state.demandeSAV.entity);

  const { isOpen : messageIsOpen, onOpen : messageOnOpen, onClose :messageOnclose } = useDisclosure();
  const { isOpen : confirmIsOpen, onOpen : confirmOnOpen, onClose : confirmOnClose } = useDisclosure();

  // store if request to ls is fullfiled or not
  const [isLoading, setIsLoading] = useState(false);

  // store Ls retour
  const [retourLS, setRetourLS] = useState<any>();


  // store decision valider || refuser || modifier
  const [decisionMade, setDecisionMade] = useState('');

  // store value of comment input
  const [commentValue, setCommentValue] = React.useState('');
  const handleChange = event => {
    setCommentValue(event.target.value);
  };

  

  // get step from url this represent which tache are we talking about
  const { idTache }: { idTache: string } = useParams();
  // get step from url this represent which process are we talking about
  const { idDemandeSav }: { idDemandeSav: string } = useParams();

  let role = 'none';

  if (user.authorities[0] === 'ROLE_USER' && user.authorities.length > 1) {
    role = user.authorities[1];
  } else if (user.authorities.length > 1) {
    role = user.authorities[0];
  }

  useEffect(() => {
    dispatch(getSession()); // get user info
    dispatch(getEntityTask(idTache)); // get task info
    dispatch(getHistoryEntitiesByDemandeId(idDemandeSav)); // get hist interventions
    dispatch(getEntity(parseInt(idDemandeSav, 10))); // get synthese simulation sav
  }, []);

  // get info of client id == 1
  dispatch(propositionRest());
  dispatch(identifyClient(1));

  const handlevalide = () => {
    dispatch(
      validateActiveEntity({
        taskId: ActiveTache.id,
        user_name: user.login,
        comment: commentValue,
      })
    );
  };

  // handle rejection
  const handleRefus = () => {
    dispatch(
      rejectActiveEntity({
        taskId: ActiveTache.id,
        user_name: user.login,
        comment: commentValue,
      })
    );
  };

  //  handle modifier
  const handleModif = () => {
    dispatch(
      aModifierActiveEntity({
        taskId: ActiveTache.id,
        user_name: user.login,
        comment: commentValue,
      })
    );
  };

  const createEventInLS = () => {
    confirmOnClose();
    // create event in LS
    dispatch(creatEventInLS(demandeSAV)).then(response=>{
      setRetourLS(response.payload);
      if(response.payload.code_Retour === "OK"){
        handlevalide();
        setCommentValue('');
      }
    }).catch(error => {
      setRetourLS("erreur technique");
    })
    messageOnOpen();
  }

  const handleDecision = () => {
    if (decisionMade === 'valider') {
      handlevalide();
    } else if (decisionMade === 'refuser') {
      handleRefus();
    } else if (decisionMade === 'modifier') {
      handleModif();
    } else if (decisionMade === "Créer événement sur LS") {
      createEventInLS();
    } 

    confirmOnClose();
    setCommentValue('');
    dispatch(getTasks(role));
  };

  

  const gethist = () => {
    return (
      <Box className={'mx-6'}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '15%' }}>Tâche</TableCell>
                <TableCell align="right" style={{ width: '25%' }}>
                  Commentaire
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Duration
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Modifiée le
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Modifiée par
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Statut
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {HistTache.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" style={{ verticalAlign: 'center' }}>
                    {row.task.name}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {row.comment}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {moment.duration(row.task.durationInMillis, 'milliseconds').days() + 'J '}
                    {moment.duration(row.task.durationInMillis, 'milliseconds').hours() + 'h '}
                    {moment.duration(row.task.durationInMillis, 'milliseconds').minutes() + 'min'}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {moment(row.task.endTime).format('DD/MM/YYYY à hh:mm:ss')}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {row.task.assignee}
                  </TableCell>
                  <TableCell align="center" style={{ verticalAlign: 'center' }}>
                    {getStatus(
                      row.task.description === 'validée'
                        ? 1
                        : row.task.description === 'refusée'
                        ? 0
                        : row.task.description === 'a modifier'
                        ? 3
                        : row.task.description === 'annulée'
                        ? 6
                        : 5
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const getContent = (step: string) => {
    if (step === 'Activity_valide0' || step === 'Soumettre_decision' || step === 'Soumettre_a_OGS') {
      return (
        <Box>
          <br />
          <FicheClient expand={false} id={1}/>

          <br />
          <ResultAccordionSAV idDemandeSav={idDemandeSav} />

          <br />
          <PJAccordion title={'Pièces Justificatives'} resultat={undefined} idDemande={idDemandeSav} canAdd={true}/>

          {/* get past decisions */}
          <br />
          {HistTache.length > 0 && gethist()}

          <br />
          {ActiveTache.endTime === null && (
            <div className="my-4 d-flex mx-5" style={{ display: 'flex', justifyContent: 'right' }}>
              {step !== 'Soumettre_a_OGS' && (
                <Button
                  className="mx-4"
                  onClick={() => {
                    setDecisionMade('refuser');
                    confirmOnOpen();
                  }}
                >
                  Refuser
                </Button>
              )}
              <Button
                className="mx-4"
                colorScheme="teal"
                onClick={() => {
                  setDecisionMade('valider');
                  confirmOnOpen();
                }}
              >
                Valider
              </Button>
            </div>
          )}
        </Box>
      );
    } else if (step === 'Activity_verif1' || step === 'Decision_delegataire') {
      return (
        <Box>
          <br />
          <FicheClient expand={false} id={1}/>

          <br />
          <ResultAccordionSAV idDemandeSav={idDemandeSav} />

          <br />
          <PJAccordion2
            title={'Pièces Justificatives'}
            isApprouved={ActiveTache.status === 'a decider' ? 1 : ActiveTache.status === 'annulée' ? 0 : 2}
          />

          <br />
          <Accordion className={'mx-6'}>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
              <Typography className="">
                <i className="fal fa-file-invoice mx-2"> </i>
                Scoring et engagement
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-100  border border-gray-200">
              <Typography className="w-100">add something</Typography>
            </AccordionDetails>
          </Accordion>

          {/* get past decisions */}
          <br />
          {HistTache.length !== 0 && gethist()}

          <br />
          {ActiveTache.endTime === null && (
            <div className="my-4 d-flex mx-5" style={{ display: 'flex', justifyContent: 'right' }}>
              <Button
                className="mx-4"
                variant="outline"
                onClick={() => {
                  setDecisionMade('refuser');
                  confirmOnOpen();
                }}
              >
                Refuser
              </Button>
              <Button
                className="mx-4"
                colorScheme="orange"
                variant="outline"
                onClick={() => {
                  setDecisionMade('modifier');
                  confirmOnOpen();
                }}
              >
                A modifier
              </Button>
              <Button
                className="mx-4"
                colorScheme="teal"
                onClick={() => {
                  setDecisionMade('valider');
                  confirmOnOpen();
                }}
              >
                Valider
              </Button>
            </div>
          )}
        </Box>
      );
    } else if (step === 'Activity_verif2' || step === 'Controle_OGS_NOTIF') {
      return (
        <Box>
          <br />
          <FicheClient expand={false} id={1}/>

          <br />
          <ResultAccordionSAV idDemandeSav={idDemandeSav} />

          <br />
          <PJAccordion2
            title={'Pièces Justificatives'}
            isApprouved={ActiveTache.status === 'a decider' ? 1 : ActiveTache.status === 'annulée' ? 0 : 2}
          />

          <br />
          <Accordion className={'mx-6'}>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
              <Typography className="">
                <i className="fal fa-file-invoice mx-2"> </i>
                Scoring et engagement
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-100  border border-gray-200">
              <Typography className="w-100">add something</Typography>
            </AccordionDetails>
          </Accordion>

          <br />
          {HistTache.length !== 0 && gethist()}

          <br />
          {ActiveTache.endTime === null && (
            <div className="my-4 d-flex mx-5" style={{ display: 'flex', justifyContent: 'right' }}>
              {step === 'Activity_verif2' && (
                <Button
                  className="mx-4"
                  variant="outline"
                  onClick={() => {
                    setDecisionMade('refuser');
                    confirmOnOpen();
                  }}
                >
                  Refuser
                </Button>
              )}
              <Button
                className="mx-4"
                colorScheme="orange"
                variant="outline"
                onClick={() => {
                  setDecisionMade('modifier');
                  confirmOnOpen();
                }}
              >
                A modifier
              </Button>
              <Button
                className="mx-4"
                colorScheme="teal"
                onClick={() => {
                  setDecisionMade('valider');
                  confirmOnOpen();
                }}
              >
                Valider
              </Button>
            </div>
          )}
        </Box>
      );
    } else if (step === 'Controle_OGS_MEG') {
      return (
        <Box>
          <br />
          <FicheClient expand={false} id={1}/>

          <br />
          <ResultAccordionSAV idDemandeSav={idDemandeSav} />

          <br />
          <PJAccordion2
            title={'Pièces Justificatives'}
            isApprouved={ActiveTache.status === 'a decider' ? 1 : ActiveTache.status === 'annulée' ? 0 : 2}
          />

          <br />
          <Accordion className={'mx-6'}>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
              <Typography className="">
                <i className="fal fa-file-invoice mx-2"> </i>
                Scoring et engagement
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-100  border border-gray-200">
              <Typography className="w-100">add something</Typography>
            </AccordionDetails>
          </Accordion>

          <br />
          {HistTache.length !== 0 && gethist()}

          <br />
          {ActiveTache.endTime === null && (
            <div className="my-4 d-flex mx-5" style={{ display: 'flex', justifyContent: 'right' }}>
              <Button
                className="mx-4"
                colorScheme="orange"
                variant="outline"
                onClick={() => {
                  setDecisionMade('modifier');
                  confirmOnOpen();
                }}
              >
                A modifier
              </Button>
              <Button
                className="mx-4"
                colorScheme="telegram"
                onClick={() => {
                  setDecisionMade("Créer événement sur LS");
                  confirmOnOpen();
                }}
              >
                Créer événement sur LS
              </Button>
            </div>
          )}
        </Box>
      );
    } else {
      return <>nothing to show here move on man</>;
    }
  };

  return (
    <>
      <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
        <Box p={5} shadow="base" borderWidth="3px">
          <div className="col-10 p-8" style={{ margin: '0 auto' }}>
            <Steps
              direction="horizontal"
              current={
                ActiveTache.taskDefinitionKey === 'Activity_valide0' || ActiveTache.taskDefinitionKey === 'Soumettre_decision' ? 1 : 2
              }
            >
              <Step title="En cours de saisie" />
              <Step title="Documents validés" />
              <Step title="À valider" />
              <Step title="Demande clôturée" />
            </Steps>
          </div>

          <div className="pt-8" style={{ margin: '0 auto' }}>
            <h2 className="text-md text-gray-900 text-left ml-1">
            <span className="border-b-4 border-blue-800">{ActiveTache.name}</span>
            </h2>
            {getContent(ActiveTache.taskDefinitionKey)}
          </div>

          <Modal onClose={confirmOnClose} isOpen={confirmIsOpen} isCentered>
            <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
            <ModalContent>
              <ModalHeader>Ajouter un commentaire :</ModalHeader>
              <ModalCloseButton />

              <ModalBody className="">
                <div className="">
                  <div className={'d-flex items-center container'}>
                    <div className="row">
                      <div className="col-12">
                        <TextareaAutosize
                          minRows={6}
                          maxRows={6}
                          cols={45}
                          style={{ boxSizing: 'border-box' }}
                          placeholder="Votre Commentaire Ici ..."
                          defaultValue={commentValue}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-2">
                        <Link to={decisionMade === "Créer événement sur LS"? null :'/tab'}>
                          <Button
                            colorScheme={decisionMade === 'valider' ? 'teal' : decisionMade === 'refuser' ? 'red' : decisionMade === 'modifier' ? 'orange' : 'telegram'}
                            onClick={() => {
                              handleDecision();
                            }}
                          >
                            {decisionMade}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>

          <MessageLS 
            messageIsOpen={messageIsOpen} 
            messageOnOpen={messageOnOpen} 
            messageOnclose={messageOnclose}
            retourLS={retourLS}
          />
        </Box>
      </Stack>
    </>
  );
};

export default CompleteTache;
