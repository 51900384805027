import React from 'react';

const Tile = ({ label, value }) => {
  return (
    <div className="py-2 sm:py-2 sm:grid sm:grid-cols-3  sm:px-2">
      <dt className="text-sm font-bold text-gray-500">{label}</dt>
      <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
    </div>
  );
};

export default Tile;
