import React, { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/simulation/simulation.reducer';
import 'react-awesome-lightbox/build/style.css';
import Lightbox from 'react-awesome-lightbox';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import PDFViewer from 'pdf-viewer-reactjs';
import Modal from 'styled-react-modal';

import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  PropTypes,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { getPieceJustificatifsBySimulationId } from 'app/entities/piece-justificatif/piece-justificatif.reducer';
import { Link } from 'react-router-dom';

/* eslint-disable no-console */
export const PJ = () => {
  const dispatch = useAppDispatch();
  const simulationList = useAppSelector(state => state.simulation.entities);
  // const loading = useAppSelector(state => state.client.loading);
  useEffect(() => {
    dispatch(getEntities({}));
  }, []);
  //const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => {
    return simulationList;
  }, [simulationList]);

  return <CollapsibleTable data={data} />;
};
const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition : all 0.3s ease-in-out;`;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useAppDispatch();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const PieceJustificatifList = useAppSelector(state => state.pieceJustificatif.entities);
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [PdfUrl, setPdfUrl] = useState(''); // workaround LightBox int
  const [imgUrl, setImageUrl] = useState(''); // workaround LightBox int
  const PJList = useMemo(() => {
    return PieceJustificatifList;
  }, [PieceJustificatifList]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            color="primary"
            aria-label="expand row"
            onClick={() => {
              !open ? dispatch(getPieceJustificatifsBySimulationId(row.id)) : null;
              setOpen(!open);
              console.log(PieceJustificatifList);
            }}
          >
            {open ? <i className="fal fa-arrow-up"> </i> : <i className="fal fa-arrow-down"> </i>}
          </IconButton>
          <IconButton color="primary" aria-label="expand row" className="mx-3">
            <Link to="/">
              {' '}
              <i className="fal fa-file-user "> </i>
            </Link>
          </IconButton>
        </TableCell>
        <TableCell align="left"> </TableCell>

        <TableCell component="th" scope="row">
          {row.client.id}
        </TableCell>
        <TableCell align="right">{row.client.nom + row.client.prenom}</TableCell>
        <TableCell align="right">{row.libProduit}</TableCell>
        <TableCell align="right">{row.etat}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Piece Justificatifs
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell>Document</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell align="right">Modifie</TableCell>
                    <TableCell align="right">Modifie Par</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PJList !== undefined ? (
                    PJList.map((Piece, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          {/* ICONBUTTN */}
                          {Piece.type === 'IMAGE' ? (
                            <IconButton
                              color="primary"
                              className="mx-2"
                              onClick={() => {
                                setImageUrl(Piece.emplacement);
                                setOpenImgModal(!openImgModal);
                              }}
                            >
                              <i className="fal fa-images"> </i>
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              className="mx-2"
                              onClick={() => {
                                setPdfUrl(Piece.emplacement);
                                setOpenPDFModal(!openImgModal);
                              }}
                            >
                              <i className="fal fa-file-pdf"> </i>
                            </IconButton>
                          )}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {Piece.libele}
                        </TableCell>
                        <TableCell>{Piece.statut}</TableCell>
                        <TableCell align="right">{Piece.modifie}</TableCell>
                        <TableCell align="right">{Piece.modifiePar}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell> Pas de donnée</TableCell>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* MODALS */}
      {openImgModal ? (
        <Lightbox
          image={imgUrl}
          title={''}
          onClose={() => {
            setOpenImgModal(!openImgModal);
          }}
        >
          {' '}
        </Lightbox>
      ) : null}

      {openPDFModal ? (
        <StyledModal
          isOpen={openPDFModal}
          onBackgroundClick={() => {
            setOpenPDFModal(!openPDFModal);
          }}
          onEscapeKeydown={() => {
            setOpenPDFModal(!openPDFModal);
          }}
        >
          <span>
            <PDFViewer
              navbarOnTop
              document={{
                url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
              }}
            />
          </span>
        </StyledModal>
      ) : null}
    </React.Fragment>
  );
}

function CollapsibleTable(props) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>ID Client</TableCell>
            <TableCell align="right">Intitule</TableCell>
            <TableCell align="right">Produit</TableCell>
            <TableCell align="right">Etat Simulation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map(row => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
