/* eslint-disable no-console */
import { HStack, Box } from '@chakra-ui/react';
import React from 'react';
import { REPORT_ECHEANCE, REPROFILAGE } from '../../SimulationEvenementStep/CreditEventsForm';

const mapSyntheseReportEcheance = [
  { libelle: 'Date debut du report', keyForValue: 'dateEvent', type: 'date' },
  { libelle: "Nombre d'échéances reportées", keyForValue: 'nbrEcheancesReport' },
  { libelle: 'Option de report', keyForValue: 'modeContinuation' },
  { libelle: 'Code barème de frais', keyForValue: 'codebareme' },
  { libelle: 'Montant de frais', keyForValue: 'montantFrais' },
];

const mapResultatReportEcheance = [
  { libelle: 'Date prochaine échéance', keyForValue: 'dateOfNextRepaymentAfterEvent', type: 'date' },
  { libelle: 'Montant échéance avant', keyForValue: 'amountRepaymentBeforeEvent', type: 'montant' },
  { libelle: 'Montant échéance apres', keyForValue: 'amountRepaymentAfterEvent', type: 'montant' },
  { libelle: 'Durée restante avant', keyForValue: 'durationRemainingBeforeEvent', type: 'duree' },
  { libelle: 'Durée restante apres', keyForValue: 'durationRemainingAfterEvent', type: 'duree' },
];

export const EventDescriptionList = ({ demandeSAV }) => {
  console.log('props', demandeSAV);
  const { savInputSimulationDTO } = demandeSAV;

  const tile = (libelle, value) => {
    return (
      <div className="py-4 grid grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500 text-left mx-2">{libelle} :</dt>
        <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{value}</dd>
      </div>
    );
  };

  const formatLsDate = (date: any) => {
    if (date === undefined) return undefined;

    let x = date?.toString();
    x = x.substring(0, 4) + '/' + x.substring(4, x.length);
    x = x.substring(0, x.length - 2) + '/' + x.substring(x.length - 2, x.length);
    return x.split('/').reverse().join('/');
  };

  const formatDateSynthese = (date: string) => {
    if (date === undefined) return undefined;

    const x = date?.substring(0, 10);

    return x.split('-').reverse().join('/');
  };

  const format = (x: number, suffix: string) => {
    if (x === null) x = 0;
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'MAD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(x);
  };

  switch (savInputSimulationDTO?.eventInfo?.nomEvent) {
    case REPORT_ECHEANCE:
      return (
        <div>
          <div className="mt-5 ">
            <HStack spacing="24px">
              <Box w="100%" h="100%">
                <h4 className="text-sm text-gray-900 text-left ml-1">
                  <span className="border-b-4 border-blue-800">Synthèse de l&apos;événement</span>
                </h4>
                <dl className="divide-y divide-gray-200">
                  {demandeSAV !== undefined &&
                    mapSyntheseReportEcheance.map(({ libelle, keyForValue, type }) => {
                      if (type === 'date') {
                        console.log('idk', keyForValue, savInputSimulationDTO?.eventInfo?.[keyForValue]);
                        return tile(libelle, formatDateSynthese(savInputSimulationDTO?.eventInfo?.[keyForValue]));
                      }
                      if (type === 'montant') return tile(libelle, format(demandeSAV?.[keyForValue], 'MAD'));
                      return tile(libelle, savInputSimulationDTO?.eventInfo?.[keyForValue]);
                    })}
                </dl>
              </Box>
              <Box w="100%" h="100%">
                <h4 className="text-sm  text-gray-900 text-left ml-1">
                  <span className="border-b-4 border-blue-800">Résultat</span>
                </h4>
                <dl className="divide-y divide-gray-200">
                  {demandeSAV !== undefined &&
                    mapResultatReportEcheance.map(({ libelle, keyForValue, type }) => {
                      if (type === 'date') {
                        return tile(libelle, formatLsDate(demandeSAV?.[keyForValue]));
                      }
                      if (type === 'montant') return tile(libelle, format(demandeSAV?.[keyForValue], 'MAD'));
                      return tile(libelle, demandeSAV?.[keyForValue]);
                    })}
                </dl>
              </Box>
              {/* <Box w='40px' h='40px' bg='pink.100'>
    3
  </Box> */}
            </HStack>
            {/* <div className="mt-8 ">
              
             
            </div> */}
          </div>
        </div>
      );
    case REPROFILAGE:
      return (
        <div>
          <div className="mt-5 ">
            <div className="grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-4"></div>
            </div>
            <div className="mt-8 ">
              <h4 className="text-sm text-gray-900 text-left ml-1">
                <span className="border-b-4 border-blue-800">{"Synthèse de l'événement"}</span>
              </h4>

              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date événement </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">{'02/02/2023'}</dd>
                </div>
              </dl>
              <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 mb-4 mt-16">
                <span className="border-b-4 border-blue-800">Resultat</span>
              </h4>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de valeur</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">10-11-2021</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Ancien capital restant dû</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">20 450,00 DH</dd>
              </div>
              <div className="ml-8">
                <h4 className="text-base font-extrabold text-gray-900 text-left  my-4">
                  <span className="border-b-2 border-blue-500">Repartition du traitement</span>
                </h4>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant Global</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">6 000,00 DH</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant en accessoires</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2"> 450,00 DH</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant en assurances</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">2 000,00 DH</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant en intérêts de retard </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">1 450,00 DH</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant en frais de mise en impayé</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">1 000,00 DH</dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div>
          <div className="mt-5 ">
            <div className="grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-4"></div>
            </div>
            <div className="mt-8 ">
              <h4 className="text-sm text-gray-900 text-left ml-1">
                <span className="border-b-4 border-blue-800">Synthèse de l&apos;événement</span>
              </h4>
              <dl className="divide-y divide-gray-200"></dl>
              <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 my-4">
                <span className="border-b-4 border-blue-800">Resultat</span>
              </h4>
            </div>
          </div>
        </div>
      );
  }
};
