import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalBody,
  Button,
  Spinner,
  Text,
  Box,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon, CheckCircleIcon, SaveIcon, XIcon } from '@heroicons/react/outline';

import { saveSynthese } from 'app/modules/CreditStepper/Reducers/simulationCreditSlice';
import { saveEntity } from 'app/entities/demande/demande.reducer';
import { BannerResultat } from 'app/modules/CreditStepper/ResultatStep/Banner/BannerResultat';
import { ResultAccordion } from 'app/modules/CreditStepper/ResultatStep/Accordions/ResultAccordion';

export const ResultStepper = ({ onReset, isValidationProcessed, onValidationProcess, getProduit }) => {
  const login = useAppSelector(state => state.authentication.account.login);
  const [isSaved, setIsSaved] = useState(false);
  const savedDemandeId = useAppSelector(state => state.demande.entity)?.id;
  const resultats = useAppSelector(state => state.resultatSimCredit);

  const simulations = useAppSelector(state => state.syntheseCredit);
  const ficheClient = useAppSelector(state => state.client.entity);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleReset = () => {
    onValidationProcess(false);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(saveSynthese({ synthese: simulations, resultat: resultats }));
  }, [resultats]);

  return (
    <div>
      <BannerResultat simulations={simulations} resultat={resultats} />
      {resultats.credits ? (
        <ResultAccordion
          simulation={simulations?.propositions[0]}
          resultat={resultats?.credits[0]}
          index={0}
          title={'Crédit 1 : ' + simulations?.propositions[0].nomProduit}
        />
      ) : (
        <HStack justify="center" h="50vh">
          <Spinner size="xl" color="blue.600" />
        </HStack>
      )}
      <br />
      {resultats.credits?.length === 2 && simulations.propositions.length === 2 ? (
        <ResultAccordion
          simulation={simulations?.propositions[1]}
          resultat={resultats?.credits[1]}
          index={1}
          title={'Crédit 2 : ' + simulations?.propositions[1].nomProduit}
        />
      ) : null}

      <br />

      <Modal isOpen={isValidationProcessed} onClose={handleReset} isCentered size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div>
              {!isSaved ? (
                <div className="m-auto">
                  <CheckCircleIcon className="m-auto w-12 py-11 text-gray-500" />

                  <Text textAlign="center" fontWeight="semibold" mb="20px" fontSize="18px">
                    Êtes-vous sur de vouloir enregistrer la simulation ?
                  </Text>
                  <div className="flex justify-center">
                    <Button rightIcon={<XIcon className="w-4 text-red-500" />} colorScheme="red" variant="outline" onClick={handleReset}>
                      Non
                    </Button>
                    <Button
                      rightIcon={<SaveIcon className="w-4" />}
                      // colorScheme="blue"
                      colorScheme={'blue'}
                      className={'mx-4'}
                      onClick={() => {
                        setIsSaved(true);
                        dispatch(saveEntity({ ...resultats, loanInputSimulation: { ...simulations, ficheClient }, login }));
                      }}
                    >
                      Oui
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="m-auto">
                  <CheckCircleIcon className={`m-auto w-12 py-11 text-${savedDemandeId ? 'teal' : 'gray'}-600`} />
                  <Box fontWeight="semibold" mb="20px" fontSize="18px" textAlign="center">
                    {savedDemandeId ? (
                      <Text paddingY="1.5" textAlign="center" backgroundColor="teal.50">
                        {' '}
                        Demande enregistrée sous le numéro {savedDemandeId}{' '}
                      </Text>
                    ) : (
                      <Spinner size="lg" thickness="2px" speed=".8s" color="blue.500" />
                    )}
                  </Box>
                  {savedDemandeId && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        rightIcon={<XIcon className="w-4 text-red-500" />}
                        type="button"
                        colorScheme="red"
                        variant="outline"
                        onClick={handleReset}
                      >
                        quitter
                      </Button>
                      <Link to="/tableSimulationCredit" style={{ textDecoration: 'none' }}>
                        <Button
                          rightIcon={<ArrowRightIcon className="w-4 text-white" />}
                          type="button"
                          colorScheme="blue"
                          marginLeft="3"
                          outline="none"
                        >
                          Visualiser la Demande
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
