import React, { useEffect } from 'react';
import { Button, Box } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { UserGroupIcon } from '@heroicons/react/outline';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { editFicheClient2 } from 'app/entities/client/client.reducer';

const Prospect = ({ onStepperChange, onSimulationProspect }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
        <UserGroupIcon className="h-8 w-8 text-blue-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <div className="mt-2">
          <p className="text-md text-gray-500">Veillez à indiquer la nature du tiers.</p>
        </div>
      </div>
      <Box className="d-flex justify-center">
        <Link to={'/step'} style={{ textDecoration: 'none' }}>
          <Button
            colorScheme="blue"
            mr={3}
            mt={6}
            variant="outline"
            onClick={() => {
              onSimulationProspect(true), dispatch(editFicheClient2({ champ: 'typeEntite', inputValue: 'PROSPECT' }));
            }}
          >
            Prospect 2
          </Button>
        </Link>
        <Button colorScheme="blue" mr={3} mt={6} onClick={() => onStepperChange(1)}>
          Client BOA
        </Button>
      </Box>
    </>
  );
};

export default Prospect;
