import { IDemande } from './demande.model';
import { ISimulationSav } from './simulation-sav.model';
import { IUser } from './user.model';

export interface ITache {
  id?: number | null;
  name?: string | null;
  taskKey?: string | null;
  typeDemande?: string | null;
  statut?: string | null;
  comment?: string | null;
  user?: IUser | null;
  savDemandeId?: string | null;
  nextTaskKey?: string | null;
  dateTime?: Date | null;
}

export const defaultValue: Readonly<ITache> = {
  name: 'validation',
  typeDemande: 'sav',
  statut: 'a decide',
  user: null,
  taskKey: null,
};
