import { TextField } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

const FormattedInput = ({ label, value, onChange, readOnly, isAllowed }) => {
  const x = Math.floor(Math.random() * 10);
  return (
    <div className="mt-2 ml-8 ">
      <div className="row ">
        <div className="col-5">
          <label className=" block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">{label} </label>
        </div>
        {label === 'Valeur du Bien :' && <span> </span>}
        <div className="col-4 ">
          <div className={label === 'Valeur du Bien :' ? 'ml-14' : 'ml-8'}>
            {readOnly === true ? (
              <NumberFormat
                isAllowed={isAllowed}
                customInput={TextField}
                placeholder="Valeur en MAD"
                value={value}
                thousandSeparator=" "
                decimalSeparator=","
                suffix=" MAD"
                onChange={onChange}
                disabled
              />
            ) : (
              <NumberFormat
                data-tip
                data-for={'title require' + x}
                isAllowed={isAllowed}
                customInput={TextField}
                thousandSeparator=" "
                placeholder="Valeur en MAD"
                suffix=" MAD"
                value={value}
                onValueChange={onChange}
              />
            )}
          </div>
        </div>
        <div className="col-sm invisible">One of three columns</div>
      </div>
    </div>
  );
};

export default FormattedInput;
