import dayjs from 'dayjs';
import { IPieceJustificatif } from 'app/shared/model/piece-justificatif.model';
import { EtatSimul } from 'app/shared/model/enumerations/etat-simul.model';
import { TypeTaux } from 'app/shared/model/enumerations/type-taux.model';
import { TypeCredit } from 'app/shared/model/enumerations/type-credit.model';
import { NatureCredit } from 'app/shared/model/enumerations/nature-credit.model';
import { TypeBien } from 'app/shared/model/enumerations/type-bien.model';
import { EtatBien } from 'app/shared/model/enumerations/etat-bien.model';
import { IClient } from './client.model';

export interface ISimulation {
  id?: number;
  date?: string | null;
  etat?: EtatSimul | null;
  type?: string | null;
  libProduit?: string;
  montPret?: number;
  duree?: number;
  tauxHT?: number;
  dateFinPrev?: string;
  typeTaux?: TypeTaux;
  premiereEcheance?: string;
  derniereEchance?: string;
  typeCredit?: TypeCredit;
  natureCredit?: NatureCredit;
  typeBien?: TypeBien;
  etatBien?: EtatBien;
  produit?: string;
  isFranchise?: boolean;
  isConventione?: boolean;
  isAssurance?: boolean;
  pieceJustificatifs?: IPieceJustificatif[] | null;
  client?: IClient | null;
}

export const defaultValue: Readonly<ISimulation> = {
  isFranchise: false,
  isConventione: false,
  isAssurance: false,
};
