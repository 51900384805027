import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { Button, useDisclosure } from '@chakra-ui/react';
import AssuranceModal from './AssuranceModal';
import { useAppSelector } from 'app/config/store';

export default function AssuranceStepper(props) {
  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        'label + &': {
          marginLeft: '5%',
        },
      },
      formControl: {
        //  margin: theme.spacing(1),
        minWidth: 240,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const classes = useStyles();
  const [typeAssur, setTypeAssur] = React.useState('');
  const [fraisDossier, setFraisDossier] = React.useState('');
  const [fraisExpertise, setFraisExpertise] = React.useState('');
  const [natureFDossier, setNatureFDossier] = React.useState('');
  const [natureFExpertise, setNatureFExpertise] = React.useState('');
  const [produit, setProduit] = useState('');
  const [indexL, setIndexL] = useState(0);
  const [proposition, setProposition] = useState(null);

  const { isOpen: propositionIsOpen, onOpen: propositionOnOpen, onClose: propositionOnClose } = useDisclosure();
  const { isOpen: proposition2IsOpen, onOpen: proposition2OnOpen, onClose: proposition2OnClose } = useDisclosure();

  const handleClick = (e, c) => {
    if (c.id === simulationCredit.propositions[0].id) {
      setIndexL(0);
      setProposition(simulationCredit.propositions[0]);
    } else if (c.id === simulationCredit.propositions[1].id) {
      setIndexL(1);
      setProposition(simulationCredit.propositions[1]);
    }
    propositionOnOpen();
  };

  let selectedIDs;

  const rows = simulationCredit.propositions;
  return (
    <div className="mx-5">
      <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
        <p className="mb-4 font-medium text-grey-800 text-xl text-center">
          <span className="hidden md:inline">Assurances et Frais</span>
        </p>
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center justify-center">
            <div style={{ height: 400, width: '80%' }} className="">
              <DataGrid
                onSelectionModelChange={ids => {
                  selectedIDs = new Set(ids);
                  const selectedRowData = props.prdtArray.filter(row => selectedIDs.has(row.id));
                }}
                rows={rows}
                hideFooterSelectedRowCount
                pageSize={10}
                columns={[
                  {
                    field: 'nomProduit',
                    headerName: 'Nom du produit',
                    flex: 0.2,
                  },
                  {
                    field: 'valueAssurance',
                    headerName: 'Valeur Assurance',
                    renderCell: cellValues => {
                      var affichage = cellValues.row.valueAssurance;
                      var suffix = cellValues.row.typeAssurance === 'TAUX' ? ' %' : ' Dhs';
                      return affichage + suffix + '';
                    },
                    flex: 0.2,
                  },
                  {
                    field: 'natureFDossier',
                    headerName: 'Type de frais',
                    renderCell: cellValues => {
                      return cellValues.row.natureFDossier;
                    },
                    flex: 0.2,
                  },
                  {
                    field: 'fraisDossier',
                    headerName: 'Frais de Dossier',
                    renderCell: cellValues => {
                      return cellValues.row.fraisDossier + ' Dhs';
                    },
                    flex: 0.2,
                  },

                  {
                    field: 'id',
                    headerName: ' ',
                    sortable: false,
                    flex: 0.1,
                    renderCell: cellValues => {
                      return (
                        <Button
                          colorScheme="teal"
                          variant="ghost"
                          onClick={event => {
                            handleClick(event, cellValues);
                          }}
                        >
                          <i className="fal fa-pen"> </i>
                        </Button>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      {simulationCredit.propositions.length > 0 ? (
        <AssuranceModal
          prdtArray={props.prdtArray}
          setPrdtArray={props.setPrdtArray}
          onClose={propositionOnClose}
          isOpen={propositionIsOpen}
          produit={proposition}
          setProduit={a => setProduit(a)}
          isCentered
          size={'xl'}
        />
      ) : null}
    </div>
  );
}
