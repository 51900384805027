import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { Storage } from 'react-jhipster';
import { ITache2, defaultValue } from 'app/shared/model/tachecopy.model';

interface IDecision {
  taskId: string;
  user_name: string;
  comment: string;
}

interface IAssigneeAndDate {
  taskId: string;
  owner: string | null;
  dueDate?: string | null;
}

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  demandeId: null,
  history: [],
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl_camunda_int = 'http://localhost:8090/engine-rest';
const apiUrl_camunda_cust = 'http://localhost:8090/camunda';
const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

// Actions

// fetch tasks of a certain group using its ID
export const getEntities = createAsyncThunk(
  'taches/fetch_entity_list',
  async (user_role: string) => {
    const requestUrl = `${apiUrl_camunda_cust}/filter/group/${user_role}/tasks`;
    return axios.get<ITache2[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// fetch tasks and demande info of a group using its ID
export const getTasks = createAsyncThunk(
  'taches/fetch_tasks_list',
  async (user_role: string) => {
    const requestUrl = `${apiUrl_camunda_cust}/filter/group/${user_role}/tasks/tasks`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// fetch history of tasks/decisions using process Instance ID
export const getHistoryEntities = createAsyncThunk(
  'taches/fetch_history_entity_list',
  async (processInstanceId: string) => {
    const requestUrl = `${apiUrl_camunda_cust}/history/task?processInstanceId=${processInstanceId}`;
    const histTaches: any = await axios.get<any>(requestUrl).then(res => {
      return res.data;
    });

    return histTaches;
  },
  { serializeError: serializeAxiosError }
);

// get tasks by user login
export const getEntitiesByUserLogin = createAsyncThunk(
  'taches/fetch_entity_list__by_demandeId',
  async (userLogin: string) => {
    const requestUrl = `${apiUrl_camunda_cust}/filter/user/${userLogin}/tasks`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// get tasks history
export const getHistoryEntitiesByDemandeId = createAsyncThunk(
  'taches/fetch_entity_list__by_demandeId',
  async (demandeId: string) => {
    const requestUrl = `${apiUrl_camunda_cust}/history/task/simulationId/${demandeId}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// get task by ID
export const getEntityTask = createAsyncThunk(
  'taches/fetch_entity',
  async (tacheId: string) => {
    const result = await axios.get<ITache2>(`${apiUrl_camunda_cust}/task/` + tacheId);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// assigne task to user and/or set due date
export const updateOwnerAndDueDate = createAsyncThunk(
  'taches/update_entity',
  async (data: IAssigneeAndDate) => {
    // update task assigne
    if (!(data.owner === '' || data.owner === null)) {
      const result = await axios.post<ITache2>(`${apiUrl_camunda_cust}/update/assignee/` + data.taskId, data.owner, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    // update task due date
    if (!(data.dueDate === '' || data.dueDate === null)) {
      await axios.post<ITache2>(`${apiUrl_camunda_cust}/update/dueDate/` + data.taskId, data.dueDate, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  },
  { serializeError: serializeAxiosError }
);

// assigne task to user
export const assigneTask = createAsyncThunk(
  'tache/update_assignee',
  async (data: IAssigneeAndDate) => {

    // update task assigne
    const result = await axios.post<ITache2>(
      `${apiUrl_camunda_cust}/update/assignee/` + data.taskId,
      data.owner,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },
  { serializeError: serializeAxiosError }
);

export const getActiveEntity = createAsyncThunk('taches/fetch_active_entity', async (demandeId: string) => {
  const requestUrl = `${apiUrl_camunda_cust}/task/active/${demandeId}`;

  const result = await axios.get<ITache2>(requestUrl);
  return result;
});

export const validateActiveEntity = createAsyncThunk(
  'taches/validate_active_entity',
  async (data: IDecision) => {
    const { taskId, user_name, comment } = data;

    const requestUrl = `${apiUrl_camunda_cust}/complete/task/${taskId}`;

    const result = await axios.post<ITache2>(
      requestUrl,
      [
        {
          varName: 'valider',
          value: 1,
        },
      ],
      {
        headers: {
          'Content-Type': 'application/json',
          userName: user_name,
          comment: comment,
        },
      }
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const rejectActiveEntity = createAsyncThunk(
  'taches/reject_active_entity',
  async (data: IDecision) => {
    const { taskId, user_name, comment } = data;
    const requestUrl = `${apiUrl_camunda_cust}/complete/task/${taskId}`;

    const result = await axios.post<ITache2>(
      requestUrl,
      [
        {
          varName: 'valider',
          value: 0,
        },
      ],
      {
        headers: {
          'Content-Type': 'application/json',
          userName: user_name,
          comment: comment,
        },
      }
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const aModifierActiveEntity = createAsyncThunk(
  'taches/modifier_active_entity',
  async (data: IDecision) => {
    const { taskId, user_name, comment } = data;
    const requestUrl = `${apiUrl_camunda_cust}/complete/task/${taskId}`;

    const result = await axios.post<ITache2>(
      requestUrl,
      [
        {
          varName: 'valider',
          value: -1,
        },
      ],
      {
        headers: {
          'Content-Type': 'application/json',
          userName: user_name,
          comment: comment,
        },
      }
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const fetchDemandeId = createAsyncThunk('taches/fetch-demande_id', async (instanceId: string) => {
  const requestUrl = `${apiUrl_camunda_cust}/demande/processInstanceId/${instanceId}`;

  const result = await axios.get<string>(requestUrl);
  return result;
});

// slice

export const Taches2Slice = createEntitySlice({
  name: 'taches',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getActiveEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityTask.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(fetchDemandeId.fulfilled, (state, action) => {
        state.loading = false;
        state.demandeId = action.payload.data;
      })
      // .addCase(updateOwnerAndDueDate.fulfilled, state => {
      //   state.updating = false;
      //   state.updateSuccess = true;
      // })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getTasks), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getEntitiesByUserLogin), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getHistoryEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          history: action.payload,
        };
      })
      .addMatcher(isFulfilled(getHistoryEntitiesByDemandeId), (state, action) => {
        return {
          ...state,
          loading: false,
          history: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(rejectActiveEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(validateActiveEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(aModifierActiveEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(updateOwnerAndDueDate), (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isFulfilled(assigneTask), (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isPending(getEntities, getTasks, updateOwnerAndDueDate, fetchDemandeId, getEntitiesByUserLogin, getHistoryEntitiesByDemandeId, getHistoryEntities, getActiveEntity, getEntityTask), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(rejectActiveEntity, validateActiveEntity, aModifierActiveEntity, assigneTask), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = Taches2Slice.actions;

// Reducer
export default Taches2Slice.reducer;
