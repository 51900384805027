import React, { useEffect } from 'react';
import { Modal, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Select, ModalFooter, Button } from '@chakra-ui/react';
import ModalBody from 'reactstrap/es/ModalBody';
import { TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { ModeDeContinuationModel } from 'app/modules/SavStepper/models/enums/Report Echeance/mode-de-continuation.model';
import { ModeContinuationModel } from '../../models/enums/Remboursement Partiel/mode-continuation.model';
import { CodeBareme } from '../../models/enums/Report Echeance/codeBareme.model';
import FormattedInput3 from 'app/modules/commonComponents/FormatedInputs/FormattedInput3';
import NumberFormat from 'react-number-format';

const ReportEcheance = ({
  isOpen,
  onClose,
  eventObject,
  onEventChange,
  setStepperPage,
  singleStateManagement,
  onSelectCredit,
  onSelectionReset,
  checkEmpty,
  updatedEvent,
  onUpdatedEventReset,
}) => {
  const MODE_CONTINUATION = [
    { libelle: "Maintiens de la durée avec augmentation de l'échéance", value: 1 },
    { libelle: "Extension de la durée initiale avec impact sur l'échéance", value: 2 },
    { libelle: "Extension de la durée initiale sans impact sur l'échéance", value: 3 },
  ];

  const CODE_BAREME = [
    { libelle: 'Code 1', value: 1 },
    { libelle: 'Code 2', value: 2 },
    { libelle: 'Code 3', value: 3 },
  ];
  // this logic is repetitive & should refactored later on
  const handleSubmit = e => {
    e.preventDefault();
    singleStateManagement();
    onEventChange({});
    onSelectCredit([]);
    onSelectionReset([]);
    onUpdatedEventReset({});
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose(), onEventChange({});
        onSelectCredit([]);
        onSelectionReset([]);
        onUpdatedEventReset({});
      }}
      isCentered
      size={'xl'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(0deg)" />
      <ModalContent>
        <ModalHeader>Report échéance</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="sm:mt-5 space-y-2 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5  border-t">
                <label htmlFor="montant_Frais" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Date événement
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <TextField
                    id="dateEvent"
                    type="date"
                    value={eventObject.dateEvent}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        dateEvent: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {/* Date de début du report */}
              <div className="bg-grey-500  sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 border-t">
                <label htmlFor="nombre_Echeance_report" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Nombre d&apos;échéances reportées
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <NumberFormat
                    required
                    id="nbreReportees"
                    // ref={toolTipRef}
                    // data-tip
                    // data-for={'title require' + x}
                    // data-event={'focusout'}
                    // className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                    isAllowed={undefined}
                    customInput={TextField}
                    placeholder="Nombre d'échéances"
                    value={eventObject.nbrEcheancesReport}
                    thousandSeparator=" "
                    decimalSeparator=","
                    suffix=""
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        nbrEcheancesReport: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {/*Mode Continuation*/}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="Mode_de_continuation" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Mode de continuation
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    required
                    id="Mode_de_continuation"
                    placeholder="Mode de continuation"
                    value={eventObject.modeContinuation}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        modeContinuation: e.target.value,
                      });
                    }}
                  >
                    {MODE_CONTINUATION.map(mode => {
                      return <option label={mode.libelle} value={mode.value}></option>;
                    })}
                  </Select>
                </div>
              </div>
              {/* Barème de capitalisation */}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="Mode_de_continuation" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Code barème de frais
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    required
                    id="Code_Bareme_Frais"
                    placeholder="Choix Barème"
                    value={eventObject.codebareme}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        codebareme: e.target.value,
                      });
                    }}
                  >
                    {CODE_BAREME.map(bareme => {
                      return <option label={bareme.libelle} value={bareme.value}></option>;
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 border-t">
              <label htmlFor="montant_Frais" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                Montant des frais
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <NumberFormat
                  id="montantFrais"
                  // ref={toolTipRef}
                  // data-tip
                  // data-for={'title require' + x}
                  // data-event={'focusout'}
                  // className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                  isAllowed={undefined}
                  customInput={TextField}
                  placeholder="Valeur en MAD"
                  value={eventObject.montantFrais}
                  thousandSeparator=" "
                  decimalSeparator=","
                  suffix=" MAD"
                  onValueChange={values => {
                    const { formattedValue, value } = values;
                    onEventChange({ ...eventObject, montantFrais: value });
                  }}
                />
                {/* <TextField
                    required
                    id="montant_Frais"
                    value={eventObject.montantFrais}
                    placeholder=""
                    type="number"
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        montantFrais: Number(e.target.value),
                      });
                    }}
                  /> */}
              </div>
            </div>
            <div className="pt-4 flex justify-center">
              {checkEmpty(updatedEvent) && (
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={() => {
                    setStepperPage('start');
                    onEventChange({});
                    onSelectCredit([]);
                    onSelectionReset([]);
                  }}
                >
                  Retour
                </Button>
              )}

              <Button type="submit" colorScheme="blue" marginLeft="4" variant="outline">
                Appliquer
              </Button>
            </div>
          </form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportEcheance;
