import './table.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import { Box, Stack, HStack, Spinner } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { Table as Table2, Typography } from '@material-ui/core';
import _ from 'lodash';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { AdjustmentsIcon } from '@heroicons/react/outline';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { creditEventReset } from '../SavStepper/Reducers/CreditEvents.reducer';
import { getEntities, advancedFilterEntitiesForSav2 } from '../../entities/demande/demandeSAV.reducer';
import { IFilterCreditAndSav } from 'app/shared/model/filterCredit.model';

import { FilterComposant } from 'app/modules/ListeDemandesSAV/FilterComposantSAV';

interface ICategory {
  title: string;
  label: string;
  type: string;
}

const STATE_COLORS = [
  { statut: 'DEMANDE_CLOTURE', label: 'Tout', color: 'gray' },
  { statut: 'DEMANDE_ACCORDEE', label: 'Validée', color: 'green' },
  { statut: '2', label: 'Refusée', color: 'red' },
  { statut: '1', label: ' À décider', color: 'blue' },
  { statut: 'A_COMPLETER', label: 'À compléter', color: 'indigo' },
  { statut: 'EN_COURS_DE_SAISIE', label: 'En cours', color: 'yellow' },
];
const CATEGORIES = [
  { title: 'NomClient', label: 'Nom Client', type: 'text' },
  { title: 'CIN', label: 'CIN', type: 'text' },
  { title: 'NumCompte', label: 'Num de compte', type: 'number' },
];
const pageStart = 0;

const HEADER_TITLES = ['N° Demande', 'Utilisateur', 'Nom Évènement', 'Nom Client', 'Date Demande', 'Statut'];

const formatDateSynthese = (date: string) => {
  if (date === undefined) return undefined;

  const x = date?.substring(0, 10);

  return x.split('-').reverse().join('/');
};

// all the data here should be retrived from a central place that intialized by calling services to the backend
// to be resolved later on.
export const ListeSimulation = props => {
  const initial: Partial<ICategory> = {};
  const initialFilter: Partial<IFilterCreditAndSav> = {};

  const [value, setValue] = React.useState('Conso');

  const [selectedCategory, setSelectedCategory] = useState(initial);
  const [currentPage, setCurrentPage] = useState(0);

  const events = useAppSelector(state => state.events.entities);
  const { entities: demandes, totalPages, isFiltered } = useAppSelector(state => state.demandeSAV);

  const loading = useAppSelector(state => state.demande.loading);
  const [filterQuery, setFilterQuery] = useState('');
  const [filterCredit, setFilterCredit] = useState(initialFilter);
  const [demandesAfterFilter, setDemandesAfterFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const filtreStateGlobal = useAppSelector(state => state.filtreCredit);

  useEffect(() => {
    setDemandesAfterFilter(demandes);
  }, [demandes]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntities(pageStart));
  }, []);

  useEffect(() => {
    dispatch(creditEventReset());
  });

  useEffect(() => {
    setFilterCredit({ ...filterCredit, typeCredit: value });
  }, [value]);

  const getDemandeStateColor = (state: string) => {
    return STATE_COLORS.find(stateColor => stateColor.statut === state)?.color;
  };

  const getDemandeStatutLabel = (status: string) => {
    return STATE_COLORS.find(statutColor => statutColor.statut === status)?.label;
  };

  function RemoveElementFromStringArray(element: string) {
    selectedFilter.forEach((value, index) => {
      if (value === element) {
        selectedFilter.splice(index, 1);
      }
    });
  }

  const buildQueryString = (filtre: any, page: any) => {
    const pageParameters = `&sort=id,asc&page=${page}&size=20`;
    const x = Object.keys(filtre).filter(key => !(key.includes('selected') || key.includes('filter')));

    const queryString = Object.keys(filtre)
      .filter(key => !(key.includes('selected') || key.includes('filter') || filtre[key] === '' || filtre[key] === 0))
      .map(key => key + '=' + filtre[key])
      .join('&');
    return queryString.concat(pageParameters);
  };

  const handlePageChange2 = ({ selected }) => {
    setCurrentPage(selected);
    dispatch(advancedFilterEntitiesForSav2(buildQueryString(filtreStateGlobal, selected)));
  };
  const nameEvent = weirdNameFromjson => {
    switch (weirdNameFromjson) {
      case 'REMBOURSEMENT_TOTAL':
        return 'Remboursement Total';
      case 'REMBOURSEMENT_PARTIEL':
        return 'Remboursement Partiel';
      case 'REPORT_ECH':
        return 'Report Échéance';
      case 'REPROFILAGE':
        return 'Réintégration de la dette';
      default:
        return 'Evénement non géré';
    }
  };

  const displayDemandes = () => {
    return demandesAfterFilter?.map(demande => {
      return (
        <tr key={demande.id}>
          <td>
            <Link to={`/testing/${demande.savSimulationResultId}`}> {demande.savId} </Link>
          </td>
          <td>{demande.userId || 'UserId'}</td>
          <td> {nameEvent(demande.nomEvent) || 'Nom évènement'}</td>
          <td> {demande.prenomClient? demande.prenomClient + ' ' + demande.nomClient : 'Leigh Cringle'} </td>
          <td>{demande.dateDemande}</td>
          <td>
            <span
              className={`px-2 inline-flex text-xs leading-5  rounded-full
              font-bold bg-${getDemandeStateColor(demande.statut)}-200
              text-${getDemandeStateColor(demande.statut)}-800`}
            >
              {getDemandeStatutLabel(demande.statut)}
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Container fluid>
        <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100 ' /* classes.root */}>
          <Box p={5} shadow="base" borderWidth="3px">
            <Row>
              <Col md="12">
                <div className="p-1 md:p-4">
                  <h3 className="mt-8 text-center title mb-24 ">Tableau des demandes SAV</h3>
                  <Accordion expanded={showFilter} className={'mx-5 mt-36'} onChange={e => setShowFilter(!showFilter)}>
                    <AccordionSummary
                      expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="">
                        <span className="d-flex align-items-center ">
                          <AdjustmentsIcon className="w-5 text-blue-800" />
                          <h3 className="smalltitle ml-4 ">Filtrer les données </h3>{' '}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="w-100">
                      <Typography className="w-100">
                        <FilterComposant />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <br />

                  <h3 className="my-4 smalltitle">Tableau des demandes SAV </h3>

                  {loading ? (
                    <HStack justify="center" h="50vh">
                      <Spinner size="xl" color="teal.600" />
                    </HStack>
                  ) : (
                    <table className="table table-auto table-bordered">
                      <thead>
                        <tr>
                          {HEADER_TITLES.map(title => (
                            <th className="p-3" key={title}>
                              {title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody id="myTable"> {displayDemandes()}</tbody>
                    </table>
                  )}
                  {totalPages > 1 && (
                    <ReactPaginate
                      previousLabel="Précédent"
                      nextLabel="Suivant"
                      pageCount={totalPages}
                      forcePage={currentPage}
                      onPageChange={handlePageChange2}
                      containerClassName="paginationBtns"
                      previousLinkClassName="previousBtns"
                      nextLinkClassName="nextBtn"
                      disabledClassName="paginationDisabled"
                      activeClassName="paginationActive"
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ListeSimulation);
