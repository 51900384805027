import { IAssurance } from './assurance.model';
import { IClient } from './client.model';
import { ICredit2 } from './credit2.model';
import { iFicheClient } from './ficheClient.model';
import { iResultatSimCredit } from './resultatSimCredit.model';
import { ISimulation } from './simulation.model';
import { ISimulationWithType } from './simulationWithType.model';

export interface IDemandeSimulationCredit {
  id?: number | null;
  agence?: string | null;
  client?: IClient | null;
  simulations?: ISimulationWithType[] | null;

  statut?: string;
  dateDemande?: string;

  loanSimulationResultId?: number;
  userId?: string;
  codeBanque?: string;
  nomBanque?: string;
  codeAgence?: string;
  nomAgence?: string;
  loanInputSimulation?: any;
  credits?: any;
}

export const defaultValue: Readonly<IDemandeSimulationCredit> = {
  agence: 'casablanca',
  //   valeurBien: 500000,
  //   montantFrais: 10000,
  //   montantProjet: 510000,
  //   mntCrdt: 410000,
  //   apportPersonnel: 100000,
  //   objetCredit: 'Acquisition',
  //   typeCredit: 'Immobilier',
  //   typeBien: 'Appartement',
};
