import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IDemande } from 'app/shared/model/demande.model';

const initialState: EntityState<IDemande> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalPages: 0,
  number: 0,
  isFiltered: false,
};

export const filterEntitiesByState = createAsyncThunk(
  'demande/filter_entities_by_state',
  async ({ filter, page }: { filter: string; page: number }) => {
    const requestUrl = `http://localhost:8080/api/demande/filterbyStatut/${filter}?size=12&page=${page}`;
    const { data } = await axios.get(requestUrl);
    return data;
  }
);

export const filterEntities = createAsyncThunk(
  'demande/filter_entities',
  async ({ search, filter, page }: { search: string; filter: string; page: number }) => {
    const requestUrl = `http://localhost:8080/api/demande/filterby${search}/${filter}?size=12&page=${page}`;
    const { data } = await axios.get(requestUrl);
    return data;
  }
);

export const advancedFilterEntities = createAsyncThunk('demande/advanced_filter_entities', async (filter: any) => {
  var pageParameters = 'sort=id,asc&page=0&size=20';
  var str = filter;
  var mapObj = {
    objetCredit: 'objetCredit.equals',
    montantMax: 'montantCredit_max.lessThanOrEqual',
    montantMin: 'montantCredit_min.greaterThanOrEqual',
    dateMin: 'dateDemande.greaterThanOrEqual',
    dateMax: 'dateDemande.lessThanOrEqual',
    typeCredit: 'typeCredit.equals',
  };
  str = str.replace(/objetCredit|typeCredit|montantMax|montantMin|dateMin|dateMax/gi, function (matched) {
    return mapObj[matched];
  });
  const requestUrl = `api/demandesCredit?${str}&${pageParameters}`;

  const { data } = await axios.get(requestUrl);

  //  const { data } = await axios.post('api/demande/filter', filter);
  // return data;
  return data;
});

export const advancedFilterEntities2 = createAsyncThunk('demande/advanced_filter_entities2', async (filter: any) => {
  var str = filter;

  var mapObj = {
    objetCredit: 'objetCredit.equals',
    montantMax: 'montantCredit_max.lessThanOrEqual',
    montantMin: 'montantCredit_min.greaterThanOrEqual',
    dateMin: 'dateDemande.greaterThanOrEqual',
    dateMax: 'dateDemande.lessThanOrEqual',
    typeCredit: 'typeCredit.equals',
  };
  str = str.replace(/objetCredit|typeCredit|montantMax|montantMin|dateMin|dateMax/gi, function (matched) {
    return mapObj[matched];
  });
  const requestUrl = `api/demandesCredit?${str}`;

  const { data } = await axios.get(requestUrl);

  return data;
});

export const filterEntitiesByNomEvent = createAsyncThunk(
  'demande/filter_entities_by_event',
  async ({ filter, page }: { filter: string; page: number }) => {
    const filterQuery = filter.replace(' ', '_').trim();
    const requestUrl = `api/demande/filterbyNomEvent/${filter}?size=12&page=${page}`;
    const { data } = await axios.get(requestUrl);
    return data;
  }
);

export const getEntities = createAsyncThunk('demandes/fetch_entity_list', async (page: number) => {
  const requestUrl = `api/demandesCredit?page=${page}&size=20&sort=id,asc`;
  const { data } = await axios.get(requestUrl);
  return data;
});

export const saveEntity = createAsyncThunk('demandes/save_entity', async (simulationDemande: any) => {
  const { data } = await axios.post('api/saveSimulationCredit', simulationDemande);
  return data;
});

export const getEntity = createAsyncThunk('demandes/fetch_entity', async (id: number) => {
  const requestUrl = `api/demandesCredit/detail/${id}`;
  return axios.get<IDemande>(requestUrl);
});

export const demandeSlice = createSlice({
  name: 'demande',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.totalPages = totalPages;
        state.number = number;
        state.entities = content;
        state.isFiltered = false;
        state.loading = false;
      })
      .addCase(getEntities.pending, state => {
        state.loading = true;
      })
      .addCase(advancedFilterEntities.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.totalPages = totalPages;
        state.number = number;
        state.entities = content;
        state.isFiltered = false;
        state.loading = false;
      })
      .addCase(advancedFilterEntities2.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.totalPages = totalPages;
        state.number = number;
        state.entities = content;
        state.isFiltered = false;
        state.loading = false;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
      })
      .addCase(saveEntity.fulfilled, (state, action) => {
        state.entity.id = action.payload;
      })
      .addCase(filterEntitiesByState.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(filterEntitiesByState.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.entities = content;
        state.totalPages = totalPages;
        state.number = number;
        state.isFiltered = true;
        state.loading = false;
      })
      .addCase(filterEntities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(filterEntities.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;

        state.entities = content;
        state.totalPages = totalPages;
        state.number = number;
        state.isFiltered = true;
        state.loading = false;
      });
  },
});

export default demandeSlice.reducer;
