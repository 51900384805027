import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  extendTheme,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Select,
  Switch,
  Flex,
  VStack,
} from '@chakra-ui/react';

import { BanIcon } from '@heroicons/react/outline';

import { FormControl, InputLabel, makeStyles, MenuItem, FormGroup } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import NumberPicker from 'react-widgets/NumberPicker';
import FormattedInput2 from 'app/modules/commonComponents/FormatedInputs/FormattedInput2';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import '../outlineDisable.scss';
import { propositionUpdate } from '../../Reducers/syntheseCreditSlice';
import GeneriqueModalError from './GeneriqueModalError';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export const PropositionModal = props => {
  const dispatch = useAppDispatch();

  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const [produit, setProduit] = useState(null);

  useEffect(() => {
    console.log('produit23', produit);
  }, [produit]);

  const [limit, setLimit] = useState(simulationCredit.mntCrdt);
  const [limitInverted, setLimitInverted] = useState(0);
  const { isOpen: echecIsOpen, onOpen: echecOnOpen, onClose: echecOnClose } = useDisclosure();
  const autre = props.indexL === 0 ? 1 : 0;
  const [precedent, setPrecedent] = useState(false);
  const { isOpen: generiqueErrorIsOpen11, onOpen: generiqueErrorOnOpen11, onClose: generiqueErrorOnClose11 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen22, onOpen: generiqueErrorOnOpen22, onClose: generiqueErrorOnClose22 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen33, onOpen: generiqueErrorOnOpen33, onClose: generiqueErrorOnClose33 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen441, onOpen: generiqueErrorOnOpen441, onClose: generiqueErrorOnClose441 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen443, onOpen: generiqueErrorOnOpen443, onClose: generiqueErrorOnClose443 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen444, onOpen: generiqueErrorOnOpen444, onClose: generiqueErrorOnClose444 } = useDisclosure();

  const [msgErreur11, setMsgErreur11] = useState('');
  const [msgErreur22, setMsgErreur22] = useState('');
  const [msgErreur33, setMsgErreur33] = useState('');
  const [msgErreur441, setMsgErreur441] = useState('');
  const [msgErreur443, setMsgErreur443] = useState('');
  const [msgErreur444, setMsgErreur444] = useState('');

  useEffect(() => {
    setProduit(props.produit);
  }, [props.produit]);

  useEffect(() => {
    if (simulationCredit.propositions.length === 1) {
      setLimit(simulationCredit.mntCrdt);
    } else if (simulationCredit.propositions.length === 2) {
      setLimit(simulationCredit.mntCrdt - simulationCredit.propositions[props.index === 0 ? 1 : 0].montant);
    }
  }, [simulationCredit, props.index]);

  const theme = extendTheme({
    components: {
      Modal: {
        baseStyle: props => ({
          dialog: {
            maxWidth: ['60%', '60%', '60%'],
            minWidth: '60%',
          },
        }),
      },
    },
  });

  const rng = () => {
    return Math.floor(Math.random() * 1000);
  };

  const withValueCap = inputObj => {
    const { value } = inputObj;
    if (value <= limit) return true;
    return false;
  };

  const handleValidate = () => {
    var produitTempo = { ...simulationCredit.propositions[props.index] };
    var palierPrincipal = produit.paliers.find(palier => palier.typePalier === 1);
    const indexPalierPrincipal = produitTempo.paliers.findIndex(prop => prop.typePalier === 1);

    const variationDureeCredit = produit.dureeMois - produitTempo.dureeMois;
    const variationDureeFranchise = produit.dureeFranchise - produitTempo.dureeFranchise;

    var dureeFranchiseControle = palierPrincipal.duree - produit.dureeFranchise >= 1;

    const palierFranchise = produit.paliers.find(palier => palier.typePalier === 2);
    const indexPalierFranchise = produitTempo.paliers.findIndex(prop => prop.typePalier === 2);

    if (produitTempo.isFranchise === false && produit.isFranchise === false) {
      if (variationDureeCredit >= 0) {
        const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + variationDureeCredit };
        var copiePaliers = [...produit.paliers];
        copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
        var newProduit = { ...produit, paliers: copiePaliers };
        dispatch(propositionUpdate(newProduit));
      } else if (variationDureeCredit < 0) {
        if (palierPrincipal.duree + variationDureeCredit >= 1) {
          const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + variationDureeCredit };
          var copiePaliers = [...produit.paliers];
          copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
          var newProduit = { ...produit, paliers: copiePaliers };
          dispatch(propositionUpdate(newProduit));
        } else {
          setMsgErreur11('Modification Refusée : [Erreur-11] (' + palierPrincipal.duree + variationDureeCredit + ')');
          resetProduitLocal();
          generiqueErrorOnOpen11();
        }
      }
    } else if (produitTempo.isFranchise === false && produit.isFranchise === true) {
      // ##### AJOUT FRANCHISE
      if (variationDureeCredit >= 0 && palierPrincipal.duree + variationDureeCredit - produit.dureeFranchise >= 1) {
        const newPFranchise = {
          id: rng(),
          typePalier: 2,
          typeFranchise: produit.typeFranchise,
          duree: produit.dureeFranchise,
          taux: produit.taux,
        };

        const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + variationDureeCredit - produit.dureeFranchise };

        var copiePaliers = [...produit.paliers];
        copiePaliers = copiePaliers.concat(newPFranchise);
        copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
        var newProduit = { ...produit, paliers: copiePaliers };
        dispatch(propositionUpdate(newProduit));
      } else if (variationDureeCredit < 0 && palierPrincipal.duree + variationDureeCredit - produit.dureeFranchise >= 1) {
        const newPFranchise = {
          id: rng(),
          typePalier: 2,
          typeFranchise: produit.typeFranchise,
          duree: produit.dureeFranchise,
          taux: produit.taux,
        };
        const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + variationDureeCredit - produit.dureeFranchise };

        var copiePaliers = [...produit.paliers];
        copiePaliers = copiePaliers.concat(newPFranchise);
        copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
        var newProduit = { ...produit, paliers: copiePaliers };
        dispatch(propositionUpdate(newProduit));
      } else {
        setMsgErreur22('Modification Refusée : [Erreur-22]');
        resetProduitLocal();
        generiqueErrorOnOpen22();
      }
    } else if (produitTempo.isFranchise === true && produit.isFranchise === false) {
      // ##### SUPPRESSION FRANCHISE
      if (palierPrincipal.duree + produit.dureeFranchise + variationDureeCredit >= 1) {
        const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + variationDureeCredit + produit.dureeFranchise };
        var copiePaliers = [...produit.paliers];
        copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
        copiePaliers.splice(indexPalierFranchise, 1);
        var newProduit = { ...produit, paliers: copiePaliers };
        dispatch(propositionUpdate(newProduit));
      } else {
        resetProduitLocal();
        setMsgErreur33('Modification Refusée : [Erreur-33]');
        generiqueErrorOnOpen33();
      }
    } else if (produitTempo.isFranchise === true && produit.isFranchise === true) {
      // ##### MODIF FRANCHISE
      if (variationDureeCredit !== 0 || variationDureeFranchise !== 0) {
        if (variationDureeCredit >= 0 && variationDureeFranchise >= 0) {
          if (variationDureeCredit >= variationDureeFranchise || variationDureeCredit + palierPrincipal.duree > variationDureeFranchise) {
            const newPFranchise = { ...palierFranchise, duree: produit.dureeFranchise };
            const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + variationDureeCredit - variationDureeFranchise };
            var copiePaliers = [...produit.paliers];
            copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
            copiePaliers.splice(indexPalierFranchise, 1, newPFranchise);
            var newProduit = { ...produit, paliers: copiePaliers };
            dispatch(propositionUpdate(newProduit));
          } else {
            resetProduitLocal();
            setMsgErreur441('Modification Refusée : [Erreur-441]');
            generiqueErrorOnOpen441();
          }
        } else if (variationDureeCredit >= 0 && variationDureeFranchise <= 0) {
          const newPFranchise = { ...palierFranchise, duree: produit.dureeFranchise };
          const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + variationDureeCredit + -variationDureeFranchise };
          var copiePaliers = [...produit.paliers];
          copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
          copiePaliers.splice(indexPalierFranchise, 1, newPFranchise);
          var newProduit = { ...produit, paliers: copiePaliers };
          dispatch(propositionUpdate(newProduit));
        } else if (variationDureeCredit <= 0 && variationDureeFranchise >= 0) {
          {
            if (palierPrincipal.duree + variationDureeCredit - variationDureeFranchise >= 1) {
              const newPFranchise = { ...palierFranchise, duree: produit.dureeFranchise };
              const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree - variationDureeFranchise + variationDureeCredit };

              var copiePaliers = [...produit.paliers];
              copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
              copiePaliers.splice(indexPalierFranchise, 1, newPFranchise);
              var newProduit = { ...produit, paliers: copiePaliers };
              dispatch(propositionUpdate(newProduit));
            } else {
              setMsgErreur443(
                'Modification Refusée : [Erreur-44 3]  (' +
                  palierPrincipal.duree +
                  '-' +
                  -variationDureeCredit +
                  '-' +
                  variationDureeFranchise +
                  '<1)'
              );
              resetProduitLocal();
              generiqueErrorOnOpen443();
            }
          }
        } else {
          var condition1 = Math.abs(variationDureeFranchise) >= Math.abs(variationDureeCredit);
          const condition2 = palierPrincipal.duree - Math.abs(variationDureeCredit) + Math.abs(variationDureeFranchise) >= 1;
          if (condition1 || condition2) {
            const newPFranchise = { ...palierFranchise, duree: produit.dureeFranchise };
            const newPPrincipal = { ...palierPrincipal, duree: palierPrincipal.duree + -variationDureeFranchise - -variationDureeCredit };
            var copiePaliers = [...produit.paliers];
            copiePaliers.splice(indexPalierPrincipal, 1, newPPrincipal);
            copiePaliers.splice(indexPalierFranchise, 1, newPFranchise);
            var newProduit = { ...produit, paliers: copiePaliers };
            dispatch(propositionUpdate(newProduit));
          } else {
            resetProduitLocal();
            setMsgErreur444('Modification Refusée : [Erreur-44 4]');
            generiqueErrorOnOpen444();
            setProduit({ ...produit, dureeMois: props.produit.dureeMois });
          }
        }
      } else {
        dispatch(propositionUpdate(produit));
      }
      // ###### BLOC GESTION FRANCHISE [FIN]
    }
  };

  const resetProduitLocal = () => {
    setProduit(props.produit);
  };

  return (
    <div>
      <ChakraProvider theme={theme}>
        <Modal closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modification de la proposition</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody>
              <form>
                <div>
                  <div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <Flex>
                        <VStack flex="1" align="left" className="space-y-8  sm:space-y-5">
                          <FormattedInput2
                            label={'Montant du crédit'}
                            value={produit?.montant}
                            onChange={values => {
                              const { formattedValue, value } = values;
                              setProduit({ ...produit, montant: parseFloat(value) });
                            }}
                            readOnly={false}
                            isAllowed={withValueCap}
                            placeholder={undefined}
                            suffix={undefined}
                          />

                          <FormattedInput2
                            label={'Durée du crédit'}
                            value={produit?.dureeMois}
                            onChange={values => {
                              const { formattedValue, value } = values;
                              setProduit({ ...produit, dureeMois: parseInt(value) });
                            }}
                            readOnly={false}
                            suffix={' Mois'}
                            placeholder={'Duree en mois'}
                            isAllowed={undefined}
                          />

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px sm:pt-2">
                              Type de taux
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <FormControl component="fieldset" required>
                                <Select
                                  value={produit?.typeTaux}
                                  onChange={e => {
                                    setProduit({ ...produit, typeTaux: e.target.value });
                                  }}
                                  id="demo-simple-select"
                                >
                                  <option value={'Variable'}>Variable</option>
                                  <option value={'Fixe'}>Fixe</option>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="mb-96">
                            <FormattedInput2
                              label={'Taux'}
                              value={produit?.taux}
                              onChange={values => {
                                const { formattedValue, value } = values;
                                setProduit({ ...produit, taux: value });
                              }}
                              readOnly={false}
                              suffix={' %'}
                              placeholder={'Taux (%)'}
                              isAllowed={undefined}
                            />
                          </div>
                        </VStack>
                        <VStack flex="1" align="left" className="space-y-8  sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                            <label htmlFor="franchise" className="block text-lg font-medium text-black-500 sm:mt-px sm:pt-2">
                              Franchise
                            </label>
                            <div className="mt-4 sm:mt-0 sm:col-span-2">
                              <Switch
                                colorScheme="blue"
                                size="md"
                                isChecked={produit?.isFranchise}
                                onChange={() => {
                                  if (produit.isFranchise === false) {
                                    setProduit({ ...produit, isFranchise: true, dureeFranchise: 3 });
                                  }
                                  if (produit.isFranchise === true) {
                                    setProduit({ ...produit, isFranchise: false });
                                  }
                                }}
                                name="checkedA"
                              />
                            </div>
                          </div>

                          {produit?.isFranchise ? (
                            <FormGroup>
                              <div className="xl:ml-24">
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 animate__animated animate__fadeInDown">
                                  <label htmlFor="franchise" className="-text-gray-500 sm:mt-px sm:pt-2 ml-2">
                                    Duree Franchise
                                  </label>
                                  <FormControl component="fieldset" required className="focus:outline-none">
                                    <NumberPicker
                                      className="focus:outline-none"
                                      placeholder="Valeur en mois"
                                      value={produit?.dureeFranchise}
                                      onChange={value => {
                                        // TODO: add error message when user inputs numbers too high or too low
                                        if (value === null || value < 3) {
                                          setProduit({ ...produit, dureeFranchise: 3 });
                                        } else if (value > 18) {
                                          setProduit({ ...produit, dureeFranchise: 18 });
                                        } else {
                                          setProduit({ ...produit, dureeFranchise: value });
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 animate__animated animate__fadeInDown">
                                  <label htmlFor="franchise" className="text-gray-500 sm:mt-px sm:pt-2 ml-2">
                                    Type Franchise
                                  </label>
                                  <FormControl component="fieldset" required>
                                    <Select
                                      id="demo-simple-select"
                                      onChange={e => {
                                        setProduit({ ...produit, typeFranchise: e.target.value });
                                      }}
                                    >
                                      <option value={'T'}>Total</option>
                                      <option value={'P'}>Partielle</option>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </FormGroup>
                          ) : null}

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label htmlFor="franchise" className="block text-lg font-medium text-black sm:mt-px sm:pt-2">
                              Convention
                            </label>
                            <div className="mt-4 sm:mt-0 sm:col-span-2">
                              <Switch
                                colorScheme="blue"
                                size="md"
                                isChecked={produit?.isConvention}
                                onChange={() => {
                                  setProduit({ ...produit, isConvention: !produit?.isConvention });
                                }}
                                name="checkedA"
                              />
                            </div>
                          </div>
                          {produit?.isConvention ? (
                            <FormGroup>
                              <div className=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 animate__animated animate__fadeInDown">
                                <label htmlFor="franchise" className="text-gray-500 sm:mt-px sm:pt-2 xl:ml-6">
                                  Nom Convention
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                  <FormControl component="fieldset" required className="">
                                    <Select
                                      id="ConventionChoixLabel"
                                      onChange={e => {
                                        setProduit({ ...produit, convention: e.target.value });
                                      }}
                                    >
                                      <option value={'Damane Sakane'}>Damane Sakane</option>
                                      <option value={'Damane Express'}>Damane Express</option>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </FormGroup>
                          ) : null}
                        </VStack>
                      </Flex>
                    </div>
                  </div>
                </div>
              </form>
              {/* </div> */}
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  handleValidate();
                  props.onClose();
                }}
              >
                Enregistrer
              </Button>
              <Button
                onClick={() => {
                  resetProduitLocal();
                  props.onClose();
                }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
      <Modal onClose={echecOnClose} isOpen={echecIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody className="d-flex flex-col justify-content-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <BanIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="m-5">
              <div className={'d-flex mt-4 items-center'}>
                <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                  La durée global du credit doit être supérieur a la somme des durées des paliers ({limitInverted} mois)
                </label>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose11}
        generiqueErrorIsOpen={generiqueErrorIsOpen11}
        errorMsg={msgErreur11}
      />
      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose22}
        generiqueErrorIsOpen={generiqueErrorIsOpen22}
        errorMsg={msgErreur22}
      />
      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose33}
        generiqueErrorIsOpen={generiqueErrorIsOpen33}
        errorMsg={msgErreur33}
      />
      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose441}
        generiqueErrorIsOpen={generiqueErrorIsOpen441}
        errorMsg={msgErreur441}
      />
      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose443}
        generiqueErrorIsOpen={generiqueErrorIsOpen443}
        errorMsg={msgErreur443}
      />
      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose444}
        generiqueErrorIsOpen={generiqueErrorIsOpen444}
        errorMsg={msgErreur444}
      />
    </div>
  );
};
