import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Select, ModalFooter, Button } from '@chakra-ui/react';
import ModalBody from 'reactstrap/es/ModalBody';
import { TextField } from '@material-ui/core';
import { BaremePenaliteModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/bareme-penalite.model';
import { MotifRemboursementModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/motif-remboursement.model';

import NumberFormat from 'react-number-format';

const choix_du_motif = [
  { name: 'Fonds propres', value: 1 },
  { name: 'Vente du bien', value: 2 },
  { name: 'Rachat Crédit', value: 3 },
  { name: 'Autre', value: 4 },
];

const choix_du_bareme = [
  { name: 'Avantage client', value: '00000' },
  { name: '1 mois interets', value: '00001' },
  { name: '2% CRD', value: '00005' },
];

const RemboursementTotal = ({
  isOpen,
  onClose,
  onEventChange,
  eventObject,
  setStepperPage,
  onSelectCredit,
  onSelectionReset,
  checkEmpty,
  onUpdatedEventReset,
  updatedEvent,
  singleStateManagement,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    singleStateManagement();
    onEventChange({});
    onSelectCredit([]);
    onSelectionReset([]);
    onUpdatedEventReset([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose(), onEventChange({});
        onSelectCredit([]);
        onSelectionReset([]);
        onUpdatedEventReset({});
      }}
      isCentered
      size={'xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remboursement total</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="sm:mt-5 space-y-2 sm:space-y-2">
              {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="Montant_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Montant du remboursement
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <TextField
                    required
                    id="Montant_remboursement"
                    placeholder="Valeur en MAD"
                    value={eventObject.amountOfEvent}
                    type="number"
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        amountOfEvent: Number(e.target.value),
                      });
                    }}
                  />
                </div>
              </div> */}
              {/*  Numero Compte Remboursement */}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="compte_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Le numéro compte de remboursement
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <NumberFormat
                    // ref={toolTipRef}
                    // data-tip
                    // data-for={'title require' + x}
                    // data-event={'focusout'}
                    // className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                    isAllowed={undefined}
                    customInput={TextField}
                    placeholder="Num de compte"
                    value={eventObject.accountNumber}
                    thousandSeparator=" "
                    decimalSeparator=""
                    suffix=""
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        accountNumber: Number(e.target.value),
                      });
                    }}
                  />
                  {/* <Select
                    required
                    id="compte_remboursement"
                    value={eventObject.accountNumber}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        accountNumber: Number(e.target.value),
                      });
                    }}
                  >
                    <option value={'13207701998699878'}>13207701998699878</option>
                    <option value={'508243918770199869'}>508243918770199869</option>
                  </Select> */}
                </div>
              </div>

              {/*  Barème de pénalité */}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="Montant_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Barème de pénalité
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    id="bareme_penalite"
                    placeholder="Choix Barème"
                    value={eventObject.penalty}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        penalty: e.target.value,
                      });
                    }}
                  >
                    {choix_du_bareme.map(key => {
                      return (
                        <option key={key.name} value={key.value}>
                          {key.name}
                        </option>
                      );
                    })}
                    {/* <option value={BaremePenaliteModel.SANS_PENALITE}> Sans pénalité</option>
                    <option value={BaremePenaliteModel.UN_MOIS_INTERET}> 1 mois d'intérêt</option>
                    <option value={BaremePenaliteModel.DEUX_MOIS_INTERET}> 2 mois d'intérêt</option>
                    <option value={BaremePenaliteModel.TROIS_MOIS_INTERET}> 3 mois d'intérêt</option> */}
                  </Select>
                </div>
              </div>
              {/* Motif Remboursement */}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="Montant_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Motif de remboursement
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    required
                    id="mode_cont"
                    placeholder={'choix motif'}
                    value={eventObject.motif}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        motif: e.target.value,
                      });
                    }}
                  >
                    {choix_du_motif.map(key => {
                      return (
                        <option key={key.name} value={key.value}>
                          {key.name}
                        </option>
                      );
                    })}
                    {/* <option value={MotifRemboursementModel.FONDS_PROPRES}>{MotifRemboursementModel.FONDS_PROPRES} </option>
                    <option value={MotifRemboursementModel.VENTE_DU_BIEN}>{MotifRemboursementModel.VENTE_DU_BIEN} </option>
                    <option value={MotifRemboursementModel.RACHAT_CREDIT}>{MotifRemboursementModel.RACHAT_CREDIT}</option>
                    <option value={MotifRemboursementModel.AUTRE}>{MotifRemboursementModel.AUTRE} </option> */}
                  </Select>
                </div>
              </div>
            </div>
            <div className="pt-4 flex justify-center">
              {checkEmpty(updatedEvent) && (
                <Button
                  colorScheme="red"
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setStepperPage('start');
                    onEventChange({});
                    onSelectCredit([]);
                    onSelectionReset([]);
                  }}
                >
                  Retour
                </Button>
              )}

              <Button
                type="submit"
                colorScheme="blue"
                marginLeft="4"
                // className={'mx-4'}
                variant="outline"
              >
                Appliquer
              </Button>
            </div>
          </form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemboursementTotal;
