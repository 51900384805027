import './header.scss';

import React, { useState } from 'react';

import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Taches, Agence, Home, Brand, TableauDeBord, TableauDeBordSAV, MonitoringMenu, ListesMenu } from './header-components';

import { Demandes } from './demandesHeader';
import { AdminMenu, EntitiesMenu, AccountMenu } from '../menus';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [devmenu, setDevmenu] = useState(false);
  const [selected, setSelected] = useState('home');

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    //TODO: Header to be responsive later on => responsive for the app break when screen size changes ;
    <div id="app-header" className="shadow-sm bg-red-300">
      <LoadingBar className="loading-bar" />
      {/* fixed = "top" */}
      <Navbar data-cy="navbar" expand="sm" background="green" className=" bg-gray-500 navbar-container">
        {/* <NavbarToggler aria-label="Menu" onClick={toggleMenu} /> */}
        <Brand />
        <div className="flex w-full flex-center ">
          {/* <div className="invisible w-1/2 ">
            <Agence />
          </div> */}
          <div className="w-full ">
            <Agence />
          </div>
          {/* <div className="invisible">
            <Agence />
          </div> */}
        </div>

        {devmenu && (
          <Collapse isOpen={menuOpen} navbar>
            <Nav id="header-tabs" className="ml-auto" navbar>
              <Home />
              {props.isAuthenticated && <EntitiesMenu />}
              {props.isAuthenticated && props.isAdmin && (
                <AdminMenu showOpenAPI={props.isOpenAPIEnabled} showDatabase={!props.isInProduction} />
              )}
              <AccountMenu isAuthenticated={props.isAuthenticated} />
            </Nav>
          </Collapse>
        )}

        {!devmenu && (
          <Collapse isOpen={menuOpen} navbar>
            <Nav id="header-tabs" className="ml-auto" navbar>
              {/* <div className="invisible">
                <AccountMenu isAuthenticated={props.isAuthenticated} />
              </div>{' '} */}
              {/* <div className="invisible">
                <AccountMenu isAuthenticated={props.isAuthenticated} />
              </div> */}
              <Home path="/" />
              {/* <TableauDeBord />
              <TableauDeBordSAV /> */}
              <Taches path="/tab" />
              <MonitoringMenu path="/tableauDeBordSAV" />
              <ListesMenu path="/tableSAV" />
              {/* <DemandesSAV /> */}

              <AccountMenu isAuthenticated={props.isAuthenticated} />
            </Nav>
          </Collapse>
        )}
      </Navbar>
    </div>
  );
};

export default Header;
