import './home.scss';
import React, { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import { Button, useDisclosure } from '@chakra-ui/react';
import { IdentificationModal } from 'app/modules/home/Identification/IdentificationModal/IdentificationModal';
import HomeSimulation from './Identification/HomeComponent/homeSimulation';
import { useDispatch } from 'react-redux';
import { resetEntity } from 'app/entities/client/client.reducer';
import { IdentificationModalSAV } from './Identification/IdentificationModal/IdentificationModalSAV';

export const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenSAV, onOpen: onOpenSAV, onClose: onCloseSAV } = useDisclosure();

  const [isSimulationSavActivate, setIsSimulationSavActivate] = useState(false);
  const [isProspect, setIsProspect] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetEntity());
  });

  const handleSimulationSavActivation = (arg: boolean) => {
    setIsSimulationSavActivate(arg);
  };

  const handleSimulationProspect = (arg: boolean) => {
    setIsProspect(arg);
  };

  return (
    <div>
      <IdentificationModal
        isOpen={isOpen}
        onClose={onClose}
        isSimulationSavActivate={isSimulationSavActivate}
        isProspect={isProspect}
        onSimulationProspect={handleSimulationProspect}
      />
      <IdentificationModalSAV
        isOpen={isOpenSAV}
        onClose={onCloseSAV}
        isSimulationSavActivate={isSimulationSavActivate}
        isProspect={isProspect}
        onSimulationProspect={handleSimulationProspect}
      />
      <HomeSimulation onOpenSAV={onOpenSAV} onOpen={onOpen} onSimulationSavActivate={handleSimulationSavActivation} />
    </div>
  );
};

export default Home;
