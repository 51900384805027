import React, { useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Flex, Heading, SimpleGrid, Button, Spacer, Stack, StackDivider, Text } from '@chakra-ui/react';
import InfoTile3 from 'app/modules/commonComponents/InfoTiles/InfoTile3';
import { EchancierDataGridDyn } from '../../../commonComponents/Echeancier/EchancierDataGridDyn';
import { useReactToPrint } from 'react-to-print';
import { ArrowRightIcon, DocumentDownloadIcon, EyeIcon } from '@heroicons/react/outline';
import EcheancierPDF from 'app/modules/CreditStepper/ResultatStep/PdfPrints/echeancierPDF';
import { BrandIconPdf } from 'app/shared/layout/header/header-components';

const format = (x: number, suffix: string) => {
  return new Intl.NumberFormat('MA', {
    style: 'currency',
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};

export const ResultAccordion2 = props => {
  const componentRef = useRef(null);
  const componentRef2 = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
  });
  return (
    <div>
      <Accordion className="mx-6">
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="">
            {' '}
            <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>
            {props.synthese.propositions[props.index].nomProduit}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-100 border-t border-gray-200">
          <Typography className="w-100">
            <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
              <Box as="section">
                <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: '12', md: '8' }}>
                    <Stack mt="6">
                      <Text as="h3" className="-ml-8" fontSize="xl" fontWeight="extrabold">
                        Informations générales 4
                      </Text>
                      <Text pr="6" lineHeight="tall">
                        <ul>
                          <InfoTile3 label="Montant crédit:" value={format(props.synthese.propositions[props.index].montant, 'MAD')} />
                          <InfoTile3 label="Durée crédit:" value={props.synthese.propositions[props.index].dureeMois + ' mois'} />
                          {props.synthese.propositions[props.index].isFranchise ? (
                            <InfoTile3
                              label="Durée de la Franchise :"
                              value={props.synthese.propositions[props.index].dureeFranchise + ' mois'}
                            />
                          ) : null}
                          <InfoTile3 label="Taux d’intérêt  :" value={props.synthese.propositions[props.index].taux + ' %'} />
                          <InfoTile3 label="Convention:" value={props.synthese.propositions[props.index].convention} />
                          <InfoTile3 label="Périodicité échéance:" value={'Mensuelle'} />
                          {/* <InfoTile3 label="Montant échéance TTC :" value={format(props.resultat?.echeance, 'MAD')} /> */}
                          <InfoTile3 label="Taux TVA  :" value={props.synthese.propositions[props.index].taux + ' %'} />
                        </ul>
                      </Text>
                    </Stack>

                    <Stack mt="6">
                      <Text as="h3" className="-ml-8" fontSize="xl" fontWeight="extrabold">
                        Coût total du crédit
                      </Text>
                      <Text pr="6" lineHeight="tall">
                        <ul>
                          <InfoTile3 label="TEG: " value="3.5" />
                          <InfoTile3 label="Intérêts du prêt: " value={format(props.resultat.credits[0].montantInteret, 'MAD')} />
                          <InfoTile3
                            label="Frais dossier (HT):"
                            value={format(props.synthese.propositions[props.index].fraisDossier, 'MAD')}
                          />
                          <InfoTile3 label="Coût total assurances:" value={format(props.resultat.credits[0].coutTotalAssurance, 'MAD')} />
                          <InfoTile3 label="Prime actuarielle:" value="544" />
                        </ul>
                      </Text>
                    </Stack>
                    <Stack mt="6">
                      <Text as="h3" className="-ml-8" fontSize="xl" fontWeight="extrabold">
                        Informations sur les assurances
                      </Text>
                      <Text pr="6" lineHeight="tall">
                        <ul>
                          <InfoTile3
                            label={
                              (props.synthese.propositions[props.index].typeAssurance === 'MONTANT' ? 'Montant' : 'Taux') +
                              ' de l’assurance:'
                            }
                            value={
                              props.synthese.propositions[props.index].typeAssurance === 'MONTANT'
                                ? format(props.synthese.propositions[props.index].valueAssurance, 'MAD')
                                : props.synthese.propositions[props.index].valueAssurance + '%'
                            }
                          />
                          <InfoTile3 label="Mode de perception:" value={'Perçue Trimestriellement'} />
                        </ul>
                      </Text>
                    </Stack>
                  </SimpleGrid>
                </Box>

                <div className="hidden">
                  <div ref={componentRef}>
                    <div className="ml-8 mt-8 brandpdf-logo">
                      {' '}
                      <BrandIconPdf />{' '}
                    </div>
                    <Text as="h3" className="ml-32 my-24" fontSize="xl" fontWeight="extrabold">
                      Simulation Crédit:"{props.synthese.propositions[props.index].nomProduit}"
                    </Text>
                    <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: '12', md: '8' }}>
                        <Stack mt="6">
                          <Text as="h3" className="-ml-8" fontSize="md">
                            Informations générales 5
                          </Text>
                          <Text pr="6" lineHeight="tall">
                            <ul>
                              <InfoTile3 label="Montant crédit:" value={format(props.synthese.propositions[props.index].montant, 'MAD')} />
                              <InfoTile3 label="Durée crédit:" value={props.synthese.propositions[props.index].dureeMois + ' mois'} />
                              {props.synthese.propositions[props.index].isFranchise ? (
                                <InfoTile3
                                  label="Durée de la Franchise :"
                                  value={props.synthese.propositions[props.index].dureeFranchise + ' mois'}
                                />
                              ) : null}
                              <InfoTile3 label="Taux d’intérêt  :" value={props.synthese.propositions[props.index].taux + ' %'} />
                              <InfoTile3 label="Convention:" value={props.synthese.propositions[props.index].convention} />
                              <InfoTile3 label="Périodicité échéance:" value={'Mensuelle'} />
                              <InfoTile3 label="Taux TVA  :" value={props.synthese.propositions[props.index].taux + ' %'} />
                            </ul>
                          </Text>
                        </Stack>

                        <Stack mt="6">
                          <Text as="h3" className="-ml-8" fontSize="md">
                            Coût total du crédit
                          </Text>
                          <Text pr="6" lineHeight="tall">
                            <ul>
                              <InfoTile3 label="TEG: " value="3.5" />
                              <InfoTile3 label="Intérêts du prêt: " value={format(props.resultat.credits[0].montantInteret, 'MAD')} />
                              <InfoTile3
                                label="Frais dossier (HT):"
                                value={format(props.synthese.propositions[props.index].fraisDossier, 'MAD')}
                              />
                              <InfoTile3
                                label="Coût total assurances:"
                                value={format(props.resultat.credits[0].coutTotalAssurance, 'MAD')}
                              />
                              <InfoTile3 label="Prime actuarielle:" value="544" />
                            </ul>
                          </Text>
                        </Stack>
                        <Stack mt="6">
                          <Text as="h3" className="-ml-8" fontSize="md">
                            Informations sur les assurances
                          </Text>
                          <Text pr="6" lineHeight="tall">
                            <ul>
                              <InfoTile3
                                label="Montant de l’assurance:"
                                value={format(props.synthese.propositions[props.index].valueAssurance, 'MAD')}
                              />
                              <InfoTile3 label="Mode de perception:" value={'Perçue Trimestriellement'} />
                            </ul>
                          </Text>
                        </Stack>
                      </SimpleGrid>
                    </Box>
                  </div>
                </div>
                <Flex>
                  {' '}
                  <Box> </Box> <Spacer />{' '}
                  <Box>
                    {' '}
                    <Button
                      className="justify-self-end"
                      rightIcon={<DocumentDownloadIcon className="w-5" />}
                      colorScheme="blue"
                      variant="outline"
                      onClick={handlePrint}
                    >
                      Exporter
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </div>
          </Typography>
        </AccordionDetails>
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className="">
                {' '}
                <i className="fal fa-file-invoice mx-2"> </i> Echeancier{' '}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-100 border-t border-gray-200">
              <Typography className="w-100">
                <EchancierDataGridDyn ta={props.resultat.credits[props.index].echeancier} />
              </Typography>
            </AccordionDetails>
            <Flex>
              {' '}
              <Box> </Box> <Spacer />{' '}
              <Box>
                {' '}
                <Button
                  className="justify-self-end mr-4 mb-4"
                  rightIcon={<DocumentDownloadIcon className="w-5" />}
                  colorScheme="blue"
                  variant="outline"
                  onClick={handlePrint2}
                >
                  Exporter TA
                </Button>
              </Box>
            </Flex>
          </Accordion>
        </div>
      </Accordion>

      <div className="hidden">
        <EcheancierPDF
          ta={props.resultat.credits[props.index].echeancier}
          produit={props.synthese.propositions[props.index].nomProduit}
          ref={componentRef2}
        />
      </div>
    </div>
  );
};
