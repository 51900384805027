import { createAsyncThunk, createSlice, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { CreditEventsModel } from 'app/modules/SavStepper/models/CreditEvents.model';
import { defaultValue } from 'app/shared/model/user.model';
import { EntityState } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';

const initialState: EntityState<CreditEventsModel> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

// The creditEvent is used to handle Object under construction

// TODO this is normally can be combined with demande reducer, at least saving can be done using

// export const getResult = createAsyncThunk('resultats/fetch_entity_list', async (simulations: CreditEventsModel[]) => {
//   let { data } = await axios.post('api/resultats/debug/simSAV', simulations);
//   return data;
// });

export const getResult = createAsyncThunk('resultats/fetch_entity_list', async (simulation: CreditEventsModel) => {
  let { data } = await axios.post('api/resultats/simuler/eventSav', simulation);
  return data;
});

export const CreditEventsSlice = createSlice({
  name: 'creditEvents',
  initialState,
  reducers: {
    creditEventConcat: (state, action: PayloadAction<CreditEventsModel>) => {
      state.loading = true;
      state.entities = state.entities.concat(action.payload);
      state.loading = false;
    },
    creditEventReset: state => {
      state.entities = [];
    },
    creditEventDelete: (state, action: PayloadAction<number>) => {
      // console.log("ccccc",state.entities.filter(credit => credit.id !== action.payload));
      state.entities = state.entities.filter(credit => credit.id != action.payload);
      // state.entities = [];
    },
    creditEventUpdate: (state, action: PayloadAction<CreditEventsModel>) => {
      let index = state.entities.findIndex(entity => entity.id === action.payload.id);
      state.entities[index] = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getResult.fulfilled, (state, action) => {
      action.payload.forEach(element => {
        // state.entities.find(entity => entity.id === element[" id:"]).result = element;
        //TODO: here should match each result with the appropiete result;

        for (let i = 0; i < state.entities.length; i++) {
          state.entities[i].result = action.payload[i];
        }
      });
    });
  },
});

export const { creditEventReset, creditEventConcat, creditEventDelete, creditEventUpdate } = CreditEventsSlice.actions;

// Reducer
export default CreditEventsSlice.reducer;
