import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IDemandeGraphe } from 'app/shared/model/demandeGraphe.model';
import { ITempo } from './demandeGraphe1.reducer';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalPages: 0,
  number: 0,
  isFiltered: false,
};

export const getEntitiesForGraph3 = createAsyncThunk('demandes/fetch_entities_for_graph3', async () => {
  const requestUrl = `api/graphe3Get`;
  const { data } = await axios.get(requestUrl);
  return data;
});

export interface ITempo2 {
  choix?: any;
}

export const filterEntitiesForGraph3 = createAsyncThunk('demandes/fetch_test_graph3', async (values: ITempo) => {
  let params = `${values.filter}`;

  for (let i = 0; i < values.choix.length; i++) {
    params = `${params}&array=${values.choix[i].value}`;
  }

  const requestUrl = `api/graphe3Filter?${params}`;

  const { data } = await axios.get(requestUrl);
  return data;
});

export const demandeGraph1Slice = createSlice({
  name: 'demande',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntitiesForGraph3.fulfilled, (state, action) => {
        state.entities = action.payload;
      })
      .addCase(filterEntitiesForGraph3.fulfilled, (state, action) => {
        state.entities = action.payload;
      });
  },
});

export default demandeGraph1Slice.reducer;
