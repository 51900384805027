import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityState } from 'app/shared/reducers/reducer.utils';

interface SimulationModel {
  id?: number;
  creditId?: number;
  eventId?: number;
  agence?: string;
  date?: string | Date;
  state?: string;
}

const initialState: EntityState<SimulationModel> = {
  loading: false,
  errorMessage: null,
  entities: [
    {
      id: 1,
      creditId: 3339560,
      eventId: 1,
      agence: 'Casablanca',
      date: '10/06/2019',
      state: 'En cours',
    },
    {
      id: 2,
      creditId: 3339561,
      eventId: 3,
      agence: 'Casablanca',
      date: '7/08/2021',
      state: 'En cours',
    },
    {
      id: 3,
      creditId: 3339562,
      eventId: 3,
      agence: 'Casablanca',
      date: '02/05/2020',
      state: 'Validée',
    },
    {
      id: 4,
      creditId: 3339563,
      eventId: 1,
      agence: 'Casablanca',
      date: '10/02/2016',
      state: 'Validée',
    },
    {
      id: 5,
      creditId: 3339563,
      eventId: 1,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'Refusée',
    },
    {
      id: 6,
      creditId: 3339565,
      eventId: 2,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'En cours',
    },
    {
      id: 7,
      creditId: 3339563,
      eventId: 3,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'Validée',
    },
    {
      id: 8,
      creditId: 3339567,
      eventId: 1,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'Validée',
    },
    {
      id: 9,
      creditId: 3339568,
      eventId: 3,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'En cours',
    },
    {
      id: 10,
      creditId: 3339569,
      eventId: 2,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'Refusée',
    },
    {
      id: 11,
      creditId: 3339570,
      eventId: 3,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'Refusée',
    },

    {
      id: 12,
      creditId: 3339569,
      eventId: 2,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'Refusée',
    },
    {
      id: 13,
      creditId: 3339572,
      eventId: 3,
      agence: 'Casablanca',
      date: '10/01/2021',
      state: 'En cours',
    },
    {
      id: 14,
      creditId: 3339560,
      eventId: 3,
      agence: 'casablanca',
      date: '0/01/2021',
      state: 'Validée',
    },
  ],
  entity: {},
  updating: false,
  updateSuccess: false,
};

export const SimulationsSlice = createSlice({
  name: 'simulations',
  initialState,
  reducers: {
    // this to add clients later on
    simulationConcat: (state, action: PayloadAction<SimulationModel>) => {
      state.loading = true;
      state.entities = state.entities.concat(action.payload);
      state.loading = false;
    },
    updateSimulationState: (state, action) => {
      let index = state.entities.findIndex(entity => entity.id === action.payload.id);
      state.entities[index].state = action.payload.isValid ? 'Validée' : 'Refusée';
    },
    submitSimulationState: (state, action) => {
      let index = state.entities.findIndex(entity => entity.id === action.payload.id);
      state.entities[index].state = 'Soumise';
    },
  },
});

export const { simulationConcat, updateSimulationState, submitSimulationState } = SimulationsSlice.actions;
export default SimulationsSlice.reducer;

// name: 'clients',
// intialState,
// reducers : {
//     creditEventConcat: (state, action: PayloadAction<ClientModel>) => {
//         state.loading = true;
//         state.entities = state.entities.concat(action.payload);
//         state.loading = false;
//       },
// }
