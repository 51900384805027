import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

const SavStepperHeader = ({ activeStep, steps, isStepOptional, isStepSkipped, onActiveStep, classes, getSteps }) => {
  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};
        if (isStepOptional(index)) {
          labelProps.optional = <Typography variant="caption">Optionel</Typography>;
        }
        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps} classes={{ label: classes.step_label }}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
    //
  );
};

export default SavStepperHeader;
