import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IDemandeGraphe } from 'app/shared/model/demandeGraphe.model';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalPages: 0,
  number: 0,
  isFiltered: false,
};

export const getEntitiesSAVForGraph1 = createAsyncThunk('demandes/fetch_entitiesSAV_for_graph1', async () => {
  const requestUrl = `api/graphe12SAVGet`;
  const { data } = await axios.get(requestUrl);
  return data;
});

export interface ITempo {
  filter?: any;
  choix?: any;
}

export const filterEntitiesSAVForGraph1 = createAsyncThunk('demandes/fetch_entitiesSAV_for_graph11', async (x: ITempo) => {
  const map1 = new Map();

  map1.set('Casa1', '1');
  map1.set('Casa2', '2');
  map1.set('Casa3', '3');
  map1.set('Rabat1', '4');
  map1.set('Rabat2', '5');
  map1.set('Tanger1', '6');
  map1.set('Tanger2', '7');
  map1.set('Agadir1', '8');
  map1.set('Agadir2', '9');
  map1.set('Fes1', '10');
  map1.set('Fes2', '11');
  map1.set('Marrakech1', '12');
  map1.set('Marrakech2', '13');

  let params = `array=${x.choix[0].value}`;

  for (let i = 1; i < x.choix.length; i++) {
    params = `${params}&array=${x.choix[i].value}`;
  }

  params = params.concat('&');

  const requestUrl = `api/graphe12SAVFilter?${params}${x.filter}`;
  const { data } = await axios.get(requestUrl);

  return data;
});

export const demandeSAV1Graph1Slice = createSlice({
  name: 'demandeSAV1',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntitiesSAVForGraph1.fulfilled, (state, action) => {
        state.entities = sortData(action.payload);
      })
      .addCase(filterEntitiesSAVForGraph1.fulfilled, (state, action) => {
        state.entities = sortData(action.payload);
      });
  },
});

const monthNames = {
  Jan: 1,
  Fev: 2,
  Mars: 3,
  Avr: 4,
  Mai: 5,
  Juin: 6,
  Juil: 7,
  Aout: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

function sortData(columnsResult) {
  columnsResult = columnsResult.sort((a, b) => (monthNames[a.name] > monthNames[b.name] ? 1 : -1));

  return columnsResult;
}

export default demandeSAV1Graph1Slice.reducer;
