import { createSlice } from '@reduxjs/toolkit';
import { ICreditSelectionne } from 'app/shared/model/creditSelectionne.model';

const initialState: ICreditSelectionne = {
  creditsSelectionnes: [],
};

export const creditSelectionneSlice = createSlice({
  name: 'creditSelectionne',
  initialState,
  reducers: {
    editCreditSelectionne: (state, action) => {
      var ids = action.payload.selectedIds;
      state.creditsSelectionnes = action.payload.creditsSelectionnes.filter(credit => ids.includes(credit.id));
    },
  },
});

export const { editCreditSelectionne } = creditSelectionneSlice.actions;

export default creditSelectionneSlice.reducer;
