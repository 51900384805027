import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { ListeDesSimulation } from 'app/modules/workflow/listeDesSimulation';
import { ListeDesTaches } from 'app/modules/random/listeDesTaches';
import { RecapDecision } from 'app/modules/workflow/recapDecision';
// import { RecapDecision1 } from './modules/table/recapDecision1';
import { PriseDecision } from 'app/modules/random/priseDecision';
import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import StepperPage from 'app/modules/CreditStepper/MainSteppers/StepperPage';
import SavStepperPage from 'app/modules/SavStepper/MainStepper/SavStepperPage';
// import { ListeSimulation } from './modules/table/listeSimulations';
import { ListeSimulationCredit } from 'app/modules/ListeDemandesCredit/MainComponent';

import { TableauDeBord } from 'app/modules/MonitoringGraphes/TableauDeBord';
import { TableauDeBordSAV } from 'app/modules/MonitoringGraphes/TableauDeBordSAV';

import { DetailSimulationCredit2 } from 'app/modules/ListeDemandesCredit/detail/MainComponent';
import { DetailSimulationSAV } from 'app/modules/ListeDemandesSAV/detail/detailSimulationSAV';

import { ResultStepper } from 'app/modules/ListeDemandesSAV/detail/MainComponent';

// import { ListeSimulation } from './modules/table/listeSimulations';

import listDemandes from './modules/ListeDemandesSAV/MainComponent';

import ListeMesTaches from './entities/taches2/listeMesTaches';
import CompleteTache from './entities/taches2/completeTache';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => {
  return (
    <div className="view-routes">
      <Switch>
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute path="/account/register" component={Register} />
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
        <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
        <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
        <PrivateRoute path="/table" exact component={ListeDesSimulation} />
        {/* <PrivateRoute path="/table2" exact component={ListeSimulation} /> */}
        {/* <PrivateRoute path="/tableSimulationCredit" exact component={ListeSimulationCredit} />
        <PrivateRoute path="/tab" exact component={ListeDesTaches} /> */}
        <PrivateRoute path="/detail" exact component={RecapDecision} />
        <PrivateRoute path="/demandesCredit/detail/:id" exact component={DetailSimulationCredit2} />

        <PrivateRoute path="/tableSAV" exact component={listDemandes} />
        <PrivateRoute path="/tableSimulationCredit" exact component={ListeSimulationCredit} />
        <PrivateRoute path="/tableauDeBord" exact component={TableauDeBord} />
        <PrivateRoute path="/tableauDeBordSAV" exact component={TableauDeBordSAV} />

        <PrivateRoute path="/tab" exact component={ListeMesTaches} />
        {/* <PrivateRoute path="/detail/:id" exact component={ResultStepper} /> */}
        <PrivateRoute path="/detail/:id" exact component={DetailSimulationSAV} />
        {/* <PrivateRoute path='/detail/:id/viewPDF' exact component={Test}/> */}
        <PrivateRoute path="/decision" exact component={PriseDecision} />
        {/* <PrivateRoute path="/decision2/:simulationId/:idTache" exact component={CompleteTache} /> */}
        <PrivateRoute path="/decision2/:idDemandeSav/:idTache" exact component={CompleteTache} />
        <PrivateRoute path="/SAV" component={SavStepperPage} hasAnyAuthorities={[AUTHORITIES.USER]} />
        {<PrivateRoute path="/" exact component={Home} hasAnyAuthorities={[AUTHORITIES.USER]} />}
        <PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.USER]} />

        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
