import { iFicheClient } from 'app/shared/model/ficheClient.model';
import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { TypeId } from 'app/shared/model/enumerations/type-id.model';

const initialState: iFicheClient = {
  id: '1',
  nom: 'XXXXX',
  prenom: 'Youssef ',
  email: 'Youssef@gmail.com',
  numTelephone: '07 95 46 54',
  CIN: 'AD123456',
  addresse: '12 Rue de provence. Résidence la Paix. Appt N°8.Casablanca',
  revenus: 20000,
  echeance: 3500,
  echeanceAutreCredit: 1000,
  employeur: 'Sopra Banking Solution',
  activite: 'Cadre',
  paysNaissance: 'Maroc',
  paysResidence: 'Maroc',
  dateNaissance: '27/08/1990',
  age: 23,
  categorieSocioPro: 'AZEAZe',
  credits: null,
};

interface clientIdentification {
  chaineId?: string;
  typeId?: TypeId;
}

export const postSyntheseCredit = createAsyncThunk(
  'credit/postSynthese',
  async () => {
    const requestUrl = `api/client/getByIdentifiant`;
    return axios.post(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const ficheClientSlice = createSlice({
  name: 'ficheClient',
  initialState,
  reducers: {
    editFicheClient: (state, action) => {
      state[action.payload.champ] = action.payload.inputValue;
    },
    deleteFichClient: state => {
      return null;
    },
  },
  extraReducers(builder) {},
});

export const { editFicheClient, deleteFichClient } = ficheClientSlice.actions;

export default ficheClientSlice.reducer;
