import 'app/modules/random/detail.scss';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import 'react-awesome-lightbox/build/style.css';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'styled-react-modal';
import { Button as Button2 } from '@material-ui/core';

import Lightbox from 'react-awesome-lightbox';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import PDFViewer from 'pdf-viewer-reactjs';
import { Row, Col, Alert, NavLink } from 'reactstrap';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import EchancierDataGrid from 'app/modules/commonComponents/Echeancier/EchancierDataGrid';
import { Feature } from 'app/modules/CreditStepper/SyntheseStep/SyntheseStepper';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { Box, Divider, Heading, SimpleGrid, Stack, StackDivider, Text } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';

import {
  Select,
  ModalFooter,
  Button,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { Modal as Modal2 } from '@chakra-ui/react';
import TextField from '@material-ui/core/TextField';
import ModalBody from 'reactstrap/es/ModalBody';

import { useColorModeValue as mode } from '@chakra-ui/react';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import TextareaAutosize from 'react-textarea-autosize';

import { ResultStepper } from 'app/modules/ListeDemandesSAV/detail/ResultStepper2SAV';
// import { getEntity } from 'app/entities/piece-justificatif/piece-justificatif.reducer';

import { getEntity } from 'app/entities/demande/demandeSAV.reducer';
import { Link, useParams } from 'react-router-dom';

const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition : all 0.3s ease-in-out;`;
export const DetailSimulationSAV = props => {
  /* const PieceJustificatifList = useAppSelector(state => state.pieceJustificatif.entities); //pour les requete apres

  const PJList = useMemo(() => {
    return PieceJustificatifList;
  }, [PieceJustificatifList]);*/

  const [openImgModal, setOpenImgModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [validated, setValidated] = useState(false);

  const [PdfUrl, setPdfUrl] = useState(''); // workaround LightBox int
  const [imgUrl, setImageUrl] = useState(''); // workaround LightBox int
  const { isOpen: validateIsOpen, onOpen: validateOnOpen, onClose: validateOnClose } = useDisclosure();
  const { isOpen: validateDecisionIsOpen, onOpen: validateDecisionOnOpen, onClose: validateDecisionOnClose } = useDisclosure();
  const { isOpen: refuseDecisionIsOpen, onOpen: refuseDecisionOnOpen, onClose: refuseDecisionOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
  const [deletedIndex, setDeletedIndex] = useState(0);
  const [validatedIndex, setValidatedIndex] = useState(0); // workaround LightBox int

  // const remove2 = (nb: number) => {
  //   const array = [...prdtArray];
  //     array.splice(nb, 1);
  //     setPrdtArray(array);
  //     };
  // const confirmDelete = () => {
  //   const array = [...prdtArray];
  //   array.splice(deletedIndex, 1);
  //   setPrdtArray(array);
  // };

  // const confirmValidate = () => {
  //   const array = [...prdtArray];
  //   array[validatedIndex].statut = 'Recu';
  //   setPrdtArray(array);
  // };

  return (
    <div>
      <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
        <Box p={5} shadow="base" borderWidth="3px">
          {/* <div className="col-6 p-8" style={{ margin: '0 auto' }}>
            <Steps direction="horizontal" current={2}>
              <Step title="En cours de saisie" />
              <Step title="À valider" />
              <Step title="Documents validés" />
              <Step title="Demande clôturée" />
            </Steps>
          </div> */}
          <div className="col-9 pt-8" style={{ margin: '0 auto' }}>
            <ResultStepper />
          </div>
        </Box>
      </Stack>
    </div>
  );
};
// function dispatch(arg0: any) {
//   throw new Error('Function not implemented.');
// }
