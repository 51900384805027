import dayjs from 'dayjs';

import { ISimulation } from 'app/shared/model/simulation.model';

import { ISimulationSav } from 'app/shared/model/simulation-sav.model';

import { TypeIdentite } from 'app/shared/model/enumerations/type-identite.model';

import { TypeActivite } from 'app/shared/model/enumerations/type-activite.model';

export interface IClient {
  id?: number;
  referenceClient?: string;

  typeEntite?: string;
  nom?: string;

  prenom?: string;

  age?: number;

  dateOfBirthday?: string;

  paysNationalite?: string;

  countryOfResidence?: string;

  refTiers?: number;

  typeId?: TypeIdentite;

  numIdentite?: string;

  typeActivite?: TypeActivite;

  activity?: string;

  employeur?: string;

  revenuMensuel?: number;

  autreRevenu?: number | null;

  email?: string;
  numTel?: string;
  address?: string;
  categorieSocioPro?: string;
  date?: string;
  dateInscription?: string;
  amountRepayment?: number;
  amountOthersRepayments?: number;
  identifiants?: null;
  telephoneNumber?: string;
  countryOfBirth?: string;
  incomes?: number;
  credits?: any[];
  simulations?: ISimulation[] | null;
  simulationSavs?: ISimulationSav[] | null;
  numeroCompte?: any;
}

//TODO credits atttribute need to be declared using credits.model
export const defaultValue: Readonly<IClient> = {
  numeroCompte: ['43434'],
  typeEntite: 'PROSPECT',
  // clientId:1111,
};
