import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IClient, defaultValue } from 'app/shared/model/client.model';
import { TypeId } from 'app/shared/model/enumerations/type-id.model';

// import {retrieveClientLoans} from '../credit/credit.reducer';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

interface clientIdentification {
  chaineId?: string;
  typeId?: TypeId;
}
/*
//although we can use IClient as payload , i prefer to use a separate interface for code security
interface ficheClient{
  id:number, //for updating purposes , no '?' -> can't be null
  email: string,
  numTelephone: string | number, //for 0 in the beginning
  addresse: string,
  echeanceAutreCredit: number,
}*/
const apiUrl = 'api/clients';

// Actions

export const getEntities = createAsyncThunk('client/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IClient[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'client/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IClient>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
// export const identifyClient = createAsyncThunk(
//   'client/identify_client',
//   async (identification: clientIdentification) => {
//     const requestUrl = `api/client/getByIdentifiant`;
//     return axios.post<IClient>(requestUrl, identification);
//   },
//   { serializeError: serializeAxiosError }
// );
export const identifyClient = createAsyncThunk(
  'client/identify_client',

  async (id: string | number, thunkAPI) => {
    const requestUrl = `api/client/getInfoClientByIdentifiant/1`;
    const result = await axios.get<IClient>(requestUrl);
    const referenceClient = result.data.referenceClient;
    console.log('refClient', referenceClient);

    thunkAPI.dispatch(retrieveClientLoans(referenceClient));
    return result;
  },

  { serializeError: serializeAxiosError }
);

export const retrieveClientLoans = createAsyncThunk(
  'client/retrieve_loans',

  async (id: string | number) => {
    // console.log('hitRETRIVED');
    if (id == null) {
      console.log('IDNULL!!');
      id = '18700113119';
    } else {
      console.log('IDNULL!!2:' + id);
    }
    const requestUrl = `api/getCreditsByCLientReference/${id}`;

    return axios.get<any[]>(requestUrl);
  },

  { serializeError: serializeAxiosError }
);

export const identifyClientForSav = createAsyncThunk(
  'client/identify_client',

  async (id: string | number) => {
    const requestUrl = `api/client/getInfoClientByIdentifiant/1`;

    return axios.get<IClient>(requestUrl);
  },

  { serializeError: serializeAxiosError }
);

export const identifyCorporate = createAsyncThunk(
  'client/identify_corporate',

  async (id: string | number) => {
    const requestUrl = `api/client/getInfoClientByIdentifiant/1`;

    return axios.get<IClient>(requestUrl);
  },

  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'client/create_entity',
  async (entity: IClient, thunkAPI) => {
    const result = await axios.post<IClient>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'client/update_entity',
  async (entity: IClient, thunkAPI) => {
    const result = await axios.put<IClient>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'client/partial_update_entity',
  async (entity: IClient, thunkAPI) => {
    const result = await axios.patch<IClient>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'client/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IClient>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ClientSlice = createEntitySlice({
  name: 'client',
  initialState,
  reducers: {
    resetEntity: state => {
      state.entity = {};
    },
    editFicheClient2: (state, action) => {
      state.entity[action.payload.champ] = action.payload.inputValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(identifyClient.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
        state.entity.typeEntite = 'CLIENT';
        // state.entity.numeroCompte = [{numeroCompte:"45646545474751"}];
      })
      .addCase(retrieveClientLoans.fulfilled, (state, action) => {
        state.loading = false;
        console.log('RETRIEVED1', action.payload.data);
        console.log('RETRIEVED2', state.entity);

        state.entities = action.payload.data;
        console.log('RETRIEVED3', state);
        // state.entity = action.payload.data;
        // state.entity.typeEntite = 'CLIENT';
      })
      .addCase(identifyCorporate.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
        state.entity.typeEntite = 'CORPORATE';
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, identifyClient, identifyCorporate), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetEntity, editFicheClient2 } = ClientSlice.actions;

// Reducer
export default ClientSlice.reducer;
