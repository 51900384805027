import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Badge,
} from '@chakra-ui/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { getStatus } from '../testing/decisionMaking';
import { getActiveEntity, getHistoryEntitiesByDemandeId } from './taches.reducer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/config/store';
import moment from 'moment';

interface Props {
  idDemandeSav: string;
}

const HistModal = ({ idDemandeSav }: Props) => {
  const HistTache = useAppSelector(state => state.taches2.history);
  const activeTache = useAppSelector(state => state.taches2.entity);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveEntity(idDemandeSav)); // get active task
    dispatch(getHistoryEntitiesByDemandeId(idDemandeSav)); // get history of tasks
  }, []);

  const gethist = () => {
    return (
      <Box className={'mx-6'}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: '#3ABED6' }}>
                <TableCell style={{ width: '15%', color: 'white' }}>Décision</TableCell>
                <TableCell align="right" style={{ width: '25%', color: 'white' }}>
                  Commentaire
                </TableCell>
                <TableCell align="right" style={{ width: '15%', color: 'white' }}>
                  Duration
                </TableCell>
                <TableCell align="right" style={{ width: '15%', color: 'white' }}>
                  Modifié le
                </TableCell>
                <TableCell align="right" style={{ width: '15%', color: 'white' }}>
                  Modifié par
                </TableCell>
                <TableCell align="right" style={{ width: '15%', color: 'white' }}>
                  Statut
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {HistTache.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" style={{ verticalAlign: 'center', fontWeight: 'bold' }}>
                    {row.task.name}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {row.comment}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {moment.duration(row.task.durationInMillis, 'milliseconds').days() + 'J '}
                    {moment.duration(row.task.durationInMillis, 'milliseconds').hours() + 'h '}
                    {moment.duration(row.task.durationInMillis, 'milliseconds').minutes() + 'min'}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {moment(row.task.endTime).format('DD/MM/YYYY à hh:mm:ss')}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {row.task.assignee}
                  </TableCell>
                  <TableCell align="center" style={{ verticalAlign: 'center' }}>
                    {getStatus(
                      row.task.description === 'validée'
                        ? 1
                        : row.task.description === 'refusée'
                        ? 0
                        : row.task.description === 'a modifier'
                        ? 3
                        : row.task.description === 'annulée'
                        ? 6
                        : 5
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const fetchHist = () => {
    return (
      <>
        {activeTache?.name!== undefined && <Box ml="3" borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor={'black'}>
          <Box p="4">
            <Box display="flex" alignItems="baseline">
              <Box fontWeight="bold" letterSpacing="wide" fontSize="sm" textTransform="uppercase" ml="2">
                {activeTache.name}
              </Box>
              <Badge borderRadius="full" px="2" marginRight={0} marginLeft={'auto'}>
                En Attente
              </Badge>
            </Box>
            <br />
            <Box alignItems="baseline">
              <Box fontWeight="semibold" fontSize="xs" ml="2">
                Statut : {activeTache.assignee === null ? 'Non Affectée' : 'Affecté pour traitement'}
              </Box>
              <Box fontWeight="semibold" fontSize="xs" ml="2">
                Affectée à : {activeTache.assignee === null ? '--------' : activeTache.assignee}
              </Box>
              <Box fontWeight="semibold" fontSize="xs" ml="2">
                Créée le : {moment(activeTache.startTime).format('DD/MM/YYYY à hh:mm:ss')}
              </Box>
            </Box>
          </Box>
        </Box>}
        
        <br />
        {HistTache.map((row, index) => (
          <>
            <Box ml="3" borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor={'black'}>
              <Box p="4">
                <Box display="flex" alignItems="baseline">
                  <Box fontWeight="bold" letterSpacing="wide" fontSize="sm" textTransform="uppercase" ml="2">
                    {row.task.name}
                  </Box>
                  <Badge
                    borderRadius="full"
                    px="2"
                    marginRight={0}
                    marginLeft={'auto'}
                    colorScheme={
                      row.task.description === 'validée'
                        ? 'green'
                        : row.task.description === 'refusée'
                        ? 'red'
                        : row.task.description === 'a modifier'
                        ? 'yellow'
                        : 'gray'
                    }
                  >
                    {row.task.description}
                  </Badge>
                </Box>
                <br />
                <Box display="flex" alignItems="baseline">
                  <Box alignItems="baseline">
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Statut : Traité
                    </Box>
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Affectée à : {row.task.assignee}
                    </Box>
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Créée le : {moment(row.task.startTime).format('DD/MM/YYYY à hh:mm:ss')}
                    </Box>
                  </Box>
                  <Box alignItems="baseline" width={'50%'} margin={'0 auto'}>
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Commentaire : {row.comment}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <>
    
    <Button onClick={onOpen} color={"#3ABED6"}>
      Ouvrir Liste des Tâches
    </Button>
      

      <Modal isOpen={isOpen} onClose={onClose} size={'2'}>
        <ModalOverlay backdropFilter="blur(10px) hue-rotate(90deg)" />
        <ModalContent style={{ width: '40%', maxHeight: '70%' }}>
          <ModalHeader className="text-blue-800">Liste Des Tâches</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="overflow-auto">{fetchHist()}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HistModal;
