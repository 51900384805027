import React, { useEffect, useState } from 'react';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import appConfig from 'app/config/constants';
import { BookmarkIcon, HomeIcon, MenuIcon, PencilAltIcon, ChartBarIcon } from '@heroicons/react/outline';
import { useWindowDimensions } from 'app/shared/customHook';
import { ListeDropdown, MonitoringDropdow } from '../menus/menu-components';
import MenuItem from 'app/shared/layout/menus/menu-item';

// import { Menu, MenuIcon } from '@chakra-ui/menu';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="https://pbs.twimg.com/profile_images/1229797275876151301/fSVPBcPP_400x400.png" alt="Logo" />
  </div>
);

export const BrandIconPdf = props => (
  <div {...props} className="brandpdf-icon">
    <img src="https://www.timlogsolutions.com/wp-content/uploads/2020/03/Logo-Credit-du-Maroc-1024x275-1.png" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
    <span className="brand-title " style={{ color: '#122f96' }}>
      Simulateur SAV
    </span>
    <span className="navbar-version">{appConfig.VERSION}</span>
  </NavbarBrand>
);

export const Home = props => {
  const location = useLocation();
  const selectedBoolean = location.pathname == props.path;
  return (
    <NavItem>
      <NavLink tag={Link} to={props.path} className="d-flex align-items-center hover:text-teal-600">
        <HomeIcon className={`md:w-3 xl:w-5  ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`} />
        <span className={`font-thin ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`}>Accueil</span>
      </NavLink>
    </NavItem>
  );
};

export const Taches = props => {
  const location = useLocation();
  const selectedBoolean = location.pathname == props.path;
  return (
    <NavItem>
      <NavLink tag={Link} to={props.path} className="d-flex align-items-center ">
        <BookmarkIcon className={`md:w-3 xl:w-5  ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`} />
        <span className={`font-thin ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`}>Tâches</span>
      </NavLink>
    </NavItem>
  );
};

export const Demandes = props => (
  <NavItem>
    <NavLink tag={Link} to="/tableSimulationCredit" className="d-flex align-items-center ">
      {/* <FontAwesomeIcon icon="list" className="mx-1" /> */}
      <PencilAltIcon className="md:w-3 xl:w-5" />
      <span className="md:font-thin	 xl:font-normal	">Demandes</span>
    </NavLink>
  </NavItem>
);

export const DemandesSAV = props => (
  <NavItem>
    <NavLink tag={Link} to="/tableSimulationSAV" className="d-flex align-items-center ">
      {/* <FontAwesomeIcon icon="list" className="mx-1" /> */}
      <PencilAltIcon className="md:w-3 xl:w-5" />
      <span className="md:font-thin	 xl:font-normal	">Demandes SAV</span>
    </NavLink>
  </NavItem>
);

export const TableauDeBord = props => (
  <NavItem>
    <NavLink tag={Link} to="/tableauDeBord" className="d-flex align-items-center ">
      {/* <FontAwesomeIcon icon="list" className="mx-1" /> */}
      <ChartBarIcon className="md:w-3 xl:w-5" />
      <span className="md:font-thin	 xl:font-normal	text-blue-800">Monitoring</span>
    </NavLink>
  </NavItem>
);

export const TableauDeBordSAV = props => (
  <NavItem>
    <NavLink tag={Link} to="/tableauDeBordSAV" className="d-flex align-items-center ">
      {/* <FontAwesomeIcon icon="list" className="mx-1" /> */}
      <ChartBarIcon className="md:w-3 xl:w-5" />
      <span className="md:font-thin	 xl:font-normal	">Monitoring SAV</span>
    </NavLink>
  </NavItem>
);

export const Agence = props => {
  const { height, width } = useWindowDimensions();

  return (
    <div>
      {width > 1015 ? (
        <span className="mt-15 pl-1 md:pl-5 inline-flex text-xs leading-10 font-extrabold text-gray-600">Agence Casablanca</span>
      ) : null}
      {width > 1210 ? (
        <span className="mt-15 pl-1 md:pl-5 inline-flex text-xs leading-10 font-thin text-gray-600">{moment().format('LLL')}</span>
      ) : null}
    </div>
  );
};

export const MonitoringMenu = props => {
  const location = useLocation();
  const selectedBoolean = location.pathname == props.path;
  return (
    <NavItem>
      <NavLink tag={Link} to={props.path} className="d-flex align-items-center ">
        {/* <FontAwesomeIcon icon="list" className="mx-1" /> */}
        <ChartBarIcon className={`md:w-3 xl:w-5  ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`} />
        <span className={`font-thin ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`}>Monitoring</span>
      </NavLink>
    </NavItem>
  );
};

export const ListesMenu = props => {
  const location = useLocation();
  const selectedBoolean = location.pathname == props.path;
  return (
    <NavItem>
      <NavLink tag={Link} to={props.path} className="d-flex align-items-center ">
        <MenuIcon className={`md:w-3 xl:w-5  ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`} />
        <span className={`font-thin ${selectedBoolean ? 'text-teal-600' : 'text-blue-800'}`}>Liste</span>
      </NavLink>
    </NavItem>
  );
};

const listeMenuItems = (
  <>
    <MenuItem icon="signal-alt-1" to="/tableSimulationCredit" data-cy="loqgin">
      Liste Credit
    </MenuItem>
    <MenuItem icon="signal-alt-1" to="/tableSAV" data-cy="rezgister">
      Liste SAV
    </MenuItem>
  </>
);
