import React from 'react';

const ReadOnlyTile = ({ label, value }) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-bold text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
      {/* <div className="pngcontainer1">
    <img src="https://pbs.twimg.com/profile_images/1229797275876151301/fSVPBcPP_400x400.png" />
  <img src="http://boursenews.fnh.ma/uploads/actualites/5e6744682564a.png" />
</div> */}
    </div>
  );
};

export default ReadOnlyTile;
