import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityState } from 'app/shared/reducers/reducer.utils';

interface ProduitModel {
  id?: number;
  nomProduit?: string;
  taux?: number;
}

const initialState: EntityState<ProduitModel> = {
  loading: false,
  errorMessage: null,
  entities: [
    { id: 1, nomProduit: 'E-CONSO', taux: 4 },
    { id: 2, nomProduit: 'IMMOBILIER SAKANE', taux: 4 },
    { id: 3, nomProduit: 'CRÉDIT AUTO SANS GRANTIE', taux: 9 },
    { id: 4, nomProduit: 'CRÉDIT SAKANE SANS GRANTIE', taux: 2 },
  ],
  entity: {},
  updating: false,
  updateSuccess: false,
};

export const ProduitSlice = createSlice({
  name: 'produit',
  initialState,
  reducers: {
    produitConcat: (state, action: PayloadAction<ProduitModel>) => {
      state.loading = true;
      state.entities = state.entities.concat(action.payload);
      state.loading = false;
    },
  },
});

export const { produitConcat } = ProduitSlice.actions;
export default ProduitSlice.reducer;
