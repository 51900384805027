import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputLabel } from '@material-ui/core';
import { Button, Select, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { propositionUpdate } from '../Reducers/syntheseCreditSlice';

const AsssuranceModal = props => {
  const dispatch = useAppDispatch();

  const [produit, setProduit] = useState(null);

  const decimalCount = num => {
    const numStr = String(num);
    if (numStr.includes('.')) {
      return numStr.split('.')[1].length;
    }
    return 0;
  };

  const withOneDecimal5Cap = inputObj => {
    const { value } = inputObj;
    // console.log('decimal portion', value % 1);
    if (decimalCount(value) === 0 || (decimalCount(value) === 1 && (value % 1) * 10 === 5)) return true;
    else return false;
  };

  const onChange = (cle: string, index: number, v: any) => {
    props.setPrdtArray(state => {
      const list = state.map((item, j) => {
        if (props.prdtArray[j].id === index) {
          return {
            ...item,
            [cle]: v,
          };
        } else {
          return item;
        }
      });

      return list;
    });
  };

  useEffect(() => {
    setProduit(props.produit);
  }, [props.produit]);

  const resetProduitLocal = () => {
    setProduit(props.produit);
  };

  const handleValidate = () => {
    dispatch(propositionUpdate(produit));
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Ajouter assurance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form className="space-y-8 divide-y divide-gray-200">
              <div>
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-xl font-medium text-gray-500">Assurance</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <dt className="text-md font-medium text-gray-500">
                        <Select
                          // labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={produit?.typeAssurance}
                          onChange={e => {
                            setProduit({ ...produit, typeAssurance: e.target.value });
                          }}
                        >
                          <option value={'TAUX'}>Taux</option>
                          <option value={'MONTANT'}>Montant</option>
                        </Select>
                      </dt>
                      <dd className="mt-1 text-md  font-medium text-gray-900 sm:mt-0 sm:col-span-2">
                        {produit?.typeAssurance === 'MONTANT' ? (
                          <NumberFormat
                            placeholder="Valeur en MAD"
                            label={produit?.typeAssurance}
                            customInput={TextField}
                            thousandSeparator=" "
                            suffix=" MAD"
                            isAllowed={withOneDecimal5Cap}
                            value={produit?.valueAssurance}
                            onValueChange={values => {
                              const { formattedValue, value } = values;
                              setProduit({ ...produit, valueAssurance: parseFloat(value) });
                            }}
                          />
                        ) : (
                          <NumberFormat
                            placeholder="Pourcentage"
                            label={produit?.typeAssurance}
                            customInput={TextField}
                            thousandSeparator=" "
                            suffix=" %"
                            isAllowed={undefined}
                            value={produit?.valueAssurance}
                            onValueChange={values => {
                              const { formattedValue, value } = values;
                              setProduit({ ...produit, valueAssurance: parseFloat(value) });
                            }}
                          />
                        )}
                      </dd>
                    </dd>
                  </div>
                </dl>
                <dl className="sm:divide-y sm:divide-gray-200 border-t">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-xl font-medium text-gray-500 ">Accessoire</dt>
                    <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <dt className="text-md font-medium text-gray-500">
                        <NumberFormat
                          placeholder="Valeur en MAD"
                          label="Frais dossier"
                          customInput={TextField}
                          thousandSeparator=" "
                          suffix=" MAD"
                          isAllowed={withOneDecimal5Cap}
                          value={produit?.fraisDossier}
                          onValueChange={values => {
                            const { formattedValue, value } = values;
                            setProduit({ ...produit, fraisDossier: parseFloat(value) });
                          }}
                        />
                      </dt>
                      {produit?.fraisDossier !== '' ? (
                        <dd className="mt-1 text-md  font-medium text-gray-900 sm:mt-0 sm:col-span-2  animate__animated animate__fadeInDown">
                          <InputLabel id="demo-simple-select-label" className="mt-3">
                            Nature :
                          </InputLabel>
                          <Select
                            id="demo-simple-select"
                            value={produit?.natureFDossier}
                            onChange={e => {
                              setProduit({ ...produit, natureFDossier: e.target.value });
                            }}
                          >
                            <option value={'PERCEVOIR'}>A percevoir</option>
                            <option value={'CAPITALISER'}>A capitaliser</option>
                          </Select>
                        </dd>
                      ) : null}
                    </div>
                  </div>
                </dl>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                handleValidate();
                props.onClose();
              }}
            >
              Enregistrer
            </Button>
            <Button
              onClick={() => {
                resetProduitLocal();
                props.onClose();
              }}
            >
              Annuler
            </Button>{' '}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AsssuranceModal;
