import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IDemandeGraphe } from 'app/shared/model/demandeGraphe.model';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalPages: 0,
  number: 0,
  isFiltered: false,
};

export const getEntitiesForGraph2 = createAsyncThunk('demandes/fetch_entities_for_graph2', async () => {
  const requestUrl = `api/graphe12Get`;
  const { data } = await axios.get(requestUrl);
  return data;
});

export interface ITempo {
  filter?: any;
  choix?: any;
}

export const filterEntitiesForGraph2 = createAsyncThunk('demandes/fetch_entities_for_graph22', async (x: ITempo) => {
  const map1 = new Map();

  map1.set('Casa1', '1');
  map1.set('Casa2', '2');
  map1.set('Casa3', '3');
  map1.set('Rabat1', '4');
  map1.set('Rabat2', '5');
  map1.set('Tanger1', '6');
  map1.set('Tanger2', '7');
  map1.set('Agadir1', '8');
  map1.set('Agadir2', '9');
  map1.set('Fes1', '10');
  map1.set('Fes2', '11');
  map1.set('Marrakech1', '12');
  map1.set('Marrakech2', '13');

  let params = `array=${x.choix[0].value}`;

  for (let i = 1; i < x.choix.length; i++) {
    params = `${params}&array=${x.choix[i].value}`;
  }

  params = params.concat('&');

  const requestUrl = `api/graphe12Filter?${params}${x.filter}`;
  const { data } = await axios.get(requestUrl);

  return data;
});

export const demandeGraph2Slice = createSlice({
  name: 'demande2',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntitiesForGraph2.fulfilled, (state, action) => {
        state.entities = accumulate(action.payload);
      })
      .addCase(filterEntitiesForGraph2.fulfilled, (state, action) => {
        state.entities = accumulate(action.payload);
      });
  },
});

const monthNames = {
  Jan: 1,
  Fev: 2,
  Mars: 3,
  Avr: 4,
  Mai: 5,
  Juin: 6,
  Juil: 7,
  Aout: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

function sortData(columnsResult) {
  columnsResult = columnsResult.sort((a, b) => (monthNames[a.name] > monthNames[b.name] ? 1 : -1));

  return columnsResult;
}

function accumulate(columnsResult) {
  let grapheData1 = sortData(columnsResult);

  let x = [];
  let sum = 0;
  for (let i = 0; i < grapheData1.length; i++) {
    if (typeof grapheData1[i].Validée !== 'undefined') sum = sum + grapheData1[i].Validée;

    x = x.concat({
      ...grapheData1[i],
      Validée: sum,
    });
  }

  return x;
}

export default demandeGraph2Slice.reducer;
