import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Select, ModalFooter, Button } from '@chakra-ui/react';
import ModalBody from 'reactstrap/es/ModalBody';
import { TextField } from '@material-ui/core';
import { ModeContinuationModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/mode-continuation.model';
import { BaremePenaliteModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/bareme-penalite.model';
import { MotifRemboursementModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/motif-remboursement.model';

const RembousementPartiel = ({
  isOpen,
  onClose,
  onEventChange,
  eventObject,
  setStepperPage,
  singleStateManagement,
  onSelectCredit,
  onSelectionReset,
  onUpdatedEventReset,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    singleStateManagement();
    onEventChange({});
    onSelectCredit([]);
    onSelectionReset([]);
    onUpdatedEventReset({});
  };

  return (
    <div style={{ background: 'red' }}>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose(), onEventChange({});
          onSelectCredit([]);
          onSelectionReset([]);
          onUpdatedEventReset({});
        }}
        isCentered
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remboursement Partiel </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <div className="sm:mt-5 space-y-2 sm:space-y-2">
                {/* Montant du remboursement */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 sm:border-t">
                  <label htmlFor="Montant_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                    Montant du remboursement
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <TextField
                      required
                      // id="Montant_remboursement"
                      name="amountOfEvent"
                      placeholder="Valeur en MAD"
                      type="number"
                      value={eventObject.amountOfEvent}
                      onChange={e => {
                        onEventChange({
                          ...eventObject,
                          amountOfEvent: Number(e.target.value),
                        });
                      }}
                    />
                  </div>
                </div>
                {/*  Numero Compte Remboursement */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="compte_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                    Numéro compte de remboursement
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      required
                      id="compte_remboursement"
                      placeholder="choix compte remboursement"
                      value={eventObject.accountNumber}
                      onChange={e => {
                        onEventChange({
                          ...eventObject,
                          accountNumber: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={'13207701998692878'}>13207701998692878</option>
                      <option value={'12395450505066404'}>12395450505066404</option>
                      <option value={'12340450505157404'}>12340450505157404</option>
                      <option value={'12340450504566404'}>12340450504566404</option>
                      <option value={'12349999999999999'}>12349999999999999</option>
                    </Select>
                  </div>
                </div>
                {/* Mode de continuation */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="Montant_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                    Mode de continuation
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      required
                      id="mode_cont"
                      placeholder={'choix motif'}
                      value={eventObject.modeContinuation}
                      onChange={e => {
                        onEventChange({
                          ...eventObject,
                          modeContinuation: 1,
                        });
                      }}
                    >
                      <option value={ModeContinuationModel.CONSERVATION_DUREE}> Conservation de la durée </option>
                      <option value={ModeContinuationModel.CONSERVATION_ECHEANCE}> Conservation de l'échéance </option>
                    </Select>
                  </div>
                </div>
                {/*  Barème de pénalité */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="Montant_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                    Barème de pénalité
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      required
                      id="bareme_penalite"
                      placeholder="Choix Barème"
                      value={eventObject.penalty}
                      onChange={e => {
                        onEventChange({
                          ...eventObject,
                          penalty: Number(e.target.value),
                        });
                      }}
                    >
                      <option value={BaremePenaliteModel.SANS_PENALITE}> Sans pénalité</option>
                      <option value={BaremePenaliteModel.UN_MOIS_INTERET}> 1 mois intérêt</option>
                      <option value={BaremePenaliteModel.DEUX_MOIS_INTERET}> 2 mois intérêt</option>
                      <option value={BaremePenaliteModel.TROIS_MOIS_INTERET}> 3 mois intérêt</option>
                    </Select>
                  </div>
                </div>
                {/* Motif Remboursement */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="Montant_remboursement" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                    Motif de remboursement
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      required
                      id="mode_cont"
                      placeholder={'choix motif'}
                      value={eventObject.motif}
                      onChange={e => {
                        onEventChange({
                          ...eventObject,
                          motif: e.target.value,
                        });
                      }}
                    >
                      <option value={MotifRemboursementModel.REMBOURSEMENT_NORMAL}> Remboursement normal </option>
                      <option value={MotifRemboursementModel.MOTIF_UN}> Motif 1 </option>
                      <option value={MotifRemboursementModel.MOTIF_DEUX}> Motif 2</option>
                      <option value={MotifRemboursementModel.REMBOURSEMENT_NORMAL}> Motif 3 </option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="pt-4 flex justify-center">
                <Button
                  type="button"
                  colorScheme="red"
                  variant="outline"
                  onClick={() => {
                    setStepperPage('start');
                  }}
                >
                  Retour
                </Button>

                <Button type="submit" colorScheme="teal" marginLeft="4" variant="outline">
                  Appliquer
                </Button>
              </div>
            </form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default RembousementPartiel;
