import './table.scss';

import React, { createRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Heading, ModalBody, SimpleGrid, Stack, StackDivider, Text } from '@chakra-ui/react';
// import 'react-month-picker/css/month-picker.css';
import Picker from 'react-month-picker';

import { ModalFooter, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Switch } from '@chakra-ui/react';
import { Modal } from '@chakra-ui/react';

import {
  ResponsiveContainer,
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Line,
} from 'recharts';
import TextField from '@material-ui/core/TextField';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/demande/demande.reducer';
import { filterEntitiesForGraph4, getEntitiesForGraph4 } from 'app/entities/demande/demandeGraphe4.reducer';
import { getEntitiesForGraph1, filterEntitiesForGraph1 } from 'app/entities/demande/demandeGraphe1.reducer';
import { getEntitiesForGraph2, filterEntitiesForGraph2 } from 'app/entities/demande/demandeGraphe2.reducer';
import { getEntitiesForGraph3, filterEntitiesForGraph3 } from 'app/entities/demande/demandeGraphe3.reducer';

import { Select, HStack, Button, Spinner, IconButton, Radio, RadioGroup, useDisclosure } from '@chakra-ui/react';
import { MultiSelect } from 'react-multi-select-component';
import { BanIcon } from '@heroicons/react/outline';
import { getEntitiesSAVForGraph1, filterEntitiesSAVForGraph1 } from 'app/entities/demande/demandeSAVGraphe1.reducer';
import { getEntitiesSAVForGraph2, filterEntitiesSAVForGraph2 } from 'app/entities/demande/demandeSAVGraphe2.reducer';
import { getEntitiesSAVForGraph4, filterEntitiesSAVForGraph4 } from 'app/entities/demande/demandeSAVGraphe4.reducer';
import { getEntitiesSAVForGraph3, filterEntitiesSAVForGraph3 } from 'app/entities/demande/demandeSAVGraphe3.reducer';

const STATE_COLORS = [
  { statut: 'DEMANDE_CLOTURE', label: 'Casa1', color: 'green' },
  { statut: 'DEMANDE_ACCORDEE', label: 'Casa2', color: 'green' },
  { statut: '2', label: 'Casa3', color: 'green' },
  { statut: '1', label: 'Rabat1', color: 'green' },
  { statut: 'A_COMPLETER', label: 'Fes', color: 'green' },
  { statut: 'EN_COURS_DE_SAISIE', label: 'Tanger', color: 'green' },
  { statut: 'EN_COURS_DE_SAISIE2', label: 'Rabat2', color: 'gray' },
  { statut: 'EN_COURS_DE_SAISIE3', label: 'Tetouan', color: 'gray' },
  { statut: 'EN_COURS_DE_SAISIE4', label: 'Marrakech', color: 'gray' },
];

const optionsDirectionRegional = [
  {
    label: 'Tanger',
    value: 'Tanger',
    list: [
      { label: 'Tanger1', value: 'Tanger1' },
      { label: 'Tanger2', value: 'Tanger2' },
    ],
  },
  {
    label: 'Rabat',
    value: 'Rabat',
    list: [
      { label: 'Rabat1', value: 'Rabat1' },
      { label: 'Rabat2', value: 'Rabat2' },
    ],
  },
  {
    label: 'Casa',
    value: 'Casa',
    list: [
      { label: 'Casa1', value: 'Casa1' },
      { label: 'Casa2', value: 'Casa2' },
      { label: 'Casa3', value: 'Casa3' },
    ],
  },
  {
    label: 'Marrakech',
    value: 'Marrakech',
    list: [
      { label: 'Marrakech1', value: 'Marrakech1' },
      { label: 'Marrakech2', value: 'Marrakech2' },
    ],
  },
  {
    label: 'Agadir',
    value: 'Agadir',
    list: [
      { label: 'Agadir1', value: 'Agadir1' },
      { label: 'Agadir2', value: 'Agadir2' },
    ],
  },
  {
    label: 'Fes',
    value: 'Fes',
    list: [
      { label: 'Fes1', value: 'Fes1' },
      { label: 'Fes2', value: 'Fes2' },
    ],
  },
];

const data4 = [
  {
    name: 'Jan',
    Imtilak: 400,
    Mounassib: 400,
    Sakane: 400,
  },
  {
    name: 'Fev',
    Imtilak: 400,
    Mounassib: 1000,
    Sakane: 450,
  },
  {
    name: 'Mars',
    Imtilak: 600,
    Mounassib: 2000,
    Sakane: 700,
  },
  {
    name: 'Avril',
    Imtilak: 1000,
    Mounassib: 3000,
    Sakane: 1100,
  },
  {
    name: 'Mai',
    Imtilak: 1500,
    Mounassib: 4000,
    Sakane: 2000,
  },
  {
    name: 'Juin',
    Imtilak: 1600,
    Mounassib: 5000,
    Sakane: 2000,
  },
  {
    name: 'Juillet',
    Imtilak: 1800,
    Mounassib: 8000,
    Sakane: 2000,
  },
];

const ANNEE = [
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
];

const MOIS = [
  { value: 1, label: 'Janvier' },
  { value: 2, label: 'Fevrier' },
  { value: 3, label: 'Mars' },
  { value: 4, label: 'Avril' },
  { value: 5, label: 'Mai' },
  { value: 6, label: 'Juin' },
  { value: 7, label: 'Juillet' },
  { value: 8, label: 'Aout' },
  { value: 9, label: 'Septembre' },
  { value: 10, label: 'Octobre' },
  { value: 11, label: 'Novembre' },
  { value: 12, label: 'Decembre' },
];

const pageStart = 0;

export const TableauDeBordSAV = props => {
  const [cAnneeDebut, setcAnneeDebut] = useState(ANNEE);
  const [cAnneeFin, setcAnneeFin] = useState(ANNEE);
  const [cMoisDebut, setcMoisDebut] = useState(MOIS);
  const [cMoisFin, setcMoisFin] = useState(MOIS);

  const dispatch = useAppDispatch();
  let { entities: grapheData4 } = useAppSelector(state => state.demandeGrapheSAV4);
  // let { entities: grapheData1 } = useAppSelector(state => state.demandeGraphe1);
  let { entities: grapheData1 } = useAppSelector(state => state.demandeGrapheSAV1);
  let { entities: grapheData2 } = useAppSelector(state => state.demandeGrapheSAV2);

  let { entities: grapheData3 } = useAppSelector(state => state.demandeGrapheSAV3);

  const [choix, setChoix] = useState([]);

  const [optionAgences, setOptionAgences] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const buildQueryString = () => {
    return `anneeDebut=${anneeDebut}&anneeFin=${anneeFin}&moisDebut=${moisDebut}&moisFin=${moisFin}`;
  };

  useEffect(() => {
    dispatch(getEntitiesSAVForGraph4());
    dispatch(getEntitiesSAVForGraph1());
    dispatch(getEntitiesSAVForGraph2());
    dispatch(getEntitiesSAVForGraph3());
  }, []);

  const [selected, setSelected] = useState([]);
  const [selectedR, setSelectedR] = useState([]);

  const [anneeDebut, setAnneeDebut] = useState(2022);
  const [moisDebut, setMoisDebut] = useState(1);
  const [anneeFin, setAnneeFin] = useState(2022);
  const [moisFin, setMoisFin] = useState(6);

  useEffect(() => {
    setcAnneeFin(
      ANNEE.filter(a => {
        return a.value - anneeDebut == 1 || a.value - anneeDebut == 0;
      })
    );
  }, [anneeDebut]);

  useEffect(() => {
    if (anneeFin == anneeDebut)
      setcMoisFin(
        MOIS.filter(m => {
          return m.value > moisDebut;
        })
      );
    else
      setcMoisFin(
        MOIS.filter(m => {
          return m.value < moisDebut;
        })
      );
  }, [moisDebut, anneeDebut, anneeFin]);

  useEffect(() => {
    let x = [];
    //boucler sur selectedR et concateneer la list de chaqun dans otions agence
    selectedR.forEach(value => {
      x = x.concat(value.list);
    });
    setOptionAgences(x);
  }, [selectedR]);

  useEffect(() => {
    let options = [];
    optionAgences.forEach(val => {
      options = options.concat(val.value);
    });
    setSelected(
      selected.filter(e => {
        return options.includes(e.value);
      })
    );
  }, [optionAgences]);

  const triggerFilter = () => {
    dispatch(filterEntitiesSAVForGraph1({ filter: buildQueryString(), choix: selected }));
    dispatch(filterEntitiesSAVForGraph2({ filter: buildQueryString(), choix: selected }));
    dispatch(filterEntitiesSAVForGraph4({ filter: buildQueryString(), choix: selected }));
    dispatch(filterEntitiesSAVForGraph3({ filter: buildQueryString(), choix: selected }));
  };

  return (
    <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
      <Box p={5} shadow="base" borderWidth="3px">
        <Row>
          <div>{/* <Ho/> */}</div>
          <Col md="12">
            <div className="container">
              <h3 className="title">Tableau de bord </h3>
              <div className="container">
                <div className="grid grid-cols-2  gap-y-8">
                  <div>
                    <dt className=" ml-4 mr-2.5 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Du :</dt>
                    <div className=" flex justify-start">
                      <div className="">
                        <dt className=" ml-8 mr-3 block text-base font-thin text-grey-500 ">Annee </dt>
                      </div>
                      <div className="ml-16 ">
                        <Select placeholder="Annee ..." defaultValue={anneeDebut} onChange={e => setAnneeDebut(+e.target.value)}>
                          {cAnneeDebut.map(code => (
                            <option value={code.value}>{code.label}</option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className=" flex justify-start">
                      <div className="">
                        <dt className=" ml-8  mr-2.5 block text-base font-thin text-grey-500 sm:mt-px sm:pt-2">Mois </dt>
                      </div>
                      <div className="ml-20 mt-1">
                        <Select placeholder="Mois ..." defaultValue={moisDebut} onChange={e => setMoisDebut(+e.target.value)}>
                          {cMoisDebut.map(code => (
                            <option value={code.value}>{code.label}</option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="border-l-2 ">
                    <dt className=" ml-4 mr-2.5 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2 ">Au :</dt>
                    <div className=" flex justify-start">
                      <div className="">
                        <dt className=" ml-8 mr-3 block text-base font-thin text-grey-500 sm:mt-px sm:pt-2">Annee </dt>
                      </div>
                      <div className="ml-16">
                        <Select placeholder="Annee ..." defaultValue={anneeFin} onChange={e => setAnneeFin(+e.target.value)}>
                          {cAnneeFin.map(code => (
                            <option value={code.value}>{code.label}</option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className=" flex justify-start">
                      <div className="">
                        <dt className=" ml-8 mr-2.5 block text-base font-thin text-grey-500 sm:mt-px sm:pt-2">Mois </dt>
                      </div>
                      <div className="ml-20 mt-1">
                        <Select placeholder="Mois ..." defaultValue={moisFin} onChange={e => setMoisFin(+e.target.value)}>
                          {cMoisFin.map(code => (
                            <option value={code.value}>{code.label}</option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className=" flex justify-start">
                    <div className="">
                      <dt className="mt-24 ml-4 mr-2.5 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Direction Regional :</dt>
                    </div>
                    <div className="ml-16">
                      <div className="ml-16">
                        <MultiSelect options={optionsDirectionRegional} value={selectedR} onChange={setSelectedR} labelledBy="Choisir" />
                      </div>
                    </div>
                  </div>
                  <div className=" flex justify-start border-l-2">
                    <div className="">
                      <dt className="mt-24 ml-4 mr-2.5 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Agences :</dt>
                    </div>{' '}
                    <div className="ml-16">
                      <MultiSelect options={optionAgences} value={selected} onChange={setSelected} labelledBy="Choisir" />
                    </div>
                  </div>
                </div>

                <div className="mt-16 flex justify-start ">
                  {STATE_COLORS.map(({ statut, label, color }, index) => {
                    return (
                      <div key={statut} className="p-2 bd-highlight">
                        <span
                          onClick={() => {
                            setChoix(choix.concat(label));
                          }}
                          className={`px-2 inline-flex text-xs leading-5 font-bold
                                  rounded bg-${color}-200
                                  text-${color}-800 cursor-pointer 
                                  hover:bg-${color}-300 `}
                        >
                          {label}
                        </span>
                        {/*  */}
                      </div>
                    );
                  })}
                </div>
                <div>
                  <div className="flex justify-between">
                    <div>
                      {/* <Button className="ml-8" colorScheme="teal" mr={3} mt={6} variant="outline" onClick={() => {}}>
                        Ré-initialiser Filtre
                      </Button> */}
                    </div>
                    <div>
                      <Button
                        className="mr-8"
                        colorScheme="blue"
                        mr={3}
                        mt={6}
                        onClick={() => {
                          selected.length == 0 ? onOpen() : triggerFilter();
                        }}
                        variant="solid"
                      >
                        Appliquer Filtre
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <Modal onClose={onClose} isOpen={isOpen} isCentered>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader></ModalHeader>
                    <ModalBody className="d-flex flex-col justify-content-center">
                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                        <BanIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="m-5">
                        <div className={'d-flex mt-4 items-center'}>
                          <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                            Vous devez selectionner d'abord des agences <br />{' '}
                          </label>
                        </div>
                      </div>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </div>

              <div className="grid grid-cols-2 gap-4">
                {' '}
                <div>
                  <BarChart
                    width={500}
                    height={300}
                    data={grapheData1.concat()}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Refusée" stackId="a" fill="#d60404" />
                    <Bar dataKey="EnCours" stackId="a" fill="#8884d8" />
                    <Bar dataKey="Validée" stackId="a" fill="#82ca9d" />
                  </BarChart>
                  <p> Evolution de l'etat des demandes</p>
                </div>
                <div>
                  <BarChart
                    width={500}
                    height={300}
                    data={grapheData2.concat()}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Validée" stackId="a" fill="#82ca9d" />
                  </BarChart>
                  <p> Evolution des demandes "Validées"</p>
                </div>
                <div>
                  <ComposedChart
                    width={500}
                    height={340}
                    data={grapheData3.concat()}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Validée" barSize={20} fill="#413ea0" />
                    <Line type="monotone" dataKey="Validée" stroke="#ff7300" />
                  </ComposedChart>
                  Evolution des demandes de credits par agence
                </div>
                <div>
                  <ResponsiveContainer width="100%" height="80%">
                    <AreaChart
                      data={grapheData4.concat()} // <==== .concat() is not an error its used to convert "readonly any[]" to "any[]"
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                  </ResponsiveContainer>
                  <p className="mt-12">Evolution global des demandes de credits </p>
                </div>
              </div>
              <div>
                <BarChart
                  width={500}
                  height={300}
                  data={data4}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Imtilak" stackId="a" fill="#d60404" />
                  <Bar dataKey="Mounassib" stackId="a" fill="#8884d8" />
                  <Bar dataKey="Sakane" stackId="a" fill="#82ca9d" />
                </BarChart>
                <p> Evolution des produits commercialisés</p>
              </div>
            </div>
          </Col>
        </Row>
      </Box>
    </Stack>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(TableauDeBordSAV);
