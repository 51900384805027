import { IDemande } from './demande.model';
import { ISimulationSav } from './simulation-sav.model';
import { IUser } from './user.model';

export interface ITache2 {
  id?: string | null;
  name?: string | null;
  assignee?: string | null;
  created: string | null;
  due: string | null;
  followUp: string | null;
  delegationState?: string | null;
  description?: string | null;
  executionId?: string | null;
  owner?: string | null;
  parentTaskId?: string | null;
  processDefinitionId?: string | null;
  processInstanceId?: string | null;
  taskDefinitionKey?: string | null;
  suspended?: boolean | null;
  deleteReason?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  duration?: number | null;
  status?: string | null;
  comment?: string | null;
}

export const defaultValue: Readonly<ITache2> = {
  name: 'aName',
  assignee: 'anAssignee',
  created: '2013-01-23T13:42:42.000+0200',
  due: '2013-01-23T13:49:42.576+0200',
  followUp: '2013-01-23T13:44:42.437+0200',
  delegationState: 'RESOLVED',
  description: 'aDescription',
  executionId: 'anExecution',
  owner: 'anOwner',
  parentTaskId: 'aParentId',
  processDefinitionId: 'aProcDefId',
  processInstanceId: 'aProcInstId',
  taskDefinitionKey: 'aTaskDefinitionKey',
  suspended: false,
  status: null,
  comment: '',
};
