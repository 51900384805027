import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { resetFiltreCredit } from 'app/modules/CreditStepper/Reducers/filtreCreditSlice';

const SimulationSav = ({ onOpenSAV, onSimulationSavActivate }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-500 ease-in-out">
      <div className="flex-shrink-0 ">
        <img className="h-48 w-full object-scale-down	" src="/content/images/Simulation SAV BMCE.png" alt="" />
      </div>
      <div className="flex-1 bg-white pb-6  flex flex-col justify-between ">
        <div className="flex-1 	mt-12		">
          <Text fontSize="4xl" fontWeight="semibold" color="gray.700" align="center">
            Simulation SAV
          </Text>
        </div>
        <div className=" flex items-center justify-center">
          <Button
            colorScheme="blue"
            variant={'outline'}
            className="mx-4 p3"
            onClick={() => {
              onOpenSAV();
              onSimulationSavActivate(true);
            }}
          >
            {' '}
            Nouvelle demande
          </Button>
          <Link to={'/tableSAV'} style={{ textDecoration: 'none' }}>
            <Button colorScheme="blue" onClick={e => dispatch(resetFiltreCredit())} variant={'outline'} className="p3">
              {' '}
              Demandes en cours
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SimulationSav;
