import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Text, ListItem, Stack, useDisclosure, VStack, HStack, Heading } from '@chakra-ui/react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, getEntityTask } from './taches.reducer';
import moment from 'moment';
// import BpmnModdle from 'bpmn-moddle';

import './delegateTache.css';
import { strXml } from './delegateBpmn';
import TacheContent from './tacheContent';
import { getSession } from 'app/shared/reducers/authentication';

const DelegateTaches = (props: RouteComponentProps<{ url: string }>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tacheSelectedId, setTacheSelectedId] = useState('');
  const tacheList = useAppSelector(state => state.taches2.entities);
  const user = useAppSelector(state => state.authentication.account);

  let role = 'none';

  if (user.authorities[0] === 'ROLE_USER' && user.authorities.length > 1) {
    role = user.authorities[1];
  } else if (user.authorities.length > 1) {
    role = user.authorities[0];
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
  }, []);

  useEffect(() => {
    // fetch by role
    dispatch(getEntities(role));

    if (tacheSelectedId !== '') {
      dispatch(getEntityTask(tacheSelectedId));
    }
  }, [tacheSelectedId]);

  return (
    <Row>
      <Col md="3">
        <Box shadow="base" borderWidth="3px" display={'flex'} height={'100vh'} overflow="hidden" bg="#fafafa">
          <div style={{ width: '100%' }}>
            <Text p={8}>Taches</Text>
            <Stack w="100%" minH="100vh" alignItems="stretch" spacing={2} direction="column" align="flex-start">
              {tacheList.map((row, index) => (
                <div key={index} className="selectButton">
                  <button
                    className={tacheSelectedId === row.id ? 'selectButton selected' : 'selectButton'}
                    onClick={() => {
                      setTacheSelectedId(row.id);
                    }}
                  >
                    <Heading fontSize="xl" paddingLeft={5} paddingBottom={2} color={'black'}>
                      {row.name}
                    </Heading>
                    <p className="selectButton">{row.processDefinitionKey}</p>
                    <p className="selectButton">{moment(row.startTime).locale('fr').fromNow()}</p>
                  </button>
                </div>
              ))}
            </Stack>
          </div>
        </Box>
      </Col>
      <Col md="9">
        <Box borderWidth="1px" borderRadius="lg" display={'flex'} height={'100vh'} overflow="hidden">
          <div className="container">
            <TacheContent tacheId={tacheSelectedId} />
          </div>
        </Box>
      </Col>
    </Row>
  );
};

export default DelegateTaches;
