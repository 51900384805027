// import '../detail.scss';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import 'react-awesome-lightbox/build/style.css';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'styled-react-modal';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { Box, Stack } from '@chakra-ui/react';

import { useDisclosure } from '@chakra-ui/react';

import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

// import { getEntity } from 'app/entities/piece-justificatif/piece-justificatif.reducer';
import { getEntity } from 'app/entities/demande/demande.reducer';
import { useParams } from 'react-router-dom';

import { ResultStepper } from './ResultStepper2';

const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition : all 0.3s ease-in-out;`;
export const DetailSimulationCredit2 = props => {
  const dispatch = useAppDispatch();

  const demande = useAppSelector(state => state.demande.entity);
  const demandeId: { id: string } = useParams();

  useEffect(() => {
    dispatch(getEntity(parseInt(demandeId.id)));
  }, []);

  const [openImgModal, setOpenImgModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [validated, setValidated] = useState(false);

  const [PdfUrl, setPdfUrl] = useState(''); // workaround LightBox int
  const [imgUrl, setImageUrl] = useState(''); // workaround LightBox int
  const { isOpen: validateIsOpen, onOpen: validateOnOpen, onClose: validateOnClose } = useDisclosure();
  const { isOpen: validateDecisionIsOpen, onOpen: validateDecisionOnOpen, onClose: validateDecisionOnClose } = useDisclosure();
  const { isOpen: refuseDecisionIsOpen, onOpen: refuseDecisionOnOpen, onClose: refuseDecisionOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
  const [deletedIndex, setDeletedIndex] = useState(0);
  const [validatedIndex, setValidatedIndex] = useState(0); // workaround LightBox int

  return (
    <div>
      <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
        <Box p={5} shadow="base" borderWidth="3px">
          <div className="col-xl-8 col-md-12 p-8" style={{ margin: '0 auto' }}>
            <Steps direction="horizontal" current={2}>
              <Step title="En cours de saisie" />
              <Step title="À valider" />
              <Step title="Documents validés" />
              <Step title="Demande clôturée" />
            </Steps>
          </div>
          <div className="col-xl-12 col-md-12 pt-8" style={{ margin: '0 auto' }}>
            <ResultStepper />
          </div>
        </Box>
      </Stack>
    </div>
  );
};
// function dispatch(arg0: any) {
//   throw new Error('Function not implemented.');
// }
