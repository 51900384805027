import { TextField } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useAppDispatch } from 'app/config/store';
import { editFicheClient2 } from 'app/entities/client/client.reducer';
import { PencilIcon, CheckIcon, MenuIcon, PencilAltIcon, ChartBarIcon } from '@heroicons/react/outline';

interface NumEditTileProps {
  label: string;
  value: number;
  champ: string;
  toggle?: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  isInputToggle?: boolean;
}

const format = (x: number, suffix: string) => {
  return new Intl.NumberFormat('MA', {
    style: 'currency',
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};

const NumEditTile = ({ label, value, champ, toggle = false, setToggle, isInputToggle = false }: NumEditTileProps) => {
  const dispatch = useAppDispatch();
  const handleOnChange = (formattedValue, value) => {
    dispatch(editFicheClient2({ champ, inputValue: value }));
  };

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-bold text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {toggle || !isInputToggle ? (
          <span className="d-flex align-baseline">
            <NumberFormat
              customInput={TextField}
              placeholder="Valeur en MAD"
              thousandSeparator=" "
              suffix=" MAD"
              value={value}
              onValueChange={values => {
                const { formattedValue, value } = values;
                handleOnChange(formattedValue, value);
              }}
            />
            {isInputToggle && (
              <CheckIcon className="md:w-3 xl:w-5 text-green-600 hover:text-green-700" onClick={() => setToggle(!toggle)} />
            )}
          </span>
        ) : (
          <span className="d-flex ">
            {format(value, 'MAD')}
            {isInputToggle && (
              <PencilIcon className="ml-2 md:w-3 xl:w-5 text-blue-800 hover:text-green-700" onClick={() => setToggle(!toggle)} />
            )}
          </span>
        )}
      </dd>
    </div>
  );
};

export default NumEditTile;
