import dayjs from 'dayjs';
import { IPieceJustificatif } from 'app/shared/model/piece-justificatif.model';
// import { IClient } from 'app/shared/model/ficheClient.model';
import { TypeTaux } from 'app/shared/model/enumerations/type-taux.model';
import { TypeCredit } from 'app/shared/model/enumerations/type-credit.model';
import { NatureCredit } from 'app/shared/model/enumerations/nature-credit.model';
import { TypeBien } from 'app/shared/model/enumerations/type-bien.model';
import { EtatBien } from 'app/shared/model/enumerations/etat-bien.model';
import { IClient } from './client.model';

export interface ISimulationSav {
  id?: number;
  numPret?: number;
  libProduit?: string;
  montPret?: number;
  duree?: number;
  montantRestant?: number;
  dureeRest?: number;
  montantImpaye?: number;
  montantEcheance?: number;
  tauxHT?: number;
  dateFinPrev?: string;
  typeTaux?: TypeTaux;
  premiereEcheance?: string;
  derniereEchance?: string;
  typeCredit?: TypeCredit;
  natureCredit?: NatureCredit;
  typeBien?: TypeBien;
  etatBien?: EtatBien;
  produit?: string;
  isFranchise?: boolean;
  isConventione?: boolean;
  isAssurance?: boolean;
  pieceJustificatifs?: IPieceJustificatif[] | null;
  client?: IClient | null;
}

export const defaultValue: Readonly<ISimulationSav> = {
  isFranchise: false,
  isConventione: false,
  isAssurance: false,
};
