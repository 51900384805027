//TODO: interface properties has no meaning if they are declared optional

import { IClient } from './client.model';

export interface IDemande {
  id?: number;
  statut?: string;
  simulations?: any[];
  decisions?: any[];
  agence?: string;
  client?: IClient;
  dateDemande?: Date;

  loanSimulationResultId?: number;
  userId?: string;
  codeBanque?: string;
  nomBanque?: string;
  codeAgence?: string;
  nomAgence?: string;
  loanInputSimulation?: any;
  // statut?:string;
  // dateDemande?:string;
}

// export interface IDemandeArg {
//   agence?: string,
//   client?: {id: number},
//   simulations?: {
//     typeSimulation: string,
//     syntheseSimulation: {
//         typeSynthese: string,
//         dateEvenement: string,
//         amountOfEvent: number,
//         accountNumber: string,
//         modeContinuation: string,
//         penalty: string,
//         motif: string
//     },
//     resultat: {
//         amountOfEvent: number,
//         capitalRestantDu: number,
//         interets: number,
//         taxeInterets: number,
//         montantPenalite: number,
//         taxePenalite: number
//     }
// }
// }

export const defaultValue: Readonly<IDemande> = {};
