import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

const FormattedInput2 = ({ placeholder, suffix, label, value, onChange, readOnly, isAllowed }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
      <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <FormControl component="fieldset" required>
          {readOnly === true ? (
            <NumberFormat
              className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
              customInput={TextField}
              isAllowed={isAllowed}
              placeholder="Valeur en MAD"
              value={value}
              thousandSeparator=" "
              decimalSeparator=","
              suffix={suffix ? suffix : ' MAD'}
              onChange={onChange}
              readOnly
            />
          ) : (
            <NumberFormat
              className="text-lg block border-b-2 border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50"
              isAllowed={isAllowed}
              customInput={TextField}
              thousandSeparator=" "
              placeholder="Valeur en MAD"
              suffix={suffix ? suffix : ' MAD'}
              value={value}
              onValueChange={onChange}
            />

            // <NumberFormat
            //   className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
            //   placeholder="Valeur en MAD"
            //   value={value}
            //   thousandSeparator=" "
            //   decimalSeparator=","
            //   suffix="dhs"
            //   onChange={onChange}
            // />
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default FormattedInput2;
