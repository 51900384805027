import { Box, Flex, SimpleGrid, Spacer, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';
import { CreditAccordion } from 'app/modules/CreditStepper/SyntheseStep/CreditAccordion';
import { Select, Button, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Switch, useDisclosure } from '@chakra-ui/react';
import './Synthese.scss';
import { useState } from 'react';
import Tile from 'app/modules/CreditStepper/SyntheseStep/Tile';
import InfoTile from 'app/modules/commonComponents/InfoTiles/InfoTile';
import InfoTile2 from 'app/modules/commonComponents/InfoTiles/InfoTile2';
import { useAppSelector } from 'app/config/store';

interface FeatureProps {
  title: string;
  children: React.ReactNode;
  icon: React.ReactElement;
}

export const Feature = (props: FeatureProps) => {
  const { title, children, icon } = props;
  return (
    <Stack spacing={{ base: '3', md: '6' }} direction={{ base: 'column', md: 'row' }}>
      <Box fontSize="6xl">{icon}</Box>
      <Stack spacing="1">
        <Text fontWeight="extrabold" fontSize="lg">
          {title}
        </Text>
        <Box color={mode('gray.600', 'gray.400')}>{children}</Box>
      </Stack>
    </Stack>
  );
};
const format = (x: number, suffix: string) => {
  return new Intl.NumberFormat('MA', {
    style: 'currency',
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};
export const SyntheseStepper = props => {
  const ficheClient = useAppSelector(state => state.ficheClient);
  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const [toggle, setToggle] = useState(false);

  return (
    <Box as="section" maxW="5xl" mx="auto" py="12" px={{ base: '6', md: '8' }}>
      <SimpleGrid columns={{ base: 2, md: 2 }} spacingX="0" spacingY={{ base: '8', md: '14' }}>
        <Feature title="Fiche Client" icon={<i className="fal fa-user"> </i>}>
          <Flex>
            <Box p="2">
              <ul>
                <InfoTile label={'Nom & Prénom :'} value={ficheClient.nom + ' ' + ficheClient.prenom} />
                <InfoTile label={'CIN :'} value={ficheClient.CIN} />
                <InfoTile label={'Numero de telephone :'} value={ficheClient.numTelephone} />
                <InfoTile label={'Email :'} value={ficheClient.email} />
                <InfoTile label={'Activité :'} value={ficheClient.activite} />
                <InfoTile label={'Adresse :'} value={ficheClient.addresse} />{' '}
                <li>
                  {!toggle && (
                    <Button className="but ml-32" colorScheme="blue" variant="outline" onClick={() => setToggle(!toggle)}>
                      <i className="far fa-chevron-down"> Details</i>
                    </Button>
                  )}
                </li>
                {toggle && (
                  <div className="animate__animated animate__fadeInDown">
                    <InfoTile label={'Echéance autre crédit :'} value={format(ficheClient.echeanceAutreCredit, 'MAD')} />
                    <InfoTile label={'Revenu :'} value={format(ficheClient.revenus, 'MAD')} />
                    <InfoTile label={'Echéance crédit :'} value={format(ficheClient.echeance, 'MAD')} />{' '}
                    <InfoTile label={'Employeur :'} value={ficheClient.employeur} />
                    <InfoTile label={'Age :'} value={format(ficheClient.age, 'ans')} />
                    <InfoTile label={'Pays de residence :'} value={ficheClient.paysResidence} />
                    <InfoTile label={'Pays de naissance :'} value={ficheClient.paysNaissance} />
                    <li>
                      {' '}
                      <InfoTile label={'Date de naissance :'} value={ficheClient.dateNaissance} />{' '}
                      {toggle && (
                        <Button className="but ml-32" colorScheme="blue" variant="outline" onClick={() => setToggle(!toggle)}>
                          <i className="far fa-chevron-up"> Reduire</i>
                        </Button>
                      )}
                    </li>
                  </div>
                )}
              </ul>{' '}
            </Box>
          </Flex>
        </Feature>

        <Feature title="Besoins financiers" icon={<i className="fal fa-piggy-bank"> </i>}>
          <Flex>
            <Box p="2">
              <ul>
                <InfoTile label={'Objet :'} value={simulationCredit.objetCredit} />
                <InfoTile label={'Type de crédit :'} value={simulationCredit.typeCredit} />
                {simulationCredit.typeBien !== '' && <InfoTile label={'Type de bien :'} value={simulationCredit.typeBien} />}
                <InfoTile label={'Valeur du bien:'} value={format(simulationCredit.valeurBien, 'MAD')} />
                <InfoTile label={'Montant des frais:'} value={format(simulationCredit.montantFrais, 'MAD')} />
                <InfoTile label={'Montant du projet:'} value={format(simulationCredit.montantProjet, 'MAD')} />
                <InfoTile label={'Montant crédit:'} value={format(simulationCredit.mntCrdt, 'MAD')} />
                <li className="mr-30">
                  <InfoTile label={'Apport personnel:'} value={format(simulationCredit.apportPersonnel, 'MAD')} />
                </li>

                <li className="invisible">
                  {' '}
                  <InfoTile label={'Adresse :'} value={props.client.adresse} />{' '}
                  {!toggle && (
                    <Button className="but ml-32" colorScheme="blue" variant="outline" onClick={() => setToggle(!toggle)}>
                      Details
                    </Button>
                  )}{' '}
                </li>
              </ul>{' '}
            </Box>
          </Flex>
        </Feature>
      </SimpleGrid>
      {simulationCredit.propositions.length > 0 && <CreditAccordion index={1} produit={simulationCredit.propositions[0]} />}
      <br />
      {simulationCredit.propositions.length > 1 && <CreditAccordion index={2} produit={simulationCredit.propositions[1]} />}
    </Box>
  );
};
