// import './detail.scss';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import 'react-awesome-lightbox/build/style.css';
import React, { useState } from 'react';
import Modal from 'styled-react-modal';
import { Button as Button2 } from '@material-ui/core';

import Lightbox from 'react-awesome-lightbox';
import PDFViewer from 'pdf-viewer-reactjs';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from '@material-ui/core';
import EchancierDataGrid from 'app/modules/commonComponents/Echeancier/EchancierDataGrid';

import { Box, Divider, Heading, SimpleGrid, Stack, StackDivider, Text } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';

import {
  Select,
  ModalFooter,
  Button,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { Modal as Modal2 } from '@chakra-ui/react';
import TextField from '@material-ui/core/TextField';
import ModalBody from 'reactstrap/es/ModalBody';
import { Link } from 'react-router-dom';

import { useColorModeValue as mode } from '@chakra-ui/react';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import TextareaAutosize from 'react-textarea-autosize';

import { BannerDecisionRecap } from './worflowDecisionPage';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition : all 0.3s ease-in-out;`;
export const RecapDecision = props => {
  const PJList = [
    {
      id: 1,
      emplacement: 'https://www.demarches.ma/wp-content/uploads/sites/12/2020/06/CIN_maroc-1024x670.jpg',
      type: 'IMAGE',
      libele: 'Carte CIN',
      statut: 'PasRecu',
      modifie: '2021-08-01',
      modifiePar: 'Utilisateur 2',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 2,
      emplacement: 'https://www.pedagogie.ac-aix-marseille.fr/upload/docs/application/pdf/2012-06/bulletin_de_salaire.pdf',
      type: 'PDF',
      libele: 'Bulletin Paie',
      statut: 'PasRecu',
      modifie: '2021-08-02',
      modifiePar: 'Utilisateur 1',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 4,
      emplacement: 'https://www.demarches.ma/wp-content/uploads/sites/12/2020/06/CIN_maroc-1024x670.jpg',
      type: 'IMAGE',
      libele: 'Attestation de salaire',
      statut: 'PasRecu',
      modifie: '2021-08-02',
      modifiePar: 'Utilisateur 1',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
  ];

  const com1 =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus fermentum ultrices ultrices. Aenean quis vestibulum lectus, sed vehicula eros. Quisque dapibus sit amet nisl nec placerat. Cras ut dolor elit. Nam a nisl et tellus pulvinar blandit. Donec mi diam.';
  const com2 = com1;
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [validated, setValidated] = useState(false);
  const [prdtArray, setPrdtArray] = useState(PJList);

  const [PdfUrl, setPdfUrl] = useState(''); // workaround LightBox int
  const [imgUrl, setImageUrl] = useState(''); // workaround LightBox int
  const { isOpen: validateIsOpen, onOpen: validateOnOpen, onClose: validateOnClose } = useDisclosure();
  const { isOpen: validateDecisionIsOpen, onOpen: validateDecisionOnOpen, onClose: validateDecisionOnClose } = useDisclosure();
  const { isOpen: refuseDecisionIsOpen, onOpen: refuseDecisionOnOpen, onClose: refuseDecisionOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
  const [deletedIndex, setDeletedIndex] = useState(0);
  const [validatedIndex, setValidatedIndex] = useState(0); // workaround LightBox int

  // const remove2 = (nb: number) => {
  //   const array = [...prdtArray];
  //     array.splice(nb, 1);
  //     setPrdtArray(array);
  //     };
  const confirmDelete = () => {
    const array = [...prdtArray];
    array.splice(deletedIndex, 1);
    setPrdtArray(array);
  };

  const confirmValidate = () => {
    const array = [...prdtArray];
    array[validatedIndex].statut = 'Recu';
    setPrdtArray(array);
  };

  return (
    <div>
      <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
        <Box p={5} shadow="base" borderWidth="3px">
          <div className="col-6 p-8" style={{ margin: '0 auto' }}>
            <Steps direction="horizontal" current={2}>
              <Step title="En cours de saisie" />
              <Step title="A valider" />
              <Step title="Documents validés" />
              <Step title="Demande clôturée" />
            </Steps>
          </div>

          <div className="col-9 pt-8" style={{ margin: '0 auto' }}>
            {/* <BannerDecision /> */}
            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>E-CONSO <span className="ml-24">1 évènement(s) </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                    <Carousel showThumbs={false} showIndicators={false}>
                      <div>
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
                            <span className="border-b-4 border-blue-800 pb-2">Report Échéance</span>
                          </h3>
                        </div>
                        <div className="mt-8 ">
                          <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
                            <span>Synthèse événement</span>
                          </h4>
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de {"l'évènement"}</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">12-04-2020</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Nombre {"d'échéances"} reportés</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">5</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date Prochaine échéance</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">10-11-2021</dd>
                            </div>
                            <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 py-4">
                              <span>Resultat</span>
                            </h4>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant échéance Avant</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">2 450,00 DH</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant des frais </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">1 450,00 DH</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">TEG </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2"> 4,1 %</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant intérêt de prêt </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">328 000 DH</dd>
                            </div>
                          </dl>
                        </div>
                      </div>{' '}
                      <div className="invisible"></div>
                    </Carousel>
                  </div>
                </Typography>
              </AccordionDetails>
              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="">
                      {' '}
                      <i className="fal fa-file-invoice mx-2"> </i> Échancier{' '}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="w-100">
                    <Typography className="w-100">
                      <EchancierDataGrid />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>IMMOBILIER SAKANE{' '}
                  <span className="ml-2"> 1 évènement(s) </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                    <Carousel showThumbs={false} showIndicators={false}>
                      <div>
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
                            <span className="border-b-4 border-blue-800 pb-2">Remboursement Total</span>
                          </h3>
                        </div>
                        <div className="mt-8 ">
                          <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
                            <span>Synthèse événement</span>
                          </h4>
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l{"'"}évènement</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">10-04-2020</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numero du compte de remboursement</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2"></dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Barème calcul des pénalités</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">1 mois interet</dd>
                            </div>
                            <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 py-4">
                              <span>Resultat</span>
                            </h4>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant du RAT </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">50 000 DH</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital Restant du </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">102 150,00 DH</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Intérêts </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2"> 3,1 %</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur intérêts à percevoir </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">69 DH</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant Pénalités </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">420.00 DH</dd>
                            </div>

                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur pénalité </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">12 DH</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div className="invisible"></div>
                    </Carousel>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <i className="fal fa-file-user mx-2"> </i>
                  Piècess Justificatives{' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                ></th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Document
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Statut
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Modifiée le
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Modifiée par
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {prdtArray.map((piece, index) => (
                                <tr key={index}>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    {piece.type === 'IMAGE' ? (
                                      <IconButton
                                        color="primary"
                                        className="mx-2"
                                        onClick={() => {
                                          setImageUrl(piece.emplacement);
                                          setOpenImgModal(!openImgModal);
                                        }}
                                      >
                                        <i className="fal fa-images"> </i>
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        color="primary"
                                        className="mx-2"
                                        onClick={() => {
                                          setPdfUrl(piece.emplacement);
                                          setOpenPDFModal(!openPDFModal);
                                          // console.group('lol', piece.emplacement, openPDFModal);
                                        }}
                                      >
                                        <i className="fal fa-file-pdf"> </i>
                                      </IconButton>
                                    )}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                      <div className="ml-4">
                                        <div className="text-sm font-medium text-gray-900">{piece.libele}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {validated || piece.statut === 'Recu' ? (
                                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                        Approuvée
                                      </span>
                                    ) : (
                                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                        Non Approuvée
                                      </span>
                                    )}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">{piece.modifie}</div>
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{piece.modifiePar}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <IconButton
                                      color="primary"
                                      className="mx-2"
                                      onClick={() => {
                                        editOnOpen();
                                      }}
                                    >
                                      <label htmlFor="actual-btn">
                                        <i className="fal fa-edit"></i>
                                      </label>{' '}
                                      <input type="file" id="actual-btn" hidden />
                                    </IconButton>
                                    <IconButton
                                      color="primary"
                                      className="mx-2"
                                      onClick={() => {
                                        validateOnOpen();
                                        setValidatedIndex(index);
                                      }}
                                    >
                                      <i className="fal fa-check "> </i>
                                    </IconButton>

                                    <IconButton
                                      color="primary"
                                      className="mx-2"
                                      onClick={() => {
                                        deleteOnOpen();
                                        setDeletedIndex(index);
                                      }}
                                    >
                                      <i className="fal fa-trash-alt "> </i>
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="mt-4">
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {' '}
                        <i className="fal fa-user mx-2"> </i> Décision Niveau 1:
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#"> Accordé</a>
                      </li>
                    </ol>
                  </nav>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <TextareaAutosize
                    minRows={6}
                    maxRows={6}
                    cols={160}
                    style={{ boxSizing: 'border-box' }}
                    defaultValue={com1}
                    disabled={true}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="">
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {' '}
                        <i className="fal fa-user mx-2"> </i> Décision Niveau 2:
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#"> Accordé</a>
                      </li>
                    </ol>
                  </nav>{' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <TextareaAutosize
                    minRows={6}
                    maxRows={6}
                    cols={160}
                    style={{ boxSizing: 'border-box' }}
                    defaultValue={com1}
                    disabled={true}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* MODALS */}
          {openImgModal ? (
            <Lightbox
              image={imgUrl}
              title={''}
              onClose={() => {
                setOpenImgModal(!openImgModal);
              }}
            >
              {' '}
            </Lightbox>
          ) : null}
          {openPDFModal ? (
            <StyledModal
              isOpen={openPDFModal}
              onBackgroundClick={() => {
                setOpenPDFModal(!openPDFModal);
              }}
              onEscapeKeydown={() => {
                setOpenPDFModal(!openPDFModal);
              }}
            >
              <span>
                <PDFViewer
                  navbarOnTop
                  document={{
                    url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
                  }}
                />
              </span>
            </StyledModal>
          ) : null}

          <Modal2 onClose={validateOnClose} isOpen={validateIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader></ModalHeader>
              <ModalBody className="d-flex flex-col justify-content-center">
                <div className="m-5">
                  <div className={'d-flex mt-4 items-center'}>
                    <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                      Etes-vous sur de valider cette piéce justificative ?
                    </label>

                    <Button
                      colorScheme="teal"
                      onClick={() => {
                        validateOnClose();
                        confirmValidate();
                      }}
                    >
                      {' '}
                      Valider
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>

          <Modal2 onClose={deleteOnClose} isOpen={deleteIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader></ModalHeader>
              <ModalBody className="d-flex flex-col justify-content-center">
                {/* <div className="m-5"> */}
                <div className={'m-5 d-flex mt-4 items-center'}>
                  <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                    Etes-vous sur de supprimer cette piéce justificative ?
                  </label>

                  <Button
                    colorScheme="teal"
                    onClick={() => {
                      deleteOnClose();
                      confirmDelete();
                    }}
                  >
                    {' '}
                    Supprimer
                  </Button>
                </div>
                {/* </div> */}
              </ModalBody>
            </ModalContent>
          </Modal2>

          <Modal2 onClose={validateDecisionOnClose} isOpen={validateDecisionIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Ajouter un commentaire :</ModalHeader>
              <ModalCloseButton />

              <ModalBody className="">
                <div className="">
                  <div className={'d-flex items-center container'}>
                    <div className="row">
                      <div className="col-12">
                        <TextareaAutosize
                          minRows={6}
                          maxRows={6}
                          cols={45}
                          style={{ boxSizing: 'border-box' }}
                          defaultValue="Commentaire ..."
                        />
                      </div>
                      <div className="col-2">
                        <Button
                          colorScheme="teal"
                          onClick={() => {
                            validateDecisionOnClose();
                          }}
                        >
                          {' '}
                          Valider
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>

          <Modal2 onClose={refuseDecisionOnClose} isOpen={refuseDecisionIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Ajouter un commentaire :</ModalHeader>
              <ModalCloseButton />

              <ModalBody className="">
                <div className="">
                  <div className={'d-flex items-center container'}>
                    <div className="row">
                      <div className="col-12">
                        <TextareaAutosize
                          minRows={6}
                          maxRows={6}
                          cols={45}
                          style={{ boxSizing: 'border-box' }}
                          defaultValue="Commentaire ..."
                        />
                      </div>
                      <div className="col-2">
                        <Button
                          colorScheme="teal"
                          onClick={() => {
                            refuseDecisionOnClose();
                          }}
                        >
                          {' '}
                          Refuser
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>
          <div className="container">
            <div className="mt-4">
              <Link to={'/decision'}>
                <Button2 color="secondary">{'<'} Retour</Button2>
              </Link>
            </div>
          </div>
        </Box>
      </Stack>
    </div>
  );
};
