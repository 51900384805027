import { Select, Button } from '@chakra-ui/react';
import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getListePj, uploadEntity } from 'app/entities/pieceJustificative/pj.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteFromPjList } from 'app/entities/pieceJustificative/pj.reducer';

export const FuncUpload = props => {
  const dispatch = useAppDispatch();
  const pjListe = useAppSelector(state => state.pj);

  const [listePjState, setListePjState] = useState([]);

  const demandeId: { idDemandeSav: string } = useParams();

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [selectedTypePiece, setSelectedTypePiece] = useState('');

  const selectFileCheck = event => {
    setSelectedFiles(event.target.files);
  };

  useEffect(() => {
    dispatch(getListePj(null));
  }, []);

  useEffect(() => {
    setListePjState(pjListe.entity?.listePj);
  }, [pjListe]);

  const upload = () => {
    dispatch(deleteFromPjList(selectedTypePiece));

    let currentFile = selectedFiles[0];
    setProgress(0);
    setCurrentFile(currentFile);

    dispatch(
      uploadEntity({
        file: currentFile,
        name: currentFile.name + Math.floor(Math.random() * 1000),
        selectedTypePiece: selectedTypePiece,
        type: 'Credit',
        demandeId: demandeId.idDemandeSav,
        onUploadProgress: event => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        },
      })
    )
      .unwrap()
      .then(() =>
        dispatch(getEntity(parseInt(demandeId.idDemandeSav)))
          .unwrap()
          .then(() => {})
      );

    props.setCloseAddPj(true);

    setSelectedFiles(true);
  };

  return (
    <div className="d-flex flex-col justify-content-center">
      {currentFile && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress }}
          >
            {progress}%
          </div>
        </div>
      )}
      <label className="btn btn-default">
        <input type="file" onChange={selectFileCheck} />
      </label>

      <Select
        placeholder="Choisir Document a joinde"
        variant="flushed"
        textAlign="center"
        onChange={event => {
          setSelectedTypePiece(event.target.value);
        }}
      >
        {listePjState.length > 0 ? (
          listePjState.map(key => {
            return (
              <option key={key} value={key}>
                {key}
              </option>
            );
          })
        ) : (
          <option value="Carte d'identite Nationale">Carte d'Identite Nationale</option>
        )}
      </Select>

      <Button className="mr-8" colorScheme="teal" mr={3} mt={6} onClick={upload} disabled={!selectedFiles || !selectedTypePiece}>
        Upload{' '}
      </Button>

      <div className="alert alert-light" role="alert"></div>
    </div>
  );
};
