import React from 'react';

const infoTile3 = ({ label, value }) => {
  return (
    <li className="row text-sm my-1">
      <div className="col-7 font-bold text-gray-500">{label}</div> <div className="col-5">{value}</div>{' '}
    </li>
  );
};

export default infoTile3;
