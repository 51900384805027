import { Heading, Text } from '@chakra-ui/react';
import { Input, Link, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Dayjs } from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import CompleteTache from './completeTache';
import { getEntityTask, updateOwnerAndDueDate } from './taches.reducer';

interface Props {
  tacheId: string;
}

const TacheContent = ({ tacheId }: Props) => {
  const tacheSelected = useAppSelector(state => state.taches2.entity);
  const [dateValue, setDateValue] = React.useState('');
  const [ownerValue, setOwnerValue] = React.useState('');

  const options = ['user', 'demo'];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tacheId !== '') {
      dispatch(getEntityTask(tacheId));
    }
  }, []);

  useEffect(() => {
    setDateValue(moment(tacheSelected.dueDate).format('YYYY-MM-DDTHH:mm').toString());
    setOwnerValue(tacheSelected.assignee);
  }, [tacheId, tacheSelected]);

  const valider = () => {
    dispatch(
      updateOwnerAndDueDate({
        taskId: tacheId,
        owner: ownerValue,
        dueDate: dateValue,
      })
    ).then(() => {
      dispatch(getEntityTask(tacheId));
    });
    // throw new Error('Function not implemented.');
  };

  return (
    <>
      {tacheId === '' ? (
        <>
          <Heading>select a task from the list</Heading>
        </>
      ) : (
        <>
          <Row>
            <Heading>{tacheSelected.name}</Heading>
            <Link href={'/decision2/' + tacheSelected.processInstanceId + '/' + tacheSelected.id}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                  fillRule="evenodd"
                  d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </Row>
          <br />
          <Row>
            <Col md="4">
              <Autocomplete
                value={ownerValue}
                onChange={(e: any, newOner: string | null) => {
                  setOwnerValue(newOner);
                }}
                autoHighlight
                options={options}
                renderInput={params => <TextField {...params} label="Assigner la tâche" variant="standard" />}
              />
            </Col>
            <Col md="2"></Col>
            <Col md="4">
              <TextField
                label="Définir un délai"
                type="datetime-local"
                value={dateValue}
                onChange={e => {
                  setDateValue(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                  variant: 'standard',
                }}
              />
            </Col>
            <Col md="2">
              <Button
                id="valider"
                onClick={() => {
                  valider();
                }}
                color="error"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
              </Button>
              <Button
                id="annuler"
                onClick={() => {
                  setDateValue(moment(tacheSelected.dueDate).format('YYYY-MM-DDTHH:mm').toString());
                  setOwnerValue(tacheSelected.assignee);
                }}
                color="error"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path
                    fillRule="evenodd"
                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </Button>
            </Col>
          </Row>
          <br />
          <Row p={8}>
            <Col md="12">
              <CompleteTache />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TacheContent;
