import { Box, Text, Button } from '@chakra-ui/react';
import React from 'react';
import SimulationBody from './simulationBody';
import { useAppDispatch } from 'app/config/store';
import { deleteFichClient } from 'app/modules/CreditStepper/Reducers/ficheClientSlice';

const HomeSimulation = ({ onOpenSAV, onOpen, onSimulationSavActivate }) => {
  const dispatch = useAppDispatch();
  return (
    <div className="relative  pt-8  pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8 bg-white">
      <div className="relative max-w-3xl mx-auto">
        <div className="text-center">
          {/* <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Accueil</h2> */}
          <Text bgGradient="linear(to-l, #065c4c, #122f96 )" bgClip="text" fontSize="6xl" fontWeight="extrabold">
            Accueil
          </Text>
          <Text fontSize="3xl" fontWeight="bold" color="gray.700"></Text>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4"></p>
        </div>
        <SimulationBody onOpenSAV={onOpenSAV} onOpen={onOpen} onSimulationSavActivate={onSimulationSavActivate} />
        {/* <Box p="8" display="flex" alignItems="center" justifyContent="center">
          <Button colorScheme="teal" onClick={() => dispatch(deleteFichClient())}>
            Changer Client
          </Button>
        </Box> */}
      </div>
    </div>
  );
};

export default HomeSimulation;
