import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'reactstrap';

const styles = theme => ({
  input: {
    '& input.Mui-disabled': {
      color: 'green',
    },
  },
});
const FormattedInput3 = ({ label, value, onChange, readOnly, isAllowed, errorCarac, ide }) => {
  const [tooltipOpen, setTooltipOpen] = useState(true);

  useEffect(() => {
    setTooltipOpen(errorCarac);
  }, [errorCarac]);
  const x = Math.floor(Math.random() * 10);

  const SmallTextField = (props: Object) => <TextField disabled {...props} />;

  return (
    <div className="mt-2 ml-8">
      {/* <Tooltip hideArrow={true} style={{ backgroundColor: '#e39517' }} placement="bottom" isOpen={tooltipOpen} target={ide ? ide : 'e'}>
        <i className="fas fa-exclamation-triangle"></i> Champ obligatoire
      </Tooltip> */}
      <div className="row ">
        <div className="col-5">
          {/* <ReactTooltip id={'title require' + x} effect="solid" type="warning" place="bottom">
            <i className="fas fa-exclamation-triangle"></i> Champ Obligatoire {x}
          </ReactTooltip> */}
          <label className=" block text-base font-medium text-grey-500 sm:mt-px sm:pt-2 ">{label} </label>
        </div>
        {label === 'Valeur du Bien :' && <span> </span>}
        <div className="col-4">
          <div id={ide} className={label === 'Valeur du Bien :' ? 'ml-14' : 'ml-8'}>
            {readOnly === true ? (
              <NumberFormat
                // ref={toolTipRef}
                // data-tip
                // data-for={'title require' + x}
                // data-event={'focusout'}
                // className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                isAllowed={isAllowed}
                customInput={TextField}
                placeholder="Valeur en MAD"
                value={value}
                thousandSeparator=" "
                decimalSeparator=","
                suffix=" MAD"
                onChange={onChange}
                disabled
              />
            ) : (
              <NumberFormat
                data-tip
                data-for={'title require' + x}
                // data-event={'focusout'}
                // className="text-lg block border-b-2  border-gray-300 rounded  focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                isAllowed={isAllowed}
                customInput={TextField}
                thousandSeparator=" "
                placeholder="Valeur en MAD"
                suffix=" MAD"
                value={value}
                onValueChange={onChange}
              />
            )}
          </div>
        </div>
        {/* <div className="col-sm invisible">One of three columns</div> */}
      </div>
    </div>
  );
};

export default FormattedInput3;
