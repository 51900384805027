import { Box, Stack, VStack, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';

interface BannerColProps {
  children: React.ReactNode;
  icon: React.ReactElement;
}

export const BannerCol = (props: BannerColProps) => {
  const { children, icon } = props;

  return (
    <Stack mx="10px">
      <VStack direction={{ base: 'column', md: 'row' }} align={'center'}>
        <Box fontSize="6xl">{icon}</Box>

        <Box w={300} h={100} color={mode('gray.600', 'gray.400')}>
          {children}
        </Box>
      </VStack>
    </Stack>
  );
};
