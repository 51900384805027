import { TextField, FormControl } from '@material-ui/core';
import React from 'react';
import { useAppDispatch } from 'app/config/store';
import { editFicheClient } from '../../Reducers/ficheClientSlice';
import { editFicheClient2 } from 'app/entities/client/client.reducer';
import { editFicheClient3 } from '../../Reducers/simulationCreditSlice';
import { dateAdd } from 'igniteui-react-core';
import { PencilIcon, CheckIcon, MenuIcon, PencilAltIcon, ChartBarIcon } from '@heroicons/react/outline';

interface EditStringTileProps {
  label: string;
  value: string;
  champ: string;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  isInputToggle?: boolean;
}

// import Mont from 'app/modules/stepper/StepperForms/InfoTile';
const EditStringTile = ({ label, value, champ, toggle, setToggle, isInputToggle = false }: EditStringTileProps) => {
  const dispatch = useAppDispatch();

  const handleOnChange = e => {
    dispatch(editFicheClient2({ champ, inputValue: e.target.value }));
    dispatch(editFicheClient3({ champ, inputValue: e.target.value }));
  };

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:px-6">
      <dt className="text-sm font-bold text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {toggle === true ? (
          <span className="d-flex align-baseline">
            <TextField id="standard-disabled" placeholder={label} value={value} onChange={e => handleOnChange(e)} />
            {isInputToggle && (
              <CheckIcon className="md:w-3 xl:w-5 text-green-600 hover:text-green-700" onClick={() => setToggle(!toggle)} />
            )}
          </span>
        ) : (
          <span className="d-flex ">
            {value}
            {isInputToggle && (
              <PencilIcon className="ml-2 md:w-3 xl:w-5 text-blue-800 hover:text-green-700" onClick={() => setToggle(!toggle)} />
            )}
          </span>
        )}
      </dd>
    </div>
  );
};

export default EditStringTile;
