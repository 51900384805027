import React from 'react';
import { Text } from '@chakra-ui/react';
import { BrandIconPdf } from 'app/shared/layout/header/header-components';

const EcheancierPDF = React.forwardRef<HTMLDivElement, any>(({ produit, ta }, ref) => {
  const HEADER_TITLES = ['N°', 'Échéance', 'Capital', 'Assurance', 'Frais', 'Intérêts', 'Date'];

  const displayDemandes = () => {
    return ta?.map((demande, index) => {
      var v = demande.termDate.toString().substring(5, 7) === '01';
      return (
        <tr key={demande.id}>
          <td>{index + 1}</td>
          <td>{demande.mntEcheance}</td>
          <td> {demande.mntCapital}</td>
          <td>{demande.mntAssurance}</td>
          <td>{demande.mntFrais}</td>
          <td>{demande.mntInteret}</td>
          <td>
            <Text fontSize="md" color={v ? 'teal' : ''} fontWeight={v ? 'extrabold' : ''}>
              {demande.termDate.toString().substring(0, 10)}
            </Text>
          </td>
          <td></td>
        </tr>
      );
    });
  };

  return (
    <div ref={ref} className="w-full">
      <div className="ml-8 mt-8 brandpdf-logo">
        {' '}
        <BrandIconPdf />{' '}
      </div>
      <div className="flex justify-center ">
        <Text as="h3" className="my-24" fontSize="xl" fontWeight="extrabold">
          Simulation Crédit:"{produit}"
        </Text>
      </div>
      <Text className="ml-8 " fontSize="md" color="">
        Tableau d'amortissement
      </Text>

      <table className="ml-8 mt-4 table table-auto table-bordered">
        <thead>
          <tr>
            {HEADER_TITLES.map(title => (
              <th className="p-3" key={title}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody id="myTable"> {displayDemandes()}</tbody>
      </table>
    </div>
  );
});

export default EcheancierPDF;
