import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ITache, defaultValue } from 'app/shared/model/tache.model';
import { Storage } from 'react-jhipster';

const initialState: EntityState<ITache> = {
  loading: false,
  errorMessage: null,
  history: [],
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'http://localhost:9001/api/taches';
const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

// Actions

export const getEntities = createAsyncThunk(
  'taches/fetch_entity_list',
  async (userId: number) => {
    const requestUrl = `${apiUrl}/user/${userId}`;
    return axios.get<ITache[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getHistoryEntities = createAsyncThunk(
  'taches/fetch_history_entity_list',
  async (demandeId: string) => {
    const requestUrl = `${apiUrl}/tache/rev/${demandeId}`;
    const histTaches: ITache[] = await axios.get<ITache[]>(requestUrl).then(res => {
      return res.data;
    });

    // histTaches.map(async (tache) => {
    //   await axios.get<string>(`${apiUrl}/user/fullname/${tache.userId}`).then(res => {
    //     tache.userFullname = res.data;
    //   });

    // })

    return histTaches;
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByDemandeId = createAsyncThunk(
  'taches/fetch_entity_list__by_demandeId',
  async (demandeId: string) => {
    const requestUrl = `${apiUrl}/savDemande/${demandeId}`;
    return axios.get<ITache[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'taches/fetch_entity',
  async (idTache: string) => {
    const result = await axios.get<ITache>(`${apiUrl}/tache/` + idTache);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// export const getEntity = createAsyncThunk(
//   'taches/fetch_entity',
//   async (id: string | number) => {
//     const requestUrl = `${apiUrl}/${id}`;
//     return axios.get<ITache[]>(requestUrl);
//   },
//   { serializeError: serializeAxiosError }
// );

// export const createEntity = createAsyncThunk(
//   'simulationSav/create_entity',
//   async (entity: ITache, thunkAPI) => {
//     const result = await axios.post<ITache>(apiUrl, cleanEntity(entity));
//     thunkAPI.dispatch(getEntities({}));
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

// export const updateEntity = createAsyncThunk(
//   'simulationSav/update_entity',
//   async (entity: ISimulationSav, thunkAPI) => {
//     const result = await axios.put<ISimulationSav>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
//     thunkAPI.dispatch(getEntities({}));
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

export const partialUpdateEntity = createAsyncThunk(
  'taches/partial_update_entity',
  async (entity: ITache) => {
    const result = await axios.patch<ITache>(`${apiUrl}/tache/update`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getActiveEntity = createAsyncThunk('taches/fetch_active_entity', async (demandeId: string) => {
  const activeTahceKey: string = await axios.get('http://localhost:8090/camunda/nextUserTask/' + demandeId).then(res => {
    return res.data;
  });

  if (activeTahceKey === '') {
    return null;
  } else {
    const requestUrl = `${apiUrl}/active/${demandeId}/` + activeTahceKey;

    const result = await axios.get<ITache>(requestUrl);
    return result;
  }
});

export const validateActiveEntity = createAsyncThunk(
  'taches/validate_active_entity',
  async (updTache: ITache) => {
    const requestUrl = `${apiUrl}/tache/validate`;

    const result = await axios.patch<ITache>(requestUrl, updTache);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const rejectActiveEntity = createAsyncThunk(
  'taches/reject_active_entity',
  async (updTache: ITache) => {
    const requestUrl = `${apiUrl}/tache/reject`;

    const result = await axios.patch<ITache>(requestUrl, updTache);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const aModifierActiveEntity = createAsyncThunk(
  'taches/modifier_active_entity',
  async (updTache: ITache) => {
    const requestUrl = `${apiUrl}/tache/amodifer`;

    const result = await axios.patch<ITache>(requestUrl, updTache);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// export const deleteEntity = createAsyncThunk(
//   'simulationSav/delete_entity',
//   async (id: string | number, thunkAPI) => {
//     const requestUrl = `${apiUrl}/${id}`;
//     const result = await axios.delete<ISimulationSav>(requestUrl);
//     thunkAPI.dispatch(getEntities({}));
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

// slice

export const TachesSlice = createEntitySlice({
  name: 'taches',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getActiveEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      //   .addCase(deleteEntity.fulfilled, state => {
      //     state.updating = false;
      //     state.updateSuccess = true;
      //     state.entity = {};
      //   })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getHistoryEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          history: action.payload,
        };
      })
      .addMatcher(isFulfilled(getEntitiesByDemandeId), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(rejectActiveEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(validateActiveEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(aModifierActiveEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntitiesByDemandeId, getHistoryEntities, getActiveEntity, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(partialUpdateEntity, rejectActiveEntity, validateActiveEntity, aModifierActiveEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = TachesSlice.actions;

// Reducer
export default TachesSlice.reducer;
