import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RemboursementPartielModel } from 'app/modules/SavStepper/models/RemboursementPartiel.model';
import { isFulfilled, isPending } from '@reduxjs/toolkit';
import { ModeContinuationModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/mode-continuation.model';
import { BaremePenaliteModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/bareme-penalite.model';
import { MotifRemboursementModel } from 'app/modules/SavStepper/models/enums/Remboursement Partiel/motif-remboursement.model';

const initialState: RemboursementPartielModel = {
  dateEvenement: null,
  amountOfEvent: 0,
  accountNumber: 0,
  modeContinuation: null,
  penalty: null,
  motif: null,
  nomEvent: '',
};

export const RemboursementPartielSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    setDateEvenemnt: (state, action: PayloadAction<Date>) => {
      state.dateEvenement = action.payload;
    },
    setMontantRemboursement: (state, action: PayloadAction<number>) => {
      state.amountOfEvent = action.payload;
    },
    setCompteRemboursement: (state, action: PayloadAction<number>) => {
      state.accountNumber = action.payload;
    },
    setModeContinuation: (state, action: PayloadAction<ModeContinuationModel>) => {
      state.modeContinuation = action.payload;
    },
    setBaremePenalite: (state, action: PayloadAction<BaremePenaliteModel>) => {
      state.penalty = action.payload;
    },
    setMotifRemboursement: (state, action: PayloadAction<MotifRemboursementModel>) => {
      state.motif = action.payload;
    },
    reset: state => {
      state = initialState;
    },
  },
});

export const {
  reset,
  setDateEvenemnt,
  setMontantRemboursement,
  setCompteRemboursement,
  setModeContinuation,
  setBaremePenalite,
  setMotifRemboursement,
} = RemboursementPartielSlice.actions;

// Reducer
export default RemboursementPartielSlice.reducer;
