import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { iResultatSimCredit } from 'app/shared/model/resultatSimCredit.model';
import { ISyntheseCredit } from 'app/shared/model/syntheseCredit.model';

const initialState: iResultatSimCredit = {
  echeance: 2000,
  teg: 350,
  mensualite: 200,
  fraisDossier: 200,
  coutTotalAssurance: 200,
  primeActuarielle: 200,
  interetDusFranchise: 200,
  assurance: null,
  tauxEndettement: 35,
  echeanceGlobal: 4000,
  credits: null,
};

export const postSyntheseCredit = createAsyncThunk(
  'credit/postSyntheseCredit',
  async (syntheseCredit: ISyntheseCredit) => {
    const requestUrl = `api/resultats/simuler/credit`;

    return axios.post<iResultatSimCredit>(requestUrl, syntheseCredit);
  },
  { serializeError: serializeAxiosError }
);

export const resultatSimCreditSlice = createSlice({
  name: 'resultatSimCredit',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(postSyntheseCredit.fulfilled, (state, action) => {
      return {
        ...action.payload.data,
        nomBanque: 'BOA',
        codeBanque: '230',
        codeAgence: '001',
        nomAgence: 'CASABLANCA',
        dateEnregistrement: '2044-02-02',
      };
    });
  },
});

export default resultatSimCreditSlice.reducer;
