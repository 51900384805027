import { TextField, FormControl } from '@material-ui/core';
import React from 'react';
import { useAppDispatch } from 'app/config/store';
import { editFicheClient } from '../../Reducers/ficheClientSlice';
import { editFicheClient2 } from 'app/entities/client/client.reducer';
import { editFicheClient3 } from '../../Reducers/simulationCreditSlice';
import { dateAdd } from 'igniteui-react-core';

// import Mont from 'app/modules/stepper/StepperForms/InfoTile';
const DateEditTile = ({ label, value, champ, toggle, setToggle }) => {
  const dispatch = useAppDispatch();

  const handleOnChange = e => {
    dispatch(editFicheClient2({ champ, inputValue: e.target.value }));
    dispatch(editFicheClient3({ champ, inputValue: e.target.value }));
  };

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-bold text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {toggle === true ? (
          <span className="d-flex align-baseline">
            <TextField id="standard-disabled" type="date" onChange={e => handleOnChange(e)} />
          </span>
        ) : (
          <span className="d-flex ">
            {value}
            <i className="mx-4 fal fa-pencil font-medium text-gray-600 hover:text-green-700" onClick={() => setToggle(!toggle)}></i>
          </span>
        )}
      </dd>
    </div>
  );
};

export default DateEditTile;
