//TODO: interface properties has no meaning if they are declared optional

import { IClient } from './client.model';
import { IDemande } from './demande.model';

export interface IDemandeGraphe {
  statut?: string;
  demandes?: IDemande[];
  // decisions?: any[];
  // agence?: string;
  // client?: IClient;
  // dateDemande?: Date;

  // loanSimulationResultId?: number;
  // userId?: string;
  // codeBanque?: string;
  // nomBanque?: string;
  // codeAgence?: string;
  // nomAgence?: string;
  // loanInputSimulation?: any;
}

export const defaultValue: Readonly<IDemande> = {};
