import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import getStore from './config/store';
import setupAxiosInterceptors from './config/axios-interceptor';
import { clearAuthentication } from './shared/reducers/authentication';
import ErrorBoundary from './shared/error/error-boundary';
import AppComponent from './app';
import { loadIcons } from './config/icon-loader';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from '@material-ui/core/styles';
/* jhipster-needle-add-route-import - JHipster will add routes here */
import { createTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import { deepOrange, teal } from '@material-ui/core/colors';
import { ModalProvider } from 'styled-react-modal';
import { StylesProvider } from '@material-ui/styles';

const store = getStore();

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

loadIcons();
const theme = createTheme({
  overrides: {
    //Initing Placeholder
  },
  palette: {
    primary: { main: '#265ba6' },
    secondary: {
      main: '#FF5722',
    },
  },
});
const rootEl = document.getElementById('root');

const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <ChakraProvider>
              <div>
                <Component />
              </div>
            </ChakraProvider>
          </ModalProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>,
    rootEl
  );

render(AppComponent);
