import React, { useCallback, useEffect, useState } from 'react';
import { Modal as Modal2 } from '@chakra-ui/react';

import {
  ChakraProvider,
  extendTheme,
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  Select,
  Switch,
  Flex,
  Spacer,
  Square,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BanIcon, CheckCircleIcon, UserGroupIcon } from '@heroicons/react/outline';

const SucceAjoutPalierModal = ({ succeOnClose, succeIsOpen }) => {
  return (
    <Modal onClose={succeOnClose} isOpen={succeIsOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader></ModalHeader>
        <ModalBody className="d-flex flex-col justify-content-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
            <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="m-5">
            <div className={'d-flex mt-4 items-center'}>
              <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                Palier Ajouté
              </label>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SucceAjoutPalierModal;
