import React, { useEffect, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConsoForm from 'app/modules/CreditStepper/CaracteristiqueCredit/ConsoForm';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { editCaracteristiqueCredit } from '../Reducers/syntheseCreditSlice';
import ImmoForm from './ImmoForm';

export default function CaracteristiqueCredit(props) {
  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const dispatch = useAppDispatch();

  const [errorCarac, setErrorCarac] = React.useState(false);

  const cleanI = (v: string | number) => {
    if (typeof v === 'number') return v;
    if (v === undefined) return undefined;

    let y = v.toString();
    y = y.replace(/\s/g, '');
    y = y.replace('dhs', '');
    y = y.replace('MAD', '');

    return parseInt(y);
  };

  const [open, setOpen] = React.useState(false);
  const [maxVal, setMaxVal] = useState(0);

  const handleOnTypeCreditChange = (champ, formattedValue) => {
    dispatch(editCaracteristiqueCredit({ champ, inputValue: formattedValue }));
  };

  useEffect(() => {
    setMaxVal(cleanI(simulationCredit.montantProjet));
  }, [simulationCredit.montantProjet]);

  return (
    <div className="d-flex flex-column  ">
      <div className=" ">
        <div className="mx-5">
          <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
            <div className="">
              <form className="">
                <div className=" ">
                  <div>
                    <div className="sm:mt-5 ">
                      <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
                        <span className="border-b-4 border-blue-800 ">Caractéristique du crédit</span>
                      </h4>
                      <div></div>
                      <div className="ml-20 xl:ml-38  ">
                        <div className="row   ">
                          <div className="col-3 xl:ml-8">
                            <dt className="block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Type du crédit : </dt>
                          </div>
                          <div className="col-6">
                            <RadioGroup
                              aria-label="Type Credit"
                              name="typeCredit"
                              value={simulationCredit.typeCredit}
                              onChange={event => {
                                handleOnTypeCreditChange('typeCredit', event.target.value);
                                props.setErrorCarac(false);

                                // TODO: make changes in local state and reflect it on global state once for all attributes
                              }}
                              row
                            >
                              <FormControlLabel value="IMMOBILIER" control={<Radio />} label="Immobilier" />
                              <FormControlLabel value="CONSOMATION" control={<Radio />} label="Consommation" />
                            </RadioGroup>
                          </div>
                        </div>{' '}
                      </div>

                      {simulationCredit.typeCredit === 'CONSOMATION' ? (
                        <div className="ml-8">
                          <ConsoForm />
                        </div>
                      ) : null}

                      {simulationCredit.typeCredit === 'IMMOBILIER' ? (
                        <ImmoForm errorCarac={props.errorCarac} setErrorCarac={a => setErrorCarac(a)} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
