import React from 'react';
import { ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

const LoginModal = (props: ILoginModalProps) => {
  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const { loginError, handleClose } = props;

  return (
    // <Modal isOpen={props.showModal} toggle={handleClose} backdrop="static" id="login-page" autoFocus={false}>
    <div className="min-h-screen boa-gradient flex flex-col justify-center py-12 sm:px-6 lg:px-8  ">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="rounded-full bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10  ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-18 w-38" src="../../../content/svgs/boa2.png" alt="Bank Of Africa" />
          </div>
          <Form onSubmit={handleSubmit(login)}>
            <Row>
              <Col md="12">
                {loginError ? (
                  <Alert color="danger" data-cy="loginError">
                    <strong>Failed to sign in!</strong> Please check your credentials and try again.
                  </Alert>
                ) : null}
              </Col>
              <Col md="12">
                <div>
                  <label htmlFor="username" className=" block text-sm font-medium text-gray-700">
                    {' '}
                    Identifiant{' '}
                  </label>
                  <div className="mt-1">
                    <ValidatedField
                      name="username"
                      placeholder="Your username"
                      required
                      data-cy="username"
                      validate={{ required: 'Username cannot be empty!' }}
                      register={register}
                      error={errors.username}
                      isTouched={touchedFields.username}
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border
                      border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-indigo-500
                      focus:border-indigo-500
                      sm:text-sm
                    />
                  </div>
                </div>

                <div>
                  <ValidatedField
                    name="password"
                    type="password"
                    placeholder="Your password"
                    required
                    data-cy="password"
                    validate={{ required: 'Password cannot be empty!' }}
                    register={register}
                    error={errors.password}
                    isTouched={touchedFields.password}
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border
                    border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                  />
                </div>
                <div className=" flex items-center justify-between">
                  <div className=" flex items-center">
                    <ValidatedField
                      name="rememberMe"
                      type="checkbox"
                      check
                      label="Remember Me"
                      value={true}
                      register={register}
                      h-50
                      w-40
                      text-gray-600
                      focus:ring-green-500
                      border-green-300
                      rounded
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <button
                type="submit"
                className="
             w-full
             flex
             justify-center
             py-2
             px-4
             border border-transparent
             rounded-md
             shadow-sm
             text-sm
             font-medium
             text-white
             bg-blue-600
             hover:bg-blue-700
             focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
          "
              >
                Login
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
