import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  extendTheme,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import TextField from '@material-ui/core/TextField';

import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import '../outlineDisable.scss';

import { DataGrid } from '@material-ui/data-grid';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { propositionAllUpdate, propositionUpdate } from '../../Reducers/syntheseCreditSlice';
import EchecAjoutPalierModalError from './EchecAjoutPalierModalError';
import SucceAjoutPalierModal from './SucceAjoutPalierModal';
import GeneriqueModalError from './GeneriqueModalError';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const rng = () => {
  return Math.floor(Math.random() * 1000);
};

const cleanI = (v: string | number) => {
  if (typeof v === 'number') return v;
  let y = v.toString();
  y = y.replace(/\s/g, '');
  y = y.replace('dhs', '');
  y = y.replace('MAD', '');

  return parseInt(y);
};

const cleanF = (v: string | number) => {
  if (typeof v === 'number') return v;
  let y = v.toString();
  y = y.replace(/\s/g, '');
  y = y.replace('dhs', '');
  y = y.replace('MAD', '');

  return parseFloat(y);
};

const tauxMax = 35;
const tauxMin = 5;

export const PalierModal = props => {
  const dispatch = useAppDispatch();

  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const [paliers, setPaliers] = useState(null);
  const [propositions, setPropositions] = useState(null);
  const { isOpen: succeIsOpen, onOpen: succeOnOpen, onClose: succeOnClose } = useDisclosure();
  const { isOpen: echecIsOpen, onOpen: echecOnOpen, onClose: echecOnClose } = useDisclosure();

  const { isOpen: generiqueErrorIsOpen1, onOpen: generiqueErrorOnOpen1, onClose: generiqueErrorOnClose1 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen2, onOpen: generiqueErrorOnOpen2, onClose: generiqueErrorOnClose2 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen3, onOpen: generiqueErrorOnOpen3, onClose: generiqueErrorOnClose3 } = useDisclosure();

  const [tempo, setTempo] = useState([{ id: 45 }]);

  const [duree, setDuree] = useState(3);
  const [taux, setTaux] = useState(5);

  const [switchAjout, setSwitchAjout] = useState(false);
  const [modif, setModif] = useState(false);

  const [flagged, setFlagged] = useState(null);

  const dureeMaxNouveauPalier = simulationCredit.propositions[props.propositionId]?.paliers?.find(palier => palier.typePalier === 1).duree;

  useEffect(() => {
    setPaliers(simulationCredit.propositions[props.propositionId]?.paliers);
  }, [props.palierArray, props.prdtArray]);

  useEffect(() => {
    setPropositions(props.prdtArray);
  }, [props.prdtArray]);

  const handleValider = (e, c) => {
    if (taux < tauxMin || taux > tauxMax) {
      generiqueErrorOnOpen3();
      return;
    }
    const index = props.propositionId;

    let s = 0;
    // ############# RECUPERATION DU PALIER PRINCIPAL POUR CONTROLE
    var paliersList = [...simulationCredit.propositions[props.propositionId].paliers];
    var palierPrincipal = paliersList.find(palier => palier.typePalier === 1);

    if (palierPrincipal.duree - duree >= 1) {
      /*
    TODO: check this later
    */
      // ############# NOUVEAU PALIER AJOUTé
      const palier = {
        // ident: rng(),
        id: rng(),
        typeFranchise: '',
        typePalier: 3,
        duree: duree,
        taux: taux,
      };

      const newPalierPrincipal = {
        ...palierPrincipal,
        duree: palierPrincipal.duree - duree,
      };
      const paliers2 = paliers.concat(palier);
      // TODO remplacer le 1 par une recherche plus bug proof
      paliers2.splice(0, 1, newPalierPrincipal);

      const produit = { ...simulationCredit.propositions[props.propositionId], paliers: paliers2 };
      var produits = [...simulationCredit.propositions];

      let val3;

      if (index === 0) {
        val3 = produits.unshift(produit);
        produits.splice(index + 1, 1);
        dispatch(propositionAllUpdate(produits));
      }
      if (index === 1) {
        produits = produits.concat(produit);
        produits.splice(index, 1);
        dispatch(propositionAllUpdate(produits));
      }

      succeOnOpen();
      setSwitchAjout(false);
    } else {
      echecOnOpen();
    }
  };

  const handlePalierDelete = (e, c) => {
    // ############# CREATION D'UNE NOUVELLE PROPOSITION SANS LE PALIER SUPPRIMé
    let paliers = [...simulationCredit.propositions[props.propositionId].paliers];

    var palierSupprime = paliers.find(palier => palier.id === c.id);
    if (palierSupprime.typePalier === 1) {
      alert('vous ne pouvez pas supprimer le palier principal');
      return;
    } else {
      const index = props.propositionId;

      var palierPrincipal = paliers.find(palier => palier.typePalier === 1);

      const newPalierPrincipal = {
        ...palierPrincipal,
        duree: palierPrincipal.duree + palierSupprime.duree,
      };
      paliers.splice(0, 1, newPalierPrincipal);

      paliers = paliers.filter(palier => palier.id !== c.id);

      var produit = { ...simulationCredit.propositions[props.propositionId], paliers };
      if (palierSupprime.typePalier === 2) {
        produit = { ...produit, isFranchise: false, dureeFranchise: 0 };
      }

      let array = [...simulationCredit.propositions];

      if (index !== -1) {
        array.splice(index, 1, produit);

        dispatch(propositionAllUpdate(array));
      }
    }
  };

  const handleClickModif = (e, c) => {
    const palier = simulationCredit.propositions[props.propositionId].paliers.find(palier => palier.id == c.id);

    setDuree(palier.duree);
    setTaux(palier.taux);
    setFlagged(c.id);
  };

  const handleCheckClick = (e, c) => {
    const paliersT = [...simulationCredit.propositions[props.propositionId].paliers];
    const paliersModifie = paliersT.find(palier => palier.id == c.id);
    const paliersIndex = paliersT.findIndex(palier => palier.id == c.id);

    if (duree >= 0) {
      if (duree > 0) {
        const index = props.propositionId;
        // ####### INSERTION DU NOUVEAU PALIER MODIFIE
        const newP = { ...paliersModifie, duree: duree, taux: taux };
        paliersT.splice(paliersIndex, 1, newP);
        var variation = paliersModifie.duree - duree;
        // ####### INSERTION DU NOUVEAU PALIER MODIFIE [END]
        var palierPrincipal = paliersT.find(palier => palier.typePalier === 1);

        if (variation === 0) {
          // CAD la meme duree juste le taux qui a changé
          const produit = { ...props.prdtArray[props.propositionId], paliers: paliersT };
          dispatch(propositionUpdate(produit));
        } else if (variation > 0) {
          // CAD diminution de la durée du palier
          const newPalierPrincipal = {
            ...palierPrincipal,
            duree: palierPrincipal.duree + variation,
          };
          paliersT.splice(0, 1, newPalierPrincipal);
          const produit = { ...props.prdtArray[props.propositionId], paliers: paliersT };
          dispatch(propositionUpdate(produit));
        } else {
          // CAD augmentation de la durée du palier
          variation = -variation;
          if (variation < palierPrincipal.duree) {
            const newPalierPrincipal = {
              ...palierPrincipal,
              duree: palierPrincipal.duree - variation,
            };
            paliersT.splice(0, 1, newPalierPrincipal);
            const produit = { ...props.prdtArray[props.propositionId], paliers: paliersT };
            dispatch(propositionUpdate(produit));
          } else {
            setDuree(paliersModifie.duree);
            alert('erreur duree maximal du palier est ' + (palierPrincipal.duree - 1));
          }
        }
      } else {
        alert("la durée minimal d'un palier est 1");
        setDuree(paliersModifie.duree);
      }
    } else {
      alert('Les valeurs negatifs ne devraient meme pas etre possibles');
      setDuree(paliersModifie.duree);
    }
  };

  const theme = extendTheme({
    components: {
      Modal: {
        baseStyle: props => ({
          dialog: {
            maxWidth: ['60%', '60%', '60%'],
            minWidth: '60%',
          },
        }),
      },
    },
  });

  return (
    <div>
      <ChakraProvider theme={theme}>
        <Modal closeOnOverlayClick={false} onClose={props.onClose} isOpen={props.isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Gestion des paliers </ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody className="d-flex flex-col justify-content-center">
              <div style={{ height: 300, width: '100%' }} className="">
                {!switchAjout && (
                  <DataGrid
                    hideFooterSelectedRowCount
                    rows={paliers}
                    pageSize={10}
                    columns={[
                      {
                        field: 'typeFranchise',
                        headerName: 'Type',
                        flex: 0.1,
                        renderCell: cellValues => {
                          if (cellValues.row.typePalier === 1) return 'Principal';
                          else if (cellValues.row.typePalier === 3) return 'Generique';
                          else {
                            if (cellValues.row.typePalier === 'T') {
                              return 'Franchise Total';
                            } else {
                              return 'Franchise Partielle';
                            }
                          }
                        },
                      },
                      {
                        field: 'duree',
                        headerName: 'Durée',
                        renderCell: cellValues => {
                          if (modif && cellValues.id == flagged)
                            return (
                              <NumberFormat
                                className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                                customInput={TextField}
                                placeholder="Durée en mois"
                                value={duree}
                                thousandSeparator=" "
                                decimalSeparator=","
                                suffix={' mois'}
                                onValueChange={values => {
                                  const { formattedValue, value } = values;
                                  setDuree(cleanI(value));
                                }}
                              />
                            );
                          else return cellValues.value + ' Mois';
                        },
                        flex: 0.1,
                      },
                      {
                        field: 'taux',
                        headerName: 'Taux',
                        renderCell: cellValues => {
                          if (modif && cellValues.id == flagged)
                            return (
                              <NumberFormat
                                className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                                customInput={TextField}
                                placeholder="Pourcentage"
                                value={taux}
                                thousandSeparator=" "
                                suffix={' %'}
                                onValueChange={values => {
                                  const { formattedValue, value } = values;
                                  setTaux(parseFloat(value));
                                }}
                              />
                            );
                          else return cellValues.value + ' %';
                        },
                        flex: 0.1,
                      },
                      {
                        field: 'id3',
                        headerName: ' ',
                        sortable: false,
                        flex: 0.05,
                        renderCell: cellValues => {
                          if (modif && cellValues.id == flagged)
                            return (
                              <div>
                                {' '}
                                <Button
                                  colorScheme="blue"
                                  variant="ghost"
                                  onClick={event => {
                                    setModif(!modif);
                                    handleCheckClick(event, cellValues);
                                  }}
                                >
                                  <i className="fal fa-check"> </i>
                                </Button>
                                <Button
                                  colorScheme="blue"
                                  variant="ghost"
                                  onClick={event => {
                                    setModif(!modif);
                                  }}
                                >
                                  <i className="fal fa-times"> </i>
                                </Button>
                              </div>
                            );
                          return (
                            <Button
                              colorScheme="blue"
                              variant="ghost"
                              onClick={event => {
                                if (cellValues.row.typePalier !== 1) {
                                  setModif(!modif);
                                  handleClickModif(event, cellValues);
                                } else {
                                  generiqueErrorOnOpen1();
                                }
                              }}
                            >
                              <i className="fal fa-pen"> </i>
                            </Button>
                          );
                        },
                      },
                      {
                        field: 'id2',
                        headerName: ' ',
                        sortable: false,
                        flex: 0.05,
                        renderCell: cellValues => {
                          return (
                            <Button
                              colorScheme="blue"
                              variant="ghost"
                              onClick={event => {
                                if (cellValues.row.typePalier !== 1) {
                                  handlePalierDelete(event, cellValues);
                                } else {
                                  generiqueErrorOnOpen1();
                                }
                              }}
                            >
                              <i className="fal fa-trash"> </i>
                            </Button>
                          );
                        },
                      },
                    ]}
                  />
                )}
                {switchAjout && (
                  <div style={{ height: 300, width: '100%' }} className="">
                    <DataGrid
                      hideFooterSelectedRowCount
                      rows={tempo}
                      pageSize={1}
                      columns={[
                        {
                          field: 'id',
                          headerName: 'Type Palier',
                          renderCell: cellValues => {
                            return <div>Generique</div>;
                          },
                          flex: 0.2,
                        },

                        {
                          field: 'id2',
                          headerName: 'Durée',
                          sortable: false,
                          flex: 0.2,
                          renderCell: cellValues => {
                            return (
                              <NumberFormat
                                className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                                customInput={TextField}
                                placeholder="Nombre de mois"
                                value={duree}
                                thousandSeparator=" "
                                decimalSeparator=","
                                suffix={' mois'}
                                onValueChange={values => {
                                  const { formattedValue, value } = values;
                                  setDuree(cleanI(value));
                                }}
                              />
                            );
                          },
                        },
                        {
                          field: 'id4',
                          headerName: 'Taux',
                          sortable: false,
                          flex: 0.2,
                          renderCell: cellValues => {
                            return (
                              <NumberFormat
                                className="text-lg block border-b-2  border-gray-300 rounded md:text-left mb-1 md:mb-0 pr-4 focus:outline-none focus:border-green-700 hover:bg-gray-50  "
                                customInput={TextField}
                                placeholder="Valeur en MAD"
                                value={taux}
                                thousandSeparator=" "
                                suffix={' %'}
                                onValueChange={values => {
                                  const { formattedValue, value } = values;

                                  setTaux(parseFloat(value));
                                }}
                              />
                            );
                          },
                        },
                        {
                          field: 'id3',
                          headerName: ' ',
                          sortable: false,
                          flex: 0.05,
                          renderCell: cellValues => {
                            return (
                              <Button
                                colorScheme="blue"
                                variant="ghost"
                                onClick={event => {
                                  handleValider(event, cellValues);
                                }}
                              >
                                <i className="fal fa-plus"> </i>
                              </Button>
                            );
                          },
                        },
                      ]}
                    />{' '}
                  </div>
                )}
              </div>
              {/* <LinearGaugeAnimation /> */}
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  setSwitchAjout(!switchAjout);
                }}
              >
                {!switchAjout ? 'Ajouter Palier' : 'Retour'}
              </Button>
              <Button
                colorScheme="teal"
                variant="outline"
                mr={3}
                onClick={() => {
                  setSwitchAjout(false);
                  props.onClose();
                }}
              >
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
      <div>
        {/*  MODALS */}
        <EchecAjoutPalierModalError
          echecOnClose={echecOnClose}
          echecIsOpen={echecIsOpen}
          dureeMaxNouveauPalier={dureeMaxNouveauPalier - 1}
        />
        <SucceAjoutPalierModal succeOnClose={succeOnClose} succeIsOpen={succeIsOpen} />
        <GeneriqueModalError
          generiqueErrorOnClose={generiqueErrorOnClose1}
          generiqueErrorIsOpen={generiqueErrorIsOpen1}
          errorMsg={'Impossible de modifier le palier principal'}
        />
        <GeneriqueModalError
          generiqueErrorOnClose={generiqueErrorOnClose2}
          generiqueErrorIsOpen={generiqueErrorIsOpen2}
          errorMsg={'Impossible de supprimer le palier principal'}
        />
        <GeneriqueModalError
          generiqueErrorOnClose={generiqueErrorOnClose3}
          generiqueErrorIsOpen={generiqueErrorIsOpen3}
          errorMsg={`Le taux du credit doit etre compris entre ${tauxMin} % et ${tauxMax} %`}
        />
        {/*  MODALS */}
      </div>
    </div>
  );
};
