import React from 'react';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartBarIcon, MenuIcon, UserCircleIcon, CreditCardIcon } from '@heroicons/react/outline';

const styles = {
  smallArrowColor: {
    color: '#122f96',
  },
} as const;

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav caret className="d-flex align-items-center" style={styles.smallArrowColor}>
      {/* <FontAwesomeIcon icon={props.icon} /> */}
      <UserCircleIcon className="w-5 text-blue-800" />
      <span className="text-blue-800">{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);

export const MonitoringDropdow = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav caret className="d-flex align-items-center">
      {/* <FontAwesomeIcon icon={props.icon} /> */}
      <ChartBarIcon className="md:w-3 xl:w-5" />

      <span className="md:font-thin	 xl:font-normal	">{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);

export const ListeDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav caret className="d-flex align-items-center">
      {/* <FontAwesomeIcon icon={props.icon} /> */}
      <MenuIcon className="md:w-3 xl:w-5" />
      <span className="md:font-thin	 xl:font-normal	">{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
