import React, { useEffect, useState } from 'react';
import {
  Button,
  ModalBody,
  ModalCloseButton,
  HStack,
  Spinner,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { CheckIcon, PhotographIcon, DocumentIcon, TrashIcon, PlusIcon, PencilIcon } from '@heroicons/react/outline';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Viewer from 'react-viewer';

import { FuncUpload } from 'app/modules/PjFilesManagement/funcUpload';
import { FuncUpdate } from 'app/modules/PjFilesManagement/funcUpdate';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, getListePj } from 'app/entities/pieceJustificative/pj.reducer';
import PDFViewer from 'pdf-viewer-reactjs';

const listePjs = ['Bulletin de Paie', 'Attestation de Salaire'];

export const PJAccordion = ({ resultat, title, idDemande, canAdd }) => {
  const dispatch = useAppDispatch();

  const pjEntities = useAppSelector(state => state.pj.entities);
  const pjEntity = useAppSelector(state => state.pj.entity);
  const pj = useAppSelector(state => state.pj);

  const demande2 = useAppSelector(state => state.demande2.entity);
  const [listePj, setListePj] = useState(['xxx', 'yyyyyy']);
  const [closeAddPj, setCloseAddPj] = useState(false);
  const [closeEditPj, setCloseEditPj] = useState(false);

  const [visible, setVisible] = useState(false);
  const [fileViewed, setFileViewed] = useState('');

  const concatUnique = (champ: string) => {
    listePj.indexOf(champ) === -1 ? setListePj(listePj.concat(champ)) : null;
  };

  const buildPjListe = (produits, clientId) => {
    dispatch(getListePj(null));
    setListePj(['xxx', 'yyyyyy']);

    const x = pj.entity?.listePj?.forEach(object => {
      setListePj(listePj.concat(object.nomPiece));
    });
  };

  useEffect(() => {
    if (idDemande !== undefined) dispatch(getEntity(parseInt(idDemande, 10)));
  }, []);

  useEffect(() => {
    pjEntities.forEach((element, index) => {
      if (listePj.indexOf(element.typePiece) >= 0) {
        listePj.splice(listePj.indexOf(element.typePiece), 1);
        setListePj(listePj);
      }
    });
  });

  useEffect(() => {
    if (closeAddPj) {
      addPjOnClose();
      setCloseAddPj(false);
    }
    if (closeEditPj) {
      editPjOnClose();
      setCloseEditPj(false);
    }
  }, [closeAddPj, closeEditPj]);

  useEffect(() => {
    if (closeEditPj) {
      addPjOnClose();
      setCloseEditPj(false);
    }
  }, [closeEditPj]);

  useEffect(() => {
    if (demande2.loanInputSimulation) buildPjListe(demande2.loanInputSimulation?.propositions, demande2.loanInputSimulation?.clientId);
  }, [demande2]);

  const PJList = [
    {
      id: 1,
      emplacement: 'https://www.demarches.ma/wp-content/uploads/sites/12/2020/06/CIN_maroc-1024x670.jpg',
      type: 'IMAGE',
      libele: 'Carte CIN',
      statut: 'PasRecu',
      modifie: '2021-08-01',
      modifiePar: 'Utilisateur 2',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 2,
      emplacement: 'https://www.pedagogie.ac-aix-marseille.fr/upload/docs/application/pdf/2012-06/bulletin_de_salaire.pdf',
      type: 'PDF',
      libele: 'Bulletin Paie',
      statut: 'PasRecu',
      modifie: '2021-08-02',
      modifiePar: 'Utilisateur 1',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 4,
      emplacement: 'https://www.demarches.ma/wp-content/uploads/sites/12/2020/06/CIN_maroc-1024x670.jpg',
      type: 'IMAGE',
      libele: 'Attestation de salaire',
      statut: 'PasRecu',
      modifie: '2021-08-02',
      modifiePar: 'Utilisateur 1',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
  ];

  const com1 =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus fermentum ultrices ultrices. Aenean quis vestibulum lectus, sed vehicula eros. Quisque dapibus sit amet nisl nec placerat. Cras ut dolor elit. Nam a nisl et tellus pulvinar blandit. Donec mi diam.';

  const [validated, setValidated] = useState(false);
  const [prdtArray, setPrdtArray] = useState(PJList);

  const { isOpen: validateIsOpen, onOpen: validateOnOpen, onClose: validateOnClose } = useDisclosure();

  const [deletedIndex, setDeletedIndex] = useState(0);
  const [validatedIndex, setValidatedIndex] = useState(0); // workaround LightBox int

  const [editedPjType, setEditedPjType] = useState('');

  const { isOpen: editPjIsOpen, onOpen: editPjOnOpen, onClose: editPjOnClose } = useDisclosure();
  const { isOpen: addPjIsOpen, onOpen: addPjOnOpen, onClose: addPjOnClose } = useDisclosure();
  const { isOpen: pdfIsOpen, onOpen: pdfOnOpen, onClose: pdfOnClose } = useDisclosure();
  const [viewedPdf, setViewedPdf] = useState('');

  useEffect(() => {
    dispatch(getListePj(null));
  }, []);

  return (
    <div>
      <Accordion className="mx-6">
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="">
            {' '}
            <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-100 border-t border-gray-00 ">
          <Typography className="w-100 ">
            <div className="flex items-center justify-between flex-wrap">
              <div className="invisible w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg ">
                  <i className="fal fa-2x fa-money-check-edit-alt text-grey-800"> </i>
                </span>
                <p className="ml-3 font-medium text-grey-800 text-xl">
                  <span className="hidden md:inline"></span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                {canAdd && <Button className="mb-4" colorScheme="teal" variant="outline" onClick={undefined}>
                  <IconButton
                    color="primary"
                    className="mx-2"
                    onClick={() => {
                      addPjOnOpen();
                    }}
                  >
                    <span className="text-sm mr-4">Ajouter une Piece </span> <PlusIcon className="h-6 w-6" aria-hidden="false" />
                  </IconButton>{' '}
                </Button>}
              </div>
            </div>
            {pjEntities.length > 0 ? (
              <TableContainer component={Paper}>
                {!pj.loading ? (
                  <Table>
                    <TableHead className="bg-gray-200">
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Document</TableCell>
                        <TableCell align="right">Statut</TableCell>
                        <TableCell align="right">Modifiée le</TableCell>
                        <TableCell align="right">Modifiée par</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {pjEntities.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.typeDocument === 'IMAGE' ? (
                              <div>
                                <IconButton
                                  color="primary"
                                  className="mx-2"
                                  onClick={() => {
                                    setVisible(true);
                                    setFileViewed(row.libelle);
                                  }}
                                >
                                  <PhotographIcon className="h-6 w-6" aria-hidden="true" />
                                </IconButton>
                              </div>
                            ) : (
                              <IconButton
                                color="primary"
                                className="mx-2"
                                onClick={() => {
                                  setViewedPdf(row.libelle);
                                  pdfOnOpen();
                                }}
                              >
                                <DocumentIcon className="h-6 w-6" aria-hidden="true" />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <div className="text-sm font-medium text-gray-900">{row.typePiece}</div>
                          </TableCell>
                          <TableCell align="right">
                            {validated || row.statut === 'Accepte' ? (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Approuvée
                              </span>
                            ) : (
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                Non Approuvée
                              </span>
                            )}
                          </TableCell>
                          <TableCell align="right">{row.auteur}</TableCell>
                          <TableCell align="right">{row.dateModification}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              color="primary"
                              className="mx-2"
                              onClick={() => {
                                editPjOnOpen();
                                validateOnOpen();
                                setValidatedIndex(45);
                              }}
                            >
                              <CheckIcon className="h-6 w-6" aria-hidden="false" />
                            </IconButton>

                            <IconButton
                              color="primary"
                              className="mx-2"
                              onClick={() => {
                                dispatch(deleteEntity({ fileId: row.id, demandeId: idDemande }))
                                  .unwrap()
                                  .then(() => concatUnique(row.typePiece));
                              }}
                            >
                              <TrashIcon className="h-6 w-6" aria-hidden="true" />
                            </IconButton>

                            <IconButton
                              color="primary"
                              className="mx-2"
                              onClick={() => {
                                setEditedPjType(row.typePiece);
                                editPjOnOpen();
                              }}
                            >
                              <PencilIcon className="h-6 w-6" aria-hidden="true" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <HStack className="" justify="center">
                    <Box>
                      <Spinner size="xl" color="teal.600" />
                    </Box>

                    <Box>
                      <Spinner size="xl" color="teal.600" />
                    </Box>

                    <Box>
                      <Spinner size="xl" color="teal.600" />
                    </Box>
                  </HStack>
                )}
              </TableContainer>
            ) : (
              <span className="ml-12 text-base"> Aucune piece n&apos;a été jointe à cette demande.</span>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Modal onClose={editPjOnClose} isOpen={editPjIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody className="d-flex flex-col justify-content-center">
            <div className="m-5">
              <div className={'d-flex mt-4 items-center'}>
                <FuncUpdate listePj={listePj} setListePj={setListePj} editedPjType={editedPjType} setCloseEditPj={setCloseEditPj} />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal onClose={addPjOnClose} isOpen={addPjIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody className="d-flex flex-col justify-content-center">
            <div className="m-5">
              <div className={'d-flex mt-4 items-center'}>
                <FuncUpload listePj={listePj} setListePj={setListePj} setCloseAddPj={setCloseAddPj} />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        images={[{ src: fileViewed, alt: 'aa' }]}
      />
      <Modal onClose={pdfOnClose} isOpen={pdfIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody className="d-flex flex-col justify-content-center">
            <PDFViewer
              navbarOnTop
              document={{
                url: viewedPdf,
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
