import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IDemandeGraphe } from 'app/shared/model/demandeGraphe.model';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalPages: 0,
  number: 0,
  isFiltered: false,
};

export const getEntitiesForGraph4 = createAsyncThunk('demandes/fetch_entities_for_graph4', async () => {
  const requestUrl = `api/graphe4Get`;
  const { data } = await axios.get(requestUrl);
  return data;
});

export const filterEntitiesForGraph4 = createAsyncThunk('demandes/fetch_entities_for_graph44', async (values: any) => {
  let params = `${values.filter}`;

  for (let i = 0; i < values.choix.length; i++) {
    params = `${params}&array=${values.choix[i].value}`;
  }

  const requestUrl = `api/graphe4Filter?${params}`;

  const { data } = await axios.get(requestUrl);
  return data;
});

export const demandeGraphSlice = createSlice({
  name: 'demande',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntitiesForGraph4.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.entities = onGeneratedRow(action.payload);
      })
      .addCase(filterEntitiesForGraph4.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.entities = onGeneratedRow(action.payload);
      });
  },
});

const monthNames = {
  Jan: 1,
  Fev: 2,
  Mars: 3,
  Avr: 4,
  Mai: 5,
  Juin: 6,
  Juil: 7,
  Aout: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

function onGeneratedRow(columnsResult) {
  let ordered = {};

  Object.keys(columnsResult)
    .sort((a, b) => {
      return monthNames[a] - monthNames[b];
    })
    .forEach(function (key) {
      ordered[key] = columnsResult[key];
    });

  var resul = [];
  var jsonData = {};
  for (const [key, value] of Object.entries(ordered)) {
    jsonData = {};
    var columnName = 'name';
    var columnValue = 'count';

    jsonData[columnName] = key;
    jsonData[columnValue] = value;
    resul.push(jsonData);
  }

  return resul;
}

export default demandeGraphSlice.reducer;
