import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { ChakraProvider, extendTheme, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/config/store';

const PalierRecap = props => {
  const theme = extendTheme({
    components: {
      Modal: {
        baseStyle: props => ({
          dialog: {
            maxWidth: ['60%', '60%', '60%'],
            minWidth: '60%',
          },
        }),
      },
    },
  });

  return (
    <div>
      {props.paliers && (
        <ChakraProvider theme={theme}>
          <Modal closeOnOverlayClick={false} onClose={props.onClose} isOpen={props.isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Detail des paliers </ModalHeader>
              <ModalCloseButton />
              <ModalBody className="d-flex flex-col justify-content-center">
                <div style={{ height: 300, width: '100%' }} className="">
                  <DataGrid
                    hideFooterSelectedRowCount
                    rows={props.paliers}
                    pageSize={10}
                    columns={[
                      {
                        field: 'typeFranchise',
                        headerName: 'Type',
                        flex: 0.3,
                        renderCell: cellValues => {
                          if (cellValues.row.typePalier === 1) return 'Principal';
                          else if (cellValues.row.typePalier === 3) return 'Generique';
                          else {
                            if (cellValues.row.typePalier === 'T') {
                              return 'Franchise Total';
                            } else {
                              return 'Franchise Partielle';
                            }
                          }
                        },
                      },
                      {
                        field: 'duree',
                        headerName: 'Durée',
                        renderCell: cellValues => {
                          return cellValues.value + ' Mois';
                        },
                        flex: 0.3,
                      },
                      {
                        field: 'taux',
                        headerName: 'Taux',
                        renderCell: cellValues => {
                          return cellValues.value + ' %';
                        },
                        flex: 0.3,
                      },
                    ]}
                  />
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
        </ChakraProvider>
      )}
    </div>
  );
};

export default PalierRecap;
