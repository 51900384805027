import React, { ChangeEventHandler } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { Textarea } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@chakra-ui/react';
import moment from 'moment';

interface props {
  titre: string;
  value?: string;
  isdisabled?: boolean;
  isdone?: boolean;
  comment?: string;
  handleChange?: ChangeEventHandler<HTMLTextAreaElement>;
  status?: number;
  handlevalid?: React.MouseEventHandler<HTMLButtonElement>;
  handleModif?: React.MouseEventHandler<HTMLButtonElement>;
  handleRefus?: React.MouseEventHandler<HTMLButtonElement>;
  date?: Date;
}

export const getStatus = (status: number, date?: Date) => {
  if (status === 0) {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        Rejetée {date !== undefined && moment(date).locale('fr').fromNow()}
      </span>
    );
  } else if (status === 1) {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        Validée {date !== undefined && moment(date).locale('fr').fromNow()}
      </span>
    );
  } else if (status === 3) {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
      >
        A Modifié {date !== undefined && moment(date).locale('fr').fromNow()}
      </span>
    );
  } else if (status === 5) {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
      >
        En attente {date !== undefined && moment(date).locale('fr').fromNow()}
      </span>
    );
  } else if (status === 6) {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        annulée {date !== undefined && moment(date).locale('fr').fromNow()}
      </span>
    );
  } else {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
      >
        a decider {date !== undefined && moment(date).locale('fr').fromNow()}
      </span>
    );
  }
};

const DecisionMaking = ({
  titre,
  value,
  handleChange,
  isdisabled,
  isdone,
  comment,
  status,
  handlevalid,
  handleModif,
  handleRefus,
  date,
}: props) => {
  return (
    <>
      <Accordion disabled={isdisabled} className={'mx-5'}>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} style={{ textAlign: 'right' }}>
          <Typography className="">{titre} /</Typography>

          {getStatus(status, date)}
          {/* 
                {
                date!== undefined && 
                <Typography align="right">{moment(date).locale("fr").fromNow()}</Typography>
                } */}
        </AccordionSummary>
        <AccordionDetails className="w-100  border border-gray-200">
          <Typography className="w-100">
            comment
            <br />
            {isdone ? (
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                <Textarea value={comment} placeholder="commentez votre décision" size="sm" />
                <br />
              </div>
            ) : (
              <>
                <Textarea defaultValue={value} onChange={handleChange} placeholder="commentez votre décision" size="sm" />
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                  <Button className="mx-4" colorScheme="red" onClick={handleRefus}>
                    Rejeter
                  </Button>
                  <Button className="mx-4" colorScheme="orange" onClick={handleModif}>
                    A modifier
                  </Button>
                  <Button className="mx-4" colorScheme="teal" onClick={handlevalid}>
                    valider
                  </Button>
                </div>
              </>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DecisionMaking;
