import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Select, Button, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import TextField from '@material-ui/core/TextField';
import ModalBody from 'reactstrap/es/ModalBody';
import { DataGrid } from '@material-ui/data-grid';

import NumberFormat from 'react-number-format';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { TypeTaux } from 'app/shared/model/enumerations/type-taux.model';
import { TypeAssurance } from 'app/shared/model/enumerations/type-assurance.model';
import { NatureFDossier } from 'app/shared/model/enumerations/nature-frais-dossier.model';
import { IPalier } from 'app/shared/model/palier.model';
import { TypeFranchise } from 'app/shared/model/enumerations/type-franchise.model';
import { PalierModal } from './modals/PalierModal';
import { PropositionModal } from './modals/PropositionModal';
import { propositionConcat, propositionDelete } from '../Reducers/syntheseCreditSlice';
import GeneriqueModalError from './modals/GeneriqueModalError';

export default function PropositionStepper(props) {
  const dispatch = useAppDispatch();

  const produits = useAppSelector(state => state.syntheseCredit.propositions);

  useEffect(() => {
    if (produits != undefined) produits.length > 0 ? props.setPrdtArray(produits) : null;
  }, []);

  const rng = () => {
    return Math.floor(Math.random() * 1000);
  };

  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const [maxVal, setMaxVal] = useState(simulationCredit.mntCrdt);

  const [mntProduit, setMntProduit] = useState(null);
  const [produit, setProduit] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [indexL, setIndexL] = useState(0);
  const [proposition, setProposition] = useState(null);
  const { isOpen: propositionIsOpen, onOpen: propositionOnOpen, onClose: propositionOnClose } = useDisclosure();
  const { isOpen: palierIsOpen, onOpen: palierOnOpen, onClose: palierOnClose } = useDisclosure();

  const { isOpen: supprimerIsOpen, onOpen: supprimerOnOpen, onClose: supprimerOnClose } = useDisclosure();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );

  const [produitSysen, setProduitSysen] = useState([
    {
      id: 0,
      nomProduit: 'Imtilak Subventionné',
      montant: 10000,
      taux: 3,
      typeTaux: TypeTaux.FIXE,
      dureeMois: 120,
      dureeFranchise: 0,
      typeFranchise: TypeFranchise.TOTAL,
      convention: 'DAMANE SAKANE',
      isFranchise: false,
      isConvention: false,

      typeAssurance: TypeAssurance.MONTANT,
      valueAssurance: 600,
      natureFDossier: NatureFDossier.PERCEVOIR,
      fraisDossier: 200,
      modePerception: 'Mensuel',
    },
    {
      id: 1,
      nomProduit: 'Imtilak complémentaire',
      montant: 20000,
      taux: 5,
      typeTaux: TypeTaux.VARIABLE,
      dureeMois: 120,
      dureeFranchise: 0,
      typeFranchise: TypeFranchise.TOTAL,
      convention: 'DAMANE EXPRESS',
      isFranchise: false,
      isConvention: false,

      typeAssurance: TypeAssurance.TAUX,
      valueAssurance: 0.1,
      natureFDossier: NatureFDossier.PERCEVOIR,
      fraisDossier: 200.57,
      modePerception: 'Mensuel',
    },
  ]);

  const format = (x: number, suffix: string) => {
    return new Intl.NumberFormat('MA', {
      style: 'currency',
      currency: suffix,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(x);
  };

  const clean = (v: string) => {
    if (typeof v === 'string') {
      v = v.replace(/\s/g, '');
      v = v.replace('dhs', '');
    }
    return v;
  };

  const withValueCap = inputObj => {
    const { value } = inputObj;
    if (value <= maxVal) return true;
    return false;
  };

  useEffect(() => {
    let sum = 0;
    simulationCredit.propositions?.forEach(a => (sum += a.montant));
    setMaxVal(simulationCredit.mntCrdt - sum);
  }, [simulationCredit.propositions]);

  const onDelete = (index: number) => {
    props.setPrdtArray(state => {
      const list = props.prdtArray.filter(item => item.id !== index);

      return {
        list,
      };
    });
  };

  const ajouterProduit = (prod: string) => {
    setMaxVal(maxVal - mntProduit);
    const paliers: IPalier[] = [
      {
        id: rng(),
        typePalier: 1,
        typeFranchise: TypeFranchise.P_PRINCIPAL,
        duree: 120,
        taux: 3,
      },
    ];

    switch (prod) {
      case 'Imtilak Subventionné': {
        dispatch(
          propositionConcat({
            ...produitSysen[0],
            montant: mntProduit,
            id: rng(),
            paliers,
          })
        );

        break;
      }
      case 'Imtilak complémentaire': {
        dispatch(
          propositionConcat({
            ...produitSysen[1],
            montant: mntProduit,
            id: rng(),
            paliers,
          })
        );

        break;
      }
      default: {
        break;
      }
    }
    setMntProduit(null);
    let x = 0;
  };

  let selectedIDs;
  let idds;
  const handleClick = (e, c) => {
    if (c.id === simulationCredit.propositions[0].id) {
      setIndexL(0);
      setProposition(simulationCredit.propositions[0]);
    } else if (c.id === simulationCredit.propositions[1].id) {
      setIndexL(1);
      setProposition(simulationCredit.propositions[1]);
    }

    propositionOnOpen();
  };
  const handlePalierClick = (e, c) => {
    if (c.id === simulationCredit.propositions[0].id) {
      setIndexL(0);
      setProposition(simulationCredit.propositions[0]);
    } else if (c.id === simulationCredit.propositions[1].id) {
      setIndexL(1);
      setProposition(simulationCredit.propositions[1]);
    }
    palierOnOpen();
  };

  const handleClickDel = (e, c) => {
    const deletedPropositionMontant = simulationCredit?.propositions?.find(obj => {
      return obj.id === c.id;
    }).montant;
    setMaxVal(maxVal + deletedPropositionMontant);
    dispatch(propositionDelete(c.id));
  };

  const rows = simulationCredit.propositions;
  return (
    <div className="mx-5">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="mx-16 flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg ">
              <i className="fal fa-2x fa-money-check-edit-alt text-grey-800"> </i>
            </span>
            <p className=" ml-3 font-medium text-grey-800 text-xl text-center">
              <span className="hidden md:inline">{format(simulationCredit.mntCrdt, 'Dhs')} à financer</span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <Button colorScheme="blue" variant="outline" onClick={rows.length < 2 ? onOpen : supprimerOnOpen}>
              Ajouter Produit
            </Button>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center justify-center">
            <div style={{ height: 400, width: '90%' }} className="">
              <DataGrid
                onSelectionModelChange={ids => {
                  idds = ids;
                  selectedIDs = new Set(ids);
                }}
                hideFooterSelectedRowCount
                rows={rows}
                pageSize={10}
                columns={[
                  {
                    field: 'nomProduit',
                    headerName: 'Produit',
                    flex: 0.15,
                    sortable: false,
                  },
                  {
                    field: 'montant',
                    headerName: 'Montant',
                    renderCell: cellValues => {
                      return new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'MAD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      }).format(cellValues.row.montant);
                    },
                    flex: 0.15,
                    sortable: false,
                  },
                  {
                    field: 'taux',
                    headerName: 'Taux',
                    renderCell: params => {
                      return params.value + ' %';
                    },
                    flex: 0.1,
                    sortable: false,
                  },
                  {
                    field: 'dureeMois',
                    headerName: 'Durée crédit',
                    renderCell: params => {
                      return params.value + ' Mois';
                    },
                    flex: 0.15,
                    sortable: false,
                  },
                  {
                    field: 'dureeFranchise',
                    headerName: 'Durée Franchise',
                    renderCell: params => {
                      return params.value + ' Mois';
                    },
                    flex: 0.15,
                    sortable: false,
                  },
                  {
                    field: 'convention',
                    headerName: 'Convention',
                    flex: 0.2,
                    sortable: false,
                  },
                  {
                    field: 'id3',
                    headerName: ' ',
                    sortable: false,
                    flex: 0.02,
                    renderCell: cellValues => {
                      return (
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={event => {
                            handleClick(event, cellValues);
                          }}
                        >
                          <i className="fal fa-pen"> </i>
                        </Button>
                      );
                    },
                  },
                  {
                    field: 'id2',
                    headerName: ' ',
                    sortable: false,
                    flex: 0.05,
                    renderCell: cellValues => {
                      return (
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={event => {
                            handlePalierClick(event, cellValues);
                          }}
                        >
                          <i className="fal fa-cogs"> </i>
                        </Button>
                      );
                    },
                  },
                  {
                    field: 'EE',
                    headerName: '  ',
                    sortable: false,
                    flex: 0.05,
                    renderCell: cellValues => {
                      return (
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={event => {
                            handleClickDel(event, cellValues);
                          }}
                        >
                          <i className="fal fa-trash"> </i>
                        </Button>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {/*Produit modal*/}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter Produit</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="d-flex flex-col justify-content-center">
            <div className="m-5">
              <Select
                placeholder="Choisir Produit"
                onChange={e => {
                  setProduit(e.target.value);
                }}
              >
                <option value="Imtilak Subventionné">Imtilak Subventionné</option>
                <option value="Imtilak complémentaire">Imtilak complémentaire</option>
              </Select>

              <div className="flex  justify-center mt-4">
                <NumberFormat
                  placeholder="Valeur en MAD"
                  customInput={TextField}
                  thousandSeparator=" "
                  suffix=" MAD"
                  isAllowed={withValueCap}
                  value={mntProduit}
                  onValueChange={values => {
                    const { formattedValue, value } = values;
                    setMntProduit(parseFloat(value));
                  }}
                />
              </div>
              <div className="flex  justify-center">
                <Button
                  className="mt-4"
                  colorScheme="blue"
                  onClick={() => {
                    onClose();
                    ajouterProduit(produit);
                  }}
                >
                  {' '}
                  Ajouter Produit
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <PalierModal
        setPrdtArray={props.setPrdtArray}
        prdtArray={simulationCredit.propositions}
        propositionId={indexL}
        palierArray={proposition?.paliers}
        onClose={palierOnClose}
        isOpen={palierIsOpen}
      />

      {simulationCredit.propositions.length > 0 ? (
        <PropositionModal
          index={indexL}
          mntCrdt={simulationCredit.mntCrdt}
          maxVal={props.maxVal}
          setMaxVal={setMaxVal}
          prdtArray={props.prdtArray}
          setPrdtArray={props.setPrdtArray}
          onClose={propositionOnClose}
          isOpen={propositionIsOpen}
          produit={proposition}
          setProduit={a => setProduit(a)}
          isCentered
          size={'xl'}
        />
      ) : null}

      <GeneriqueModalError
        generiqueErrorOnClose={supprimerOnClose}
        generiqueErrorIsOpen={supprimerIsOpen}
        errorMsg="Vous pouvez financer un crédit avec deux produits seulement"
        hint="*Supprimez un produit pour continuer"
      />
    </div>
  );
}
