import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, current } from '@reduxjs/toolkit';

import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IDemande } from 'app/shared/model/demande.model';
import { IDemandeSimulationCredit, defaultValue as defaultValue2 } from 'app/shared/model/demandeSimulationCredit.model';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue2,
  updating: false,
  updateSuccess: false,
  totalPages: 0,
  number: 0,
  isFiltered: false,
};

const apiUrl = 'api/files';
const apiUrl2 = 'api/fileDelete';

export const uploadEntity = createAsyncThunk(
  'pj/upload_entity',
  async (x: any) => {
    const formData = new FormData();

    formData.append('file', x.file);
    formData.append('name', x.name);
    formData.append('selectedTypePiece', x.selectedTypePiece);
    formData.append('type', x.type);
    formData.append('demandeId', x.demandeId);
    // const requestUrl = `${apiUrl}?demandeId=${id}`;
    return axios.post<any>('api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'pj/update_entity',
  async (x: any, thunkAPI) => {
    const formData = new FormData();

    formData.append('file', x.file);
    formData.append('name', x.name);
    formData.append('selectedTypePiece', x.selectedTypePiece);
    formData.append('type', x.type);
    formData.append('demandeId', x.demandeId);
    const result = axios.put<any>('api/update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    thunkAPI.dispatch(getEntity(x.demandeId));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'pj/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}?demandeId=${id}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getListePj = createAsyncThunk(
  'pj/fetch_list',
  async (object: any) => {
    const requestUrl = `api/pj/getPj?typeDemande.equals=CREDIT`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'pj/delete_entity',
  async (wrapperObject: any) => {
    const requestUrl = `${apiUrl2}?fileId=${wrapperObject.fileId}&demandeId=${wrapperObject.demandeId}`;
    return axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const pjSlice = createSlice({
  name: 'pjSlice',
  initialState,
  reducers: {
    setPjListe: (state, action) => {
      state[action.payload.champ] = action.payload.inputValue;
    },
    deleteFromPjList: (state, action) => {
      const filtered = current(state.entity.listePj).filter(pj => pj != action.payload.input);

      state.entity.listePj = filtered;

      state[action.payload.champ] = action.payload.inputValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entities = action.payload.data;
        state.loading = false;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = current(state.entities).filter(pj => pj.id != action.payload.data.message);
      })
      .addCase(uploadEntity.fulfilled, (state, action) => {
        state.loading = true;

        state.entity['message'] = action.payload.data.message;
        state.loading = false;
      })
      .addCase(uploadEntity.rejected, (state, action) => {
        state.entity['message'] = 'Type de Fichier non supporté';

        state.loading = false;
      })
      .addCase(getListePj.fulfilled, (state, action) => {
        const liste = action.payload.data;
        const listepk = liste.map(object => {
          return object.nomPiece;
        });
        state.entity['listePj'] = listepk;

        state.loading = false;
      });
  },
});

export const { setPjListe, deleteFromPjList } = pjSlice.actions;

export default pjSlice.reducer;
