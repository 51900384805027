import React, { useState, useEffect } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import ReadOnlyTile from '../CommonComponents/ReadOnlyTile';
import NumEditTile from '../CommonComponents/NumEditTile';
import StringEditTile from '../CommonComponents/StringEditTile';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setClient } from '../../Reducers/simulationCreditSlice';
import { setClientForSynthese } from '../../Reducers/syntheseCreditSlice';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PencilIcon, UserIcon, MenuIcon, PencilAltIcon, ChartBarIcon } from '@heroicons/react/outline';
import { identifyClient } from 'app/entities/client/client.reducer';

const format = (x: number, suffix: string) => {
  return new Intl.NumberFormat('MA', {
    style: 'currency',
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};

const FicheClient = props => {
  //edit Toggles
  const [editEmail, setEditEmail] = useState(false);
  const [editTel, setEditTel] = useState(false);
  const [editAdresse, setEditAdresse] = useState(false);
  const [editRevenu, setEditRevenu] = useState(false);
  const [editAutresEch, setEditAutresEch] = useState(false);
  const simulation = useAppSelector(state => state.simulationCredit);

  // const ficheClient = useAppSelector(state => state.ficheClient);
  const ficheClient = useAppSelector(state => state.client.entity);
  // let ficheClient = client;
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setClient({ id: ficheClient.id }));
    dispatch(setClientForSynthese({ id: ficheClient.id }));
    dispatch(identifyClient(1));
  }, []);

  return (
    <Accordion className={'mx-6'} defaultExpanded={props.expand}>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
        <UserIcon className="w-5" />{' '}
        <Typography className="">
          {' '}
          <div className="inline-block"> </div> Fiche Client
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="w-100">
        <Typography className="w-100">
          <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
            <div className="  border-t border-gray-200 px-4 py-5 sm:p-0 w-100">
              {/* <LinearGaugeAnimation /> */}
              <div className="grid grid-rows-5 grid-flow-col divide-x  divide-solid">

                <ReadOnlyTile label="Nom et prénom :" value={ficheClient.nom + ' ' + ficheClient.prenom} />

                <StringEditTile
                  label="N° de téléphone:"
                  value={ficheClient.telephoneNumber}
                  champ={'telephoneNumber'}
                  toggle={editTel}
                  setToggle={setEditTel}
                  isInputToggle={true}
                />

                <StringEditTile
                  label="Adresse de résidence:"
                  value={ficheClient.address}
                  champ={'address'}
                  toggle={editAdresse}
                  setToggle={setEditAdresse}
                  isInputToggle={true}
                />

                <NumEditTile
                  isInputToggle={true}
                  label="Revenus Mensuels:"
                  value={ficheClient.incomes}
                  champ={'incomes'}
                  toggle={editRevenu}
                  setToggle={setEditRevenu}
                />

                <NumEditTile
                  isInputToggle={true}
                  label="Échéances autres crédits:"
                  value={ficheClient.amountOthersRepayments}
                  champ={'amountOthersRepayments'}
                  toggle={editAutresEch}
                  setToggle={setEditAutresEch}
                />

                <StringEditTile
                  label="E-mail:"
                  value={ficheClient.email}
                  champ={'email'}
                  toggle={editEmail}
                  setToggle={setEditEmail}
                  isInputToggle={true}
                />

                <ReadOnlyTile label="Employeur :" value={ficheClient.employeur} />
                <ReadOnlyTile label="Activité :" value={ficheClient.activity} />
                <ReadOnlyTile label="Pays de naissance :" value={ficheClient.countryOfBirth} />
                <ReadOnlyTile label="Pays de résidence :" value={ficheClient.countryOfResidence} />
                <ReadOnlyTile label="Date de naissance :" value={ficheClient.dateOfBirthday?.split('-').reverse().join('/')} />
                <ReadOnlyTile label="Age :" value={ficheClient.age} />
                <ReadOnlyTile label="Échéances crédits :" value={format(ficheClient.amountRepayment, 'MAD')} />
              </div>
            </div>
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FicheClient;
