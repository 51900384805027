import './table.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Select, Stack, Button, IconButton, Radio, RadioGroup, useDisclosure } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import _ from 'lodash';
import { ArrowCircleRightIcon, RefreshIcon } from '@heroicons/react/outline';
import { advancedFilterEntitiesForSav } from '../../entities/demande/demandeSAV.reducer';
import { creditEventReset } from '../SavStepper/Reducers/CreditEvents.reducer';
import { TextField } from '@material-ui/core';
import { ArrowRightIcon } from '@chakra-ui/icons';
import { IFilterCreditAndSav } from 'app/shared/model/filterCredit.model';
import GeneriqueModalError from '../CreditStepper/PropositionStep/modals/GeneriqueModalError';
import { editFiltreCredit } from '../CreditStepper/Reducers/filtreCreditSlice';

interface ICategory {
  title: string;
  label: string;
  type: string;
}

const STATE_COLORS = [
  { statut: 'DEMANDE_CLOTURE', label: 'Cloturée', color: 'gray' },
  { statut: 'DEMANDE_ACCORDEE', label: 'Validée', color: 'green' },
  { statut: 'DEMANDE_REFUSE', label: 'Refusée', color: 'red' },
  { statut: '1', label: ' À décider', color: 'blue' },
  { statut: 'A_COMPLETER', label: 'À compléter', color: 'indigo' },
  { statut: 'EN_COURS_DE_SAISIE', label: 'En cours', color: 'yellow' },
];
const CATEGORIES = [
  { title: 'NomClient', label: 'Nom Client', type: 'text' },
  { title: 'CIN', label: 'CIN', type: 'text' },
  { title: 'NumCompte', label: 'Num de compte', type: 'number' },
];

const CODE_AGENCE = [
  { value: '111', label: '111' },
  { value: '222', label: '222' },
  { value: '333', label: '333' },
  { value: '444', label: '444' },
  { value: '555', label: '555' },
  { value: '777', label: '777' },
];

const PRODUITS = [
  { value: 'SubvCpl', label: 'SubvCpl' },
  { value: 'Subv', label: 'Subv' },
];

export const FilterComposant = props => {
  const dispatch = useAppDispatch();

  const initial: Partial<ICategory> = {};
  const savedStateFromPreviousFilter = useAppSelector(state => state.filtreCredit);

  const initialFilter: Partial<IFilterCreditAndSav> = savedStateFromPreviousFilter;

  const [demandesAfterFilter, setDemandesAfterFilter] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(savedStateFromPreviousFilter.selectedCategory);

  const events = useAppSelector(state => state.events.entities);
  const { entities: demandes, totalPages, isFiltered } = useAppSelector(state => state.demande);
  const [filterQuery, setFilterQuery] = useState(savedStateFromPreviousFilter.filterQuery);
  const [filterCredit, setFilterCredit] = useState(initialFilter);
  const { isOpen: generiqueErrorIsOpen1, onOpen: generiqueErrorOnOpen1, onClose: generiqueErrorOnClose1 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen2, onOpen: generiqueErrorOnOpen2, onClose: generiqueErrorOnClose2 } = useDisclosure();

  const inputElement = useRef('');

  const handleSubmit2 = e => {
    e.preventDefault();
    const { title } = selectedCategory;
    setFilterCredit({ ...filterCredit, [title]: filterQuery });
  };
  useEffect(() => {
    dispatch(creditEventReset());
  });

  const handleResetSelectedCatrgory = () => {
    setFilterQuery('');
    setSelectedCategory(initial);
  };

  const handleReset = () => {
    setFilterCredit(initialFilter);
    Array.from(document.querySelectorAll('input')).forEach(input => (input.value = ''));
    Array.from(document.querySelectorAll('select')).forEach(input => (input.value = ''));
    handleResetSelectedCatrgory();
  };

  const handleQueryChange = e => {
    setFilterQuery(e.currentTarget.value);
  };

  const handleCategorySelect = title => {
    const selected = CATEGORIES.find(category => category.title === title);
    setSelectedCategory(selected);
  };

  const focusInput = () => {
    const node = inputElement.current as any;
    node.focus();
  };

  const buildQueryString = (filtre: any) => {
    try {
      delete filtre.selectedCategory;
      delete filtre.filterQuery;
    } catch (error) {}

    var queryString = Object.keys(filtre)
      .map(key => key + '=' + filtre[key])
      .join('&');
    return queryString;
  };

  const filterDemandeByStatut = (status: string) => {
    if (filterCredit.status !== status) {
      setFilterCredit({ ...filterCredit, status });
    } else setFilterCredit({ ...filterCredit, status: '' });

    setDemandesAfterFilter(demandes.filter(o => o.statut === status));
  };
  return (
    <div>
      <div className="grid  sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 content-start  xl:divide-x-2 2xl:divide-x-2 ">
        <div className="grid grid-cols-1">
          {' '}
          <div className=" flex justify-start">
            <div className="">
              <dt className=" ml-8 mr-2.5 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Code Agence: </dt>
            </div>
            <div className="ml-16">
              <Select
                placeholder="Choisir un code ..."
                defaultValue={filterCredit.codeAgence}
                onChange={e => setFilterCredit({ ...filterCredit, codeAgence: e.currentTarget.value })}
              >
                {CODE_AGENCE.map(code => (
                  <option value={code.value}>{code.label}</option>
                ))}
              </Select>
            </div>
          </div>
          <div className=" flex justify-start">
            <div className="">
              <dt className=" ml-8 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Identification client:</dt>
            </div>
            <div className="ml-8">
              <form onSubmit={handleSubmit2}>
                {selectedCategory?.title ? (
                  <div className="w-full flex flex-between ">
                    <IconButton
                      onClick={handleResetSelectedCatrgory}
                      color="black"
                      border="none"
                      backgroundColor="transparent"
                      _hover={{ color: 'teal' }}
                      aria-label="reset search"
                      icon={<RefreshIcon className="w-3.5" />}
                    />
                    <input
                      required
                      className="w-full"
                      name="filterInput"
                      onChange={handleQueryChange}
                      value={filterQuery}
                      type={selectedCategory.type}
                      placeholder={selectedCategory ? `Chercher par ${selectedCategory?.label}` : 'Chercher ...'}
                    />

                    <IconButton
                      backgroundColor="transparent"
                      outline="none"
                      type="submit"
                      color="black"
                      border="none"
                      _hover={{ color: 'teal' }}
                      aria-label="execute search"
                      icon={<ArrowCircleRightIcon className="w-4" />}
                    />
                  </div>
                ) : (
                  <Select
                    required
                    placeholder="Chercher par ..."
                    name={selectedCategory.label}
                    value={selectedCategory?.title}
                    onChange={e => handleCategorySelect(e.currentTarget.value)}
                  >
                    {CATEGORIES.map(category => (
                      <option key={category.label} label={category.label} value={category.title} className="text-xs">
                        <span className="text-xs">extra</span>
                      </option>
                    ))}
                  </Select>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:border-l-2">
          <div className=" flex justify-start">
            <div className="mr-16">
              <dt className=" ml-8 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Type Credit:</dt>
            </div>
            <RadioGroup
              className="mt-2.5 ml-4"
              onChange={event => setFilterCredit({ ...filterCredit, typeCredit: event })}
              value={filterCredit.typeCredit}
            >
              <Stack direction="row">
                <Radio value="1" hidden></Radio>
                <Radio value="CONSOMMATION">Conso</Radio>
                <Radio value="IMMOBILIER">Immo</Radio>
              </Stack>
            </RadioGroup>
          </div>
          <div className=" flex justify-start my-2">
            <div className="">
              <dt className=" ml-8 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Produit:</dt>
            </div>
            <div className="ml-32">
              <Select
                placeholder="Choisir Produit"
                defaultValue={filterCredit.produit}
                onChange={e => setFilterCredit({ ...filterCredit, produit: e.currentTarget.value })}
              >
                <option value="Subv">Subv</option>
                <option value="SubvCpl">SubvCpl</option>
                {PRODUITS.map(produit => (
                  <option value={produit.value}>{produit.label}</option>
                ))}
              </Select>
            </div>
          </div>
          <div className=" flex justify-start">
            <div className="">
              <dt className=" ml-8 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Type d'Evenement:</dt>
            </div>
            <div className="ml-14">
              <Select
                placeholder="Choisir evenement"
                defaultValue={filterCredit.typeEvenement}
                onChange={e => setFilterCredit({ ...filterCredit, typeEvenement: e.currentTarget.value })}
              >
                <option value="REMBOURSEMENT_PARTIEL">RAP</option>
                <option value="REMBOURSEMENT_TOTAL">RAT</option>
                <option value="REPORT_ECHEANCE">Report Echeance</option>
              </Select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:border-t-2 xl:border-t-0">
          <div className="">
            <dt className=" ml-4 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Date: </dt>
          </div>
          <div className=" flex justify-between">
            <div className="">
              <dt className="ml-8 mr-4 block text-sm font-thin text-grey-500 sm:mt-px sm:pt-2">Du </dt>
            </div>
            <TextField
              className="w-60"
              id="dateEvent"
              type="date"
              value={filterCredit.dateMin}
              onChange={e => {
                if (e.target.value < filterCredit.dateMax || filterCredit.dateMax === undefined)
                  setFilterCredit({ ...filterCredit, dateMin: e.target.value });
                else {
                  setFilterCredit({ ...filterCredit, dateMin: e.target.value });
                  generiqueErrorOnOpen1();
                }
              }}
            />
            <div className="">
              <dt className="mx-8 block text-sm font-thin text-grey-500 sm:mt-px sm:pt-2">Au </dt>
            </div>
            <TextField
              className="w-60"
              id="dateEvent"
              type="date"
              value={filterCredit.dateMax}
              onChange={e => {
                if (e.target.value > filterCredit.dateMin || filterCredit.dateMin === undefined)
                  setFilterCredit({ ...filterCredit, dateMax: e.target.value });
                else {
                  setFilterCredit({ ...filterCredit, dateMax: e.target.value });
                  generiqueErrorOnOpen1();
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-start ">
        {STATE_COLORS.map(({ statut, label, color }, index) => {
          return (
            <div key={statut} className="p-2 bd-highlight">
              <span
                onClick={() => filterDemandeByStatut(statut)}
                className={`px-2 inline-flex text-xs leading-5 font-bold
                                  rounded bg-${color}-${filterCredit.status === statut ? 500 : 200}
                                   cursor-pointer 
                                  hover:bg-${color}-300`}
              >
                {label}
              </span>
              {index + 1 < STATE_COLORS.length && <ArrowRightIcon className="ml-4" h={6} color="blue" />}
            </div>
          );
        })}
      </div>
      <div className="flex justify-between">
        <div>
          <Button className="ml-8" colorScheme="blue" mr={3} mt={6} variant="outline" onClick={() => handleReset()}>
            Ré-initialiser Filtre
          </Button>
        </div>
        <div>
          <Button
            className="mr-8"
            colorScheme="blue"
            mr={3}
            mt={6}
            onClick={() => {
              if (filterCredit.dateMax !== null && filterCredit.dateMax !== null && filterCredit.dateMax <= filterCredit.dateMin) {
                generiqueErrorOnOpen2();
                return;
              }
              if (filterCredit.montantMax !== null && filterCredit.montantMin !== null) {
                if (filterCredit.montantMax < filterCredit.montantMin) generiqueErrorOnOpen2();
                else {
                  dispatch(editFiltreCredit({ filterCredit, selectedCategory, filterQuery }));
                  dispatch(advancedFilterEntitiesForSav(buildQueryString(filterCredit)));
                }
              } else {
                dispatch(editFiltreCredit({ filterCredit, selectedCategory, filterQuery }));
                dispatch(advancedFilterEntitiesForSav(buildQueryString(filterCredit)));
              }
            }}
            variant="solid"
          >
            Appliquer Filtre
          </Button>
        </div>
      </div>
      <div>
        <GeneriqueModalError
          generiqueErrorOnClose={generiqueErrorOnClose2}
          generiqueErrorIsOpen={generiqueErrorIsOpen2}
          errorMsg={'Le Montant Min doit être inférieur au Montant Max'}
        />
        <GeneriqueModalError
          generiqueErrorOnClose={generiqueErrorOnClose1}
          generiqueErrorIsOpen={generiqueErrorIsOpen1}
          errorMsg={'Le Date Min doit être inférieur a la Date Max'}
        />
      </div>
    </div>
  );
};
