import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

import { IDemandeSimulationCredit } from 'app/shared/model/demandeSimulationCredit.model';
import { ISimulationWithType } from 'app/shared/model/simulationWithType.model';

const initialState: IDemandeSimulationCredit = {
  agence: 'casablanca',
};

const apiUrl = 'api/clients';

export const postDemandeCredit = createAsyncThunk(
  'credit/postSimulationCredit',
  async (demandeCredit: IDemandeSimulationCredit) => {
    const requestUrl = `api/demandes`;
    return axios.post<IDemandeSimulationCredit>(requestUrl, demandeCredit);
  },
  { serializeError: serializeAxiosError }
);

export const simulationCreditSlice = createSlice({
  name: 'simulationCredit',
  initialState,
  reducers: {
    saveSynthese: (state, action) => {
      var simWithType = { typeSimulation: 'CREDIT', syntheseSimulation: action.payload.synthese, resultat: action.payload.resultat };
      var arraySim: ISimulationWithType[] = [];
      arraySim.push(simWithType);
      state['simulations'] = arraySim;
    },
    setClient: (state, action) => {
      state['client'] = { id: action.payload.id };
    },
    editFicheClient3: (state, action) => {
      state['client'] = { ...state.client, [action.payload.champ]: action.payload.inputValue };
    },
  },

  extraReducers(builder) {
    builder.addCase(postDemandeCredit.fulfilled, (state, action) => {
      return action.payload.data;
    });
  },
});

export const { saveSynthese, setClient, editFicheClient3 } = simulationCreditSlice.actions;

export default simulationCreditSlice.reducer;
