import React, { useCallback, useEffect, useState } from 'react';
import { Button, Box, Select } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { FingerPrintIcon, UserGroupIcon } from '@heroicons/react/outline';
import TextField from '@material-ui/core/TextField';
import { TypeId } from 'app/shared/model/enumerations/type-id.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { editFicheClient2, identifyClient, reset, resetEntity } from 'app/entities/client/client.reducer';

import { setClient } from 'app/modules/CreditStepper/Reducers/simulationCreditSlice';
import { propositionRest } from 'app/modules/CreditStepper/Reducers/syntheseCreditSlice';

const FindClient = ({ onStepperChange, isSimulationSavActivate, isProspect }) => {
  const [searchRequest, setSearchRequest] = useState({ chaineId: '', typeId: null });
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.client.loading);
  const client = useAppSelector(state => state.client.entity);
  const history = useHistory();
  useEffect(() => {
    if (client.id != null) {
      if (isSimulationSavActivate) {
        history.push('/SAV', { isSimulationSavActivate });
      } else {
        history.push('/step');
      }
    }
  }, [loading]);

  return (
    <>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-50">
        <FingerPrintIcon className="h-10 w-10 text-blue-800" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Select
          placeholder="Choisir Type identification"
          variant="flushed"
          textAlign="center"
          onChange={e => setSearchRequest({ ...searchRequest, typeId: e.target.value })}
        >
          {Object.keys(TypeId).map(key => {
            return (
              <option key={key} value={key}>
                {TypeId[key]}
              </option>
            );
          })}
        </Select>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <TextField
            required
            id="id-input"
            InputLabelProps={{ required: false }}
            placeholder={'N° Identifiant'}
            onChange={e => {
              setSearchRequest({
                ...searchRequest,
                chaineId: e.target.value,
              });
              dispatch(setClient({ id: e.target.value }));
            }}
          />
        </div>
      </div>
      <Box className="d-flex justify-center">
        {!isSimulationSavActivate && (
          <Button colorScheme="blue" mr={3} mt={6} variant="outline" onClick={() => onStepperChange(0)}>
            Retour
          </Button>
        )}

        <Button
          colorScheme="blue"
          mr={3}
          mt={6}
          onClick={() => {
            dispatch(propositionRest());
            dispatch(identifyClient(1));
          }}
        >
          Suivant
        </Button>
      </Box>
    </>
  );
};

export default FindClient;
