import React, { useEffect } from 'react';
import { Modal, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Select, ModalFooter, Button } from '@chakra-ui/react';
import ModalBody from 'reactstrap/es/ModalBody';
import { TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { ModeDeContinuationModel } from 'app/modules/SavStepper/models/enums/Report Echeance/mode-de-continuation.model';
import { ModeContinuationModel } from '../../models/enums/Remboursement Partiel/mode-continuation.model';
import { CodeBareme } from '../../models/enums/Report Echeance/codeBareme.model';
import FormattedInput3 from 'app/modules/commonComponents/FormatedInputs/FormattedInput3';
import NumberFormat from 'react-number-format';
import { OptionTraitement } from '../../models/enums/Reprofilage/optionTraitement.model';

const Reprofilage = ({
  isOpen,
  onClose,
  eventObject,
  onEventChange,
  setStepperPage,
  singleStateManagement,
  onSelectCredit,
  onSelectionReset,
  checkEmpty,
  updatedEvent,
  onUpdatedEventReset,
}) => {
  // this logic is repetitive & should refactored later on
  const handleSubmit = e => {
    e.preventDefault();
    singleStateManagement();
    onEventChange({});
    onSelectCredit([]);
    onSelectionReset([]);
    onUpdatedEventReset({});
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose(), onEventChange({});
        onSelectCredit([]);
        onSelectionReset([]);
        onUpdatedEventReset({});
      }}
      isCentered
      size={'xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Réintégration de la dette</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="sm:mt-5 space-y-2 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 border-t mr-4">
                <label htmlFor="montant_Frais" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Date de l'evenement
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <TextField
                    id="dateEvent"
                    type="date"
                    value={eventObject.dateDebutReport}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        dateDebutReport: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              {/* option traitement */}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="Mode_de_continuation" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Option de traitement
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    required
                    id="Mode_de_continuation"
                    placeholder="Choix Barème"
                    value={eventObject.codeBareme}
                    onChange={e => {
                      onEventChange({
                        ...eventObject,
                        optionTraitement: e.target.value,
                      });
                    }}
                  >
                    <option value={OptionTraitement.O}> {OptionTraitement.O}</option>
                    <option value={OptionTraitement.N}> {OptionTraitement.N}</option>
                    <option value={OptionTraitement.I}> {OptionTraitement.I}</option>
                  </Select>
                </div>
              </div>
            </div>

            <div className="pt-4 flex justify-center">
              {checkEmpty(updatedEvent) && (
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={() => {
                    setStepperPage('start');
                    onEventChange({});
                    onSelectCredit([]);
                    onSelectionReset([]);
                  }}
                >
                  Retour
                </Button>
              )}

              <Button type="submit" colorScheme="teal" marginLeft="4" variant="outline">
                Appliquer
              </Button>
            </div>
          </form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Reprofilage;
