import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import Prospect from './prospect';
import FindClient from './findCient';
import { Link, useHistory } from 'react-router-dom';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 180,
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//   })
// );

const INITIAL = 0;

export const IdentificationModalSAV = ({ isSimulationSavActivate, isOpen, onClose, onSimulationProspect, isProspect }) => {
  const [stepper, setStepper] = useState(INITIAL);
  // const classes = useStyles();
  // const [typeID, setTypeID] = useState('');
  //Stepper mghchouch

  useEffect(() => {
    if (isSimulationSavActivate) setStepper(prev => prev + 1);
    else setStepper(INITIAL);
  }, [isSimulationSavActivate]);

  const handleStepperChange = (wantedStepper: number) => {
    setStepper(wantedStepper);
  };

  const stepperLite = (stepper: number) => {
    switch (stepper) {
      case 0:
        return <Prospect onStepperChange={handleStepperChange} onSimulationProspect={onSimulationProspect} />;
      case 1:
        return (
          <FindClient isSimulationSavActivate={isSimulationSavActivate} onStepperChange={handleStepperChange} isProspect={isProspect} />
        );
      default:
        return <div> Unknown State</div>;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {' '}
          <Text fontWeight="extraBold" color="gray.700">
            Choisir un client
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{stepperLite(stepper)}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
