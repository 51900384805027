import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFilterCreditAndSav } from 'app/shared/model/filterCredit.model';

const initialState: IFilterCreditAndSav = {
  selectedCategory: {},
  filterQuery: '',
};

export const filtreCreditSlice = createSlice({
  name: 'filtreCredit',
  initialState,
  reducers: {
    editFiltreCredit: (state, action) => {
      return { ...action.payload.filterCredit, filterQuery: action.payload.filterQuery, selectedCategory: action.payload.selectedCategory };
    },
    getFiltreCredit: (state, action) => {
      return state;
    },
    resetFiltreCredit: state => {
      return initialState;
    },
  },
});

export const { getFiltreCredit, editFiltreCredit, resetFiltreCredit } = filtreCreditSlice.actions;

export default filtreCreditSlice.reducer;
