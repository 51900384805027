import React from 'react';
import { Switch } from 'react-router-dom';

const Routes = ({ match }) => (
  <>
    <Switch></Switch>
  </>
);

export default Routes;
