import React, { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Select,
  ModalFooter,
  ModalBody,
  Button,
  Stack,
  IconButton,
  Spinner,
  Text,
  Box,
} from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, ArrowRightIcon, CheckCircleIcon, SaveIcon, XIcon } from '@heroicons/react/outline';

import { saveEntity } from 'app/entities/demande/demande.reducer';
import { CreditsSlice } from 'app/entities/credit/list-credits.reducers';
import { getEntity } from 'app/entities/demande/demandeSAV.reducer';
import { getSimulationEntity as getSimulations } from 'app/entities/simulation/simulation.reducer';
import { BannerDecision } from 'app/modules/SavStepper/ResultatSAVStep/Banner/BannerResultat';

import _ from 'lodash';
import { ResultAccordionSAV } from 'app/modules/ListeDemandesSAV/detail/Accordions/SAVResultAccordion';
export const ResultStepper = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  const [isSaved, setIsSaved] = useState(false);
  // const savedDemandeId = useAppSelector(state => state.demande.entity)?.id;
  const resultats = useAppSelector(state => state.resultatSimCredit);
  // console.log('DEBUG 23', resultats);
  const simulations = useAppSelector(state => state.syntheseCredit);
  const creditEvents = useAppSelector(state => state.CreditEvents.entities);
  const [groupByNP, setGgroupByNP] = useState(_.groupBy(creditEvents, creditEvent => creditEvent.creditsSelectionnes[0].nomProduit)); // grouper les credit par nom Produit
  const produits = Object.keys(groupByNP);

  const [resultat, setResultat] = useState(null);
  const [synthese, setSynthese] = useState(null);
  //const demande = useAppSelector(state => state.demande.entity);

  // let simulations2 ;
  // let resultats2 ;
  const demandeId: { id: string } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getEntity(parseInt(demandeId.id)));
  }, []);

  const demande = useAppSelector(state => state.demande2.entity);
  const simulationIds = demande?.credits?.length > 0 ? demande.credits : [];

  useEffect(() => {
    if (demande?.credits?.length > 0) {
      console.log('log3', demande);
      console.log('log4', demande.loanInputSimulation);
      // if (simulationIds.length > 0) dispatch(getSimulations(simulationIds));
      setSynthese(demande.loanInputSimulation);
      setResultat(demande);
    }
  }, [simulationIds]);
  // const simulation = useAppSelector(state => state.simulationCredit);

  // useEffect(() => {
  //   if (demande.simulations) {
  //     console.log('debug 63', demande.simulations[0].resultat.credits.length);
  //     console.log('debug 632 ', demande.simulations[0].syntheseSimulation);
  //     //setResultat(demande.simulations[0].resultat);
  //     // setSynthese(demande.simulations[0].syntheseSimulation);
  //   }
  // }, [demande]);

  useEffect(() => {
    console.log('aa', resultat);
    console.log('bb', synthese);
  }, [resultat, synthese]);
  //console.log('render resultStepper(persist)', simulation);

  return (
    <div ref={ref}>
      <BannerDecision data={groupByNP} produits={produits} />
      <ResultAccordionSAV />
      {/* <ul>
  		{Object.keys(produits).map(function(keyName, keyIndex) {
    		return (
      			<li key={keyName}>
					{keyName} 
                    {console.log(produits[keyName])}
          		</li>
    		)
		})}
	</ul> */}
      {/* {resultat && synthese ? <BannerResultat simulations={synthese} resultat={resultat} /> : null}


      {resultat ? (
        <ResultAccordion2
          synthese={synthese}
          resultat={resultat}
          index={0}
         
        />
      ) : null}
      <br />

      {resultat?.credits?.length > 1 ? (
        <ResultAccordion2
          synthese={synthese}
          resultat={resultat}
          index={1}
        
        />
      ) : null}

      <br />
      <PJAccordion title={'Pièces Jusitificatives'} /> */}
    </div>
  );
});
