import { Box, ChakraProvider, Stack, StackDivider, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { BannerCol } from './BannerCol';
import InfoTile3 from 'app/modules/commonComponents/InfoTiles/InfoTile3';
import { faMoneyBill, faMoneyCheckAlt, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { extendTheme } from '@chakra-ui/react';

import { useWindowDimensions } from 'app/shared/customHook';
import moment from 'moment';

const format = (x: number, suffix: string) => {
  return new Intl.NumberFormat('MA', {
    style: 'currency',
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};

const breakpoints = {
  sm: '30em',
  md: '1100px',
  lg: '1400px',
  xl: '1500px',
};

const theme = extendTheme({ breakpoints });

const getCurrentDate = () => {
  const separator = '/';
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
};

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }

// export default function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return windowDimensions;
// }

export const BannerResultatSav = props => {
  const { savInputSimulationDTO } = props.demandeSAV;
  const {amountRepaymentAfterEvent} = props.demandeSAV;
  
  return (
    <Box as="section" p="2">
      <ChakraProvider theme={theme}>
        <Box maxW={{ sm: '2xl', md: '5xl', lg: '7xl', xl: '7xl' }} py={{}} mx="auto" px={{ base: '6', md: '8' }}>
          <Box py={{ sm: '2', md: '2', lg: '2', xl: '7' }} rounded="xl" shadow="base">
            <Stack
              spacing="0"
              justify="space-between"
              direction={{ sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              divider={<StackDivider />}
            >
              <BannerCol
                icon={<FontAwesomeIcon icon={faMoneyBill} color="#082482" />}
                children={
                  <ul>
                    <InfoTile3 label="Produit:" value={'DAMANE SAKANE'} />
                    <InfoTile3 label="Type d'événement:" value={"Report d'échéance"} />
                  </ul>
                }
              />
              <div className="md:pb-12 2xl:pb-1">
                <BannerCol
                  icon={<FontAwesomeIcon icon={faMoneyCheckAlt} color="#082482" />}
                  children={
                    <ul>
                      <InfoTile3 label="Date événement:" value={moment(savInputSimulationDTO?.eventInfo?.dateEvent).format('DD/MM/YYYY')} />
                      <InfoTile3 label="Nom et prénom:" value={savInputSimulationDTO?.nomClient + " " + savInputSimulationDTO?.prenomClient} />
                      <InfoTile3 label="N° Crédit Evolan: " value={savInputSimulationDTO?.creditsSelectionnes[0]?.loanAcountNumber} />
                    </ul>
                  }
                />
              </div>

              <BannerCol
                icon={<FontAwesomeIcon icon={faHandHoldingUsd} color="#082482" />}
                children={
                  <ul>
                    <InfoTile3 label="Nombre d'échéances reportées:" value={savInputSimulationDTO?.eventInfo.nbrEcheancesReport} />

                    <InfoTile3 label="Montant échéance aprés:" value={format(amountRepaymentAfterEvent, 'MAD')} />
                  </ul>
                }
              />
            </Stack>
          </Box>
        </Box>
      </ChakraProvider>
    </Box>
  );
};
