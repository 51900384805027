import React, { useState } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Button } from '@material-ui/core';

export const CreditInfo = props => {
  const [stepper, setStepper] = useState(0);
  switch (stepper) {
    case 0:
      return (
        <div>
          <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size={'xl'}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Information crédit</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0 w-100">
                    <dl className="sm:divide-y sm:divide-gray-200 w-100">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500">Nom produit</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Produit IMTILAK SUBV-M1 - IMTAF</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500">Montant du crédit</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">500 000 Dh</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500">Durée du crédit</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">60 mois</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500">Durée de la franchise </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">3 Mois</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500">Taux d’intérêt </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">3%</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500">Franchise </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">0 Dh</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500">Périodicité de l’échéance </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Mensuelle</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500"> Montant échéance TTC </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">69420 Dh</dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500"> Taux TVA </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">2%</dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-bold text-gray-500"> Montant échéance TTC </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">69420 Dh</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={props.onClose} color="secondary">
                  Fermer
                </Button>
                <Button variant="contained" className={'ml-2'} color="primary" onClick={() => setStepper(1)}>
                  Suivant
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      );
    case 1:
      return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size={'xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Coût total du crédit</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0 w-100">
                  <dl className="sm:divide-y sm:divide-gray-200 w-100">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">TEG </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> 4,35 %</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Montant des intérêts du prêt</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">150 000 MAD</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Frais dossier (HT)</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">1000 Dh</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Coût total des assurances </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">25 000 MAD</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Montant de la prime actuarielle </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> 9 000 MAD</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Intérêts dûs à la franchise </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">3 500 MAD</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setStepper(0)} color="secondary">
                Precedant
              </Button>
              <Button variant="contained" className={'ml-2'} color="primary" onClick={() => setStepper(2)}>
                Suivant
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      );
    case 2:
      return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size={'xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Informations sur les assurances</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0 w-100">
                  <dl className="sm:divide-y sm:divide-gray-200 w-100">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">TEG </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> 70 000 Dh</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Montant des intérêts du prêt</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">50 000 Dh</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Frais </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">1000 Dh</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Type capitalisation des accessoires </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">A percevoir</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Cout total des assurances </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> 690 Dh </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Montant de l’assurance </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">1 520 Dh</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-bold text-gray-500">Mode de perception </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Pérçue trimestriellement</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setStepper(1)} color="secondary">
                Precedant
              </Button>
              <Button variant="contained" className={'ml-2'} color="primary" onClick={props.onClose}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      );
    default:
      return null;
  }
};
