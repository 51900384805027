import React from 'react';
import SimulationCredit from './simulationCredit';
import SimulationSav from './simulationSav';

const SimulationBody = ({ onOpenSAV, onOpen, onSimulationSavActivate }) => {
  return (
    // <div className="mt-12 px-28 lg:px-48 2xl:px-72 xl:px-80">
    <div className="mt-12 ">
      {/* <SimulationCredit onOpen={onOpen} onSimulationSavActivate={onSimulationSavActivate} /> */}
      <SimulationSav onOpenSAV={onOpenSAV} onSimulationSavActivate={onSimulationSavActivate} />
    </div>
  );
};

export default SimulationBody;
