import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  color,
  Icon,
  Tooltip,
  Flex,
  Badge,
} from '@chakra-ui/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
  withStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { getStatus } from '../testing/decisionMaking';
import { getActiveEntity, getHistoryEntitiesByDemandeId } from './taches.reducer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/config/store';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface Props {
  idDemandeSav: string;
}

const HistAccordion = ({ idDemandeSav }: Props) => {
  const HistTache = useAppSelector(state => state.taches2.history);
  const activeTache = useAppSelector(state => state.taches2.entity);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveEntity(idDemandeSav)); // get active task
    dispatch(getHistoryEntitiesByDemandeId(idDemandeSav)); // get history of tasks
  }, []);

  const fetchHist = () => {
    return (
      <>
        {activeTache.name!== undefined && <Box ml="3" mr="0" borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor={'black'}>
          <Box p="4">
            <Box display="flex" alignItems="baseline">
              <Box fontWeight="bold" letterSpacing="wide" fontSize="sm" textTransform="uppercase" ml="2">
                {activeTache.name}
              </Box>
              <Badge borderRadius="full" px="2" marginRight={0} marginLeft={'auto'}>
                En Attente
              </Badge>
            </Box>
            <br />
            <Box alignItems="baseline">
              <Box fontWeight="semibold" fontSize="xs" ml="2">
                Statut : {activeTache.assignee === null ? 'Non Affectée' : 'Affecté pour traitement'}
              </Box>
              <Box fontWeight="semibold" fontSize="xs" ml="2">
                Affectée à : {activeTache.assignee === null ? '--------' : activeTache.assignee}
              </Box>
              <Box fontWeight="semibold" fontSize="xs" ml="2">
                Créée le : {moment(activeTache.startTime).format('DD/MM/YYYY à hh:mm:ss')}
              </Box>
            </Box>
          </Box>
        </Box>}
        <br />
        {HistTache.map((row, index) => (
          <>
            <Box ml="3" mr="0" borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor={'black'}>
              <Box p="4">
                <Box display="flex" alignItems="baseline">
                  <Box fontWeight="bold" letterSpacing="wide" fontSize="sm" textTransform="uppercase" ml="2">
                    {row.task.name}
                  </Box>
                  <Badge
                    borderRadius="full"
                    px="2"
                    marginRight={0}
                    marginLeft={'auto'}
                    colorScheme={
                      row.task.description === 'validée'
                        ? 'green'
                        : row.task.description === 'refusée'
                        ? 'red'
                        : row.task.description === 'a modifier'
                        ? 'yellow'
                        : 'gray'
                    }
                  >
                    {row.task.description}
                  </Badge>
                </Box>
                <br />
                <Box display="flex" alignItems="baseline">
                  <Box alignItems="baseline">
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Statut : Traité
                    </Box>
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Affectée à : {row.task.assignee}
                    </Box>
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Créée le : {moment(row.task.startTime).format('DD/MM/YYYY à hh:mm:ss')}
                    </Box>
                  </Box>
                  <Box alignItems="baseline" width={'50%'} margin={'0 auto'}>
                    <Box fontWeight="semibold" fontSize="xs" ml="2">
                      Commentaire : {row.comment}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <Accordion className={'mx-6'}>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <Typography className="">
          <i className="fal fa-file-invoice mx-2"> </i>
          Liste des Tâches
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="w-100 border border-gray-200" style={{ maxHeight: '400px' }}>
        <Box className="w-100 overflow-auto">{fetchHist()}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default HistAccordion;
