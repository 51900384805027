import { createAsyncThunk } from '@reduxjs/toolkit';
import { propositionRest } from 'app/modules/CreditStepper/Reducers/syntheseCreditSlice';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import { Storage } from 'react-jhipster';

const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');

export const startInstance = (idSimul: number | string) => {
  const requestUrl = `http://localhost:8090/camunda/startProcess/Process_report/${idSimul}`;

  axios.get(requestUrl);
};

export const startProcess = (idSimul: number | string) => {
  const requestUrl = `http://localhost:8090/camunda/process/Process_report_echeance/${idSimul}`;

  axios.post(requestUrl,
    {
      "score" : 100,
      "nb_impayes" : 1
    }
    );
};

export const conpleteTask = (nb: number, idSimul: number | string) => {
  axios.post('http://localhost:8090/camunda/completeNextTask/' + idSimul, [
    {
      varName: 'valider',
      value: nb,
    },
  ]);
};

export const creatEventInLS = async (demandeSAV: any) => {
  const creditSelectionne = demandeSAV.savInputSimulationDTO?.creditsSelectionnes[0];
  const modifCreditSelectionne = [];
  modifCreditSelectionne[0] = { ...creditSelectionne, loanAcountNumber: '2343435353' };
  const modifSimulationDemande = { 
    id : demandeSAV.id,
    eventInfo :demandeSAV.savInputSimulationDTO.eventInfo, 
    creditsSelectionnes: modifCreditSelectionne 
  };
  console.log('modifSimulationDemande12', modifSimulationDemande);
  const { data } = await axios.post('api/resultats/create/eventSav', modifSimulationDemande);
  console.log('Création d événement sur LS ........');
  console.log(data);
  console.log('Evénement créé avec succès');
  return data;
};


// export const updateSavStatus = async (idSimul:string, status:string) => {
//     await axios.patch(
//         'http://localhost:9001/api/savDemandes/' + idSimul,
//         status,
//         {
//             headers: {
//                 'Authorization': 'Bearer ' + token,
//                 "Content-Type" : "application/json"
//             }
//         }
//     ).then((value)=>{
//         // console.log("Patch request has been made.");
//     })
//     .catch(err=>{
//         // console.log(err.response.data);
//     });
// }
