import { Select, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateEntity } from 'app/entities/pieceJustificative/pj.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from 'app/entities/pieceJustificative/pj.reducer';

export const FuncUpdate = props => {
  const dispatch = useAppDispatch();

  const demandeId: { id: string } = useParams();

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [fileInfos, setFileInfos] = useState([]);
  const [selectedTypePiece, setSelectedTypePiece] = useState('');

  useEffect(() => {
    setSelectedTypePiece(props.editedPjType);
  }, []);

  const selectFile = event => {
    setSelectedFiles(event.target.files);
  };

  const upload = () => {
    let currentFile = selectedFiles[0];
    setProgress(0);
    setCurrentFile(currentFile);
    dispatch(
      updateEntity({
        file: currentFile,
        name: currentFile.name + Math.floor(Math.random() * 1000),
        selectedTypePiece: selectedTypePiece,
        type: 'Credit',
        demandeId: demandeId.id,
        onUploadProgress: event => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        },
      })
    )
      .unwrap()
      .then(() => dispatch(getEntity(parseInt(demandeId.id))).unwrap());

    setSelectedFiles(true);
    props.setCloseEditPj(true);
  };

  return (
    <div className="d-flex flex-col justify-content-center">
      {currentFile && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress }}
          >
            {progress}%
          </div>
        </div>
      )}
      <label className="btn btn-default">
        <input type="file" onChange={selectFile} />
      </label>

      <Select
        variant="flushed"
        textAlign="center"
        onChange={event => {
          setSelectedTypePiece(event.target.value);
        }}
        placeholder={props.editedPjType}
        value={props.editedPjType}
        disabled
      >
        <option value="Carte d'identite Nationale">Carte d'Identite Nationale</option>
        <option value="Bulletin de Paie">Bulletin de Paie</option>
        <option value="Attestation de salaire">Attestation de salaire</option>
      </Select>

      <Button className="mr-8" colorScheme="teal" mr={3} mt={6} onClick={upload} disabled={!selectedFiles}>
        Upload{' '}
      </Button>
      <div className="alert alert-light" role="alert"></div>
    </div>
  );
};
