import React, { useEffect, useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Select, ModalFooter } from '@chakra-ui/react';
import ModalBody from 'reactstrap/es/ModalBody';
import { TextField } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { creditEventConcat, creditEventUpdate, getResult } from 'app/modules/SavStepper/Reducers/CreditEvents.reducer';
import RembousementPartiel from './eventsForms/RembousementPartiel';
import RemboursementTotal from './eventsForms/RemboursementTotal';
import ReportEcheance from './eventsForms/ReportEcheance';
import Reprofilage from './eventsForms/Reprofilage';

const INITIAL_STEPPER_PAGE = 'start';
export const REMBOURSEMENT_TOTAL = 'REMBOURSEMENT_TOTAL';
export const REMBOURSEMENT_PARTIEL = 'REMBOURSEMENT_PARTIEL';
export const REPORT_ECHEANCE = 'REPORT_ECH';
export const REPROFILAGE = 'REPROFILAGE';
const find = '-';
const re = new RegExp(find, 'g');

export const CreditEventsForm = props => {
  console.log('updatedEventE', props.updatedEvent);
  const [stepperPage, setStepperPage] = useState(INITIAL_STEPPER_PAGE);
  const [cpcIndex, setCpcIndex] = useState(0); //manually increment credit array's index
  console.log('stepperPageupdatedEventE', stepperPage);

  const dispatch = useAppDispatch();
  let eventsRedux = useAppSelector(state => state.events.entities);
  const [events, setEvents] = useState(eventsRedux);
  let appliedEvents = useAppSelector(state => state.CreditEvents.entities);
  const applyEventRoleMangement = () => {
    for (let appliedEvent of appliedEvents) {
      for (let credit of props.selectedCreditsArray) {
        if (credit?.id === appliedEvent.creditsSelectionnes.id) {
          setEvents(prevEvents => prevEvents.filter(event => event.title !== appliedEvent.nomEvent));
          if (appliedEvent.nomEvent === REMBOURSEMENT_PARTIEL) {
            setEvents(prevEvents => prevEvents.filter(event => event.title !== REMBOURSEMENT_TOTAL));
          } else if (appliedEvent.nomEvent === REMBOURSEMENT_TOTAL) {
            setEvents(prevEvents => prevEvents.filter(event => event.title !== REMBOURSEMENT_PARTIEL));
          }
        }
      }
    }
  };

  useEffect(() => {
    setEvents(eventsRedux);
    applyEventRoleMangement();
  }, [appliedEvents, props.selectedCreditsArray]);

  useEffect(() => {
    console.log('hit');
    setStepperPage(props.updatedEvent.nomEvent);
  }, [props.updatedEvent]);
  // useEffect(() => {
  //   setStepperPage(INITIAL_STEPPER_PAGE);
  // }, [props.selectedCreditsArray]);

  const checkEmpty = (obj: Object) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const singleStateManagement = () => {
    if (!checkEmpty(props.updatedEvent)) {
      dispatch(
        creditEventUpdate({
          ...props.updatedEvent,
          eventInfo: props.eventObject,
        })
      );
    } else {
      props.selectedCreditsArray.map(credit => {
        dispatch(
          creditEventConcat({
            id: credit.id,
            eventInfo: { ...props.eventObject, nomEvent: stepperPage },
            nomEvent: stepperPage,
            creditsSelectionnes: [{ ...credit }],
          })
        );
      });
    }
    props.onClose();
    // setStepperPage('start');
  };

  const handleUpdatedEventStepper = () => {
    if (props.updatedEvent?.nomEvent) {
      setStepperPage(props.updatedEvent.nomEvent);
    }
  };

  const getDate = () => {
    handleUpdatedEventStepper();
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 " style={{ marginTop: '30px' }}>
        <label
          htmlFor="dateEvnt"
          className="block text-lg font-medium text-gray-500 "
          style={{ display: 'block', width: '100%', fontSize: '16px', color: '#000', paddingTop: '5px' }}
        >
          {' '}
          Date de l'événement:{' '}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2" style={{ marginLeft: '25%' }}>
          {' '}
          <TextField
            id="dateEvent"
            type="date"
            value={props.eventObject.dateEvent}
            onChange={e => {
              props.onEventChange({
                ...props.eventObject,
                dateEvent: e.target.value,
              });
            }}
          />
        </div>
      </div>
    );
  };

  //END EVENTS IN-SCOPE STATE MANAGEMENT
  switch (stepperPage) {
    case 'start':
      return (
        <Modal
          isOpen={props.isOpen}
          onClose={() => {
            props.onClose();
            props.onSelectCredit([]);
            props.onEventChange({});
            props.onSelectionReset([]);
          }}
          isCentered
          size="xl"
        >
          {' '}
          {/* avoid scuffed stepper heade */}
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(0deg)" />
          <ModalContent>
            <ModalHeader>Initialisation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="mb-4 mt-2">
                <div>
                  {/* {getDate()} */}
                  <label htmlFor="">Choix évènement :</label>
                  <Select
                    placeholder="Choisir l'évènement à appliquer"
                    //@ts-ignore
                    // disabled={props.eventObject.dateEvent && events.length > 0 ? false : true}
                    onChange={e => setStepperPage(e.target.value)}
                  >
                    {events.map(event => (
                      <option value={event.title}>{event.label}</option>
                    ))}
                  </Select>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      );
    // REMBOURSEMENT_PARTIEL
    case REMBOURSEMENT_PARTIEL:
      return (
        <RembousementPartiel
          onUpdatedEventReset={props.onUpdatedEventReset}
          onSelectCredit={props.onSelectCredit}
          onSelectionReset={props.onSelectionReset}
          singleStateManagement={singleStateManagement}
          setStepperPage={setStepperPage}
          onEventChange={props.onEventChange}
          eventObject={props.eventObject}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      );
    //REMBOURSEMENT_TOTAL
    case REMBOURSEMENT_TOTAL:
      return (
        <RemboursementTotal
          checkEmpty={checkEmpty}
          updatedEvent={props.updatedEvent}
          onUpdatedEventReset={props.onUpdatedEventReset}
          onSelectCredit={props.onSelectCredit}
          onSelectionReset={props.onSelectionReset}
          singleStateManagement={singleStateManagement}
          setStepperPage={setStepperPage}
          onEventChange={props.onEventChange}
          eventObject={props.eventObject}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      );
    //Report echeance
    case REPORT_ECHEANCE:
      return (
        <ReportEcheance
          checkEmpty={checkEmpty}
          updatedEvent={props.updatedEvent}
          onUpdatedEventReset={props.onUpdatedEventReset}
          onSelectCredit={props.onSelectCredit}
          onSelectionReset={props.onSelectionReset}
          singleStateManagement={singleStateManagement}
          onEventChange={props.onEventChange}
          eventObject={props.eventObject}
          setStepperPage={setStepperPage}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      );
    //Reprofilage
    case REPROFILAGE:
      return (
        <Reprofilage
          checkEmpty={checkEmpty}
          updatedEvent={props.updatedEvent}
          onUpdatedEventReset={props.onUpdatedEventReset}
          onSelectCredit={props.onSelectCredit}
          onSelectionReset={props.onSelectionReset}
          singleStateManagement={singleStateManagement}
          onEventChange={props.onEventChange}
          eventObject={props.eventObject}
          setStepperPage={setStepperPage}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      );

    default:
      return (
        <Modal
          isOpen={props.isOpen}
          onClose={() => {
            props.onClose();
            props.onSelectCredit([]);
            props.onEventChange({});
            props.onSelectionReset([]);
          }}
          isCentered
          size="xl"
        >
          {' '}
          {/* avoid scuffed stepper heade */}
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(0deg)" />
          <ModalContent>
            <ModalHeader>Initialisation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="mb-4 mt-2">
                <div>
                  {/* {getDate()} */}
                  <label htmlFor="">Choix évènement :</label>
                  <Select
                    placeholder="Choisir l'évènement à appliquer"
                    //@ts-ignore
                    // disabled={props.eventObject.dateEvent && events.length > 0 ? false : true}
                    onChange={e => setStepperPage(e.target.value)}
                  >
                    {events.map(event => (
                      <option value={event.title}>{event.label}</option>
                    ))}
                  </Select>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      );
  }
};
