import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityState } from 'app/shared/reducers/reducer.utils';

interface CreditModel {
  id?: number;
  clientId?: number;
  produitId?: number;
  montant?: number;
  capitalRestant?: number;
  dureeRestante?: number;
  // to be looked later should be a string
  dateStart?: string | Date;
  dateEnd?: string | Date;
}

const initialState: EntityState<CreditModel> = {
  loading: false,
  errorMessage: null,
  entities: [
    {
      id: 3339560,
      produitId: 1,
      clientId: 2,
      capitalRestant: 798174.89,
      dureeRestante: 68,
      dateStart: '12-01-2020',
      dateEnd: '12-08-2035',
      montant: 352020,
    },

    {
      id: 3339561,
      produitId: 2,
      clientId: 4,
      capitalRestant: 686868.89,
      dureeRestante: 34,
      dateStart: '12-08-2010',
      dateEnd: '12-08-2045',
      montant: 552020,
    },
    {
      id: 3339563,
      produitId: 3,
      clientId: 3,
      capitalRestant: 798174.89,
      dureeRestante: 190,
      dateStart: '12-08-2015',
      dateEnd: '12-08-2045',
      montant: 152020,
    },
    {
      id: 3339562,
      produitId: 4,
      clientId: 5,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2012',
      dateEnd: '12-08-2045',
      montant: 152020,
    },

    {
      id: 3339564,
      produitId: 4,
      clientId: 5,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2017',
      dateEnd: '12-08-2045',
      montant: 152020,
    },
    {
      id: 3339565,
      produitId: 4,
      clientId: 1,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2018',
      dateEnd: '12-08-2045',
      montant: 152020,
    },
    {
      id: 3339566,
      produitId: 4,
      clientId: 5,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2001',
      dateEnd: '12-08-2045',
      montant: 152020,
    },
    {
      id: 3339567,
      produitId: 4,
      clientId: 5,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2007',
      dateEnd: '12-08-2035',
      montant: 152020,
    },
    {
      id: 3339568,
      produitId: 4,
      clientId: 1,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2045',
      dateEnd: '12-08-2023',
      montant: 152020,
    },
    {
      id: 3339569,
      produitId: 4,
      clientId: 5,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2011',
      dateEnd: '12-08-2067',
      montant: 152020,
    },
    {
      id: 3339570,
      produitId: 4,
      clientId: 3,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2011',
      dateEnd: '12-08-2023',
      montant: 132020,
    },
    {
      id: 3339371,
      produitId: 4,
      clientId: 1,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2010',
      dateEnd: '12-08-2026',
      montant: 152020,
    },
    {
      id: 3339572,
      produitId: 4,
      clientId: 1,
      capitalRestant: 798174.89,
      dureeRestante: 10,
      dateStart: '12-08-2001',
      dateEnd: '12-08-2022',
      montant: 152020,
    },
  ],
  entity: {},
  updating: false,
  updateSuccess: false,
};

export const CreditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    intializeCredits: (state, action: PayloadAction<CreditModel>) => {
      state.loading = true;
      state.entities = state.entities.concat(action.payload);
      state.loading = false;
    },
  },
});

export const { intializeCredits } = CreditsSlice.actions;

// Reducer
export default CreditsSlice.reducer;
