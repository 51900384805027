import './table.scss';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Heading, SimpleGrid, Stack, StackDivider, Text } from '@chakra-ui/react';
import {
  Box as Box2,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  PropTypes,
  Table as Table2,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import $ from 'jquery';

//export type IHomeProp = StateProps;

export const ListeDesSimulation = props => {
  const { account } = props;
  const [open, setOpen] = React.useState(false);

  $(document).ready(function () {
    $('#myInput').on('keyup', function () {
      const v = $(this).val().toString();
      const value = v.toLowerCase();
      $('#myTable tr').filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        return true;
      });
    });
    $('#input1').on('keyup', function () {
      const v = $(this).val().toString();
      const value = v.toLowerCase();
      $('#myTable tr').filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        return true;
      });
    });
    $('#input2').on('keyup', function () {
      const v = $(this).val().toString();
      const value = v.toLowerCase();
      $('#myTable tr').filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        return true;
      });
    });
    $('#input3').on('keyup', function () {
      const v = $(this).val().toString();
      const value = v.toLowerCase();
      $('#myTable tr').filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        return true;
      });
    });
    $('#input4').on('keyup', function () {
      const v = $(this).val().toString();
      const value = v.toLowerCase();
      $('#myTable tr').filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        return true;
      });
    });

    $('#input5').on('keyup', function () {
      const v = $(this).val().toString();
      const value = v.toLowerCase();
      $('#myTable tr').filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        return true;
      });
    });
  });

  return (
    <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
      <Box p={5} shadow="base" borderWidth="3px">
        <Row>
          <Col md="12">
            <div className="container">
              <h3 className="title">Tableau des demandes SAV crédits</h3>
              <div className="container">
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-grow-1 bd-highlight">
                    <input className="form-control" id="myInput" type="text" placeholder="Chercher.." />
                  </div>
                  <div className="p-2 bd-highlight">
                    <Link to="/table">
                      {' '}
                      <FontAwesomeIcon icon="hourglass" color="light-grey" />{' '}
                      <span className="px-2 inline-flex text-xs leading-5 font-bold rounded bg-gray-200 text-gray-800">En cours</span>{' '}
                    </Link>{' '}
                  </div>
                  <div className="p-2 bd-highlight">
                    <Link to="/table">
                      {' '}
                      <FontAwesomeIcon icon="check" color="green" />{' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded bg-green-100 text-green-800">Validée</span>{' '}
                    </Link>
                  </div>
                  <div className="p-2 bd-highlight">
                    {' '}
                    <Link to="/table">
                      {' '}
                      <FontAwesomeIcon icon="times" color="red" />{' '}
                      <span className="px-2 inline-flex text-xs leading-5 rounded bg-red-100 text-red-800">Refusé</span>{' '}
                    </Link>
                  </div>
                </div>
              </div>
              <br />
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      No Simulation <br /> <input className="form-control" id="input1" type="text" placeholder="Chercher.." />{' '}
                    </th>
                    <th>
                      Référence crédit <br /> <input className="form-control" id="input2" type="text" placeholder="Chercher.." />{' '}
                    </th>
                    <th>
                      Nom / Prenom <br /> <input className="form-control" id="input3" type="text" placeholder="Chercher.." />{' '}
                    </th>
                    <th>
                      No. Compte <br /> <input className="form-control" id="input4" type="text" placeholder="Chercher.." />{' '}
                    </th>
                    <th>
                      Agence <br /> <input className="form-control" id="input7" type="text" placeholder="Chercher.." />{' '}
                    </th>
                    <th>
                      Date demande <br /> <input className="form-control" id="input5" type="text" placeholder="Chercher.." />{' '}
                    </th>
                    <th>
                      Statut Simulation <br /> <input className="form-control" id="input6" type="text" placeholder="Chercher.." />{' '}
                    </th>
                    <th>
                      {' '}
                      <br />{' '}
                    </th>
                  </tr>
                </thead>
                <tbody id="myTable">
                  <tr>
                    <td>
                      {' '}
                      <Link to=""> 11 </Link>{' '}
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Ahmed XXXXX</td>
                    <td>684651354654</td>
                    <td>Casablanca-Centre</td>
                    <td>10/06/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold bg-gray-200 text-gray-800">
                        En cours
                      </span>{' '}
                    </td>
                    <td>
                      {' '}
                      <Link to="">
                        {' '}
                        <IconButton color="primary" aria-label="expand row">
                          <i className="fal fa-eye"> </i>
                        </IconButton>
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Link to=""> 12 </Link>
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Safae XXXXXX</td>
                    <td>684651354654</td>
                    <td>Casablanca-Centre</td>
                    <td>18/07/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold bg-gray-200 text-gray-800">
                        En cours
                      </span>{' '}
                    </td>
                    <td>
                      {' '}
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <i className="fal fa-eye"> </i>
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to=""> 13 </Link>
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Zineb XXXXXX</td>
                    <td>783837833782</td>
                    <td>Casablanca-Centre</td>
                    <td>15/10/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold bg-gray-200 text-gray-800">
                        En cours
                      </span>{' '}
                    </td>
                    <td>
                      {' '}
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <i className="fal fa-eye"> </i>
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to=""> 15 </Link>
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Youssef XXXXXX</td>
                    <td>796578397383</td>
                    <td>Casablanca-Centre</td>
                    <td>18/10/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold bg-green-100 text-green-800">
                        Validée
                      </span>
                    </td>
                    <td>
                      {' '}
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <i className="fal fa-eye"> </i>
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to=""> 16 </Link>
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Salma XXXXXX</td>
                    <td>578456786766</td>
                    <td>Casablanca-Centre</td>
                    <td>10/11/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold bg-green-100 text-green-800">
                        Validée
                      </span>
                    </td>
                    <td>
                      {' '}
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <i className="fal fa-eye"> </i>
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {' '}
                      <Link to=""> 17 </Link>{' '}
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Ahmed XXXXX</td>
                    <td>684651354654</td>
                    <td>Casablanca-Centre</td>
                    <td>10/06/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold  bg-gray-200 text-gray-800">
                        En cours
                      </span>{' '}
                    </td>
                    <td>
                      {' '}
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <i className="fal fa-eye"> </i>
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Link to=""> 18 </Link>
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Safae XXXXXX</td>
                    <td>684651354654</td>
                    <td>Casablanca-Centre</td>
                    <td>18/07/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold  bg-red-100 text-red-800">
                        Refusée
                      </span>{' '}
                    </td>
                    <td>
                      {' '}
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <i className="fal fa-eye"> </i>
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link to=""> 19 </Link>
                    </td>
                    <td>Nouveau Crédit</td>
                    <td>Zineb XXXXXX</td>
                    <td>783837833782</td>
                    <td>Casablanca-Centre</td>
                    <td>15/10/2018</td>
                    <td>
                      {' '}
                      <span className="px-2 inline-flex text-xs leading-5  rounded-full font-bold bg-red-100 text-red-800">
                        Refusée
                      </span>{' '}
                    </td>
                    <td>
                      {' '}
                      <IconButton
                        color="primary"
                        aria-label="expand row"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <i className="fal fa-eye"> </i>
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    Piece Justificatifs
                  </Typography>
                  <Table2 size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell>Document</TableCell>
                        <TableCell>Statut</TableCell>
                        <TableCell align="right">Modifie</TableCell>
                        <TableCell align="right">Modifie Par</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableCell> Pas de donnée</TableCell>
                    </TableBody>
                  </Table2>
                </Box>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Box>
    </Stack>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ListeDesSimulation);
