import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import './EcheancierStyle.scss';
import { useEffect } from 'react';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'N° ', flex: 0.1 },
  {
    field: 'mntEcheance',
    headerName: 'Échéance',
    flex: 0.2,
  },
  {
    field: 'mntCapital',
    headerName: 'Capital',
    flex: 0.2,
  },
  {
    field: 'mntAssurance',
    headerName: 'Assurance',
    flex: 0.2,
  },
  {
    field: 'mntFrais',
    headerName: 'Frais',
    flex: 0.2,
  },
  {
    field: 'mntInteret',
    headerName: 'Intérêts',
    flex: 0.2,
  },
  {
    field: 'termDate',
    headerName: 'Date',
    renderCell: params => {
      // const x=params.value.toString();
      return params.value.toString().substring(0, 10);
    },
    flex: 0.2,
  },
];

// const rows2 = props.ta?.map(row => {
//   const { termId, ...rest } = row;
//   return { id: termId, ...rest };
// });

export const EchancierDataGridDyn = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  const rows = props.ta?.map(row => {
    const { termId, ...rest } = row;
    return { id: termId, ...rest };
  });

  return (
    <div ref={ref} style={{ height: 400, width: '100%' }} className="">
      <DataGrid
        //pagination
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
      />
    </div>
  );
});
