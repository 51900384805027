import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICredit, defaultValue } from 'app/shared/model/credit.model';
import { toast } from 'react-toastify';

const initialState: EntityState<ICredit> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/credits';

// Actions

export const getEntities = createAsyncThunk('credit/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<ICredit[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'credit/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICredit>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'credit/create_entity',
  async (entity: ICredit, thunkAPI) => {
    const result = await axios.post<ICredit>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'credit/update_entity',
  async (entity: ICredit, thunkAPI) => {
    const result = await axios.put<ICredit>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'credit/partial_update_entity',
  async (entity: ICredit, thunkAPI) => {
    const result = await axios.patch<ICredit>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'credit/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICredit>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getClientsCredit = createAsyncThunk(
  'credit/get_clients',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/client/${id}`;
    return await axios.get<ICredit[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const retrieveClientLoans = createAsyncThunk(
  'client/retrieve_loans',

  async (id: string | number) => {
    const requestUrl = `api/getCreditsByCLientReference/1`;

    return axios.get<any[]>(requestUrl);
  },

  { serializeError: serializeAxiosError }
);

export const CreditSlice = createEntitySlice({
  name: 'credit',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getClientsCredit.fulfilled, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.entities = action.payload.data;
        console.group('credit reducer', action.payload.data);
      })
      .addCase(retrieveClientLoans.fulfilled, (state, action) => {
        state.loading = false;

        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getClientsCredit), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CreditSlice.actions;

// Reducer
export default CreditSlice.reducer;
