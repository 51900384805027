import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs.ts';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Component, useEffect } from 'react';
import { Card } from 'reactstrap';
import { BrowserRouter as Router, useLocation, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import Login from 'app/modules/login/login';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
export const App = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const paddingTop = '0px';
  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  return (
    <>
      <Router basename={baseHref}>
        <div
          className={isAuthenticated ? '' : 'app-container'}
          style={!isAuthenticated ? null : { paddingTop, overflow: '', backgroundColor: '#FCFDFF' }}
        >
          {!isAuthenticated ? (
            <div> </div>
          ) : (
            <div>
              <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
              <ErrorBoundary>
                <Header
                  isAuthenticated={isAuthenticated}
                  isAdmin={isAdmin}
                  ribbonEnv={ribbonEnv}
                  isInProduction={isInProduction}
                  isOpenAPIEnabled={isOpenAPIEnabled}
                />
              </ErrorBoundary>
            </div>
          )}
          <div className={!isAuthenticated ? '' : 'container-fluid view-container'} id="app-view-container">
            {!isAuthenticated ? (
              <AppRoutes />
            ) : (
              <div className="jh-card">
                <ErrorBoundary>
                  <AppRoutes />
                </ErrorBoundary>
              </div>
            )}
            {!isAuthenticated ? <div> </div> : <Footer />}
          </div>
        </div>
      </Router>
    </>
  );
};

export default hot(module)(App);
