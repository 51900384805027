import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import StringEditTile from '../CommonComponents/StringEditTile';
import EditTileTD from '../CommonComponents/DateEditTile';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setClient } from '../../Reducers/simulationCreditSlice';
import { setClientForSynthese } from '../../Reducers/syntheseCreditSlice';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NumEditTile from '../CommonComponents/NumEditTile';

const FicheProspect = props => {
  //edit Toggles
  const [editEmail, setEditEmail] = useState(true);
  const [editNom, setEditNom] = useState(true);
  const [editPrenom, setEditPrenom] = useState(true);
  const [editTel, setEditTel] = useState(true);
  const [editAdresse, setEditAdresse] = useState(true);

  const [editdateOfBirthday, setEditdateOfBirthday] = useState(true);
  const [editcountryOfResidence, setEditcountryOfResidence] = useState(true);
  const [editemployeur, setEditemployeur] = useState(true);
  const [editactivity, setEditactivity] = useState(true);

  // const ficheClient = useAppSelector(state => state.ficheClient);
  const ficheClient = useAppSelector(state => state.client.entity);
  // let ficheClient = client;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setClient({ id: ficheClient.id }));
    dispatch(setClientForSynthese({ id: ficheClient.id }));
  }, []);

  const clean = (v: any) => {
    if (typeof v === 'undefined') return v;

    v = v.toString();
    v = v.replace(/\s/g, '');
    v = v.replace('dhs', '');
    v = v.replace('MAD', '');

    return parseInt(v);
  };

  return (
    <Accordion className={'mx-5'}>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography className="">
          {' '}
          <i className="fal fa-user mx-2 fa-lg"> </i>Fiche Prospect{' '}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="w-100">
        <Typography className="w-100">
          <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0 w-100">
              <dl className="sm:divide-y sm:divide-gray-200 w-100">
                <StringEditTile label="Nom" value={ficheClient.nom} champ={'nom'} toggle={editNom} setToggle={setEditNom} />
                <StringEditTile label="Prenom" value={ficheClient.prenom} champ={'prenom'} toggle={editPrenom} setToggle={setEditPrenom} />

                <StringEditTile label="E-mail " value={ficheClient.email} champ={'email'} toggle={editEmail} setToggle={setEditEmail} />

                <StringEditTile
                  label="N° de téléphone"
                  value={ficheClient.telephoneNumber}
                  champ={'telephoneNumber'}
                  toggle={editTel}
                  setToggle={setEditTel}
                />

                <StringEditTile
                  label="Adresse de résidence"
                  value={ficheClient.address}
                  champ={'address'}
                  toggle={editAdresse}
                  setToggle={setEditAdresse}
                />

                <EditTileTD
                  label="Date de naissance"
                  value={ficheClient.dateOfBirthday}
                  champ={'dateOfBirthday'}
                  toggle={editdateOfBirthday}
                  setToggle={setEditdateOfBirthday}
                />

                <StringEditTile
                  label="Pays de résidence"
                  value={ficheClient.countryOfResidence}
                  champ={'countryOfResidence'}
                  toggle={editcountryOfResidence}
                  setToggle={setEditcountryOfResidence}
                />
                <StringEditTile
                  label="Employeur"
                  value={ficheClient.employeur}
                  champ={'employeur'}
                  toggle={editemployeur}
                  setToggle={setEditemployeur}
                />
                <StringEditTile
                  label="Activité"
                  value={ficheClient.activity}
                  champ={'activity'}
                  toggle={editactivity}
                  setToggle={setEditactivity}
                />

                <NumEditTile label="Revenus" value={clean(ficheClient.incomes)} champ={'incomes'} setToggle={undefined} />

                <NumEditTile
                  label="Échéances crédits"
                  value={clean(ficheClient.amountRepayment)}
                  champ={'amountRepayment'}
                  toggle={undefined}
                  setToggle={undefined}
                />
              </dl>
            </div>
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FicheProspect;
