import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import './EcheancierStyle.scss';
import { useAppSelector } from 'app/config/store';
const columns: GridColDef[] = [
  { field: 'termId', headerName: 'N° Échéance', flex: 0.15 },
  {
    field: 'termDate',
    headerName: 'Date Échéance',
    flex: 0.2,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center'
  },
  {
    field: 'mntCapital',
    headerName: 'Amortissement',
    flex: 0.15,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
  },
  {
    field: 'mntInteret',
    headerName: 'Intérêts',
    flex: 0.15,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
  },
  {
    field: 'mntAssurance',
    headerName: 'Assurance',
    flex: 0.15,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
  },
  {
    field: 'mntAccessoire',
    headerName: 'Accéssoire',
    flex: 0.15,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
  },
  {
    field: 'mntStdu',
    headerName: 'Capital restant du',
    flex: 0.175,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
  },  
];

const rows = [
  {
    id: 1,
    DATE_ECHEANCE: '01/05/2012',
    AMORTISSEMENT: 1.0079,
    INTERES: 1833.33,
    CAPITAL_RESTANT_DU: 798174.89,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 2,
    DATE_ECHEANCE: '31/05/2012',
    AMORTISSEMENT: 4.0026,
    INTERES: 1829.15,
    CAPITAL_RESTANT_DU: 796174.89,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 3,
    DATE_ECHEANCE: '30/06/2012',
    AMORTISSEMENT: 6.941,
    INTERES: 1824.96,
    CAPITAL_RESTANT_DU: 79174.89,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 4,
    DATE_ECHEANCE: '31/07/2012',
    AMORTISSEMENT: 9.0122,
    INTERES: 1820.96,
    CAPITAL_RESTANT_DU: 794509.6,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 5,
    DATE_ECHEANCE: '31/08/2012',
    AMORTISSEMENT: 10.811,
    INTERES: 1816.53,
    CAPITAL_RESTANT_DU: 792669.39,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 6,
    DATE_ECHEANCE: '30/09/2012',
    AMORTISSEMENT: 12.0107,
    INTERES: 1812.31,
    CAPITAL_RESTANT_DU: 790824.12,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 7,
    DATE_ECHEANCE: '31/10/2012',
    AMORTISSEMENT: 14.0067,
    INTERES: 1808.06,
    CAPITAL_RESTANT_DU: 788973.78,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 8,
    DATE_ECHEANCE: '30/11/2012',
    AMORTISSEMENT: 15.9994,
    INTERES: 1803.81,
    CAPITAL_RESTANT_DU: 787118.35,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 9,
    DATE_ECHEANCE: '31/12/2012',
    AMORTISSEMENT: 18.9984,
    INTERES: 1799.55,
    CAPITAL_RESTANT_DU: 785257.81,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
  {
    id: 10,
    DATE_ECHEANCE: '31/01/2013',
    AMORTISSEMENT: 20.1797,
    INTERES: 1795.27,
    CAPITAL_RESTANT_DU: 783392.16,
    ASSURANCE_A_PERCEVOIR: 366.76,
  },
];

interface IData{
  data ?: any[]
}

export default function EchancierDataGrid({data}:IData) {
  return (
    <div style={{ height: 400, width: '100%' }} className="">
      {data !== undefined && 
      <DataGrid
        //pagination
        getRowId={(row: any) =>  row.termId}
        rows={data}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
      />}
    </div>
  );
}
