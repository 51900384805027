import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import EchancierDataGrid from 'app/modules/commonComponents/Echeancier/EchancierDataGrid';
import { BannerResultat } from 'app/modules/CreditStepper/ResultatStep/Banner/BannerResultat';
import { Box, Divider, Heading, SimpleGrid, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react';
import InfoTile3 from 'app/modules/commonComponents/InfoTiles/InfoTile3';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { CheckIcon, CheckCircleIcon, UserGroupIcon, PhotographIcon, DocumentIcon, TrashIcon } from '@heroicons/react/outline';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getStatus = (status: string | number) => {
  if (status === 0 || status === '2') {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
      >
        Refusée
      </span>
    );
  } else if (status === 'DEMANDE_ACCORDEE') {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        Approuvée
      </span>
    );
  } else {
    return (
      <span
        style={{ marginLeft: '2.5em' }}
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
      >
        a decider
      </span>
    );
  }
};

export const PJAccordion2 = ({ title, isApprouved }) => {
  const PJList = [
    {
      id: 1,
      emplacement: 'https://www.demarches.ma/wp-content/uploads/sites/12/2020/06/CIN_maroc-1024x670.jpg',
      type: 'IMAGE',
      libele: 'Carte CIN',
      statut: 'PasRecu',
      modifie: '2022-11-16',
      modifiePar: 'Utilisateur',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 2,
      emplacement: 'https://www.pedagogie.ac-aix-marseille.fr/upload/docs/application/pdf/2012-06/bulletin_de_salaire.pdf',
      type: 'PDF',
      libele: 'Bulletin Paie',
      statut: 'PasRecu',
      modifie: '2022-11-16',
      modifiePar: 'Utilisateur',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 4,
      emplacement: 'https://www.demarches.ma/wp-content/uploads/sites/12/2020/06/CIN_maroc-1024x670.jpg',
      type: 'IMAGE',
      libele: 'Attestation de salaire',
      statut: 'PasRecu',
      modifie: '2022-11-16',
      modifiePar: 'Utilisateur',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
  ];

  const com1 =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus fermentum ultrices ultrices. Aenean quis vestibulum lectus, sed vehicula eros. Quisque dapibus sit amet nisl nec placerat. Cras ut dolor elit. Nam a nisl et tellus pulvinar blandit. Donec mi diam.';
  const com2 = com1;
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [validated, setValidated] = useState(false);
  const [prdtArray, setPrdtArray] = useState(PJList);

  const [PdfUrl, setPdfUrl] = useState(''); // workaround LightBox int
  const [imgUrl, setImageUrl] = useState(''); // workaround LightBox int
  const { isOpen: validateIsOpen, onOpen: validateOnOpen, onClose: validateOnClose } = useDisclosure();
  const { isOpen: validateDecisionIsOpen, onOpen: validateDecisionOnOpen, onClose: validateDecisionOnClose } = useDisclosure();
  const { isOpen: refuseDecisionIsOpen, onOpen: refuseDecisionOnOpen, onClose: refuseDecisionOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
  const [deletedIndex, setDeletedIndex] = useState(0);
  const [validatedIndex, setValidatedIndex] = useState(0); // workaround LightBox int

  // const remove2 = (nb: number) => {
  //   const array = [...prdtArray];
  //     array.splice(nb, 1);
  //     setPrdtArray(array);
  //     };
  const confirmDelete = () => {
    const array = [...prdtArray];
    array.splice(deletedIndex, 1);
    setPrdtArray(array);
  };

  const confirmValidate = () => {
    const array = [...prdtArray];
    array[validatedIndex].statut = 'Recu';
    setPrdtArray(array);
  };

  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24),
    createData('Ice cream sandwich', 237, 9.0, 37),
    createData('Eclair', 262, 16.0, 24),
    createData('Cupcake', 305, 3.7, 67),
    createData('Gingerbread', 356, 16.0, 49),
  ];

  return (
    <div>
      <Accordion className="mx-6">
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="">
            {' '}
            <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>
            {title}
            {/* {getStatus(isApprouved)} */}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-100 border-t border-gray-00 ">
          <Typography className="w-100 ">
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="bg-gray-200">
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Document</TableCell>
                    <TableCell align="right">Statut</TableCell>
                    <TableCell align="right">Modifiée le</TableCell>
                    <TableCell align="right">Modifiée par</TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prdtArray.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.type === 'IMAGE' ? (
                          <IconButton
                            color="primary"
                            className="mx-2"
                            onClick={() => {
                              setImageUrl(row.emplacement);
                              setOpenImgModal(!openImgModal);
                            }}
                          >
                            <PhotographIcon className="h-6 w-6" aria-hidden="true" />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="primary"
                            className="mx-2"
                            onClick={() => {
                              setPdfUrl(row.emplacement);
                              setOpenPDFModal(!openPDFModal);
                              // console.group('lol', piece.emplacement, openPDFModal);
                            }}
                          >
                            <DocumentIcon className="h-6 w-6" aria-hidden="false" />

                            {/* <i className="fal fa-file-pdf"> </i> */}
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <div className="text-sm font-medium text-gray-900">{row.libele}</div>
                      </TableCell>
                      <TableCell align="right">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Approuvée
                        </span>
                      </TableCell>
                      <TableCell align="right">{row.modifie}</TableCell>
                      <TableCell align="right">{row.modifiePar}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          className="mx-2"
                          onClick={() => {
                            validateOnOpen();
                            setValidatedIndex(45);
                          }}
                        >
                          <CheckIcon className="h-6 w-6" aria-hidden="false" />
                        </IconButton>

                        <IconButton
                          color="primary"
                          className="mx-2"
                          onClick={() => {
                            deleteOnOpen();
                            setDeletedIndex(index);
                          }}
                        >
                          <TrashIcon className="h-6 w-6" aria-hidden="true" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
