import React, { useEffect, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Grid } from '@material-ui/core';
import { Select, useDisclosure } from '@chakra-ui/react';
import FormattedInput from 'app/modules/commonComponents/FormatedInputs/FormattedInput';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'reactstrap';
import FormattedInput3 from '../../commonComponents/FormatedInputs/FormattedInput3';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { editCaracteristiqueCredit } from '../Reducers/syntheseCreditSlice';
import GeneriqueModalError from '../PropositionStep/modals/GeneriqueModalError';

const ImmoForm = props => {
  const simulationCredit = useAppSelector(state => state.syntheseCredit);

  const dispatch = useAppDispatch();
  // const [errorCarac, setErrorCarac] = React.useState(false);

  const [maxVal, setMaxVal] = useState(0);
  // const [tooltipOpen, setTooltipOpen] = useState(errorCarac);
  // const [tooltipOpen2, setTooltipOpen2] = useState(errorCarac);

  const [tooltipOpen, setTooltipOpen] = useState(props.errorCarac);
  const [tooltipOpen2, setTooltipOpen2] = useState(props.errorCarac);
  const { isOpen: generiqueErrorIsOpen1, onOpen: generiqueErrorOnOpen1, onClose: generiqueErrorOnClose1 } = useDisclosure();
  const { isOpen: generiqueErrorIsOpen2, onOpen: generiqueErrorOnOpen2, onClose: generiqueErrorOnClose2 } = useDisclosure();

  // TODO: Fix tooltips

  useEffect(() => {
    setTooltipOpen(props.errorCarac);
    setTooltipOpen2(props.errorCarac);
  }, [props.errorCarac]);

  const withValueCap = inputObj => {
    const { value } = inputObj;
    if (value <= maxVal) return true;
    {
      // alert(
      //   'Le montant du crédit ne doit pas dépasser le montant du projet \n (' +
      //     new Intl.NumberFormat('MA', { style: 'currency', currency: 'MAD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
      //       maxVal
      //     ) +
      //     ')'
      // );
      generiqueErrorOnOpen2();
      return false;
    }
  };

  const clean = (v: string) => {
    v = v.replace(/\s/g, '');
    v = v.replace('dhs', '');
    return v;
  };

  const cleanI = (v: string | number) => {
    if (typeof v === 'number') return v;
    if (v === undefined) return undefined;
    let y = v.toString();
    y = y.replace(/\s/g, '');
    y = y.replace('dhs', '');
    y = y.replace('MAD', '');

    return parseFloat(y);
  };

  useEffect(() => {
    handleOnTypeCreditChange('valeurBien', cleanI(simulationCredit.valeurBien));
    handleOnTypeCreditChange('montantFrais', cleanI(simulationCredit.montantFrais));
    handleOnTypeCreditChange('montantProjet', cleanI(simulationCredit.montantProjet));
    handleOnTypeCreditChange('mntCrdt', cleanI(simulationCredit.mntCrdt));
    handleOnTypeCreditChange('apportPersonnel', cleanI(simulationCredit.apportPersonnel));
  }, [
    simulationCredit.valeurBien,
    simulationCredit.montantFrais,
    simulationCredit.montantProjet,
    simulationCredit.mntCrdt,
    simulationCredit.apportPersonnel,
  ]);

  useEffect(() => {
    const mntCrdt = simulationCredit.mntCrdt ? cleanI(simulationCredit.mntCrdt) : 0;
    const montantFrais = simulationCredit.montantFrais ? cleanI(simulationCredit.montantFrais) : 0;
    const montantProjet = simulationCredit.montantProjet ? cleanI(simulationCredit.montantProjet) : 0;
    const valeurBien = simulationCredit.valeurBien ? cleanI(simulationCredit.valeurBien) : 0;

    handleOnTypeCreditChange('montantProjet', valeurBien + montantFrais);

    if (montantProjet > 0 && mntCrdt > montantProjet) {
      generiqueErrorOnOpen1();
      // alert(
      //   'Le montant du crédit ne doit pas dépasser le cout du projet:\n' +
      //     montantProjet +
      //     ' Dhs(Valeur dur bien+ Frais de dossier)\n Le montant du crédit sera remis a 0 Dhs'
      // );
      handleOnTypeCreditChange('mntCrdt', 0);
    }
  }, [
    simulationCredit.valeurBien,
    simulationCredit.montantFrais,
    simulationCredit.montantProjet,
    simulationCredit.mntCrdt,
    simulationCredit.apportPersonnel,
  ]);

  useEffect(() => {
    const mntCrdt = simulationCredit.mntCrdt ? cleanI(simulationCredit.mntCrdt) : 0;
    const montantProjet = simulationCredit.montantProjet ? cleanI(simulationCredit.montantProjet) : 0;

    const diff = montantProjet - mntCrdt;
    handleOnTypeCreditChange('apportPersonnel', diff > 0 ? diff : 0);
  }, [simulationCredit.mntCrdt, simulationCredit.apportPersonnel, simulationCredit.montantProjet]);

  // useEffect(() => {
  //   setTooltipOpen(errorCarac);
  //   setTooltipOpen2(errorCarac);
  // }, [errorCarac]);

  const handleOnTypeCreditChange = (champ, formattedValue) => {
    dispatch(editCaracteristiqueCredit({ champ, inputValue: formattedValue }));
  };

  useEffect(() => {
    setMaxVal(cleanI(simulationCredit.montantProjet));
  }, [simulationCredit.montantProjet]);

  return (
    <div>
      <div className=" animate__animated animate__fadeInDown ml-12 2xl:ml-20 2xl:w-3/4  ">
        <div className="row ">
          <div className="col-6 col-xl-6 ">
            <div className="">
              <div className="row">
                <div className="col-6">
                  <dt className=" ml-8 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Objet du crédit </dt>
                </div>
                <div className="col-6 ">
                  <Select placeholder="Choisir Objet" onChange={event => handleOnTypeCreditChange('objetCredit', event.target.value)}>
                    <option value="Acquisition">Acquisition</option>
                    <option value="Construction">Construction</option>
                    <option value="AquiCons">AquiCons</option>
                    <option value="Amenagement">Amenagement</option>
                    <option value="Rachat">Rachat</option>
                    <option value="Autres">Autres</option>
                  </Select>
                </div>
              </div>{' '}
            </div>
          </div>
          <div className="col-6 col-xl-6 ">
            {simulationCredit.objetCredit === 'Amenagement' ||
            simulationCredit.objetCredit === 'Rachat' ||
            simulationCredit.objetCredit === 'Acquisition' ? (
              <div className=" animate__animated animate__fadeInDown  row">
                {' '}
                <div className="col-4  ">
                  {' '}
                  <dt className="ml-8 block text-base font-medium text-grey-500 sm:mt-px sm:pt-2">Type du bien</dt>
                </div>
                <div className="col-8 ">
                  <RadioGroup
                    aria-label="Type Bien"
                    name="typeBien"
                    value={simulationCredit.typeBien}
                    onChange={event => handleOnTypeCreditChange('typeBien', event.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="APPARTEMENT"
                      control={<Radio />}
                      label={
                        <Box component="div" fontSize={13}>
                          Appartement
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="MAISON"
                      control={<Radio />}
                      label={
                        <Box component="div" fontSize={13}>
                          Maison
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="TERRAIN"
                      control={<Radio />}
                      label={
                        <Box component="div" fontSize={13}>
                          Terrain
                        </Box>
                      }
                    />
                  </RadioGroup>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row ">
          <div className="col-6 col-xl-6">
            <div>
              <FormattedInput3
                label={'Valeur du Bien'}
                value={simulationCredit.valeurBien}
                onChange={values => {
                  const { formattedValue, value } = values;
                  handleOnTypeCreditChange('valeurBien', value);
                  props.setErrorCarac(false);
                }}
                readOnly={false}
                isAllowed={undefined}
                errorCarac={props.errorCarac}
                ide="ide1"
              />
            </div>
          </div>
          <div className="col-6 col-xl-6">
            <div className="">
              <FormattedInput3
                label={'Montant des Frais'}
                value={simulationCredit.montantFrais}
                onChange={values => {
                  const { formattedValue, value } = values;
                  handleOnTypeCreditChange('montantFrais', value);
                }}
                readOnly={false}
                isAllowed={undefined}
                errorCarac={props.errorCarac}
                ide="ide2"
              />
            </div>{' '}
          </div>
        </div>

        <div className="row  ">
          <div className="col-6 col-xl-6">
            <FormattedInput
              label={'Montant du Projet'}
              value={simulationCredit.montantProjet}
              onChange={values => {
                const { formattedValue, value } = values;
                handleOnTypeCreditChange('montantProjet', value);
              }}
              readOnly={true}
              isAllowed={undefined}
            />
          </div>
          <div className="col-6 col-xl-6" id="ide3">
            <FormattedInput3
              label={'Montant du Crédit'}
              isAllowed={withValueCap}
              value={simulationCredit.mntCrdt}
              onChange={values => {
                const { formattedValue, value } = values;
                handleOnTypeCreditChange('mntCrdt', value);
              }}
              readOnly={false}
              ide="ide3"
              errorCarac={props.errorCarac}
            />
          </div>{' '}
        </div>

        <div className="row ">
          <div className="col-6">
            <FormattedInput
              label={'Apport Personnel'}
              value={simulationCredit.apportPersonnel}
              onChange={values => {
                const { formattedValue, value } = values;
                handleOnTypeCreditChange('apportPersonnel', value);
              }}
              readOnly={true}
              isAllowed={undefined}
            />
          </div>

          <hr />
          {!props.errorCarac ? (
            <Tooltip hideArrow={true} style={{ backgroundColor: '#e39517' }} placement="bottom" isOpen={props.errorCarac} target="ide3">
              <i className="fas fa-exclamation-triangle"></i> Champ obligatoire
            </Tooltip>
          ) : null}
          {!props.errorCarac ? (
            <Tooltip hideArrow={true} style={{ backgroundColor: '#e39517' }} placement="bottom" isOpen={props.errorCarac} target="ide2">
              <i className="fas fa-exclamation-triangle"></i> Champ obligatoire
            </Tooltip>
          ) : null}
          <div className="col-3 invisible"></div>
        </div>
      </div>
      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose1}
        generiqueErrorIsOpen={generiqueErrorIsOpen1}
        errorMsg={
          'Le montant du crédit ne doit pas dépasser le cout du projet:\n' +
          new Intl.NumberFormat('MA', { style: 'currency', currency: 'MAD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
            simulationCredit.montantProjet
          ) +
          ' Dhs(Valeur dur bien+ Frais de dossier)\n Le montant du crédit sera remis a 0 Dhs'
        }
      />

      <GeneriqueModalError
        generiqueErrorOnClose={generiqueErrorOnClose2}
        generiqueErrorIsOpen={generiqueErrorIsOpen2}
        errorMsg={
          'Le montant du crédit ne doit pas dépasser le montant du projet \n (' +
          new Intl.NumberFormat('MA', { style: 'currency', currency: 'MAD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
            maxVal
          ) +
          ')'
        }
      />
    </div>
  );
};

export default ImmoForm;
