import { CheckCircleIcon, SpinnerIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Button, Text, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Spinner } from '@chakra-ui/react';
import React from 'react'
import { useHistory } from 'react-router-dom';


const MessageLS = ({messageIsOpen, messageOnOpen, messageOnclose, retourLS}) => {
    
    const history = useHistory();
    const closeMessage = () => {
        messageOnclose;
        history.push('/tab')
    }

    const getValidModal = () => {
        return (<Modal isOpen={messageIsOpen} onClose={closeMessage}>
            <ModalOverlay backdropFilter="blur(10px) hue-rotate(90deg)" />
            <ModalContent>
                <ModalBody>
                    <div className='container'>
                        <div className="row">
                            <div className="col-4 text-center" style={{paddingTop : "20px", paddingBottom :"20px"}}>
                                <Text fontSize='lg'>Evénement créé dans LS avec succès.</Text>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 d-flex justify-content-center text-center">
                                <Icon as= {CheckCircleIcon} color={"green"} boxSize={"100px"}/>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal> );
    }

    const getErrorModal = () => {
        return (<Modal isOpen={messageIsOpen} onClose={closeMessage}>
            <ModalOverlay backdropFilter="blur(10px) hue-rotate(90deg)" />
            <ModalContent style={{ width: '40%', maxHeight: '40%' }}>
                <ModalBody>
                    <div className='container'>
                        <div className="row">
                            <div className="inline-flex" style={{paddingTop : "20px", paddingBottom :"20px"}}>
                                <Icon as= {WarningTwoIcon} color={"red"} boxSize={"50px"}/>
                            </div>
                            <div className="col-4 text-center" style={{paddingTop : "20px", paddingBottom :"20px"}}>
                                <Text fontSize='lg'>  {retourLS.retour_Label} </Text>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <div style={{overflow : "auto"}}>
                                {
                                    retourLS.errors.map((row, index) => {
                                        return (
                                        <>
                                        <Text fontSize='sm'>Erreur : {row.code_error + "  -  " +row.error_Label }</Text>
                                        </>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal> );
    }

    const getNull = () => {
        return (<Modal isOpen={messageIsOpen} onClose={closeMessage}>
            <ModalOverlay backdropFilter="blur(10px) hue-rotate(90deg)" />
            <ModalContent style={{ width: '40%', maxHeight: '40%' }}>
                <ModalBody>
                    <div className='container'>
                        <div className="row">
                            <div className="inline-flex" style={{paddingTop : "20px", paddingBottom :"20px"}}>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='lg'
                                />
                            </div>
                            <div className="col-4 text-center" style={{paddingTop : "20px", paddingBottom :"20px"}}>
                                <Text fontSize='lg'>  LOADING ... </Text>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal> );
    }

    const getTechError = () => {
        return (<Modal isOpen={messageIsOpen} onClose={closeMessage}>
            <ModalOverlay backdropFilter="blur(10px) hue-rotate(90deg)" />
            <ModalContent style={{ width: '40%', maxHeight: '40%' }}>
                <ModalBody>
                    <div className='container'>
                        <div className="row">
                        <div className="inline-flex" style={{paddingTop : "20px", paddingBottom :"20px"}}>
                                <Icon as= {WarningTwoIcon} color={"red"} boxSize={"50px"}/>
                            </div>
                            <div className="col-4 text-center" style={{paddingTop : "20px", paddingBottom :"20px"}}>
                                <Text fontSize='lg'>  Internal Server Error </Text>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal> );
    }

    return (
        <>
            {retourLS === undefined? 
                getNull() 
            : retourLS === "erreur technique"?
                getTechError()
            : retourLS.code_Retour === "OK"? 
                getValidModal()
            : retourLS.code_Retour === "KO"?
                getErrorModal()
            :   
                getTechError()
            }
        </>
    );
};

export default MessageLS;
