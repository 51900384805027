import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CaracteristiqueCredit from 'app/modules/CreditStepper/CaracteristiqueCredit/CaracteristiqueCreditStepper';
import PropositionStepper from 'app/modules/CreditStepper/PropositionStep/PropositionStepper';
import { SyntheseStepper } from '../SyntheseStep/SyntheseStepper';
import { ResultStepper } from '../ResultatStep/ResultStepper';
import AssuranceStepper from 'app/modules/CreditStepper/AssuranceStep/AssuranceStepper';
import { IdentificationClient } from '../IdentificationDuTiers/IdentificationClient';
import ModalBody from 'reactstrap/es/ModalBody';
import { ModalFooter, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Modal as Modal2 } from '@chakra-ui/react';
import { BanIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { saveCreditSelectionne } from '../Reducers/syntheseCreditSlice';
import { postSyntheseCredit } from 'app/modules/CreditStepper/Reducers/resultatCreditSlice';
import { saveSynthese } from '../Reducers/simulationCreditSlice';

/* eslint-disable no-console */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    step_label: {
      fontSize: '1.3rem',
    },
  })
);

function getSteps() {
  return ['Identification du tiers', 'Caracteristique du crédit', 'Proposition', 'Assurance', 'Synthese', 'Resultat'];
}

const format = (x: string, suffix: string) => {
  return new Intl.NumberFormat('MA', {
    style: 'currency',
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(parseInt(x));
};

export default function HorizontalLinearStepper() {
  const dispatch = useAppDispatch();
  const syntheseCredit = useAppSelector(state => state.syntheseCredit);
  const resultatCredit = useAppSelector(state => state.resultatSimCredit);
  const ficheClient = useAppSelector(state => state.client.entity);

  const creditSelectionnes = useAppSelector(state => state.creditSelectionne);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: propositionIsOpen, onOpen: propositionOnOpen, onClose: propositionOnClose } = useDisclosure();
  const { isOpen: couvrirMontantIsOpen, onOpen: couvrirMontantOnOpen, onClose: couvrirMontantOnClose } = useDisclosure();
  const { isOpen: validerIsOpen, onOpen: validerOnOpen, onClose: validerOnClose } = useDisclosure();
  const [isValidationProcessed, setValidationProcessed] = useState(false);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = getSteps();
  /* Client State Management */

  /* CarCredit State Management */
  const [typeCredit, setTypeCredit] = React.useState('IMMOBILIER');
  const [typeBien, setTypeBien] = React.useState('');
  /* Besoin financier State Management */
  const [errorCarac, setErrorCarac] = React.useState(false);
  const [errorProposition, setErrorProposition] = React.useState(false);

  const [mntCrdt, setMntCrdt] = React.useState('');
  const [valeurBien, setValeurBien] = React.useState('');
  const [montantFrais, setMontantFrais] = React.useState('');
  const [montantProjet, setMontantProjet] = React.useState('');
  const [apportPersonnel, setApportPersonnel] = React.useState('');
  const [objetCredit, setObjetCredit] = React.useState('');

  /* Produit State Management */
  const [produit, setProduit] = React.useState('');
  const [isFranchise, setFranchise] = React.useState(false);
  const [dureeFranchise, setDureeFranchise] = React.useState(false);
  const [isConventione, setConventione] = React.useState(false);
  const [convention, setConvention] = React.useState('');

  const [prdtArray, setPrdtArray] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    setPrdtArray(syntheseCredit.propositions);
  }, []);
  interface Fiche {
    id?: string;
    nomPrenom?: string;
    email?: string;
    CIN?: string;
    tel?: string;
    adresse?: string;
    revenu?: string;
    echeance?: string;
    autresEch?: string;
    employeur?: string;
    activite?: string;
    paysNaissance?: string;
    paysResidence?: string;
    dateNaissance?: string;
    age?: string;
  }
  const fiche: Fiche = {
    id: '1',
    nomPrenom: 'Youssef XXXX',
    email: 'Youssef@gmail.com',
    tel: '07 95 46 54',
    CIN: 'AD123456',
    adresse: '12 Rue de provence. Résidence la Paix. Appt N°8.Casablanca',
    revenu: '20 000MAD',
    echeance: '3 500MAD',
    autresEch: '1 000MAD',
    employeur: 'Sopra Banking Solution',
    activite: 'Cadre',
    paysNaissance: 'Maroc',
    paysResidence: 'Maroc',
    dateNaissance: '27/08/1990',
    age: '23 ans',
  };

  interface CaracteristiqueCredit {
    errorCarac?: boolean;
    valeurBien?: string;
    montantFrais?: string;
    montantProjet?: string;
    mntCrdt?: string;
    apportPersonnel?: string;
    objetCredit?: string;
    typeCredit?: string;
    typeBien?: string;
  }

  const caracteristiqueCredit: CaracteristiqueCredit = {
    errorCarac: false,
    valeurBien: '500 000Dhs',
    montantFrais: '10 000Dhs',
    montantProjet: '510 000Dhs',
    mntCrdt: '410 000Dhs',
    apportPersonnel: '100 000Dhs',
    objetCredit: 'Acquisition',
    typeCredit: 'Immobilier',
    typeBien: 'Appartement',
  };

  const [client, setClient] = React.useState(fiche);
  const [credit, setCredit] = React.useState(caracteristiqueCredit);

  const clean = (v: any) => {
    v = v.toString();
    v = v.replace(/\s/g, '');
    v = v.replace('dhs', '');
    v = v.replace('MAD', '');

    return v;
  };

  const [maxVal, setMaxVal] = useState(mntCrdt);

  const handleValidationProcess = () => {
    setValidationProcessed(prevValue => !prevValue);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <IdentificationClient client={client} setClient={setClient} />;

      case 1:
        return (
          <CaracteristiqueCredit
            credit={credit}
            setCredit={setCredit}
            errorCarac={errorCarac}
            setErrorCarac={a => setErrorCarac(a)}
            valeurBien={valeurBien}
            setValeurBien={a => setValeurBien(a)}
            montantFrais={montantFrais}
            setMontantFrais={a => setMontantFrais(a)}
            montantProjet={montantProjet}
            setMontantProjet={a => setMontantProjet(a)}
            mntCrdt={mntCrdt}
            setMntCrdt={a => setMntCrdt(a)}
            apportPersonnel={apportPersonnel}
            setApportPersonnel={a => setApportPersonnel(a)}
            objetCredit={objetCredit}
            setObjetCredit={a => setObjetCredit(a)}
            typeCredit={typeCredit}
            setTypeCredit={a => setTypeCredit(a)}
            typeBien={typeBien}
            setTypeBien={a => setTypeBien(a)}
          />
        );

      case 2:
        return (
          <PropositionStepper
            maxVal={maxVal}
            setMaxVal={a => setMaxVal(a)}
            mntCrdt={mntCrdt}
            setMntCrdt={a => setMntCrdt(a)}
            errorProposition={errorProposition}
            setErrorProposition={a => setErrorProposition(a)}
            produit={produit}
            setProduit={a => setProduit(a)}
            isFranchise={isFranchise}
            setIsFranchise={a => setFranchise(a)}
            dureeFranchise={dureeFranchise}
            setDureeFranchise={a => setDureeFranchise(a)}
            isConventione={isConventione}
            setConventione={a => setConventione(a)}
            convention={convention}
            setConvention={a => setConventione(a)}
            prdtArray={prdtArray}
            setPrdtArray={a => setPrdtArray(a)}
          />
        );
      case 3:
        return (
          <AssuranceStepper
            prdtArray={prdtArray}
            setPrdtArray={a => setPrdtArray(a)}
            produit={prdtArray[0]}
            setProduit={a => setProduit(a)}
          />
        );
      case 4:
        return (
          <SyntheseStepper
            client={client}
            setClient={setClient}
            credit={credit}
            setCredit={setCredit}
            prdtArray={prdtArray}
            setPrdtArray={a => setPrdtArray(a)}
            errorCarac={errorCarac}
            setErrorCarac={a => setErrorCarac(a)}
            valeurBien={valeurBien}
            setValeurBien={a => setValeurBien(a)}
            montantFrais={montantFrais}
            setMontantFrais={a => setMontantFrais(a)}
            montantProjet={montantProjet}
            setMontantProjet={a => setMontantProjet(a)}
            mntCrdt={mntCrdt}
            setMntCrdt={a => setMntCrdt(a)}
            apportPersonnel={apportPersonnel}
            setApportPersonnel={a => setApportPersonnel(a)}
            objetCredit={objetCredit}
            setObjetCredit={a => setObjetCredit(a)}
            typeCredit={typeCredit}
            setTypeCredit={a => setTypeCredit(a)}
            typeBien={typeBien}
            setTypeBien={a => setTypeBien(a)}
          />
        );
      case 5:
        return (
          <ResultStepper
            getProduit={undefined}
            onReset={undefined}
            isValidationProcessed={isValidationProcessed}
            onValidationProcess={handleValidationProcess}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const isStepOptional = (step: number) => {
    return false;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      dispatch(saveCreditSelectionne(creditSelectionnes));
    }
    if (activeStep === 4) {
      dispatch(postSyntheseCredit({ ...syntheseCredit, clientId: ficheClient.id }));
    }
    if (activeStep === 1) {
      // TODO : fix this below
      if (syntheseCredit.typeCredit === 'IMMOBILIER' && (!syntheseCredit.mntCrdt || !syntheseCredit.valeurBien)) {
        alert('La "Valeur du bien" et le "Montant du crédit" sont des champs obligatoires');
        setErrorCarac(true);
        return;
      }
      if (syntheseCredit.typeCredit === 'CONSOMATION' && !syntheseCredit.mntCrdt) {
        alert('Le "Montant du crédit" est un champ obligatoire');
        setErrorCarac(true);
        return;
      }

      if (!errorCarac) {
        const format1 = new Intl.NumberFormat('MA', {
          style: 'currency',
          currency: 'MAD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(parseInt(apportPersonnel));
        const format2 = new Intl.NumberFormat('MA', {
          style: 'currency',
          currency: 'MAD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(parseInt(mntCrdt));
        const format3 = new Intl.NumberFormat('MA', {
          style: 'currency',
          currency: 'MAD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(parseInt(montantProjet));
        setApportPersonnel(format1);
        setMntCrdt(format2);
        setMontantProjet(format3);
      }
    }
    if (activeStep === 2) {
      if (!(syntheseCredit.propositions.length > 0)) {
        onOpen();
        return;
      }
      setSum(0);

      syntheseCredit.propositions.forEach(a => setSum(sum + a.montant));

      if (sum < syntheseCredit.mntCrdt) {
        // TODO:a implementer
        couvrirMontantOnOpen();
        return;
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === steps.length - 1) {
      setValidationProcessed(true);
    }
    if (activeStep !== steps.length - 1) setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
      <Modal2 onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody className="d-flex flex-col justify-content-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <BanIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="m-5">
              <div className={'d-flex mt-4 items-center'}>
                <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                  Vous devez choisir au moins un produit pour financer le crédit
                </label>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal2>

      <Modal2 onClose={couvrirMontantOnClose} isOpen={couvrirMontantIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody className="d-flex flex-col justify-content-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <BanIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="m-5">
              <div className={'d-flex mt-4 items-center'}>
                <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                  Le montant du crédit doit étre couvert dans sa totalité <br />{' '}
                  {new Intl.NumberFormat('MA', {
                    style: 'currency',
                    currency: 'MAD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  }).format(syntheseCredit.mntCrdt - sum)}{' '}
                  ne sont pas couvert{' '}
                </label>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal2>

      <Modal2 onClose={propositionOnClose} isOpen={propositionIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody className="d-flex flex-col justify-content-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <BanIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="m-5">
              <div className={'d-flex mt-4 items-center'}>
                <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                  Vous devez financer le montant du crédit en entier
                </label>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal2>

      <Modal2 onClose={validerOnClose} isOpen={validerIsOpen} isCentered size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div>
              {!isSaved ? (
                <div className="m-auto">
                  <CheckCircleIcon className="m-auto w-16 py-11 text-gray-500" />

                  <h1
                    style={{
                      textAlign: 'center',
                      padding: '0',
                      marginTop: '0px',
                      marginBottom: '20px',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    Êtes-vous sur de vouloir enregistrer la simulation ?
                  </h1>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="button" color={'secondary'} variant="outlined" onClick={validerOnClose}>
                      Non
                    </Button>
                    <Button
                      className={'mx-4'}
                      type="button"
                      color={'primary'}
                      variant="outlined"
                      onClick={() => {
                        setIsSaved(true);
                        dispatch(saveSynthese({ synthese: syntheseCredit, resultat: resultatCredit }));
                      }}
                    >
                      Oui
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="m-auto">
                  <CheckCircleIcon className="m-auto w-16 py-11 text-green-500" />

                  <h1
                    style={{
                      textAlign: 'center',
                      padding: '0',
                      marginTop: '0px',
                      marginBottom: '20px',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    Demande enregistrée sous le numéro
                  </h1>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="button" color={'secondary'} variant="outlined" onClick={validerOnClose}>
                      quitter
                    </Button>
                    <Link to="/tableSimulationCredit" style={{ textDecoration: 'none' }}>
                      <Button className={'mx-4'} type="button" color={'primary'} variant="contained">
                        Visualiser la Demande
                      </Button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal2>

      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optionel</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} classes={{ label: classes.step_label }}>
                <Button
                  onClick={() => {
                    setActiveStep(getSteps().findIndex(el => el === label)); // mapper l'array pour trouver l'index
                  }}
                >
                  {label}
                </Button>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div className="">
            {getStepContent(activeStep)}
            <div className="flex pl-52 my-4 d-flex mx-5">
              <div className="w-2/3 invisible"></div>
              {isStepOptional(activeStep) && (
                <Button variant="contained" onClick={handleSkip} className={classes.button}>
                  Passer
                </Button>
              )}
              <div className="w-1/3">
                {activeStep === 0 ? null : (
                  <Button variant="outlined" color="secondary" onClick={handleBack} className={classes.button}>
                    Retour
                  </Button>
                )}

                <Button
                  data-tip
                  data-event={'click'}
                  data-for={'title require'}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
