import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import { NavDropdown } from './menu-components';
import { useAppDispatch, useAppSelector } from 'app/config/store';

const accountMenuItemsAuthenticated = (
  <>
    <MenuItem icon="wrench" to="/account/settings" data-cy="settings">
      Paramètres
    </MenuItem>
    <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
      Mot de passe
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      Se déconnecter
    </MenuItem>
  </>
);

const accountMenuItems = (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
      Sign in
    </MenuItem>
    <MenuItem icon="user-plus" to="/account/register" data-cy="register">
      Register
    </MenuItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false }) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  let login = account.login;
  let role = account.authorities.filter(value => {
    return value !== 'ROLE_USER';
  });

  return (
    <NavDropdown icon="user" name={`${login}(${role})`} id="account-menu" data-cy="accountMenu">
      {isAuthenticated ? accountMenuItemsAuthenticated : accountMenuItems}
    </NavDropdown>
  );
};

export default AccountMenu;
