import React from 'react';
import { UserGroupIcon } from '@heroicons/react/outline';
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import TextField from '@material-ui/core/TextField';
import FicheClient from 'app/modules/CreditStepper/IdentificationDuTiers/Client/FicheClient';
import ListeCredit from 'app/modules/CreditStepper/IdentificationDuTiers/Client/ListeCredit';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import FicheProspect from './Prospect/FicheProspect';

export const IdentificationClient = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location: { state: { isSimulationSavActivate: boolean } } = useLocation();
  const dispatch = useAppDispatch();
  const ficheClient = useAppSelector(state => state.client.entity);

  return (
    <div>
      {/* <NewClientSearch onOpen={onOpen} onClose={onClose} isOpen={isOpen} /> */}
      <div>
        {ficheClient.typeEntite !== 'PROSPECT' ? (
          <FicheClient expand={true} />
        ) : (
          <FicheProspect client={props.client} setClient={props.setClient} />
        )}

        {ficheClient.typeEntite !== 'PROSPECT' && !location?.state?.isSimulationSavActivate ? <ListeCredit /> : null}
      </div>
    </div>
  );
};
// export const NewClientSearch = props => {
//   return (
//     <>
//       <Modal isOpen={props.isOpen} onClose={props.onClose}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Nouvelle recherche</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <>
//               <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
//                 <UserGroupIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
//               </div>
//               <div className="mt-3 text-center sm:mt-5">
//                 <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-gray-500 sm:mt-px sm:pt-2">
//                   Numéro compte ou CIN
//                 </label>
//                 <div className="mt-1 sm:mt-0 sm:col-span-2">
//                   <TextField required id="Apport_Personnel" InputLabelProps={{ required: false }} type="number" />
//                 </div>
//               </div>
//               <Box className="d-flex justify-center">
//                 <Button colorScheme="teal" mr={3} mt={6} variant="outline" onClick={props.onClose}>
//                   Retour
//                 </Button>
//                 <Button colorScheme="teal" mr={3} mt={6}>
//                   Chercher
//                 </Button>
//               </Box>
//             </>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     </>
//   );
// };
