import React, { useEffect, useRef } from 'react';
import React_2 from 'framer-motion/dist/framer-motion';
// import 'content/scss/BOATailWind';
import { REMBOURSEMENT_PARTIEL, REMBOURSEMENT_TOTAL, REPORT_ECHEANCE } from '../../SimulationEvenementStep/CreditEventsForm';
import { useAppSelector } from 'app/config/store';
import { Box, Select, Stack, HStack, Button, Spinner, IconButton } from '@chakra-ui/react';
import { ArrowRightIcon, DocumentDownloadIcon, EyeIcon } from '@heroicons/react/outline';
import { useReactToPrint } from 'react-to-print';
import { BaremePenaliteModel } from '../../models/enums/Remboursement Partiel/bareme-penalite.model';

/* =========PROPS LIST ======
eventInfo = DTO Object of ONE Credit Event
* */
export const EventDescriptionListPdf = ({ demandeSAV }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const resultatSimulationSAV = useAppSelector(state => state.demandeSAV.entity);

  const format = (x: number, suffix: string) => {
    if (x === null) x = 0;
    return new Intl.NumberFormat('MA', {
      style: 'currency',
      currency: suffix,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(x);
  };

  const bareme = (x: any) => {
    x = Number(x);
    switch (x) {
      case BaremePenaliteModel.SANS_PENALITE:
        return 'Sans pénalité';
      case BaremePenaliteModel.UN_MOIS_INTERET:
        return "1 mois d'intérêt";
      case BaremePenaliteModel.DEUX_MOIS_INTERET:
        return "2 mois d'intérêt";
      case BaremePenaliteModel.TROIS_MOIS_INTERET:
        return "3 mois d'intérêt";
      default:
        return 'Sans pénalité';
    }
  };

  const { savInputSimulationDTO, interest, taxeOnInterest, taxeOnPenalty, amountPenalty } = resultatSimulationSAV;

  switch (savInputSimulationDTO?.eventInfo?.nomEvent) {
    case REMBOURSEMENT_PARTIEL:
      return (
        <div>
          <div className="my-32 ml-32 grid grid-cols-6 gap-4">
            <div className="col-start-1 col-end-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
                <span className=" pb-2 mr-12">Remboursement Partiel</span>
                {/* <IconButton
                      colorScheme="teal"
                      isLoading={false}
                      onClick={handlePrint}
                      icon={<DocumentDownloadIcon className="w-5" />}
                      aria-label="download file"
                    /> */}
              </h3>
            </div>
            {/* <div className="col-end-7 col-span-1">
              <Button rightIcon={<DocumentDownloadIcon className="w-5" />} colorScheme="teal" variant="solid" onClick={handlePrint}>
                Exporter
              </Button>
            </div> */}
          </div>
          <div className="ml-8">
            <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
              <span className="border-b-4 border-teal-600">Synthèse de l'événement </span>
            </h4>
            <dl className="divide-y divide-gray-200">
              {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'événement</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">{savInputSimulationDTO?.eventInfo?.dateEvent}</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {eventInfo['accountNumber']}
                </dd>
              </div> */}

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'evenement</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.dateEvent.toString().substring(0, 10)}
                </dd>
              </div>
              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.accountNumber}
                </dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Barème calcul des pénalités</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {bareme(savInputSimulationDTO?.eventInfo?.penalty)}
                </dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4 ">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Mode de continuation </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.modeContinuation === 1 ? 'Conservation de la durée' : "Conservation de l'échéance"}
                </dd>
              </div>
              <br />
              <br />
            </dl>
            <dl className="divide-y divide-gray-200">
              <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 py-4 ">
                <span className="border-b-4 border-teal-600">Résultat</span>
              </h4>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant du remboursement </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {format(savInputSimulationDTO?.eventInfo?.amountOfEvent, 'MAD')}
                </dd>
              </div>
              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant restant du </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {format(resultatSimulationSAV.capitalRestantDu, 'MAD')}
                </dd>
              </div>

              {/* <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible Avant</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{result.}</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible après </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {262000 - eventInfo['amountOfEvent']} DH
                </dd>
              </div> */}

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Intérêts </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{resultatSimulationSAV?.tegAfterEvent} %</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Tax intérêts </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{format(taxeOnInterest, 'MAD')}</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalité </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{format(amountPenalty, 'MAD')}</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Tax Pénalité</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{format(taxeOnPenalty, 'MAD')}</dd>
              </div>
              {/* 
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital restant dû </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">90 150,00 DH</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalité HT </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">140,00 DH</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur pénalité </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">14,00 DH</dd>
              </div> */}
            </dl>
          </div>
        </div>
      );

    case REMBOURSEMENT_TOTAL:
      return (
        <div>
          <div className="grid grid-cols-6 gap-4">
            <div className="col-start-1 col-end-4">
              {/* <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
                <span className="border-b-4 border-teal-600 pb-2">Remboursement Total</span>
                <IconButton
                      colorScheme="teal"
                      isLoading={false}
                      onClick={handlePrint}
                      icon={<DocumentDownloadIcon className="w-5" />}
                      aria-label="download file"
                    />
              </h3> */}
            </div>
            {/* <div className="col-end-7 col-span-1">
              <Button rightIcon={<DocumentDownloadIcon className="w-5" />} colorScheme="teal" variant="solid" onClick={handlePrint}>
                Exporter
              </Button>
            </div> */}
          </div>
          <div className="mt-8">
            <h4 className="text-sm text-gray-900 text-left ml-1 ">
              <span className="border-b-4 border-teal-600">Synthèse de l'événement 3</span>
            </h4>
            <dl className="divide-y divide-gray-200">
              {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'événement</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">{savInputSimulationDTO?.eventInfo?.dateEvent}</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?['accountNumber']}
                </dd>
              </div> */}

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'evenement</dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.dateEvent.toString().substring(0, 10)}
                </dd>
              </div>
              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement </dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.accountNumber}
                </dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Barème calcul des pénalités</dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {bareme(savInputSimulationDTO?.eventInfo?.penalty)}
                </dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4 ">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Mode de continuation </dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.modeContinuation === 1 ? 'Conservation de la durée' : "Conservation de l'échéance"}
                </dd>
              </div>
            </dl>
            <dl className="divide-y divide-gray-200">
              <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 py-4">
                <span className="border-b-4 border-teal-600">Résultat</span>
              </h4>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant du remboursement Total </dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {format(savInputSimulationDTO?.eventInfo?.amountOfEvent, 'MAD')}
                </dd>
              </div>
              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital restant du </dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {format(resultatSimulationSAV.capitalRestantDu, 'MAD')}
                </dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Intérêts a percevoir</dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">{format(interest, 'MAD')}</dd>
              </div>

              {/* <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible Avant</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{result.}</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible après </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {262000 - savInputSimulationDTO?.eventInfo?['amountOfEvent']} DH
                </dd>
              </div> */}

              {/* <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Intérêts </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2"> {resultatSimulationSAV?.tegAfterEvent} %</dd>
              </div> */}

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur les intérêts a percevoir </dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">{format(taxeOnInterest, 'MAD')}</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalité </dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">{format(amountPenalty, 'MAD')}</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Tax Pénalité</dt>
                <dd className="mt-1 text-xs text-gray-900 mt-0 col-span-2 text-left mx-2">{format(taxeOnPenalty, 'MAD')}</dd>
              </div>
              {/* 
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital restant dû </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">90 150,00 DH</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalité HT </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">140,00 DH</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur pénalité </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">14,00 DH</dd>
              </div> */}
            </dl>
          </div>
        </div>
      );
    case REPORT_ECHEANCE:
      return (
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
              <span className="border-b-4 border-teal-600 pb-2">Report Échéance</span>
            </h3>
          </div>
          <div className="mt-5 ">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="mt-8 ">
                <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
                  <span>Synthèse événement</span>
                </h4>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'événement</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                    {savInputSimulationDTO?.eventInfo?.dateEvent}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Nombre d'échéances reportées</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                    {savInputSimulationDTO?.eventInfo?.nbrEcheanceReport}
                  </dd>
                </div>
                <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 my-4">
                  <span>Resultat</span>
                </h4>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date prochaine échéance</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">10-11-2021</dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant échéance avant</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">2 450,00 DH</dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant des frais </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">1 450,00 DH</dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">TEG </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2"> 4,1 %</dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant intérêt du prêt </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">328 000 DH</dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      );
    case 'Modulation_Echeance':
      return (
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
              <span className="border-b-4 border-teal-600 pb-2">Modulation de l'échéance</span>
            </h3>
          </div>
          <div className="mt-5 ">
            <dl className="sm:divide-y sm:divide-gray-200 ">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'événement</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.dateEvenemnt}
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Nouvelle échéance de crédit</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.nouvelleEcheanceCredit}
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date durée en mois</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">101 Mois</dd>
              </div>
            </dl>
          </div>
        </div>
      );

    default:
      return (
        <div>
          <div className="my-32 ml-32 grid grid-cols-6 gap-4">
            <div className="col-start-1 col-end-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
                <span className="border-b-4 border-teal-600 pb-2 mr-12">Remboursement Partiel</span>
                {/* <IconButton
                      colorScheme="teal"
                      isLoading={false}
                      onClick={handlePrint}
                      icon={<DocumentDownloadIcon className="w-5" />}
                      aria-label="download file"
                    /> */}
              </h3>
            </div>
            {/* <div className="col-end-7 col-span-1">
              <Button rightIcon={<DocumentDownloadIcon className="w-5" />} colorScheme="teal" variant="solid" onClick={handlePrint}>
                Exporter
              </Button>
            </div> */}
          </div>
          <div className="ml-8">
            <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
              <span>Synthèse de l'événement 7 </span>
            </h4>
            <dl className="divide-y divide-gray-200">
              {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'événement</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">{savInputSimulationDTO?.eventInfo?.dateEvent}</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {eventInfo['accountNumber']}
                </dd>
              </div> */}

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'evenement</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.dateEvent.toString().substring(0, 10)}
                </dd>
              </div>
              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.accountNumber}
                </dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Barème calcul des pénalités</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {bareme(savInputSimulationDTO?.eventInfo?.penalty)}
                </dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4 ">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Mode de continuation </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {savInputSimulationDTO?.eventInfo?.modeContinuation === 1 ? 'Conservation de la durée' : "Conservation de l'échéance"}
                </dd>
              </div>
              <br />
              <br />
            </dl>
            <dl className="divide-y divide-gray-200">
              <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 py-4 ">
                <span>Résultat</span>
              </h4>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant du remboursement </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {format(savInputSimulationDTO?.eventInfo?.amountOfEvent, 'MAD')}
                </dd>
              </div>
              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant restant du </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">
                  {format(resultatSimulationSAV.capitalRestantDu, 'MAD')}
                </dd>
              </div>

              {/* <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible Avant</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{result.}</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible après </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">
                  {262000 - eventInfo['amountOfEvent']} DH
                </dd>
              </div> */}

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Intérêts </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{resultatSimulationSAV?.tegAfterEvent} %</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Tax intérêts </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{format(taxeOnInterest, 'MAD')}</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalité </dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{format(amountPenalty, 'MAD')}</dd>
              </div>

              <div className="py-4 py-5 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Tax Pénalité</dt>
                <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 text-left mx-2">{format(taxeOnPenalty, 'MAD')}</dd>
              </div>
              {/* 
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital restant dû </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">90 150,00 DH</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalité HT </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">140,00 DH</dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur pénalité </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">14,00 DH</dd>
              </div> */}
            </dl>
          </div>
        </div>
      );
  }
};
