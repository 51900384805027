/* disable es-lint */
import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellValue, GridColDef } from '@material-ui/data-grid';
import { useDisclosure, Box, Text, IconButton } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { CreditInfo } from 'app/modules/SavStepper/SimulationEvenementStep/CreditInfo';
import { CreditEventsForm } from 'app/modules/SavStepper/SimulationEvenementStep/CreditEventsForm';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { creditEventDelete } from 'app/modules/SavStepper/Reducers/CreditEvents.reducer';
import { XCircleIcon, XIcon, PencilIcon } from '@heroicons/react/solid';
import { makeStyles } from '@material-ui/styles';

const format = (x: number, suffix: string) => {
  if (x === null) x = 0;
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'MAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: 'rgb(242, 245, 247)',
    },
  },
});

const formatDateSynthese = (date: string) => {
  if (date === undefined) return undefined;

  const x = date?.substring(0, 10);

  return x.split('-').reverse().join('/');
};

export const EvenementStepper = ({ selectedCredit, onSelectCredit, getProduit, getTaux }) => {
  const { isOpen: creditInfoIsOpen, onOpen: creditInfoOnOpen, onClose: creditInfoOnClose } = useDisclosure();
  const { isOpen: ajEventIsOpen, onOpen: ajEventOnOpen, onClose: ajEventInfoOnClose } = useDisclosure();
  const [eventObject, setEventObject] = useState({});

  const [selectionModel, setSelectionModel] = useState([]);
  const [updatedEvent, setUpdatedEvent] = useState({});
  const handleEventObjectChange = objectEvent => {
    setEventObject(objectEvent);
  };

  let creditEventsRedux = useAppSelector(state => state.CreditEvents.entities);
  const credits = useAppSelector(state => state.credits.entities);
  const dispatch = useAppDispatch();

  const resetSelectionModel = () => setSelectionModel([]);
  const handleUpdatedEventReset = (obj: Object) => {
    setUpdatedEvent(obj);
  };

  const classes = useStyles();

  const creditClientColumns: GridColDef[] = [
    {
      field: 'loanNumber',
      headerName: 'N° Crédit Evolan',
      headerClassName: 'super-app-theme--header',
      flex: 0.15,
      renderCell: params => {
        return <a onClick={creditInfoOnOpen}>{params.value}</a>;
      },
      sortable: false,
    },
    {
      field: 'productCode',
      headerName: 'Nom du produit',
      headerClassName: 'super-app-theme--header',
      flex: 0.18,
      sortable: false,
    },
    {
      field: 'loanDueAmount',
      headerName: 'Montant échéance',
      renderCell: params => format(Number(params.value), 'MAD'),

      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'loanRate',
      headerName: 'Taux(HT)',
      headerClassName: 'super-app-theme--header',
      renderCell: params => params.value + '%',
      flex: 0.13,
      sortable: false,
    },
    {
      field: 'loanCrd',
      headerName: 'Capital restant dû',
      headerClassName: 'super-app-theme--header',
      renderCell: params => format(Number(params.value), 'MAD'),
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'loanRemainingDuration',
      headerClassName: 'super-app-theme--header',
      headerName: 'Durée restante',
      flex: 0.18,
      sortable: false,
    },
    {
      field: 'loanEndDate',
      headerName: 'Date fin du crédit',
      headerClassName: 'super-app-theme--header',
      renderCell: params => formatLsDate(params.value),
      flex: 0.2,
      sortable: false,
    },
  ];

  const formatLsDate = (date: GridCellValue) => {
    var x = date.toString();
    x = x.substring(0, 4) + '/' + x.substring(4, x.length);
    x = x.substring(0, x.length - 2) + '/' + x.substring(x.length - 2, x.length);
    return x.split('/').reverse().join('/');
  };

  function reverseString(str) {
    if (str === '') return '';
    else return reverseString(str.substr(1)) + str.charAt(0);
  }
  const nCredits = credits;
  let rows2 = useAppSelector(state => state.client.entities);

  // let rows3 =[...rows2];
  // console.log("rows31",rows3);

  // rows3.forEach((v)=> {
  //  v.loanNumber=v.loanNumber + Math.floor(Math.random() * 10);

  // })

  // console.log("rows3",rows3);

  const nameEvent = weirdNameFromjson => {
    switch (weirdNameFromjson) {
      case 'REMBOURSEMENT_TOTAL':
        return 'Remboursement Total';
      case 'REMBOURSEMENT_PARTIEL':
        return 'Remboursement Partiel';
      case 'REPORT_ECH':
        return 'Report Échéance';
      case 'REPROFILAGE':
        return 'Réintégration de la dette';
      default:
        return 'Evénement non géré';
    }
  };

  return (
    <div id="EventStepperRoot">
      {false ? (
        <Box display="flex" alignItems="center" justifyContent="center" padding="20px">
          <Text fontSize="4xl" fontWeight="extrabold" color="teal.500">
            {' '}
            Ce client n'a aucun credit{' '}
          </Text>
        </Box>
      ) : (
        <div>
          <div className="mx-5 d-flex justify-space-between">
            <span className="underline mt-8 mb-3 mx-5 text-xl font-bold text-gray-700">Crédits du client</span>
            <Button
              className="btn ajout-evenement"
              variant="outline"
              colorScheme="blue"
              disabled={selectedCredit.length === 0}
              onClick={ajEventOnOpen}
            >
              {/* {' '} */}
              Ajouter un événement
            </Button>
          </div>
          <div className={classes.root}>
            <DataGrid
              headerHeight={40}
              getRowId={row => row.loanNumber}
              autoPageSize
              autoHeight
              rows={rows2}
              columns={creditClientColumns}
              pageSize={1}
              onSelectionModelChange={ids => {
                const selectedIDs = new Set(ids);
                const extract = rows2.filter(row => selectedIDs.has(row.loanNumber));
                onSelectCredit(extract);
                setSelectionModel(ids);
              }}
              selectionModel={selectionModel}
              disableSelectionOnClick
              checkboxSelection
              hideFooterSelectedRowCount
            />
          </div>
          <CreditInfo isOpen={creditInfoIsOpen} onClose={creditInfoOnClose} />

          <CreditEventsForm
            updatedEvent={updatedEvent}
            onUpdatedEventReset={handleUpdatedEventReset}
            onSelectionReset={resetSelectionModel}
            onSelectCredit={onSelectCredit}
            eventObject={eventObject}
            onEventChange={handleEventObjectChange}
            isOpen={ajEventIsOpen}
            onClose={ajEventInfoOnClose}
            selectedCreditsArray={selectedCredit}
          />

          {creditEventsRedux.length > 0 ? (
            <div className={classes.root}>
              <div className="underline mt-24 mb-3 mx-5 text-xl font-bold text-gray-700">Événements du crédit</div>
              <DataGrid
                autoPageSize
                autoHeight
                headerHeight={40}
                rows={creditEventsRedux}
                columns={[
                  {
                    field: 'nomEvent',
                    headerName: 'Événements',
                    headerClassName: 'super-app-theme--header',
                    valueGetter: params => {
                      return nameEvent(params.row.eventInfo.nomEvent);
                    },
                    flex: 0.2,
                    sortable: false,
                  },
                  {
                    field: 'productId',
                    headerName: 'Nom Produit',
                    headerClassName: 'super-app-theme--header',
                    valueGetter: params => {
                      return params.row.creditsSelectionnes.length > 0 ? params.row.creditsSelectionnes[0].productId : null;
                    },
                    flex: 0.1,
                    sortable: false,
                  },
                  {
                    field: 'loanAcountNumber',
                    headerName: 'Num Prêt',
                    headerClassName: 'super-app-theme--header',
                    valueGetter: params => {
                      return params.row.creditsSelectionnes.length > 0 ? params.row.creditsSelectionnes[0].loanAcountNumber : null;
                    },
                    flex: 0.2,
                    hide: false,
                    sortable: false,
                  },
                  {
                    field: 'dateEvent',
                    headerName: 'Date Événement',
                    headerClassName: 'super-app-theme--header',
                    valueGetter: params => params.row.eventInfo.dateEvent.split('-').reverse().join('/'),
                    flex: 0.2,
                    sortable: false,
                  },
                  {
                    field: '.',
                    headerName: '',
                    headerClassName: 'super-app-theme--header',
                    flex: 0.05,
                    sortable: false,
                    renderCell: params => {
                      return (
                        <div>
                          <IconButton
                            colorScheme="transparent"
                            color="blue"
                            aria-label="modify"
                            onClick={() => {
                              ajEventOnOpen();
                              setUpdatedEvent(params.row);
                              setEventObject(params.row.eventInfo);
                              onSelectCredit([params.row.credit]);
                            }}
                            icon={<PencilIcon className="w-5" />}
                          />
                          <IconButton
                            colorScheme="transparent"
                            color="blue"
                            aria-label="delete"
                            onClick={() => {
                              dispatch(creditEventDelete(Number(params.row.creditsSelectionnes[0].loanAcountNumber)));
                            }}
                            icon={<XIcon className="w-5" />}
                          />
                        </div>
                      );
                    },
                  },
                ]}
                pageSize={1}
                onSelectionModelChange={ids => {}}
                disableSelectionOnClick
                hideFooterSelectedRowCount
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
