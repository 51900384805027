import React from 'react';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Button } from '@chakra-ui/react';
const SavStepperFooter = ({
  activeStep,
  steps,
  classes,
  getStepContent,
  isStepOptional,
  handleBack,
  handleSkip,
  handleNext,
  handleNextBlock,
  handleReset,
  selectedCredit,
  onValidationProcess,
  evenements,
}) => {
  return (
    <div>
      {activeStep === steps.length ? (
        <div>
          <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </div>
      ) : (
        <div className="">
          <div className="pngcontainer1">
            <div>{getStepContent(activeStep)}</div>
            <div>
              <img
                style={{ width: 'auto', height: '250px' }}
                src="https://pbs.twimg.com/profile_images/1229797275876151301/fSVPBcPP_400x400.png"
              />
            </div>
          </div>
          {/* <h1> this is for testing activeStep =>  {activeStep} </h1> */}
          <div
            className="my-4 d-flex mx-5"
            style={{ display: 'flex', justifyContent: activeStep === steps.length - 1 ? 'center' : 'flex-end' }}
          >
            {activeStep === 0 ? null : (
              <Button variant="outline" colorScheme="red" onClick={handleBack} className={`mx-4`}>
                Retour
              </Button>
            )}
            {isStepOptional(activeStep) && (
              <Button colorScheme="blue" onClick={handleSkip} className={classes.button + 'mx-5'}>
                Passer
              </Button>
            )}
            <Button
              colorScheme="blue"
              onClick={
                activeStep === steps.length - 1 ? onValidationProcess : activeStep === steps.length - 2 ? handleNextBlock : handleNext
              }
              className="mx-4"
              // className={classes.button}
              disabled={activeStep >= 1 && evenements.length === 0 ? true : false}
            >
              {activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavStepperFooter;
