import React, { useEffect, useState } from 'react';
import { BannerResultat } from 'app/modules/CreditStepper/ResultatStep/Banner/BannerResultat';
import { PJAccordion } from '../../CreditStepper/ResultatStep/Accordions/PJAccordion';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Spinner, HStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { getEntity } from 'app/entities/demande/demande2.reducer';
import { ResultAccordion2 } from './Accordions/CreditResultAccordion';

export const ResultStepper = props => {
  const [isSaved, setIsSaved] = useState(false);
  const resultats = useAppSelector(state => state.resultatSimCredit);
  const simulations = useAppSelector(state => state.syntheseCredit);

  const [resultat, setResultat] = useState(null);
  const [synthese, setSynthese] = useState(null);

  const demandeId: { id: string } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getEntity(parseInt(demandeId.id)));
  }, []);

  const demande = useAppSelector(state => state.demande2.entity);
  const simulationIds = demande?.credits?.length > 0 ? demande.credits : [];

  useEffect(() => {
    if (demande?.credits?.length > 0) {
      setSynthese(demande.loanInputSimulation);
      setResultat(demande);
    }
  }, [simulationIds]);

  return (
    <div>
      {resultat && synthese ? (
        <BannerResultat simulations={synthese} resultat={resultat} />
      ) : (
        <HStack justify="center" h="50vh">
          <Spinner size="xl" color="teal.600" />
        </HStack>
      )}

      {resultat ? (
        <ResultAccordion2 synthese={synthese} resultat={resultat} index={0} />
      ) : (
        <HStack justify="center" h="50vh">
          <Spinner size="xl" color="teal.600" />
        </HStack>
      )}
      <br />

      {resultat?.credits?.length > 1 ? <ResultAccordion2 synthese={synthese} resultat={resultat} index={1} /> : null}

      <br />
      <PJAccordion resultat={synthese} title={'Pièces Justificatives'} idDemande={demandeId} canAdd={false}/>
    </div>
  );
};
