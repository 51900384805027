import React, { useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { Box, Flex, Button, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react';
import { DocumentDownloadIcon } from '@heroicons/react/outline';
import InfoTile3 from 'app/modules/commonComponents/InfoTiles/InfoTile3';
import { EchancierDataGridDyn } from '../../../commonComponents/Echeancier/EchancierDataGridDyn';
import { useReactToPrint } from 'react-to-print';
import EcheancierPDF from 'app/modules/CreditStepper/ResultatStep/PdfPrints/echeancierPDF';
import { BrandIconPdf } from 'app/shared/layout/header/header-components';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const format = (x: number, suffix: string) => {
  console.log('check montant', x);
  return new Intl.NumberFormat('MA', {
    style: 'currency',
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};

const formatPourcentage = (x: number, suffix: string) => {
  return new Intl.NumberFormat('MA', {
    currency: suffix,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(x);
};

export const ResultAccordion = props => {
  const componentRef = useRef(null);
  const componentRef2 = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
  });
  return (
    <div>
      <Accordion className="mx-6">
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="">
            {' '}
            <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>
            {props.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-100 border-t border-gray-200">
          <Typography className="w-100">
            <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
              <Box as="section">
                <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: '12', md: '8' }}>
                    <Stack mt="6">
                      <Text as="h3" className="-ml-8" fontSize="xl" fontWeight="extrabold">
                        Informations générales 1
                      </Text>
                      <Text pr="6" lineHeight="tall">
                        <ul>
                          <InfoTile3 label="Montant crédit:" value={format(props.simulation.montant, 'MAD')} />
                          <InfoTile3 label="Durée crédit:" value={props.simulation.dureeMois + ' mois'} />
                          {props.simulation.isFranchise ? (
                            <InfoTile3 label="Durée de la Franchise :" value={props.simulation.dureeFranchise + ' mois'} />
                          ) : null}
                          <InfoTile3 label="Taux d’intérêt  :" value={props.simulation.taux + ' %'} />
                          <InfoTile3 label="Périodicité échéance:" value={'Mensuelle'} />
                          <InfoTile3 label="Montant échéance TTC :" value={format(props.resultat?.echeance, 'MAD')} />
                          <InfoTile3 label="Taux TVA  :" value={'10%'} />
                        </ul>
                      </Text>
                    </Stack>

                    <Stack mt="6">
                      <Text as="h3" className="-ml-8" fontSize="xl" fontWeight="extrabold">
                        Coût total du crédit
                      </Text>
                      <Text pr="6" lineHeight="tall">
                        <ul>
                          <InfoTile3 label="TEG: " value={props.resultat && props.resultat.teg + ' %'} />
                          <InfoTile3 label="Intérêts du prêt: " value={props.resultat && format(props.resultat.montantInteret, 'MAD')} />
                          <InfoTile3 label="Frais dossier (HT):" value={props.resultat && format(props.resultat.fraisDossier, 'MAD')} />
                          <InfoTile3
                            label="Coût total assurances:"
                            value={props.resultat && format(props.resultat.coutTotalAssurance, 'MAD')}
                          />
                          <InfoTile3 label="Prime actuarielle:" value={props.resultat && format(props.resultat.primeActuarielle, 'MAD')} />
                          {props.simulation.isFranchise ? (
                            <InfoTile3
                              label="Intérêts dûs à la franchise :"
                              value={props.resultat && format(props.resultat.interetFranchise, 'MAD')}
                            />
                          ) : null}
                        </ul>
                      </Text>
                    </Stack>
                    <Stack mt="6">
                      <Text as="h3" className="-ml-8" fontSize="xl" fontWeight="extrabold">
                        Informations sur les assurances
                      </Text>
                      <Text pr="6" lineHeight="tall">
                        <ul>
                          <InfoTile3
                            label={(props.simulation.typeAssurance === 'MONTANT' ? 'Montant' : 'Taux') + ' de l’assurance:'}
                            value={
                              props.simulation.typeAssurance === 'MONTANT'
                                ? format(props.simulation.valueAssurance, 'MAD')
                                : props.simulation.valueAssurance + '%'
                            }
                          />
                          <InfoTile3 label="Mode de perception:" value={'Perçue Trimestriellement'} />
                        </ul>
                      </Text>
                    </Stack>
                  </SimpleGrid>
                </Box>
                {/*
                 *******************************************************
                 ****************** PDF file content *******************
                 *******************************************************
                 */}
                <div className="hidden">
                  <div ref={componentRef}>
                    <div className="ml-8 mt-8 brandpdf-logo">
                      {' '}
                      <BrandIconPdf />{' '}
                    </div>
                    <div className="flex justify-center ">
                      <Text as="h3" className="ml-32 my-24" fontSize="xl" fontWeight="extrabold">
                        Simulation Crédit:"{props.simulation.nomProduit}"
                      </Text>
                    </div>

                    <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: '12', md: '8' }}>
                        <Stack mt="6">
                          <Text as="h3" className="-ml-8" fontSize="md" fontWeight="extrabold">
                            Informations générales 3
                          </Text>
                          <Text pr="6" lineHeight="tall">
                            <ul>
                              <InfoTile3 label="Montant crédit:" value={format(props.simulation.montant, 'MAD')} />
                              <InfoTile3 label="Durée crédit:" value={props.simulation.dureeMois + ' mois'} />
                              {props.simulation.isFranchise ? (
                                <InfoTile3 label="Durée de la Franchise :" value={props.simulation.dureeFranchise + ' mois'} />
                              ) : null}
                              <InfoTile3 label="Taux d’intérêt  :" value={props.simulation.taux + ' %'} />
                              <InfoTile3 label="Périodicité échéance:" value={'Mensuelle'} />
                              <InfoTile3 label="Montant échéance TTC :" value={format(props.resultat?.echeance, 'MAD')} />
                              <InfoTile3 label="Taux TVA  :" value={'10%'} />
                            </ul>
                          </Text>
                        </Stack>

                        <Stack mt="6">
                          <Text as="h3" className="-ml-8" fontSize="md" fontWeight="extrabold">
                            Coût total du crédit
                          </Text>
                          <Text pr="6" lineHeight="tall">
                            <ul>
                              <InfoTile3 label="TEG: " value={props.resultat && props.resultat.teg + ' %'} />
                              <InfoTile3
                                label="Intérêts du prêt: "
                                value={props.resultat && format(props.resultat.montantInteret, 'MAD')}
                              />
                              <InfoTile3 label="Frais dossier (HT):" value={props.resultat && format(props.resultat.fraisDossier, 'MAD')} />
                              <InfoTile3
                                label="Coût total assurances:"
                                value={props.resultat && format(props.resultat.coutTotalAssurance, 'MAD')}
                              />
                              <InfoTile3
                                label="Prime actuarielle:"
                                value={props.resultat && format(props.resultat.primeActuarielle, 'MAD')}
                              />
                              {props.simulation.isFranchise ? (
                                <InfoTile3
                                  label="Intérêts dûs à la franchise :"
                                  value={props.resultat && format(props.resultat.interetFranchise, 'MAD')}
                                />
                              ) : null}
                            </ul>
                          </Text>
                        </Stack>
                        <Stack mt="6">
                          <Text as="h3" className="-ml-8" fontSize="md" fontWeight="extrabold">
                            Informations sur les assurances
                          </Text>
                          <Text pr="6" lineHeight="tall">
                            <ul>
                              <InfoTile3 label="Montant de l’assurance:" value={format(props.simulation.valueAssurance, 'MAD')} />
                              <InfoTile3 label="Mode de perception:" value={'Perçue Trimestriellement'} />
                            </ul>
                          </Text>
                        </Stack>
                      </SimpleGrid>
                    </Box>
                  </div>{' '}
                </div>
                <Flex>
                  {' '}
                  <Box> </Box> <Spacer />{' '}
                  <Box>
                    {' '}
                    <Button
                      className="justify-self-end"
                      rightIcon={<DocumentDownloadIcon className="w-5" />}
                      colorScheme="blue"
                      variant="outline"
                      onClick={handlePrint}
                    >
                      Exporter
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </div>
          </Typography>
        </AccordionDetails>
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className="">
                {' '}
                <i className="fal fa-file-invoice mx-2"> </i> Echeancier{' '}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="w-100 border-t border-gray-200">
              <Typography className="w-100">
                <EchancierDataGridDyn ta={props.resultat.echeancier} />
              </Typography>
            </AccordionDetails>
            <Flex>
              {' '}
              <Box> </Box> <Spacer />{' '}
              <Box>
                {' '}
                <Button
                  className="justify-self-end mr-4 mb-4"
                  rightIcon={<DocumentDownloadIcon className="w-5" />}
                  colorScheme="blue"
                  variant="outline"
                  onClick={handlePrint2}
                >
                  Exporter TA
                </Button>
              </Box>
            </Flex>
          </Accordion>
        </div>
      </Accordion>
      <div className="hidden">
        <EcheancierPDF ta={props.resultat.echeancier} produit={props.simulation.nomProduit} ref={componentRef2} />
      </div>
    </div>
  );
};
