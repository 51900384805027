import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack } from '@chakra-ui/react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { assigneTask, getEntities, getEntitiesByUserLogin, getTasks } from './taches.reducer';
import { getSession } from 'app/shared/reducers/authentication';
import moment from 'moment';

const nameEvent = name => {
  switch (name) {
    case 'REMBOURSEMENT_TOTAL':
      return 'Remboursement Total';
    case 'REMBOURSEMENT_PARTIEL':
      return 'Remboursement Partiel';
    case 'REPORT_ECH':
      return 'Report Échéance';
    case 'REPROFILAGE':
      return 'Réintégration de la dette';
    default:
      return 'Evénement non géré';
  }
};

const ListeMesTaches = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.authentication.account);
  const tacheList = useAppSelector(state => state.taches2.entities);
  let role = 'none';

  if (user.authorities[0] === 'ROLE_USER' && user.authorities.length > 1) {
    role = user.authorities[1];
  } else if (user.authorities.length > 1) {
    role = user.authorities[0];
  }

  useEffect(() => {
    dispatch(getSession());
    if (role === 'none') {
      // fetch by login
      dispatch(getEntitiesByUserLogin(user.login));
    } else {
      // fetch by role
      dispatch(getTasks(role));
    }
  }, []);

  const updateAssignee = (taskId: string, assignee: string | null) => {
    dispatch(
      assigneTask({
        taskId: taskId,
        owner: assignee,
      })
    );

    if (role === 'none') {
      // fetch by login
      dispatch(getEntitiesByUserLogin(user.login));
    } else {
      // fetch by role
      dispatch(getTasks(role));
    }
  };

  const statutSpan = (status: string) => {
    switch (status) {
      case 'validée':
        return (
          <span
            style={{ marginLeft: '2.5em' }}
            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            {status}
          </span>
        );
      case 'refusée':
        return (
          <span
            style={{ marginLeft: '2.5em' }}
            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
          >
            {status}
          </span>
        );
      case 'annulée':
        return (
          <span
            style={{ marginLeft: '2.5em' }}
            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
          >
            {status}
          </span>
        );
      case 'a decider':
        return (
          <span
            style={{ marginLeft: '2.5em' }}
            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
          >
            {status}
          </span>
        );
      default:
        return (
          <span
            style={{ marginLeft: '2.5em' }}
            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          >
            en attente
          </span>
        );
    }
  };

  const { match } = props;

  return (
    <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
      <Box p={5} shadow="base" borderWidth="3px">
        <Row>
          <Col md="12">
            <div className="container">
              <h3 className="title">Mes Tâches </h3>
              <div className="container">
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-grow-1 bd-highlight">
                    <input className="form-control" id="myInput" type="text" placeholder="Chercher.." />
                  </div>
                  <div className="p-2 bd-highlight">
                    <Link to="/table">
                      <FontAwesomeIcon icon="hourglass" color="light-grey" />
                      <span className="px-2 inline-flex text-xs leading-5 font-bold rounded bg-gray-200 text-gray-800">En cours</span>
                    </Link>
                  </div>
                  <div className="p-2 bd-highlight">
                    <Link to="/table">
                      <FontAwesomeIcon icon="check" color="green" />
                      <span className="px-2 inline-flex text-xs leading-5  rounded bg-green-100 text-green-800">Validée</span>
                    </Link>
                  </div>
                  <div className="p-2 bd-highlight">
                    <Link to="/table">
                      <FontAwesomeIcon icon="times" color="red" />
                      <span className="px-2 inline-flex text-xs leading-5 rounded bg-red-100 text-red-800">Refusé</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="d-flex flex-row justify-content-center">
              <div className="col-md-10">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }} className="col-md-3">
                        Nom de la tâche
                      </th>
                      <th style={{ textAlign: 'center' }} className="col-md-1">
                        Num Demande
                      </th>
                      <th style={{ textAlign: 'center' }} className="col-md-1">
                        Evénement
                      </th>
                      <th style={{ textAlign: 'center' }} className="col-md-1">
                        Sort
                      </th>
                      <th style={{ textAlign: 'center' }} className="col-md-1">
                        Modifiée
                      </th>
                      <th style={{ textAlign: 'center' }} className="col-md-1">
                        Statut
                      </th>
                      <th style={{ textAlign: 'center' }} className="col-md-1">
                        Affectée à{' '}
                      </th>
                      <th style={{ textAlign: 'center' }} className="col-md-1">
                        <br />
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody id="myTable">
                    {tacheList.length > 0 &&
                      tacheList.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <Button
                              as={Link}
                              colorScheme="black"
                              to={row.task.assignee === user.login ? `/decision2/${row.demandeId}/${row.task.id}` : '/tab'}
                              variant="link"
                              disabled={row.task.assignee !== user.login}
                            >
                              {row.task.name}
                            </Button>
                          </td>

                          <td style={{ textAlign: 'center' }}>{row.demandeId}</td>
                          <td style={{ textAlign: 'center' }}>{nameEvent(row.nomEvent)}</td>
                          <td style={{ textAlign: 'center', alignContent: 'left' }}>{statutSpan('a decider')}</td>
                          <td style={{ textAlign: 'center' }}>{moment(row.task.startTime).locale('fr').fromNow()}</td>
                          <td style={{ textAlign: 'center' }}>{row.task.assignee === null ? 'Non Affectée' : 'Affecté pour traitement'}</td>
                          <td style={{ textAlign: 'center' }}>{row.task.assignee}</td>
                          <td style={{ textAlign: 'center' }}>
                            {row.task.assignee === null ? (
                              <Button
                                colorScheme="telegram"
                                variant="link"
                                onClick={() => {
                                  updateAssignee(row.task.id, user.login);
                                }}
                              >
                                traiter
                              </Button>
                            ) : (
                              <Button
                                variant="link"
                                onClick={() => {
                                  updateAssignee(row.task.id, null);
                                }}
                                disabled={row.task.assignee !== user.login}
                              >
                                libérer
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Box>
    </Stack>
  );
};

export default ListeMesTaches;
