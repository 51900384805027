import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'N° crédit Evolan', flex: 0.2 },
  {
    field: 'productId',
    headerName: 'Nom du produit',
    flex: 0.2,
  },
  {
    field: 'amountRepayment',
    headerName: 'Montant échéance',
    flex: 0.2,
  },
  {
    field: 'taux',
    headerName: 'TAUX(HT)',
    flex: 0.15,
  },
  {
    field: 'capitalRestantDu',
    headerName: 'Capital restant dû',
    flex: 0.2,
  },
  {
    field: 'durationRemaining',
    headerName: 'Durée restante',
    flex: 0.2,
  },
  {
    field: 'dateFinCredit',
    headerName: 'Date fin du crédit',
    flex: 0.2,
  },
];

const ListeCredit = ({}) => {
  const dispatch = useAppDispatch();

  let rows = useAppSelector(state => state.client.entities);

  const [selectionModel, setSelectionModel] = React.useState(rows?.map(r => r.id));

  return (
    <Accordion className={'mx-5'}>
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography className="">
          {' '}
          <i className="fal fa-money-check-alt mx-2"> </i>Crédits du client
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="w-100">
        <Typography className="w-100">
          <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0 w-100">
              <div style={{ height: 400, width: '100%' }} className="">
                <DataGrid
                  pagination
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  disableSelectionOnClick
                  checkboxSelection
                  selectionModel={selectionModel}
                  onSelectionModelChange={setSelectionModel}
                />
              </div>{' '}
            </div>
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default ListeCredit;
