import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IDemandeSAV } from 'app/shared/model/demandeSAV.model';
import { CreditEventsModel } from 'app/modules/SavStepper/models/CreditEvents.model';

const initialState: EntityState<IDemandeSAV> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalPages: 0,
  number: 0,
  isFiltered: false,
};

export const filterEntitiesByState = createAsyncThunk(
  'demande/filter_entities_by_state',
  async ({ filter, page }: { filter: string; page: number }) => {
    const requestUrl = `http://localhost:8080/api/demande/filterbyStatut/${filter}?size=12&page=${page}`;
    const { data } = await axios.get(requestUrl);
    return data;
  }
);

export const filterEntities = createAsyncThunk(
  'demande/filter_entities',
  async ({ search, filter, page }: { search: string; filter: string; page: number }) => {
    const requestUrl = `http://localhost:8080/api/demande/filterby${search}/${filter}?size=12&page=${page}`;
    const { data } = await axios.get(requestUrl);
    return data;
  }
);

export const filterEntitiesByNomEvent = createAsyncThunk(
  'demande/filter_entities_by_event',
  async ({ filter, page }: { filter: string; page: number }) => {
    const filterQuery = filter.replace(' ', '_').trim();
    const requestUrl = `http://localhost:8080/api/demande/filterbyNomEvent/${filter}?size=12&page=${page}`;
    const { data } = await axios.get(requestUrl);
    return data;
  }
);

export const advancedFilterEntitiesForSav = createAsyncThunk('demande/advanced_filter_entities_for_sav', async (filter: any) => {
  var pageParameters = 'sort=id,asc&page=0';
  var str = filter;
  var mapObj = {
    typeEvenement: 'nomEvenement.equals',
    montantMax: 'montantCredit_max.lessThanOrEqual',
    montantMin: 'montantCredit_min.greaterThanOrEqual',
    dateMin: 'dateDemande.greaterThanOrEqual',
    dateMax: 'dateDemande.lessThanOrEqual',
    typeCredit: 'typeCredit.equals',
  };
  str = str.replace(/objetCredit|typeEvenement|typeCredit|montantMax|montantMin|dateMin|dateMax/gi, function (matched) {
    return mapObj[matched];
  });
  const requestUrl = `api/demandesSav?${str}&${pageParameters}`;

  const { data } = await axios.get(requestUrl);

  return data;
});

export const advancedFilterEntitiesForSav2 = createAsyncThunk('demande/advanced_filter_entities_for_sav2', async (filter: any) => {
  var str = filter;

  var mapObj = {
    typeEvenement: 'nomEvenement.equals',
    montantMax: 'montantCredit_max.lessThanOrEqual',
    montantMin: 'montantCredit_min.greaterThanOrEqual',
    dateMin: 'dateDemande.greaterThanOrEqual',
    dateMax: 'dateDemande.lessThanOrEqual',
    typeCredit: 'typeCredit.equals',
  };
  str = str.replace(/objetCredit|typeEvenement|typeCredit|montantMax|montantMin|dateMin|dateMax/gi, function (matched) {
    return mapObj[matched];
  });
  const requestUrl = `api/demandesSav?${str}`;

  const { data } = await axios.get(requestUrl);

  return data;
});
const find = '-';
const re = new RegExp(find, 'g');

export const getResult2 = createAsyncThunk(
  'resultats/simulationSAV',
  async (simulation: CreditEventsModel) => {
    const { data } = await axios.post('api/resultats/simuler/eventSav', simulation);
    return data;
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk('demandesSAV/fetch_entity_list', async (page: number) => {
  const requestUrl = `api/demandesSav?page=${page}&size=20&sort=id,asc`;
  const { data } = await axios.get(requestUrl);
  return data;
});

export const saveEntity = createAsyncThunk('demandesSAV/save_entity', async (simulationDemande: any) => {
  const creditSelectionne = simulationDemande.savInputSimulationDTO?.creditsSelectionnes[0];
  const modifCreditSelectionne = [];
  modifCreditSelectionne[0] = { ...creditSelectionne, loanAcountNumber: '2343435353' };
  const modifSavInputSimulationDTO = { ...simulationDemande.savInputSimulationDTO, creditsSelectionnes: modifCreditSelectionne };
  const modifSimulationDemande = { ...simulationDemande, savInputSimulationDTO: modifSavInputSimulationDTO };
  console.log('modifSimulationDemande11', modifSimulationDemande);
  const { data } = await axios.post('api/saveSimulationSav', modifSimulationDemande);
  return data;
});

export const creatEventInLS = createAsyncThunk('demandesSAV/save_entity_LS', async (demandeSAV: any) => {
  const creditSelectionne = demandeSAV.savInputSimulationDTO?.creditsSelectionnes[0];
  const modifCreditSelectionne = [];
  modifCreditSelectionne[0] = { ...creditSelectionne, loanAcountNumber: '2343435353' };
  const modifInfoEvent = {...demandeSAV.savInputSimulationDTO.eventInfo, montantFrais : 30}
  const modifSimulationDemande = { 
    id : demandeSAV.id,
    eventInfo : modifInfoEvent, 
    creditsSelectionnes: modifCreditSelectionne 
  };
  const { data } = await axios.post('api/resultats/create/eventSav', modifSimulationDemande);
  console.log('Création d événement sur LS ........');
  console.log(data);
  console.log('Evénement créé avec succès');
  return data;
});

export const getEntity = createAsyncThunk('demandessav/fetch_entity', async (id: number) => {
  const requestUrl = `api/demandesSav/detail/${id}`;
  return axios.get<IDemandeSAV>(requestUrl);
});

export const getEntityBySimulationId = createAsyncThunk('demandessav/fetch_entity_by_Simulid', async (simulId: string) => {
  const requestUrl = `api/savDemandes/${simulId}`;
  return axios.get<IDemandeSAV>(requestUrl);
});

export const demandeSAVSlice = createSlice({
  name: 'demandeSAV',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.totalPages = totalPages;
        state.number = number;
        state.entities = content;
        state.isFiltered = false;
        state.loading = false;
      })
      .addCase(getEntities.pending, state => {
        state.loading = true;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        console.log('demande', state.entity);
      })
      .addCase(getEntityBySimulationId.fulfilled, (state, action) => {
        state.entity = action.payload.data;
      })
      .addCase(saveEntity.fulfilled, (state, action) => {
        state.entity.id = action.payload;
      })
      .addCase(creatEventInLS.fulfilled, (state, action) => {
      })
      .addCase(filterEntitiesByState.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(filterEntitiesByState.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.entities = content;
        state.totalPages = totalPages;
        state.number = number;
        state.isFiltered = true;
        state.loading = false;
      })
      .addCase(advancedFilterEntitiesForSav.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.totalPages = totalPages;
        state.number = number;
        state.entities = content;
        state.isFiltered = false;
        state.loading = false;
      })
      .addCase(advancedFilterEntitiesForSav2.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;
        state.totalPages = totalPages;
        state.number = number;
        state.entities = content;
        state.isFiltered = false;
        state.loading = false;
      })
      .addCase(advancedFilterEntitiesForSav.pending, state => {
        state.loading = true;
      })
      .addCase(filterEntities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(filterEntities.fulfilled, (state, action) => {
        const { content, totalPages, number } = action.payload;

        state.entities = content;
        state.totalPages = totalPages;
        state.number = number;
        state.isFiltered = true;
        state.loading = false;
      })
      .addCase(getResult2.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getResult2.fulfilled, (state, action) => {
        state.entity = action.payload;
        state.entity['message'] = action.payload.message;
        // TODO : MAKE THIS DYNAMIC
        state.entity.savInputSimulationDTO.clientId = 1;
        state.entity.savInputSimulationDTO.nomClient = "Leigh";
        state.entity.savInputSimulationDTO.prenomClient = "Cringle";
        state.loading = false;
      })
      .addCase(getResult2.rejected, (state, action) => {
        state.entity['message'] = 'ERREUR';
        state.loading = false;
      });
  },
});

export default demandeSAVSlice.reducer;
