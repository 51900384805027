import 'app/modules/random/detail.scss';
import 'react-awesome-lightbox/build/style.css';
import React, { useState } from 'react';
import Modal from 'styled-react-modal';
import { Button as Button2 } from '@material-ui/core';

import Lightbox from 'react-awesome-lightbox';
import Viewer from 'react-viewer';

import PDFViewer from 'pdf-viewer-reactjs';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from '@material-ui/core';
import EchancierDataGrid from 'app/modules/commonComponents/Echeancier/EchancierDataGrid';

import { Box, Stack } from '@chakra-ui/react';
import { Button, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { Modal as Modal2 } from '@chakra-ui/react';
import ModalBody from 'reactstrap/es/ModalBody';
import { Link } from 'react-router-dom';

import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import TextareaAutosize from 'react-textarea-autosize';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FuncUpload } from 'app/modules/PjFilesManagement/funcUpload';
import { UploadService } from 'app/modules/PjFilesManagement/upload-files.service';

const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition : all 0.3s ease-in-out;`;
export const PriseDecision = props => {
  /* const PieceJustificatifList = useAppSelector(state => state.pieceJustificatif.entities); //pour les requete apres

  const PJList = useMemo(() => {
    return PieceJustificatifList;
  }, [PieceJustificatifList]);*/
  const PJList = [
    {
      id: 1,
      emplacement:
        'C:\\Users\\yazhar\\boa\\simFront8\\simulateurfrontend\\src\\main\\webapp\\content\\images\\simulation-credit-immobilier-BMCE.png',
      type: 'IMAGE',
      libele: 'Carte CIN',
      statut: 'PasRecu',
      modifie: '2021-08-01',
      modifiePar: 'Utilisateur 2',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 2,
      emplacement: 'https://www.pedagogie.ac-aix-marseille.fr/upload/docs/application/pdf/2012-06/bulletin_de_salaire.pdf',
      type: 'PDF',
      libele: 'Bulletin Paie',
      statut: 'PasRecu',
      modifie: '2021-08-02',
      modifiePar: 'Utilisateur 1',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
    {
      id: 4,
      emplacement:
        'C:\\Users\\yazhar\\boa\\simFront8\\simulateurfrontend\\src\\main\\webapp\\content\\images\\simulation-credit-immobilier-BMCE.png',
      type: 'IMAGE',
      libele: 'Attestation de salaire',
      statut: 'PasRecu',
      modifie: '2021-08-02',
      modifiePar: 'Utilisateur 1',
      simulation: null,
      simulationId: 1,
      simulationSav: null,
      simulationSavId: null,
    },
  ];

  const com1 =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus fermentum ultrices ultrices. Aenean quis vestibulum lectus, sed vehicula eros. Quisque dapibus sit amet nisl nec placerat. Cras ut dolor elit. Nam a nisl et tellus pulvinar blandit. Donec mi diam.';
  const com2 = com1;
  const [openImgModal, setOpenImgModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [validated, setValidated] = useState(false);
  const [prdtArray, setPrdtArray] = useState(PJList);

  const [PdfUrl, setPdfUrl] = useState(''); // workaround LightBox int
  const [imgUrl, setImageUrl] = useState(''); // workaround LightBox int
  const { isOpen: validateIsOpen, onOpen: validateOnOpen, onClose: validateOnClose } = useDisclosure();
  const { isOpen: validateDecisionIsOpen, onOpen: validateDecisionOnOpen, onClose: validateDecisionOnClose } = useDisclosure();
  const { isOpen: refuseDecisionIsOpen, onOpen: refuseDecisionOnOpen, onClose: refuseDecisionOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();
  const [deletedIndex, setDeletedIndex] = useState(0);
  const [validatedIndex, setValidatedIndex] = useState(0); // workaround LightBox int

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [fileInfos, setFileInfos] = useState([]);
  // const remove2 = (nb: number) => {
  //   const array = [...prdtArray];
  //     array.splice(nb, 1);
  //     setPrdtArray(array);
  //     };
  const confirmDelete = () => {
    const array = [...prdtArray];
    array.splice(deletedIndex, 1);
    setPrdtArray(array);
  };

  const confirmValidate = () => {
    const array = [...prdtArray];
    array[validatedIndex].statut = 'Recu';
    setPrdtArray(array);
  };

  const selectFile = event => {
    setSelectedFiles(event.target.files);
  };

  const upload = () => {
    let currentFile = selectedFiles[0];
    setProgress(0);
    setCurrentFile(currentFile);

    UploadService.upload(currentFile, event => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then(response => {
        setMessage(response.data.message);

        return UploadService.getFiles();
      })
      .then(files => {
        setFileInfos(files.data);
      })
      .catch(() => {
        setProgress(0);
        setMessage('Could not upload the file!');
        setCurrentFile(undefined);
      });
    setSelectedFiles(undefined);
  };

  return (
    <div>
      <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
        <Box p={5} shadow="base" borderWidth="3px">
          <div className="col-6 p-8" style={{ margin: '0 auto' }}>
            <Steps direction="horizontal" current={2}>
              <Step title="En cours de saisie" />
              <Step title="À valider" />
              <Step title="Documents validés" />
              <Step title="Demande clôturée" />
            </Steps>
          </div>

          <div className="col-9 pt-8" style={{ margin: '0 auto' }}>
            {/*<BannerResultat/>*/}
            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>Crédit 1 : Produit IMTILAK SUBV-M1 - IMTAF{' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
                        <span className="border-b-4 border-teal-600 pb-2">Remboursement Partiel</span>
                      </h3>
                    </div>
                    <div className="mt-8 ">
                      <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
                        <span>Synthèse de l'événement</span>
                      </h4>
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'événement</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">23/11/2021</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">069132362629420</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Barème calcul des pénalités</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2"> 2 Mois intérêts</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Mode de continuation </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">Conservation de la durée</dd>
                        </div>

                        <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 py-4">
                          <span>Résultat</span>
                        </h4>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant du RAP </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">10 232DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible Avant</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">262 000 DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Solde disponible après </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">{262000 - 10232} DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Durée restante </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">140 Mois</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant echeance avant </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">2 450,00 DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant echeance apres </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">2 150,00 DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital amorti</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">102 150,00 DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital restant dû </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">90 150,00 DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalité HT </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">140,00 DH</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur pénalité </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">14,00 DH</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="">
                      {' '}
                      <i className="fal fa-file-invoice mx-2"> </i> Echeancier{' '}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="w-100">
                    <Typography className="w-100">
                      <EchancierDataGrid />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>Crédit 1 : Produit IMTILAK COMPLEMENTAIRE{' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                    <div>
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ml-4 ">
                          <span className="border-b-4 border-teal-600 pb-2">Remboursement Total</span>
                        </h3>
                      </div>
                      <div className="mt-8 ">
                        <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 ">
                          <span>Synthèse de l'événement</span>
                        </h4>
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Date de l'evenement</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">22/11/2021</dd>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Numéro du compte de remboursement</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">053721093981069</dd>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Barème calcul des pénalités</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">Sans pénalités</dd>
                          </div>
                          <h4 className="text-base font-extrabold text-gray-900 text-left ml-1 py-4">
                            <span>Résultat</span>
                          </h4>
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant du RAT </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">20 031 DH</dd>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Capital restant dû </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">102 150,00 DH</dd>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Intérêts </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2"> 3,1 %</dd>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur les intérêts à percevoir </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">69 DH</dd>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Montant pénalités </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">420.00 DH</dd>
                          </div>

                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500 text-left mx-2">Taxe sur la pénalité </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-left mx-2">12 DH</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="">
                          {' '}
                          <i className="fal fa-file-invoice mx-2"> </i> Echeancier{' '}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="w-100">
                        <Typography className="w-100">
                          <EchancierDataGrid />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="">
                  {' '}
                  <i className="fal fa-file-user mx-2"> </i>
                  Piècess Justificatives
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100">
                <Typography className="w-100">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                ></th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Document 2
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Statut
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Modifiée le
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Modifiée par
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {prdtArray.map((piece, index) => (
                                <tr key={index}>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    {piece.type === 'IMAGE' ? (
                                      <IconButton
                                        color="primary"
                                        className="mx-2"
                                        onClick={() => {
                                          // setImageUrl(piece.emplacement);
                                          // setOpenImgModal(!openImgModal);
                                        }}
                                      >
                                        <i className="fal fa-images"> </i>
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        color="primary"
                                        className="mx-2"
                                        onClick={() => {
                                          // setPdfUrl(piece.emplacement);
                                          // setOpenPDFModal(!openPDFModal);
                                          // console.group('lol', piece.emplacement, openPDFModal);
                                        }}
                                      >
                                        <i className="fal fa-file-pdf"> </i>
                                      </IconButton>
                                    )}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                      <div className="ml-4">
                                        <div className="text-sm font-medium text-gray-900">{piece.libele}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {validated || piece.statut === 'Recu' ? (
                                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                        Approuvée
                                      </span>
                                    ) : (
                                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                        Non Approuvée
                                      </span>
                                    )}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">{piece.modifie}</div>
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{piece.modifiePar}</td>
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <IconButton
                                      color="primary"
                                      className="mx-2"
                                      onClick={() => {
                                        // editOnOpen();
                                      }}
                                    >
                                      <label htmlFor="actual-btn">
                                        <i className="fal fa-edit"></i>
                                      </label>{' '}
                                      {/* <input type="file" id="actual-btn" onChange={selectFile} hidden /> */}
                                    </IconButton>
                                    <IconButton
                                      color="primary"
                                      className="mx-2"
                                      onClick={() => {
                                        // validateOnOpen();
                                        // setValidatedIndex(index);
                                      }}
                                    >
                                      <i className="fal fa-check "> </i>
                                    </IconButton>

                                    <IconButton
                                      color="primary"
                                      className="mx-2"
                                      onClick={() => {
                                        // deleteOnOpen();
                                        // setDeletedIndex(index);
                                      }}
                                    >
                                      <i className="fal fa-trash-alt "> </i>
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {' '}
                    ddd
                    <div>
                      <button
                        onClick={() => {
                          // setVisible(true);
                        }}
                      >
                        show
                      </button>
                      <Viewer
                        visible={visible}
                        onClose={() => {
                          setVisible(false);
                        }}
                        images={[{ src: 'http://localhost:9000/content/images/test/img.png290', alt: 'aa' }]}
                      />
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* MODALS */}
          {openImgModal ? (
            <Lightbox
              image={imgUrl}
              title={''}
              onClose={() => {
                setOpenImgModal(!openImgModal);
              }}
            >
              {' '}
            </Lightbox>
          ) : null}
          {openPDFModal ? (
            <StyledModal
              isOpen={openPDFModal}
              onBackgroundClick={() => {
                setOpenPDFModal(!openPDFModal);
              }}
              onEscapeKeydown={() => {
                setOpenPDFModal(!openPDFModal);
              }}
            >
              <span>
                <PDFViewer
                  navbarOnTop
                  document={{
                    url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
                  }}
                />
              </span>
            </StyledModal>
          ) : null}

          <Modal2 onClose={validateOnClose} isOpen={validateIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader></ModalHeader>
              <ModalBody className="d-flex flex-col justify-content-center">
                <div className="m-5">
                  <div className={'d-flex mt-4 items-center'}>
                    <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                      Êtes-vous sûr de valider cette Pièces justificative ?
                    </label>

                    <Button
                      colorScheme="teal"
                      onClick={() => {
                        // validateOnClose();
                        // confirmValidate();
                      }}
                    >
                      {' '}
                      Valider
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>

          <Modal2 onClose={editOnClose} isOpen={editIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader></ModalHeader>
              <ModalBody className="d-flex flex-col justify-content-center">
                <div className="m-5">
                  <div className={'d-flex mt-4 items-center'}>
                    <FuncUpload />
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>

          <Modal2 onClose={deleteOnClose} isOpen={deleteIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader></ModalHeader>
              <ModalBody className="d-flex flex-col justify-content-center">
                <div className="m-5">
                  <div className={'d-flex mt-4 items-center'}>
                    <label htmlFor="Apport_Personnel" className="block text-lg font-medium text-black-500 sm:mt-px ">
                      Êtes-vous sûr de supprimer cette Pièces justificative ?
                    </label>

                    <Button
                      colorScheme="teal"
                      onClick={() => {
                        // deleteOnClose();
                        // confirmDelete();
                      }}
                    >
                      {' '}
                      Supprimer
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>

          <Modal2 onClose={validateDecisionOnClose} isOpen={validateDecisionIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Ajouter un commentaire :</ModalHeader>
              <ModalCloseButton />

              <ModalBody className="">
                <div className="">
                  <div className={'d-flex items-center container'}>
                    <div className="row">
                      <div className="col-12">
                        <TextareaAutosize
                          minRows={6}
                          maxRows={6}
                          cols={45}
                          style={{ boxSizing: 'border-box' }}
                          defaultValue="Votre Commentaire Ici ..."
                        />
                      </div>
                      <div className="col-2">
                        <Link to={'/detail'}>
                          <Button
                            colorScheme="teal"
                            onClick={() => {
                              // validateDecisionOnClose();
                            }}
                          >
                            {' '}
                            Valider
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>

          <Modal2 onClose={refuseDecisionOnClose} isOpen={refuseDecisionIsOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Ajouter un commentaire :</ModalHeader>
              <ModalCloseButton />

              <ModalBody className="">
                <div className="">
                  <div className={'d-flex items-center container'}>
                    <div className="row">
                      <div className="col-12">
                        <TextareaAutosize
                          minRows={6}
                          maxRows={6}
                          cols={45}
                          style={{ boxSizing: 'border-box' }}
                          defaultValue="Votre Commentaire Ici ..."
                        />
                      </div>
                      <div className="col-2">
                        <Link to={'/detail'}>
                          <Button
                            colorScheme="teal"
                            onClick={() => {
                              // refuseDecisionOnClose();
                            }}
                          >
                            {' '}
                            Refuser
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal2>
          <div className="container">
            <div className="d-flex flex-row-reverse">
              <Button2
                variant="contained"
                className={'ml-2'}
                color="primary"
                onClick={() => {
                  // validateDecisionOnOpen();
                }}
              >
                Valider
              </Button2>
              <Button2
                variant="contained"
                className={'ml-2'}
                onClick={() => {
                  // refuseDecisionOnOpen();
                }}
              >
                Refuser
              </Button2>
            </div>
            <div className="mt-4">
              <Link to={'/tab'}>
                <Button2 color="secondary">{'<'} Retour</Button2>
              </Link>
            </div>
          </div>
        </Box>
      </Stack>
    </div>
  );
};
