import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import pieceJustificatif from 'app/entities/piece-justificatif/piece-justificatif.reducer';
// prettier-ignore
import client from 'app/entities/client/client.reducer';
// prettier-ignore
import credit from 'app/entities/credit/credit.reducer';
// prettier-ignore
import simulation from 'app/entities/simulation/simulation.reducer';
// prettier-ignore
import simulationSav from 'app/entities/simulation-sav/simulation-sav.reducer';
// prettier-ignore
import RemboursementPartiel from "app/modules/SavStepper/Reducers/RemboursementPartiel.reducer";
// prettier-ignore
import CreditEvents from "app/modules/SavStepper/Reducers/CreditEvents.reducer";

import events from 'app/modules/SavStepper/Reducers/event.reducer';
import demande from 'app/entities/demande/demande.reducer';
import demandeGraphe4 from 'app/entities/demande/demandeGraphe4.reducer';
import demandeGrapheSAV4 from 'app/entities/demande/demandeSAVGraphe4.reducer';
import demandeGrapheSAV3 from 'app/entities/demande/demandeSAVGraphe3.reducer';
import demandeGraphe3 from 'app/entities/demande/demandeGraphe3.reducer';
import demandeGraphe1 from 'app/entities/demande/demandeGraphe1.reducer';
import demandeGraphe2 from 'app/entities/demande/demandeGraphe2.reducer';
import demandeGrapheSAV1 from 'app/entities/demande/demandeSAVGraphe1.reducer';
import demandeGrapheSAV2 from 'app/entities/demande/demandeSAVGraphe2.reducer';
import demande2 from 'app/entities/demande/demande2.reducer';
import demandeSAV from 'app/entities/demande/demandeSAV.reducer';

import credits from 'app/entities/credit/list-credits.reducers';
import simulations from 'app/entities/simulation-sav/simulations.reducer';
import produits from 'app/entities/produit/produit.reducer';
import ficheClient from 'app/modules/CreditStepper/Reducers/ficheClientSlice';
import resultatSimCredit from 'app/modules/CreditStepper/Reducers/resultatCreditSlice';
// import demande from 'app/entities/demande/demande.reducer';

import syntheseCredit from 'app/modules/CreditStepper/Reducers/syntheseCreditSlice';
import filtreCredit from 'app/modules/CreditStepper/Reducers/filtreCreditSlice';
import simulationCredit from 'app/modules/CreditStepper/Reducers/simulationCreditSlice';
import creditSelectionne from 'app/modules/CreditStepper/Reducers/creditSelectionneSlice';
import pj from 'app/entities/pieceJustificative/pj.reducer';
import taches from 'app/entities/taches/taches.reducer';
import taches2 from 'app/entities/taches2/taches.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  pieceJustificatif,
  client, // this one created by jhipster & //
  credit, // this one created by jhipster & //
  simulation, // this one created by the Jhipster to be looked at & delete it later
  simulationSav, // this one created by jhipster & //
  RemboursementPartiel, // this one created by jhipster & //
  CreditEvents,
  events,
  credits,
  simulations,
  produits,
  demande,
  demandeGraphe4,
  demandeGrapheSAV4,
  demandeGraphe3,
  demandeGrapheSAV3,
  demandeGraphe1,
  demandeGraphe2,
  demandeGrapheSAV1,
  demandeGrapheSAV2,
  demande2,
  demandeSAV,
  ficheClient,
  syntheseCredit,
  resultatSimCredit,
  simulationCredit,
  creditSelectionne,
  filtreCredit,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  pj,
  taches,
  taches2,
};

export default rootReducer;
