import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NatureFDossier } from 'app/shared/model/enumerations/nature-frais-dossier.model';
import { TypeAssurance } from 'app/shared/model/enumerations/type-assurance.model';
import { TypeFranchise } from 'app/shared/model/enumerations/type-franchise.model';
import { TypeTaux } from 'app/shared/model/enumerations/type-taux.model';
import { ISyntheseCredit } from 'app/shared/model/syntheseCredit.model';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
//TODO:empty this object
const initialState: ISyntheseCredit = {
  objetCredit: 'Acquisition',
  typeCredit: 'IMMOBILIER',
  typeBien: 'Appartement',
  typeSynthese: 'CREDIT',
  propositions: [
    {
      id: 353,
      nomProduit: 'Imtilak Subventionné',
      montant: 410000,
      taux: 3,
      typeTaux: TypeTaux.FIXE,
      dureeMois: 120,
      dureeFranchise: 0,
      typeFranchise: TypeFranchise.P_TOTAL,
      convention: 'DAMANE SAKANE',
      isFranchise: false,
      isConvention: false,

      typeAssurance: TypeAssurance.MONTANT,
      valueAssurance: 5,
      natureFDossier: NatureFDossier.PERCEVOIR,
      fraisDossier: 200,
      modePerception: 'Mensuel',

      paliers: [
        {
          id: 356,
          typePalier: 1,
          typeFranchise: TypeFranchise.P_PRINCIPAL,
          duree: 120,
          taux: 3,
        },
      ],
    },
  ],
  creditsSelectionnes: [],
};

export const postSyntheseCredit = createAsyncThunk(
  'credit/postSyntheseCredit',
  async (syntheseCredit: ISyntheseCredit) => {
    const requestUrl = `api/demandes`;
    return axios.post(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const syntheseCreditSlice = createSlice({
  name: 'syntheseCredit',
  initialState,
  reducers: {
    editCaracteristiqueCredit: (state, action) => {
      state[action.payload.champ] = action.payload.inputValue;
      if (action.payload.champ === 'typeCredit') {
        state['mntCrdt'] = 0;
        state['valeurBien'] = 0;
        state['montantFrais'] = 0;
        state['errorCarac'] = false;
      } else {
        state[action.payload.champ] = action.payload.inputValue;
      }
    },
    savePropositionCredit: (state, action) => {
      state['propositions'] = action.payload.array;
    },
    propositionConcat: (state, action) => {
      state.propositions = state.propositions.concat(action.payload);
    },
    propositionDelete: (state, action) => {
      state.propositions = state.propositions.filter(proposition => proposition.id !== action.payload);
    },
    setClientForSynthese: (state, action) => {
      state['clientId'] = action.payload.id;
    },
    propositionUpdate: (state, action) => {
      let index = state.propositions.findIndex(entity => entity.id === action.payload.id);
      state.propositions[index] = action.payload;
    },
    propositionAllUpdate: (state, action) => {
      state.propositions = action.payload;
    },
    propositionRest: state => {
      return initialState;
    },
    saveCreditSelectionne: (state, action) => {
      state.creditsSelectionnes = action.payload.creditsSelectionnes;
    },
  },
});

export const {
  propositionRest,
  setClientForSynthese,
  editCaracteristiqueCredit,
  savePropositionCredit,
  propositionConcat,
  propositionDelete,
  propositionUpdate,
  propositionAllUpdate,
  saveCreditSelectionne,
} = syntheseCreditSlice.actions;

export default syntheseCreditSlice.reducer;
