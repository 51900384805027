import { createSlice, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { CreditEventsModel } from 'app/modules/SavStepper/models/CreditEvents.model';
import { EntityState } from 'app/shared/reducers/reducer.utils';

interface EvenementModel {
  // this is a small description to see later what properties can be added
  id?: number;
  label?: string;
  title?: string;
  eventInfo?: any;
}

// the intialisation should be made one the app is rendered and the data should be retrived from the backend
const initialState: EntityState<EvenementModel> = {
  loading: false,
  errorMessage: null,
  entities: [
    {
      id: 1,
      title: 'REPROFILAGE',
      label: 'Réintégration de la dette',
    },
    {
      id: 2,
      title: 'REMBOURSEMENT_TOTAL',
      label: 'Remboursement Total',
    },
    {
      id: 3,
      title: 'REPORT_ECH',
      label: "Report de l'échéance",
    },
  ],
  entity: {},
  updating: false,
  updateSuccess: false,
};

export const EvenementsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    intializeEvenemets: (state, action: PayloadAction<EvenementModel>) => {
      state.loading = true;
      state.entities = state.entities.concat(action.payload);
      state.loading = false;
    },
  },
});

export const { intializeEvenemets } = EvenementsSlice.actions;

// Reducer
export default EvenementsSlice.reducer;
