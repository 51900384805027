import React, { useEffect, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';

import { EchancierDataGridDyn } from '../../../commonComponents/Echeancier/EchancierDataGridDyn';
import { EventDescriptionList } from 'app/modules/SavStepper/ResultatSAVStep/Accordions/EventDescriptionList';
import { EventDescriptionListPdf } from 'app/modules/SavStepper/ResultatSAVStep/PdfPrints/EventDescriptionListPdf';
import { useAppSelector } from 'app/config/store';
import { DocumentDownloadIcon } from '@heroicons/react/outline';
import { Box, Flex, Button, Spacer } from '@chakra-ui/react';

import { useReactToPrint } from 'react-to-print';
import EcheancierSavPDF from 'app/modules/SavStepper/ResultatSAVStep/PdfPrints/echeancierSavPDF';
import { BrandIconPdf } from 'app/shared/layout/header/header-components';
import { getEntity } from 'app/entities/demande/demandeSAV.reducer';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

export const ResultAccordionSAV = props => {
  const demandeSAV = useAppSelector(state => state.demandeSAV.entity);
  const componentRef = useRef(null);
  const componentRef2 = useRef(null);
  const idDemandeSav = props.idDemandeSav;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEntity(parseInt(idDemandeSav, 10))); // get synthese simulation sav
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
  });
  const nameEvent = weirdNameFromjson => {
    switch (weirdNameFromjson) {
      case 'REMBOURSEMENT_TOTAL':
        return 'Remboursement Total';
      case 'REMBOURSEMENT_PARTIEL':
        return 'Remboursement Partiel';
      case 'REPORT_ECH':
        return 'Report Échéance';
      case 'REPROFILAGE':
        return 'Réintégration de la dette';
      default:
        return 'Evénement non géré';
    }
  };
  return (
    <div>
      <Accordion className="mx-6">
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="">
            {' '}
            {/* TODO: This icon to be changed later on */}
            <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>
            <span className="text pb-2">{nameEvent(demandeSAV.savInputSimulationDTO?.eventInfo?.nomEvent)}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-100">
          <Typography className="w-100">
            <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
              <div className="my-4">
                <EventDescriptionList demandeSAV={demandeSAV} />
              </div>
              <div className="hidden">
                <div className="my-4" ref={componentRef}>
                  <div className="ml-8 mt-8 brandpdf-logo">
                    {' '}
                    <BrandIconPdf />{' '}
                  </div>
                  <EventDescriptionListPdf demandeSAV={demandeSAV} />
                </div>
              </div>
              <Flex>
                {' '}
                <Box> </Box> <Spacer />{' '}
                <Box>
                  {' '}
                  <Button
                    isDisabled
                    className="justify-self-end"
                    rightIcon={<DocumentDownloadIcon className="w-5" />}
                    colorScheme="blue"
                    variant="outline"
                    onClick={handlePrint}
                  >
                    Exporter
                  </Button>
                </Box>
              </Flex>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* {demandeSAV?.echeancier?.length > 0 && (
        <Accordion className="mx-6">
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="">
              {' '}
              <i className="fal fa-file-invoice mx-2"> </i> Échéancier 2{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="w-100">
            <Typography className="w-100">
              {demandeSAV?.echeancier?.length > 0 && <EchancierDataGridDyn ta={demandeSAV?.echeancier} />}
            </Typography>
          </AccordionDetails>
          <Flex>
            {' '}
            <Box> </Box> <Spacer />{' '}
            <Box>
              {' '}
              <Button
                className="justify-self-end mr-4 mb-4"
                rightIcon={<DocumentDownloadIcon className="w-5" />}
                colorScheme="blue"
                variant="outline"
                onClick={handlePrint2}
              >
                Exporter TA
              </Button>
            </Box>
          </Flex>
        </Accordion>
      )} */}
      {demandeSAV?.echeancier?.length > 0 && (
        <div className="hidden">
          <EcheancierSavPDF ta={demandeSAV?.echeancier} produit={'RAP'} ref={componentRef2} />
        </div>
      )}
    </div>
  );
};
