import React, { useEffect, useState, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalBody,
  Button,
  Stack,
  IconButton,
  Spinner,
  Text,
  Box,
  Flex,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { ArrowRightIcon, DocumentDownloadIcon, CheckCircleIcon, SaveIcon, XIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

import { getResult2, saveEntity } from 'app/entities/demande/demandeSAV.reducer';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';

import EcheancierSavPDF from 'app/modules/SavStepper/ResultatSAVStep/PdfPrints/echeancierSavPDF';
import { EventDescriptionList } from 'app/modules/SavStepper/ResultatSAVStep/Accordions/EventDescriptionList';
import { BannerDecision } from 'app/modules/SavStepper/ResultatSAVStep/Banner/BannerResultat';
import { EventDescriptionListPdf } from './PdfPrints/EventDescriptionListPdf';
import { startInstance, startProcess } from 'app/entities/testing/process';
import { EchancierDataGridDyn } from 'app/modules/commonComponents/Echeancier/EchancierDataGridDyn';
import EchancierDataGrid from 'app/modules/commonComponents/Echeancier/EchancierDataGrid';
import { BannerResultatSav } from 'app/modules/CreditStepper/ResultatStep/Banner/BannerResultatSav';

export const SAVResultat = ({ onReset, isValidationProcessed, onValidationProcess, getProduit }) => {
  // const login = useAppSelector(state => state.authentication.account.login);
  const userId = useAppSelector(state => state.authentication.account.id);
  const codeAgence = '011';

  const creditEvents = useAppSelector(state => state.CreditEvents.entities);
  const savedDemandeId = useAppSelector(state => state.demandeSAV.entity)?.id;
  const [groupByNP, setGgroupByNP] = useState(_.groupBy(creditEvents, creditEvent => creditEvent.creditsSelectionnes[0].nomProduit)); // grouper les credit par nom Produit
  const [isSaved, setIsSaved] = useState(false);
  const demandeSAV = useAppSelector(state => state.demandeSAV.entity);

  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(0);

  const componentRef = useRef(null);
  const componentRef2 = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentRef2.current,
  });

  useEffect(() => {
    dispatch(getResult2(creditEvents[0]));
  }, []);

  const handleReset = () => {
    onValidationProcess(false);
  };

  const handleNextSlide = () => {
    setCurrent(current === creditEvents.length - 1 ? 0 : current + 1);
  };

  const handlePrevSlide = () => {
    setCurrent(current === 0 ? creditEvents.length - 1 : current - 1);
  };

  const produits = Object.keys(groupByNP);

  const nameEvent = weirdNameFromjson => {
    console.log('weird2' + weirdNameFromjson);

    switch (weirdNameFromjson) {
      case 'REMBOURSEMENT_TOTAL':
        return 'Remboursement Total';
      case 'REMBOURSEMENT_PARTIEL':
        return 'Remboursement Partiel';
      case 'REPORT_ECH':
        return 'Report Échéance';
      case 'REPROFILAGE':
        return 'Réintégration de la dette';
      default:
        return 'Evénement non géré';
    }
  };

  return (
    <div className="col-12 pt-8" style={{ margin: '0 auto' }}>
      {/* <div> */}
      <BannerResultatSav simulations={undefined} demandeSAV={demandeSAV} />
      {/* <Stack direction={['column', 'row']} align="center" justify="space-between" marginX="12">
        <IconButton
          size="sm"
          marginY="2"
          border="none"
          backgroundColor="transparent"
          variant="outline"
          aria-label="left slide"
          color="gray"
          _active={{ bg: 'transparent', boxShadow: 'none', shadow: 'none', color: 'teal' }}
          _hover={{ bg: 'transparent', boxShadow: 'none', shadow: 'none', color: 'teal' }}
          icon={<ChevronLeftIcon className="h-8" />}
          onClick={handlePrevSlide}
        />
        <IconButton
          size="sm"
          border="none"
          marginY="2"
          backgroundColor="transparent"
          variant="outline"
          aria-label="right slide"
          color="gray"
          _hover={{ bg: 'transparent', shadow: 'none', color: 'teal' }}
          _active={{ bg: 'transparent', shadow: 'none', color: 'teal' }}
          icon={<ChevronRightIcon className="h-8" />}
          onClick={handleNextSlide}
        />
      </Stack> */}
      {creditEvents.map((creditEvent, index) => {
        return (
          <>
            {index === current && (
              <>
                <Accordion className="mx-4">
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="">
                      {' '}
                      {/* TODO: This icon to be changed later on */}
                      <i className="fal fa-envelope-open-dollar mx-2 fa-lg"> </i>
                      <span className="pb-2">{nameEvent(demandeSAV.savInputSimulationDTO?.eventInfo?.nomEvent)}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="w-100">
                    <Typography className="w-100">
                      <div className="bg-white  overflow-hidden sm:rounded-lg w-100 ">
                        <div className="my-4">
                          <EventDescriptionList demandeSAV={demandeSAV} />
                        </div>
                        <div className="hidden">
                          <div className="my-4" ref={componentRef}>
                            <EventDescriptionListPdf demandeSAV={demandeSAV} />
                          </div>
                        </div>
                        <Flex>
                          {' '}
                          <Box> </Box> <Spacer />{' '}
                          <Box>
                            {' '}
                            <Button
                              className="justify-self-end"
                              rightIcon={<DocumentDownloadIcon className="w-5" />}
                              colorScheme="blue"
                              variant="outline"
                              onClick={handlePrint}
                            >
                              Exporter
                            </Button>
                          </Box>
                        </Flex>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {
                  <>
                    <br />
                    <Accordion className="mx-4">
                      <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="">
                          {' '}
                          <i className="fal fa-file-invoice mx-2"> </i> Échéancier{' '}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="w-100">
                        <Typography className="w-100">
                          <EchancierDataGrid data={demandeSAV.echeancier} />{' '}
                        </Typography>
                      </AccordionDetails>
                      <Flex>
                        {' '}
                        <Box> </Box> <Spacer />{' '}
                        <Box>
                          {' '}
                          <Button
                            className="justify-self-end mr-4 mb-4"
                            rightIcon={<DocumentDownloadIcon className="w-5" />}
                            colorScheme="blue"
                            variant="outline"
                            onClick={handlePrint2}
                          >
                            Exporter TA
                          </Button>
                        </Box>
                      </Flex>
                    </Accordion>
                  </>
                }

                <div className="hidden">
                  {demandeSAV.savInputSimulationDTO?.creditsSelectionnes.length > 0 && (
                    <EcheancierSavPDF
                      ta={demandeSAV.echeancier}
                      produit={demandeSAV.savInputSimulationDTO.creditsSelectionnes[0].productName}
                      ref={componentRef2}
                    />
                  )}
                </div>
                <Modal isOpen={isValidationProcessed} onClose={handleReset} isCentered size={'xl'}>
                  <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(0deg)" />
                  <ModalContent>
                    <ModalBody>
                      <div>
                        {!isSaved ? (
                          <div className="m-auto">
                            <CheckCircleIcon className="m-auto w-12 py-11 text-gray-500" />

                            <Text textAlign="center" fontWeight="semibold" mb="20px" fontSize="18px">
                              Êtes-vous sûr de vouloir enregistrer la simulation ?
                            </Text>
                            <div className="flex justify-center">
                              <Button
                                rightIcon={<XIcon className="w-4 text-red-500" />}
                                colorScheme="red"
                                variant="outline"
                                onClick={handleReset}
                              >
                                Non
                              </Button>
                              <Button
                                rightIcon={<SaveIcon className="w-4" />}
                                colorScheme="blue"
                                className={'mx-4'}
                                onClick={() => {
                                  setIsSaved(true);
                                  // dispatch(saveEntity({ ... demandeSAV, login }));
                                  dispatch(saveEntity({ ...demandeSAV, userId, codeAgence })).then(response => {
                                    startProcess(response.payload.split('-', 3)[2]);
                                  });
                                }}
                              >
                                Oui
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="m-auto">
                            <CheckCircleIcon className={`m-auto w-12 py-11 text-${savedDemandeId ? 'teal' : 'gray'}-600`} />
                            <Box fontWeight="semibold" mb="20px" fontSize="18px" textAlign="center">
                              {savedDemandeId ? (
                                <Text paddingY="1.5" textAlign="center" backgroundColor="teal.50">
                                  {' '}
                                  Demande enregistrée sous le numéro {savedDemandeId}{' '}
                                </Text>
                              ) : (
                                <Spinner size="lg" thickness="2px" speed=".8s" color="teal.500" />
                              )}
                            </Box>
                            {savedDemandeId && (
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                  rightIcon={<XIcon className="w-4 text-red-500" />}
                                  type="button"
                                  colorScheme="red"
                                  variant="outline"
                                  onClick={handleReset}
                                >
                                  Quitter
                                </Button>
                                <Link to="/tableSAV" style={{ textDecoration: 'none' }}>
                                  <Button
                                    rightIcon={<ArrowRightIcon className="w-4 text-white" />}
                                    type="button"
                                    colorScheme="blue"
                                    marginLeft="3"
                                    outline="none"
                                  >
                                    Visualiser la Demande
                                  </Button>
                                </Link>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                  </ModalContent>
                </Modal>
              </>
            )}
          </>
        );
      })}
    </div>
  );
};
