import { Box, Stack, StackDivider, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useParams } from 'react-router-dom';
import { getEntity } from 'app/entities/demande/demandeSAV.reducer';

export const BannerDecision = ({ data, produits }) => {
  const events = useAppSelector(state => state.events.entities);
  const resultatSimulationSAV = useAppSelector(state => state.demandeSAV.entity);
  const { savInputSimulationDTO, taxeOnInterest, taxeOnPenalty, amountPenalty } = resultatSimulationSAV;
  const demandeId: { id: string } = useParams();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    !isNaN(parseInt(demandeId.id)) ? dispatch(getEntity(parseInt(demandeId.id))) : null;
  }, []);

  const getEventLabel = (nomEvent: string) => {
    const eventSearched = events.find(event => event.title === nomEvent);
    return eventSearched.label;
  };

  const formatEvenementName = (text: string) => {
    text = text
      ?.toLowerCase()
      .split('_')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    return text;
  };

  return (
    <Box as="section" p="6">
      <Box maxW={{ base: 'lg', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Box p="10" rounded="xl" shadow="base">
          <Stack spacing="8" justify="space-between" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>
            <Stack mx="auto" spacing="3">
              <Stack spacing={{ base: '3', md: '6' }} direction={{ base: 'column', md: 'row' }} align={'center'}>
                <Box fontSize="6xl">
                  <DocumentTextIcon className="w-16 text-blue-800" />
                </Box>
                <Stack spacing="1">
                  <Box color={mode('gray.600', 'gray.400')}>
                    <h3 className="font-bold"> </h3>
                    <ul>
                      <li>
                        <span className="font-bold  ml-28 font-medium text-gray-500"> Produit : </span>{' '}
                        <span>
                          {savInputSimulationDTO?.creditsSelectionnes.length > 0
                            ? savInputSimulationDTO.creditsSelectionnes[0].productName
                            : null}
                        </span>
                        <span className=" ml-24 font-bold  font-medium text-gray-500">
                          Événement 1: {formatEvenementName(savInputSimulationDTO?.eventInfo?.nomEvent)}
                        </span>
                        <span></span>
                      </li>
                    </ul>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
