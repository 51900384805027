import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IdentificationClient } from 'app/modules/CreditStepper/IdentificationDuTiers/IdentificationClient';
import { EvenementStepper } from 'app/modules/SavStepper/SimulationEvenementStep/EvenementStepper';
import { SAVResultat } from 'app/modules/SavStepper/ResultatSAVStep/SAVResultatStepper2';
import SavStepperHeader from './SavStepperHeader';
import SavStepperFooter from './SavStepperFooter';
import { useHistory } from 'react-router';
import { getResult2 } from 'app/entities/demande/demandeSAV.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

/* eslint-disable no-console */
const useStyles = makeStyles((theme: Theme) => {
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    step_label: {
      fontSize: '1.3rem',
    },
  });
});

function getSteps() {
  return ['Identification du tiers', 'Simulation des événements', 'Résultat'];
}

export default function SavStepperPage() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedCredit, setSelectedCredit] = useState([]); // to be replaced to its components laster on
  const [isValidationProcessed, setValidationProcessed] = useState(false);
  const creditEventsRedux = useAppSelector(state => state.CreditEvents.entities);
  const produits = useAppSelector(state => state.produits.entities);
  const credits = useAppSelector(state => state.credits.entities);
  const client = useAppSelector(state => state.client.entity);
  const demandeSAV = useAppSelector(state => state.demandeSAV.entity);
  const laoding = useAppSelector(state => state.demandeSAV);

  const [block, setBlock] = useState(false);
  useEffect(() => {
    if (demandeSAV.message !== undefined) {
      setBlock(true);
    }
  });

  useEffect(() => {
    if (block) {
      console.log('useEffectBLock', setActiveStep(1));
    }
  }, [block]);

  const creditEvents = useAppSelector(state => state.CreditEvents.entities);

  const history = useHistory();

  useEffect(() => {
    if (!client?.id) history.push('/');
  }, []);

  const getProduit = (creditId: number) => {
    const produitId = credits.find(credit => credit.id === creditId).produitId;
    return produits.find(produit => produit.id === produitId).nomProduit;
  };

  const getTaux = (creditId: number) => {
    const produitId = credits.find(credit => credit.id === creditId).produitId;
    return produits.find(produit => produit.id === produitId).taux;
  };

  const steps = getSteps();
  const handleSelectCredit = (arg: []) => {
    console.log("credit selected .............");
    console.log(arg)
    // i replace credit selected with a static one cause type of object to post has changed
    // setSelectedCredit(arg);
    setSelectedCredit(
      [{
          "id": "22212106",
          "clientId": 90,
          "productId": "SAKAR",
          "capitalRestantDu": 595275.78,
          "dateFinCredit": null,
          "durationRemaining": "295",
          "amountRepayment": 3167.26,
          "productName": null,
          "loanAcountNumber": "22212106",
          "taux": "03,62",
          "amountOfLoan": 600000,
          "loanInputSimulationId": null
      }]
  );
  };

  const handleResetActiveStep = () => {
    // setActiveStep(1);
  };

  const handleValidationProcess = () => {
    setValidationProcessed(prevValue => !prevValue);
  };

  /*  */
  // ////////////////////////////////////
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <IdentificationClient />;
      case 1:
        return (
          <EvenementStepper getTaux={getTaux} getProduit={getProduit} selectedCredit={selectedCredit} onSelectCredit={handleSelectCredit} />
        );
      case 2:
        return (
          <SAVResultat
            getProduit={getProduit}
            onReset={handleResetActiveStep}
            isValidationProcessed={isValidationProcessed}
            onValidationProcess={handleValidationProcess}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const isStepOptional = (step: number) => {
    return false;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNextFix = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(0);
    setSkipped(newSkipped);
  };

  const handleNextBlock = () => {
    smtj();
  };

  const smtj = () => {
    console.log('RRRR');
    console.log( creditEvents[0]);
    dispatch(getResult2(creditEvents[0]));
    if (!block && !laoding.loading) {
      handleNext();
    } else {
      return;
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleActiveStep = (arg: number) => {
    setActiveStep(arg);
  };

  return (
    <div className={'bg-white shadow overflow-hidden sm:rounded-lg w-100' /* classes.root */}>
      <SavStepperHeader
        getSteps={getSteps}
        classes={classes}
        activeStep={activeStep}
        steps={steps}
        isStepOptional={isStepOptional}
        isStepSkipped={isStepSkipped}
        onActiveStep={handleActiveStep}
      />

      <SavStepperFooter
        onValidationProcess={handleValidationProcess}
        evenements={creditEventsRedux}
        selectedCredit={selectedCredit}
        activeStep={activeStep}
        steps={steps}
        classes={classes}
        getStepContent={getStepContent}
        isStepOptional={isStepOptional}
        handleBack={handleBack}
        handleSkip={handleSkip}
        handleNext={handleNext}
        handleNextBlock={handleNextBlock}
        handleReset={handleReset}
      />
    </div>
  );
}
