import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Box, Stack, Flex, Spacer, Button } from '@chakra-ui/react';
import { Row, Col, Table } from 'reactstrap';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import Tile from 'app/modules/CreditStepper/SyntheseStep/Tile';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Steps, { Step } from 'rc-steps';
import { useDispatch } from 'react-redux';
import { getActiveEntity, getHistoryEntitiesByDemandeId, getHistoryEntities } from './taches.reducer';
import { useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import FicheClient from 'app/modules/CreditStepper/IdentificationDuTiers/Client/FicheClient';
// import { PJAccordion } from '../testing/PJAccordion';
import { PJAccordion } from 'app/modules/CreditStepper/ResultatStep/Accordions/PJAccordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DecisionMaking, { getStatus } from '../testing/decisionMaking';
import BoxSimNb from '../testing/boxSimNb';
import moment from 'moment';
import { getEntityBySimulationId } from '../demande/demandeSAV.reducer';
import { ResultAccordionSAV } from 'app/modules/ListeDemandesSAV/detail/Accordions/SAVResultAccordion';
import { getEntity } from 'app/entities/demande/demandeSAV.reducer';
import EchancierDataGrid from 'app/modules/commonComponents/Echeancier/EchancierDataGrid';
import { DocumentDownloadIcon } from '@heroicons/react/outline';
import HistModal from './HistModal';
import { BannerResultatSav } from 'app/modules/CreditStepper/ResultatStep/Banner/BannerResultatSav';
import { identifyClient } from '../client/client.reducer';
import HistAccordion from './HistAccordion';

const SuiviDemande = (props: RouteComponentProps<{ url: string }>) => {
  const HistTache = useAppSelector(state => state.taches2.history);
  const activeTache = useAppSelector(state => state.taches2.entity);
  const demandeSAV = useAppSelector(state => state.demandeSAV.entity);
  // const demandeStatut = useAppSelector(state => state.demandeSAV.entity)?.statut;

  const [step, setstep] = useState(0);

  const dispatch = useDispatch();
  // get step from url this represent witch tache are we talking about
  const { idDemandeSav }: { idDemandeSav: string } = useParams();

  const demandeId: { id: string } = useParams();


  useEffect(() => {
    dispatch(getSession());
    // dispatch(getEntityBySimulationId(idDemandeSav));
    dispatch(getHistoryEntitiesByDemandeId(idDemandeSav));
    dispatch(getActiveEntity(idDemandeSav));
    dispatch(getEntity(parseInt(idDemandeSav, 10)));
  }, []);

  useEffect(() => {
    getStep();
  }, [activeTache]);

  const getStep = () => {
    if (activeTache !== null) {
      if (activeTache.taskDefinitionKey === 'Activity_valide0' || activeTache.taskDefinitionKey === 'Soumettre_decision') {
        setstep(1);
      } else {
        setstep(2);
      }
    } else {
      setstep(3);
    }
  };

  const getTA = () => {
    return (
      <Accordion className="mx-6">
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="">
            {' '}
            <i className="fal fa-file-invoice mx-2"> </i> Échéancier{' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="w-100">
          <Typography className="w-100">
            <EchancierDataGrid data = {demandeSAV.echeancier}/>{' '}
          </Typography>
        </AccordionDetails>
        <Flex>
          {' '}
          <Box> </Box> <Spacer />{' '}
          <Box>
            {' '}
            <Button
              isDisabled
              className="justify-self-end mr-4 mb-4"
              rightIcon={<DocumentDownloadIcon className="w-5" />}
              colorScheme="blue"
              variant="outline"
              onClick={undefined}
            >
              Exporter TA
            </Button>
          </Box>
        </Flex>
      </Accordion>
    );
  };
  const getTacheEnAttente = () => {
    return (
      <>
        <Accordion disabled={true} className={'mx-6'}>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} style={{ textAlign: 'right' }}>
            <Typography className="">{activeTache.name} /</Typography>
            <span
              style={{ marginLeft: '2.5em' }}
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
            >
              en attente
            </span>
          </AccordionSummary>
          <AccordionDetails className="w-100  border border-gray-200">
            <Typography className="w-100"></Typography>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const gethist = () => {
    return (
      <Box className={'mx-5'}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '15%' }}>Decision</TableCell>
                <TableCell align="right" style={{ width: '25%' }}>
                  commentaire
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Duration
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Modifiée le
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Modifiée par
                </TableCell>
                <TableCell align="right" style={{ width: '15%' }}>
                  Statut
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {HistTache.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" style={{ verticalAlign: 'center' }}>
                    {row.task.name}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {row.comment}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {moment.duration(row.task.durationInMillis, 'milliseconds').days() + 'J '}
                    {moment.duration(row.task.durationInMillis, 'milliseconds').hours() + 'h '}
                    {moment.duration(row.task.durationInMillis, 'milliseconds').minutes() + 'min'}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {moment(row.task.endTime).format('DD/MM/YYYY à hh:mm:ss')}
                  </TableCell>
                  <TableCell align="right" style={{ verticalAlign: 'center' }}>
                    {row.task.assignee}
                  </TableCell>
                  <TableCell align="center" style={{ verticalAlign: 'center' }}>
                    {getStatus(
                      row.task.description === 'validée'
                        ? 1
                        : row.task.description === 'refusée'
                        ? 0
                        : row.task.description === 'a modifier'
                        ? 3
                        : row.task.description === 'annulée'
                        ? 6
                        : 5
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const getContent = () => {
    switch (step) {
      case 0:
        return <>WIP</>;
      case 1:
        return (
          <>
            <Accordion className={'mx-6'}>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                <Typography className="">
                  <i className="fal fa-file-invoice mx-2"> </i>
                  Scoring et Engagement
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100  border border-gray-200">
                <Typography className="w-100">add something</Typography>
              </AccordionDetails>
            </Accordion>
          </>
        );
      case 2:
        return (
          <>
            <Accordion className={'mx-6'}>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                <Typography className="">
                  <i className="fal fa-file-invoice mx-2"> </i>
                  Scoring et Engagement
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100  border border-gray-200">
                <Typography className="w-100">add something</Typography>
              </AccordionDetails>
            </Accordion>
          </>
        );
      case 3:
        return (
          <>
            <Accordion className={'mx-6'}>
              <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                <Typography className="">
                  <i className="fal fa-file-invoice mx-2"> </i>
                  Scoring et Engagement
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="w-100  border border-gray-200">
                <Typography className="w-100">add something</Typography>
              </AccordionDetails>
            </Accordion>
          </>
        );
      default:
        return <>WIP</>;
    }
  };

  return (
    <Stack spacing={8} className={'bg-white shadow overflow-hidden sm:rounded-lg w-100 ' /* classes.root */}>
      <Box p={5} shadow="base" borderWidth="3px">
        <Row>
          <Col md="12">
            <Box>
              <br />
              <BannerResultatSav simulations={undefined} demandeSAV={demandeSAV} />
              <br />
              <FicheClient expand={false} id={1}/>

              <br />
              <ResultAccordionSAV idDemandeSav={idDemandeSav} />

              <br />
              {getTA()}

              <br />
              <PJAccordion title={'Pièces Justificatives'} resultat={undefined} idDemande={idDemandeSav} canAdd={false}/>

              <br />
              {getContent()}

              <br />
              <HistAccordion idDemandeSav={idDemandeSav} />

              <br />
              <HistModal idDemandeSav={idDemandeSav} />
            </Box>
          </Col>
        </Row>
      </Box>
    </Stack>
  );
};

export default SuiviDemande;
